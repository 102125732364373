import React from 'react'
import { Rate, RateProps } from 'antd'

interface Props extends RateProps {
  readonly?: boolean
}

const Stars: React.FC<Props> = ({
  readonly = false,
  allowClear = false,
  allowHalf = true,
  count = 5,
  ...props
}) => (
  <Rate
    count={count}
    allowClear={allowClear}
    allowHalf={allowHalf}
    {...props}
    disabled={readonly}
  />
)

export default Stars
