import * as Yup from 'yup'
import { PhoneTypes } from '../formTypes'

const DriverSchema = Yup.object().shape({
  firstName: Yup.string().required('Name is required'),
  lastName: Yup.string().required('Last name is required'),
  phoneNumber: Yup.string()
    .matches(PhoneTypes?.regex, 'Phone number is not valid')
    .required('Phone number is required'),
  identification: Yup.string().required('Identification is required'),
})

export default DriverSchema
