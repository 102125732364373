/* eslint-disable func-names */
import * as Yup from 'yup'
import { PasswordTypes } from '../formTypes'

const PasswordSchema = Yup.object().shape({
  password: Yup.string()
    .matches(PasswordTypes?.regex, 'Password is invalid')
    .min(PasswordTypes?.minLength, 'passwordMinLength')
    .max(PasswordTypes?.maxLength, 'passwordMaxLength')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .test('passwords-match', 'Passwords must match', function (value) {
      return this.parent.password === value
    })
    .required('Password confirmation is required'),
})

export default PasswordSchema
