import React, { useState } from 'react'

import { ObjectWithoutKey as Data } from '@utils/interfaces/react'

import clsx from 'clsx'

import { TreeSelect as TreeSelectAD, TreeSelectProps } from 'antd'

import { isFunction, isArray, isPlainObject } from 'lodash'
import MaxTagLabel from '@utils/components/MaxTagLabel'

import { Icon } from '@components/common/index'

import { CInputGroup, CInputGroupPrepend, CInputGroupText } from '@coreui/react'

const { SHOW_PARENT, SHOW_CHILD } = TreeSelectAD

type TagCount = number | 'responsive'

interface Props extends TreeSelectProps<any> {
  initialData?: Data[]
  shouldForceLeaf?: (item: any) => boolean
  labelKey?: string
  leafLabelKey?: string
  idKey?: string
  leafIdKey?: string
  parentIdKey?: string
  leafIdPrefix?: string
  maxTags?: number
  showLeaf?: boolean
  onChange?: (values: any[]) => void
  async?: boolean
  error?: string | false
  icon?: string
  responsiveTags?: boolean
  getLeaves: (id: string) => Promise<Data[]>
  getLeafData: (leaf: Data) => Data
  getValues: (values: any[]) => any[]
}

const TreeSelect: React.FC<Props> = ({
  initialData = [],
  shouldForceLeaf,
  labelKey = 'name',
  leafLabelKey,
  idKey = 'id',
  leafIdKey,
  parentIdKey = 'parentId',
  getLeaves,
  leafIdPrefix = 'leaf',
  maxTags = 4,
  showLeaf = false,
  onChange,
  async = true,
  getLeafData = () => ({}),
  getValues,
  error,
  icon,
  responsiveTags = false,
  ...props
}) => {
  const transformedData =
    !!initialData && isArray(initialData)
      ? initialData.map((d) => {
          const isLeaf = !!shouldForceLeaf && isFunction(shouldForceLeaf) && shouldForceLeaf(d)
          return {
            ...d,
            [idKey]: d[idKey],
            isLeaf,
          }
        })
      : []

  const [value, setValue] = useState([])
  const [data, setData] = useState(transformedData)

  const genTreeNodes = (parentId, leaves) =>
    leaves.map((l) => {
      const leafData = getLeafData(l)
      return {
        ...l,
        [idKey]: `${leafIdPrefix}_${l[leafIdKey ?? idKey]}`,
        [labelKey]: l[leafLabelKey ?? labelKey],
        [parentIdKey]: parentId,
        isLeaf: true,
        ...(isPlainObject(leafData) ? leafData : {}),
      }
    })

  const onLoadData = async (dataProps) => {
    if (!dataProps[idKey]) return

    const currentData = [...data]
    const parentId = dataProps[idKey]

    const results = await getLeaves(parentId)
    if (results) {
      setData(currentData.concat(genTreeNodes(parentId, results)))
    }
  }

  const handleChange = (currentValues) => {
    setValue(currentValues)

    const values = getValues(
      data.filter((d) => currentValues.includes(d.isLeaf ? d[leafIdKey ?? idKey] : d[idKey]))
    )
    if (!!onChange && isFunction(onChange)) onChange(values)
  }

  const treeProps = {
    ...props,
    value,
    treeCheckable: true,
    treeNodeFilterProp: labelKey,
    dropdownStyle: {
      maxHeight: 400,
      overflow: 'auto',
    },
    showCheckedStrategy: showLeaf ? SHOW_CHILD : SHOW_PARENT,
    treeDataSimpleMode: { id: idKey, pId: parentIdKey },
    fieldNames: {
      label: labelKey,
      key: idKey,
    },
    treeData: data || [],
    maxTagCount: (responsiveTags ? 'responsive' : maxTags) as TagCount,
    maxTagPlaceholder: (hiddenValues) => <MaxTagLabel hidden={hiddenValues} />,
    loadData: async ? onLoadData : undefined,
    className: clsx(props.className, 'form-control', {
      'is-invalid': !!error,
    }),
  }

  return (
    <CInputGroup className={`${error ? 'invalid' : 'valid'}`}>
      {!!icon && (
        <CInputGroupPrepend>
          <CInputGroupText>
            <Icon name={icon} />
          </CInputGroupText>
        </CInputGroupPrepend>
      )}

      <TreeSelectAD {...treeProps} onChange={handleChange} />
    </CInputGroup>
  )
}

export default TreeSelect
