import React, { useEffect } from 'react'
import { capitalize } from 'lodash'
import { useHistory } from 'react-router-dom'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import translations from '@translations'
import { withRouteParams } from '@utils/helpers'

import { statusEnum } from '@utils/enums/productEnums'

import { getDashboardOffersAction, getDashboardCountAction } from '@actions/offer'

import Col from '@utils/components/ResponsiveColumn'
import { CRow, CCardFooter, CWidgetIcon } from '@coreui/react'

import { Icon, Card, Button } from '@components/common/index'
import RenderPhoneNumber from '@components/RenderPhoneNumber'

import DataTable from '@components/common/Tables/PaginatedTable'
import { routes } from '@/routes'

const OffersDashboard = ({
  userInfo,
  currentFlorist,
  offers,
  count,
  loading,
  getOffers,
  getCount,
}) => {
  const { viewMore, openNegotiations, openNegotiation } = translations.generic
  const { florist } = translations.form

  const history = useHistory()

  const imFlorist = userInfo?.type?.id === 3
  const imOwner = userInfo?.type?.id === 1
  const floristId = imFlorist ? currentFlorist?.id : null
  const type = 'fl'

  useEffect(() => {
    if (imOwner) getOffers()
    if (imFlorist) getCount(floristId)
  }, [userInfo])

  const goToFloristOffers = (id, username) => {
    if (id)
      history.push(withRouteParams(routes.FloristProducts, { type, id }), {
        filter: statusEnum.PENDING,
        username,
      })
  }

  const goToFlorist = (id) => {
    if (id) history.push(withRouteParams(routes.Details, { type, id }))
  }

  const goToProducts = () => history.push(routes.Products, { filter: statusEnum.PENDING })

  return (
    <CRow>
      {imFlorist && !!floristId && !!count && (
        <Col xs={12} lg={6} xl={4}>
          <CWidgetIcon
            text={`${(count > 1 ? openNegotiations : openNegotiation)({ count })}`}
            title={count}
            color="warning"
            iconPadding={false}
            footerSlot={
              <CCardFooter className="card-footer px-3 py-2">
                <Button
                  onClick={goToProducts}
                  text={viewMore}
                  iconPosition="right"
                  color="link"
                  className="font-weight-bold font-xs btn-block text-warning justify-content-between no-dec"
                  icon="cil-arrow-right"
                />
              </CCardFooter>
            }
          >
            <Icon width={24} name="cil-flower" />
          </CWidgetIcon>
        </Col>
      )}

      {imOwner && offers && !!offers?.length && (
        <Col xs={12} xl={[10, 12]} xxl={8}>
          <Card bodyClassName="overflow-hidden">
            <DataTable
              verticalScroll
              loading={loading}
              data={offers}
              fields={['username', 'phone', 'zone', 'count']}
              fixed={['username', 'count']}
              fieldsOrder={{ count: 0 }}
              render={{
                username: {
                  title: florist,
                  render: (_, item) => (
                    <Button onClick={() => goToFlorist(item.id)} text={item.username} link />
                  ),
                },
                phone: {
                  render: (_, item) => <RenderPhoneNumber florist={item} />,
                },
                count: {
                  title: capitalize(openNegotiations().trim()),
                  width: '15%',
                  render: (_, item) => (
                    <Button
                      onClick={() => goToFloristOffers(item.id, item.username)}
                      text={item.count}
                      icon="cil-flower"
                      color="warning"
                      small
                      className="mb-0 h5"
                      tooltip={
                        item.count > 1
                          ? openNegotiations({ count: item.count })
                          : openNegotiation({ count: item.count })
                      }
                    />
                  ),
                },
              }}
            />
          </Card>
        </Col>
      )}
    </CRow>
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.auth.user,
  currentFlorist: state.auth.currentFlorist,
  offers: state.offer.dashboard,
  loading: state.offer.getDashOffersRequesting,
  count: state.offer.count,
})

const mapDispatchToProps = {
  getOffers: getDashboardOffersAction,
  getCount: getDashboardCountAction,
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(OffersDashboard)
