import {
  ENTITY_INIT,
  CREATE_INTERMEDIATE,
  CREATE_FLORIST,
  GET_INTERMEDIATES,
  GET_FLORISTS,
  UPDATE_INTERMEDIATE,
  UPDATE_FLORIST,
  GET_INTERMEDIATE_DETAIL,
  GET_FLORIST_DETAIL,
  GET_USERS_INTERMEDIATE,
  GET_USERS_FLORIST,
  GET_PERMISSIONS_INTERMEDIATES,
  GET_PERMISSIONS_FLORISTS,
  GET_FLORISTS_BY_INTERMEDIATE,
  // GET_ZONE,
} from '../constants/entity'

const entityReducer = (state = ENTITY_INIT, action) => {
  switch (action.type) {
    // CREATE_INTERMEDIATE
    case CREATE_INTERMEDIATE.REQUEST:
      return {
        ...state,
        createIntermediateRequesting: true,
      }

    case CREATE_INTERMEDIATE.SUCCESS:
      return {
        ...state,
        createIntermediateSuccess: true,
        createIntermediateRequesting: false,
        created: action.payload,
      }

    case CREATE_INTERMEDIATE.ERROR:
      return {
        ...state,
        createIntermediateRequesting: false,
        createIntermediateErrorMessage: action.payload,
      }

    case CREATE_INTERMEDIATE.RESET:
      return {
        ...state,
        createIntermediateRequesting: false,
        createIntermediateSuccess: false,
        createIntermediateErrorMessage: '',
      }

    // CREATE_FLORIST
    case CREATE_FLORIST.REQUEST:
      return {
        ...state,
        createFloristRequesting: true,
      }

    case CREATE_FLORIST.SUCCESS:
      return {
        ...state,
        createFloristSuccess: true,
        createFloristRequesting: false,
        created: action.payload,
      }

    case CREATE_FLORIST.ERROR:
      return {
        ...state,
        createFloristRequesting: false,
        createFloristErrorMessage: action.payload,
      }

    case CREATE_FLORIST.RESET:
      return {
        ...state,
        createFloristRequesting: false,
        createFloristSuccess: false,
        createFloristErrorMessage: '',
      }

    // GET_INTERMEDIATES
    case GET_INTERMEDIATES.REQUEST:
      return {
        ...state,
        getIntermediatesRequesting: true,
      }

    case GET_INTERMEDIATES.SUCCESS:
      return {
        ...state,
        getIntermediatesSuccess: true,
        getIntermediatesRequesting: false,
        list: action.payload,
      }

    case GET_INTERMEDIATES.ERROR:
      return {
        ...state,
        getIntermediatesRequesting: false,
        getIntermediatesErrorMessage: action.payload,
      }

    case GET_INTERMEDIATES.RESET:
      return {
        ...state,
        getIntermediatesRequesting: false,
        getIntermediatesSuccess: false,
        getIntermediatesErrorMessage: '',
      }

    // GET_FLORISTS
    case GET_FLORISTS.REQUEST:
      return {
        ...state,
        getFloristsRequesting: true,
      }

    case GET_FLORISTS.SUCCESS:
      return {
        ...state,
        getFloristsSuccess: true,
        getFloristsRequesting: false,
        list: action.payload,
      }

    case GET_FLORISTS.ERROR:
      return {
        ...state,
        getFloristsRequesting: false,
        getFloristsErrorMessage: action.payload,
      }

    case GET_FLORISTS.RESET:
      return {
        ...state,
        getFloristsRequesting: false,
        getFloristsSuccess: false,
        getFloristsErrorMessage: '',
      }

    // GET_INTERMEDIATE_DETAIL
    case GET_INTERMEDIATE_DETAIL.REQUEST:
      return {
        ...state,
        getIntermediateDetailRequesting: true,
      }

    case GET_INTERMEDIATE_DETAIL.SUCCESS:
      return {
        ...state,
        getIntermediateDetailSuccess: true,
        getIntermediateDetailRequesting: false,
        detail: action.payload,
      }

    case GET_INTERMEDIATE_DETAIL.ERROR:
      return {
        ...state,
        getIntermediateDetailRequesting: false,
        getIntermediateDetailErrorMessage: action.payload,
      }

    case GET_INTERMEDIATE_DETAIL.RESET:
      return {
        ...state,
        getIntermediateDetailRequesting: false,
        getIntermediateDetailSuccess: false,
        getIntermediateDetailErrorMessage: '',
      }

    // GET_FLORIST_DETAIL
    case GET_FLORIST_DETAIL.REQUEST:
      return {
        ...state,
        getFloristDetailRequesting: true,
      }

    case GET_FLORIST_DETAIL.SUCCESS:
      return {
        ...state,
        getFloristDetailSuccess: true,
        getFloristDetailRequesting: false,
        detail: action.payload,
      }

    case GET_FLORIST_DETAIL.ERROR:
      return {
        ...state,
        getFloristDetailRequesting: false,
        getFloristDetailErrorMessage: action.payload,
      }

    case GET_FLORIST_DETAIL.RESET:
      return {
        ...state,
        getFloristDetailRequesting: false,
        getFloristDetailSuccess: false,
        getFloristDetailErrorMessage: '',
      }

    // GET_ZONE
    /*     case GET_ZONE.REQUEST:
      return {
        ...state,
        getZonesRequesting: true,
      }

    case GET_ZONE.SUCCESS:
      return {
        ...state,
        getZonesSuccess: true,
        getZonesRequesting: false,
        mainZone: action.payload,
      }

    case GET_ZONE.ERROR:
      return {
        ...state,
        getZonesRequesting: false,
        getZonesErrorMessage: action.payload,
      }

    case GET_ZONE.RESET:
      return {
        ...state,
        getZonesRequesting: false,
        getZonesSuccess: false,
        getZonesErrorMessage: '',
      } */

    // UPDATE_INTERMEDIATE
    case UPDATE_INTERMEDIATE.REQUEST:
      return {
        ...state,
        updateIntermediateRequesting: true,
      }

    case UPDATE_INTERMEDIATE.SUCCESS:
      return {
        ...state,
        updateIntermediateSuccess: true,
        updateIntermediateRequesting: false,
        detail: action.payload,
      }

    case UPDATE_INTERMEDIATE.ERROR:
      return {
        ...state,
        updateIntermediateRequesting: false,
        updateIntermediateErrorMessage: action.payload,
      }

    case UPDATE_INTERMEDIATE.RESET:
      return {
        ...state,
        updateIntermediateRequesting: false,
        updateIntermediateSuccess: false,
        updateIntermediateErrorMessage: '',
      }

    // UPDATE_FLORIST
    case UPDATE_FLORIST.REQUEST:
      return {
        ...state,
        updateFloristRequesting: true,
      }

    case UPDATE_FLORIST.SUCCESS:
      return {
        ...state,
        updateFloristSuccess: true,
        updateFloristRequesting: false,
        detail: action.payload,
      }

    case UPDATE_FLORIST.ERROR:
      return {
        ...state,
        updateFloristRequesting: false,
        updateFloristErrorMessage: action.payload,
      }

    case UPDATE_FLORIST.RESET:
      return {
        ...state,
        updateFloristRequesting: false,
        updateFloristSuccess: false,
        updateFloristErrorMessage: '',
      }

    // GET_USERS_INTERMEDIATE
    case GET_USERS_INTERMEDIATE.REQUEST:
      return {
        ...state,
        getUsersIntermediateRequesting: true,
      }

    case GET_USERS_INTERMEDIATE.SUCCESS:
      return {
        ...state,
        getUsersIntermediateSuccess: true,
        getUsersIntermediateRequesting: false,
        users: action.payload,
      }

    case GET_USERS_INTERMEDIATE.ERROR:
      return {
        ...state,
        getUsersIntermediateRequesting: false,
        getUsersIntermediateErrorMessage: action.payload,
      }

    case GET_USERS_INTERMEDIATE.RESET:
      return {
        ...state,
        getUsersIntermediateRequesting: false,
        getUsersIntermediateSuccess: false,
        getUsersIntermediateErrorMessage: '',
      }

    // GET_USERS_FLORIST
    case GET_USERS_FLORIST.REQUEST:
      return {
        ...state,
        getUsersFloristRequesting: true,
      }

    case GET_USERS_FLORIST.SUCCESS:
      return {
        ...state,
        getUsersFloristSuccess: true,
        getUsersFloristRequesting: false,
        users: action.payload,
      }

    case GET_USERS_FLORIST.ERROR:
      return {
        ...state,
        getUsersFloristRequesting: false,
        getUsersFloristErrorMessage: action.payload,
      }

    case GET_USERS_FLORIST.RESET:
      return {
        ...state,
        getUsersFloristRequesting: false,
        getUsersFloristSuccess: false,
        getUsersFloristErrorMessage: '',
      }

    // GET_PERMISSIONS_INTERMEDIATES
    case GET_PERMISSIONS_INTERMEDIATES.REQUEST:
      return {
        ...state,
        getPermissionsIntermediatesRequesting: true,
      }

    case GET_PERMISSIONS_INTERMEDIATES.SUCCESS:
      return {
        ...state,
        getPermissionsIntermediatesSuccess: true,
        getPermissionsIntermediatesRequesting: false,
        permissions: action.payload,
      }

    case GET_PERMISSIONS_INTERMEDIATES.ERROR:
      return {
        ...state,
        getPermissionsIntermediatesRequesting: false,
        getPermissionsIntermediatesErrorMessage: action.payload,
      }

    case GET_PERMISSIONS_INTERMEDIATES.RESET:
      return {
        ...state,
        getPermissionsIntermediatesRequesting: false,
        getPermissionsIntermediatesSuccess: false,
        getPermissionsIntermediatesErrorMessage: '',
      }

    // GET_PERMISSIONS_FLORISTS
    case GET_PERMISSIONS_FLORISTS.REQUEST:
      return {
        ...state,
        getPermissionsFloristRequesting: true,
      }

    case GET_PERMISSIONS_FLORISTS.SUCCESS:
      return {
        ...state,
        getPermissionsFloristSuccess: true,
        getPermissionsFloristRequesting: false,
        permissions: action.payload,
      }

    case GET_PERMISSIONS_FLORISTS.ERROR:
      return {
        ...state,
        getPermissionsFloristRequesting: false,
        getPermissionsFloristErrorMessage: action.payload,
      }

    case GET_PERMISSIONS_FLORISTS.RESET:
      return {
        ...state,
        getPermissionsFloristRequesting: false,
        getPermissionsFloristSuccess: false,
        getPermissionsFloristErrorMessage: '',
      }

    // GET_FLORISTS_BY_INTERMEDIATE
    case GET_FLORISTS_BY_INTERMEDIATE.REQUEST:
      return {
        ...state,
        getFloristByIntermediateRequesting: true,
      }

    case GET_FLORISTS_BY_INTERMEDIATE.SUCCESS:
      return {
        ...state,
        getFloristByIntermediateSuccess: true,
        getFloristByIntermediateRequesting: false,
        florists: action.payload,
      }

    case GET_FLORISTS_BY_INTERMEDIATE.ERROR:
      return {
        ...state,
        getFloristByIntermediateRequesting: false,
        getFloristByIntermediateErrorMessage: action.payload,
      }

    case GET_FLORISTS_BY_INTERMEDIATE.RESET:
      return {
        ...state,
        getFloristByIntermediateRequesting: false,
        getFloristByIntermediateSuccess: false,
        getFloristByIntermediateErrorMessage: '',
      }

    default:
      return state
  }
}

export default entityReducer
