/* eslint-disable func-names */
import * as Yup from 'yup'
import { EmailTypes, PasswordTypes } from '../formTypes'

const EntityUserSchema = Yup.object().shape({
  email: Yup.string()
    .matches(EmailTypes?.regex, 'Email is invalid')
    .max(EmailTypes?.maxLength, 'emailMaxLength')
    .required('Email is required'),
  passwordRequired: Yup.boolean(),
  password: Yup.string()
    .matches(PasswordTypes?.regex, 'Password is invalid')
    .min(PasswordTypes?.minLength, 'passwordMinLength')
    .max(PasswordTypes?.maxLength, 'passwordMaxLength')
    .when('passwordRequired', {
      is: true,
      then: Yup.string().required('Password is required'),
    }),
  confirmPassword: Yup.string()
    .test('passwords-match', 'Passwords must match', function (value) {
      return this.parent.password === value
    })
    .when('passwordRequired', {
      is: true,
      then: Yup.string().required('Password confirmation is required'),
    }),
  firstName: Yup.string().required('Name is required'),
  lastName: Yup.string().required('Last name is required'),
})

// const EntityUserSchema = UserSchema.concat(PasswordSchema);
export default EntityUserSchema
