import React, { useEffect, useState } from 'react'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { isArray } from 'lodash'

import translations from '@translations'

import { getUserAction } from '@actions/user'

import { Card } from '@components/common/index'

import { CCol, CRow } from '@coreui/react'
import StripedTable from '@components/common/Tables/StripedTable'

const UserDetail = ({ match, details, getUser }) => {
  const { detailsTitle } = translations.generic

  const [entityFields, setEntityFields] = useState({})

  const { id } = match.params

  const initialRequest = () => {
    getUser(id)
  }

  useEffect(() => {
    initialRequest()
  }, [])

  useEffect(() => {
    if (details) {
      setEntityFields(
        Object.keys(details).filter(
          (key) =>
            !['createdAt', 'updatedAt', 'deletedAt', 'intermediateId'].includes(key) &&
            !!details[key] &&
            !isArray(details[key])
        )
      )
    }
  }, [details])

  return (
    <CCol>
      <CRow>
        <CCol col="12" xl={10} className="my-3">
          <Card title={detailsTitle}>
            <StripedTable fields={entityFields} data={details} />
          </Card>
        </CCol>
      </CRow>
    </CCol>
  )
}

const mapStateToProps = (state) => ({
  details: state.user.userDetail,
})

const mapDispatchToProps = {
  getUser: getUserAction,
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(UserDetail)
