import React, { useEffect } from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import { withTranslation, useTranslation, WithTranslation } from 'react-i18next'

import translations from '@translations'
import { setCurrentLanguage } from '@actions/generic'

import { locale as dayjs } from 'dayjs'
import { locale as moment } from 'moment'

import 'dayjs/locale/es'
import 'dayjs/locale/en'

import { CDropdownMenu, CDropdownToggle, CDropdown, CDropdownItem } from '@coreui/react'
import { Icon } from '@components/common/index'
import { Languages } from '@store/constants/generic'

type PropsFromRedux = ConnectedProps<typeof connector>
interface Props extends WithTranslation, PropsFromRedux {}

const LanguageSwitcher: React.FC<Props> = ({ lngs, current, setLanguage }) => {
  const { i18n } = useTranslation()
  const localeKey = i18n.language

  const setLocales = (locale) => {
    moment(locale)
    dayjs(locale)
  }

  useEffect(() => {
    setLanguage(lngs[localeKey])
    setLocales(localeKey)
  }, [])

  const { languageSwitch } = translations.generic

  const switchLanguage = (key, l) => {
    if (i18n.language !== key || current.locale !== key) {
      i18n.changeLanguage(key)
      setLocales(l)
      setLanguage(lngs[key])
    }
  }

  const languages = lngs as Languages

  if (!current) return null

  return (
    <CDropdown>
      <CDropdownToggle color="secondary" size="sm">
        {languageSwitch}
      </CDropdownToggle>

      <CDropdownMenu>
        {Object.entries(languages).map(([key, lng]) => (
          <CDropdownItem
            key={key}
            onClick={() => switchLanguage(key, lng.locale)}
            color={current.locale === key ? 'light' : undefined}
          >
            <div className="mx-1 d-inline-flex align-items-center">
              <Icon name={`cif-${lng.flagIcon}`} className="mr-1" />
              {lng.nativeName}
            </div>
          </CDropdownItem>
        ))}
      </CDropdownMenu>
    </CDropdown>
  )
}

const mapStateToProps = (state) => ({
  lngs: state.generic.languages,
  current: state.generic.currentLanguage,
})

const mapDispatchToProps = {
  setLanguage: setCurrentLanguage,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default compose<React.FC>(withTranslation(), connector)(LanguageSwitcher)
