import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { withTranslation } from 'react-i18next'

import { CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react'
import { Button } from '@components/common'

import translations from '@translations'
import { RootState } from '@store/index'

interface Props extends CModal {
  bodyClassName?: string
  closeModal?: () => void
  onDismiss?: () => void
  closeText?: string
  confirmText?: string
  footerButtons?: React.ComponentType
  hideFooter?: boolean
  shouldClose?: boolean
  hideCancel?: boolean
  footerClassName?: string
  onConfirm?: () => void
}

const Modal: React.FC<Props> = ({
  open = false,
  children,
  title,
  className = '',
  bodyClassName = '',
  onConfirm,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  closeModal = () => {},
  onDismiss,
  closeText,
  confirmText,
  footerButtons: Footer,
  hideFooter = false,
  size,
  style,
  shouldClose = true,
  hideCancel = false,
  footerClassName = '',
}) => {
  const { cancel, confirm: confirmTranslation } = translations.generic
  const sb = useSelector((state: RootState) => state.generic.sidebarShow)

  const [translate, setTranslate] = useState('')

  useEffect(() => {
    setTranslate([true, 'responsive'].includes(sb) ? 'big-transform' : 'small-transform')
  }, [sb])

  return (
    <CModal
      style={style}
      className={`${className} w-100 ${translate}`}
      show={open}
      size={size}
      centered
      onClose={onDismiss}
      closeOnBackdrop={shouldClose}
    >
      <CModalHeader closeButton={shouldClose}>
        <CModalTitle>{title}</CModalTitle>
      </CModalHeader>

      <CModalBody className={`d-flex flex-row align-items-center ${bodyClassName}`}>
        {children}
      </CModalBody>

      {!hideFooter && (
        <CModalFooter className={footerClassName}>
          {Footer ? <Footer /> : <> </>}
          {!!onConfirm && (
            <Button
              className="ml-2"
              color="secondary"
              onClick={onConfirm}
              text={confirmText ?? confirmTranslation}
            />
          )}
          {!hideCancel && (
            <Button
              className="ml-2"
              color="secondary"
              onClick={closeModal}
              text={closeText ?? cancel}
            />
          )}
        </CModalFooter>
      )}
    </CModal>
  )
}

export default withTranslation()(Modal)
