/* eslint-disable func-names */
import * as Yup from 'yup'

const PriceSchema = Yup.object().shape({
  id: Yup.string().required('Variant id is required'),
  initialPrice: Yup.number().nullable().defined('Initial price is required'),
  price: Yup.number()
    .nullable()
    .default(undefined)
    .moreThan(0, 'Price must be greater than 0')
    .required('Price is required')
    .test('different', 'Price should be different from initial price', function (values) {
      const ref = Yup.ref('initialPrice')
      return values !== this.resolve(ref)
    }),
})

export default PriceSchema
