import React, { useEffect, useState } from 'react'

import { compose } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'

import { useHistory } from 'react-router-dom'
import { withRouteParams } from '@utils/helpers'

import translations from '@translations'

import { deleteUserAction } from '@actions/user'
import { getEntityUsersAction } from '@actions/entity'

import PaginatedTable, { Props as TableProps } from '@components/common/Tables/PaginatedTable'
import { TableButtons, TableBadge } from '@utils/components/tableActions'
import { EntityWithId } from '@utils/interfaces/react'
import { routes } from '@/routes'

interface RouteParamsType {
  type?: 'itm' | 'fl'
  entityId?: string
}
type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends Partial<TableProps> {
  entityId: string
  fromDashboard?: boolean
  type: 'itm' | 'fl'
  currentPage: number
  currentId: string
}

type ExtendedProps = Props & WithTranslation & PropsFromRedux

const UsersList: React.FC<ExtendedProps> = ({
  entityId,
  type,
  users,
  currentId,
  fromDashboard = false,
  currentPage,
  onPageChange,
  getUsersIntermediateRequesting,
  getUsersFloristRequesting,
  getUsers,
  deleteUser,
  ...tableProps
}) => {
  const history = useHistory()
  const [userList, setUserList] = useState([])

  const getUsersRequesting = getUsersIntermediateRequesting || getUsersFloristRequesting

  const { detailsTitle, permissionsTitle, deleteTitle } = translations.generic
  const { operators } = translations.form

  const initialRequest = (overrides = {}) => {
    getUsers(type, { id: entityId, page: currentPage, setList: setUserList, ...overrides })
  }

  useEffect(() => {
    initialRequest()
  }, [currentPage])

  const onSearchChange = (queries, isClear) => {
    if (isClear) initialRequest()
    else initialRequest(queries)
  }

  const onUserDeleteClick = (userId) => {
    deleteUser(type, entityId, userId)
    const index = userList.findIndex((u: EntityWithId) => u.id === userId)

    if (index > -1) {
      userList.splice(index, 1)
    }
  }

  const routeParams: RouteParamsType | false = !fromDashboard && { type, entityId }

  const goToDetails = (id) => {
    const route = fromDashboard ? routes.UserDetails : routes.EntityUserDetails
    history.push(withRouteParams(route, { id, ...routeParams }))
  }

  const goToPermissions = ({ typeId, id }) => {
    const route = fromDashboard ? routes.UserPermissions : routes.EntityUserPermissions
    history.push(withRouteParams(route, { id, ...routeParams }), {
      type: typeId,
    })
  }

  const customFields = [
    {
      dataIndex: 'user_actions',
      key: 'user_actions',
      title: '',
      width: `${fromDashboard ? '10' : '15'}%`,
      fixed: true,
      sorter: false,
      render: (_, item) => (
        <TableButtons
          disabled={currentId === item.id}
          tooltip={currentId === item.id && 'This is you'}
          buttons={[
            {
              onClick: () => goToDetails(item.id),
              icon: 'cil-description',
              text: detailsTitle,
            },
            {
              onClick: () => onUserDeleteClick(item.id),
              icon: 'cil-user-unfollow',
              color: 'danger',
              text: deleteTitle,
            },
            {
              onClick: () => goToPermissions(item),
              icon: 'cil-https',
              text: permissionsTitle,
            },
          ]}
        />
      ),
    },
  ]

  return (
    <PaginatedTable
      {...tableProps}
      fixed={['email']}
      loading={getUsersRequesting}
      data={userList || []}
      customFields={customFields}
      fields={['email', 'firstName', 'lastName', 'active']}
      pagination={{ current: currentPage, total: users?.count }}
      onPageChange={onPageChange}
      render={{
        active: {
          render: (_, item) => <TableBadge statusId={item.active} />,
        },
      }}
      onSearchChange={onSearchChange}
      queryFields={users?.queryFields ?? []}
      verticalScroll={!fromDashboard}
      fieldsOrder={{ user_actions: 0, email: 0 }}
      emptyList={operators}
    />
  )
}

const mapStateToProps = (state) => ({
  users: state.entity.users,
  getUsersIntermediateRequesting: state.entity.getUsersIntermediateRequesting,
  getUsersFloristRequesting: state.entity.getUsersFloristRequesting,
})

const mapDispatchToProps = {
  getUsers: getEntityUsersAction,
  deleteUser: deleteUserAction,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default compose<React.FC<Props>>(withTranslation(), connector)(UsersList)
