import React, { useEffect, useState } from 'react'
import useStateRef from 'react-usestateref'

import { connect, ConnectedProps } from 'react-redux'

import translations from '@translations'

import { Formik } from 'formik'
import { partition, map as propertyMap } from 'lodash'

import ProductFilterSchema from '@utils/schemas/product/filterSchema'

import { Select, TreeSelect } from '@components/common/index'

import { getCategoriesAction, getProductLinesAction } from '@actions/data'

import { CForm, CRow } from '@coreui/react'
import { initialValues, formKeys } from './formKeys'

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  onFormChange: (values: any, erros: any) => void
  categoriesOnly?: boolean
}

const ProductLinesSelector: React.FC<Props> = ({
  onFormChange,
  getCategories,
  getProductLines,
  categoriesOnly = true,
  lines,
  categories,
}) => {
  const [_, setCategoriesList, categoriesListRef] = useStateRef([])

  const { categories: categoriesPlaceholder } = translations.form

  useEffect(() => {
    if (categoriesOnly) getCategories()
    else getProductLines()
  }, [])

  const handleExpandProductLine = async (productLineId) => {
    if (productLineId) {
      await getCategories(setCategoriesList, { productLineId })
    }
    return categoriesListRef.current
  }

  const getCategoriesIds = (data) => {
    const categoriesLines = partition(data, 'isCategory')
    const categoriesIds = propertyMap(categoriesLines[0], 'ogId')
    const productLinesIds = propertyMap(categoriesLines[1], 'id')

    return [categoriesIds, productLinesIds]
  }

  return (
    <Formik
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onSubmit={() => {}}
      initialValues={initialValues}
      validationSchema={ProductFilterSchema}
    >
      {({ setFieldValue, setFieldTouched, handleBlur, values, errors, dirty }) => {
        const [initialized, setInitialized] = useState(false)
        useEffect(() => {
          if (dirty) {
            setInitialized(true)
          }
          if (initialized || dirty) {
            onFormChange(values, errors)
          }
        }, [values])

        const changeCategories = async (val) => {
          setFieldValue(formKeys.categories, val)
          setFieldTouched(formKeys.categories, true)
        }

        const changeProductLines = async (val) => {
          setFieldValue(formKeys.productLines, val)
          setFieldTouched(formKeys.productLines, true)
        }

        const changeCategoriesLines = ([catValue, linesValue]: any[]) => {
          changeCategories(catValue)
          changeProductLines(linesValue)
        }

        return (
          <CForm className="w-100">
            <CRow>
              {categoriesOnly ? (
                <Select
                  icon="cil-tags"
                  multiple
                  data={categories}
                  placeholder={categoriesPlaceholder}
                  onChange={changeCategories}
                  onBlur={handleBlur}
                />
              ) : (
                <TreeSelect
                  parentIdKey="productLineId"
                  placeholder={categoriesPlaceholder}
                  onChange={changeCategoriesLines}
                  initialData={lines}
                  getLeaves={handleExpandProductLine}
                  getValues={getCategoriesIds}
                  onBlur={handleBlur}
                  shouldForceLeaf={(pl) => !pl.count}
                  leafIdPrefix={formKeys.categories}
                  getLeafData={(c) => ({ isCategory: true, ogId: c.id })}
                  icon="cil-tags"
                  maxTags={2}
                />
              )}
            </CRow>
          </CForm>
        )
      }}
    </Formik>
  )
}

const mapStateToProps = (state) => ({
  lines: state.data.lines,
  categories: state.data.categories,
})

const mapDispatchToProps = {
  getProductLines: getProductLinesAction,
  getCategories: getCategoriesAction,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(ProductLinesSelector)
