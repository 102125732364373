/* eslint-disable func-names */
import * as Yup from 'yup'

const isLongitude = (num) => Number.isFinite(num) && Math.abs(num) <= 180
const isLatitude = (num) => Number.isFinite(num) && Math.abs(num) <= 90

Yup.addMethod(Yup.number, 'isLatitude', function (errorMessage) {
  return this.test(`test-latitude`, errorMessage, function (num) {
    const { path, createError } = this

    return isLatitude(num) || createError({ path, message: errorMessage })
  })
})

Yup.addMethod(Yup.number, 'isLongitude', function (errorMessage) {
  return this.test(`test-longitude`, errorMessage, function (num) {
    const { path, createError } = this

    return isLongitude(num) || createError({ path, message: errorMessage })
  })
})

const ExtraPolygonDataSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
})

export const PolygonSchema = Yup.object().shape({
  description: Yup.string().required('Description is required'),
  geoJson: Yup.object().required(),
  center: Yup.object()
    .shape({
      lat: Yup.number().required().isLatitude('Center latitude is required'),
      lng: Yup.number().required().isLongitude('Center longitude is required'),
    })
    .required()
    .nullable(),
})

export const CreatePolygonSchema = PolygonSchema.concat(ExtraPolygonDataSchema)
