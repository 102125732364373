import { createConstants, initConstant } from './constantsCreator'

const GET_INGREDIENTS = createConstants('GET_INGREDIENTS')

const CREATE_INGREDIENT = createConstants('CREATE_INGREDIENT')
const UPDATE_INGREDIENT = createConstants('UPDATE_INGREDIENT')
const DELETE_INGREDIENT = createConstants('DELETE_INGREDIENT')
const GET_INGREDIENT_DETAIL = createConstants('GET_INGREDIENT_DETAIL')
const ADD_INGREDIENT_TO_PRODUCT = createConstants('ADD_INGREDIENT_TO_PRODUCT')
const REMOVE_INGREDIENT_FROM_PRODUCT = createConstants('REMOVE_INGREDIENT_FROM_PRODUCT')

const GET_INGREDIENTS_INIT = {
  ...initConstant(GET_INGREDIENTS),
  list: {},
}

const GET_INGREDIENT_INIT = {
  ...initConstant(GET_INGREDIENT_DETAIL, 'getIngredientDetail'),
  detail: {},
}

const INGREDIENT_INIT = {
  ...GET_INGREDIENTS_INIT,
  ...GET_INGREDIENT_INIT,
  ...initConstant(CREATE_INGREDIENT),
  ...initConstant(DELETE_INGREDIENT),
  ...initConstant(UPDATE_INGREDIENT),
  ...initConstant(ADD_INGREDIENT_TO_PRODUCT),
  ...initConstant(REMOVE_INGREDIENT_FROM_PRODUCT),
}

export {
  GET_INGREDIENTS,
  GET_INGREDIENT_DETAIL,
  CREATE_INGREDIENT,
  UPDATE_INGREDIENT,
  DELETE_INGREDIENT,
  ADD_INGREDIENT_TO_PRODUCT,
  REMOVE_INGREDIENT_FROM_PRODUCT,
  INGREDIENT_INIT,
}
