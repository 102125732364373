const formKeys = {
  zoneId: 'zoneId',
  categories: 'categories',
  productLines: 'productLines',
}

const { categories, productLines } = formKeys

const initialValues = {
  [categories]: [],
  [productLines]: [],
}

export { formKeys, initialValues }
