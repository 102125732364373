/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  map as arrayMap,
  flatten,
  keys,
  isFunction,
  without,
  isArray,
  isPlainObject,
  entries,
} from 'lodash'

import { parse } from 'querystring'

import { typeEnum, typeIdEnum } from './enums/entityEnums'
import { daysEnum } from './enums/weekdaysEnums'
import { permissionEnum } from './enums/permissionEnums'

import { Polygon, LatLng, LatLngCoords, PointCoords } from './interfaces/maps'

import {
  FieldsConfig,
  Object as Record,
  ObjectWithValue,
  PoolFieldsConfig,
} from './interfaces/react'

export const isNumber = (number: unknown): boolean => typeof number === 'number'

export const isString = (string: unknown): boolean => typeof string === 'string'

export const isBoolean = (boolean: unknown): boolean => typeof boolean === 'boolean'

export const isObjectEmpty = (object: Record): boolean =>
  isPlainObject(object) && Object.keys(object).length === 0

export const isType = <T>(value, attribute): value is T => attribute in value

export const removeDuplicateObject = (array: Record[]): Record[] => {
  if (isArray(array)) {
    return array.filter(
      (item, index, a) => a.findIndex((iterableObject) => iterableObject.id === item.id) === index
    )
  }
  return []
}

export const firstToLowercase = (text: string): string =>
  text.trim().length ? `${text.trim()[0].toLowerCase()}${text.slice(1)}` : text

export const objectStringify = (obj: Record): string => {
  if (isPlainObject(obj)) return JSON.stringify(obj).replace(/'([^']+)':/g, '$1:')
  return ''
}

export const withRouteParams = (route: string, params: ObjectWithValue<string>): string => {
  if (isString(route) && isPlainObject(params)) {
    let newRoute = route
    const routeParams = route.matchAll(/:([-_a-z]{1,})(\(.{1,}\))?(\?)?/gi) ?? []

    Array.from(routeParams).forEach((match) => {
      const key = match[1]
      const optional = !!match[3]
      if (params[key]) {
        newRoute = newRoute.replace(`:${key}`, params[key.toString()])
      } else {
        const keyRegex = new RegExp(`\\/:${key}(\\(.{1,}\\))?\\?`, 'gi')
        if (optional) newRoute = newRoute.replace(keyRegex, '')
      }
    })
    return newRoute
  }
  return route
}

type Sorteable = string | number | any[]
export const compare = (a: Sorteable, b: Sorteable): number => {
  if (!b) return 1
  if (!a) return -1
  if (isArray(a) && isArray(b)) return a.length - b.length
  if (isString(a) && isString(b)) return a > b ? 1 : -1
  if (isNumber(a) && isNumber(b)) return (a as number) - (b as number)
  return 1
}

export const toKebab = (str: string): string => str.toLowerCase().replace('_', '-')

export const getFields = (
  fields: string[],
  { exclude = [], include = [], filter }: FieldsConfig
): string[] => [
  ...without(fields, ...exclude).filter(
    (k) => !k.toLowerCase().includes('id') && !(!!filter && isFunction(filter) && filter(k))
  ),
  ...include,
]

export const getPoolFields = ({ pool, ...fieldsConfig }: PoolFieldsConfig): string[] =>
  getFields(pool, fieldsConfig)

export const getDataFields = <T>(data: T[], fieldsConfig): string[] =>
  getFields([...flatten(arrayMap(data, keys))], fieldsConfig)

export const getSingleDataFields = <T>(data: T, fieldsConfig): string[] =>
  getFields(Object.keys(data), fieldsConfig)

export const isFlorist = (id: number | string): boolean => typeIdEnum[id] === typeEnum.FLORIST

export const isIntermediate = (id: number | string): boolean =>
  typeIdEnum[id] === typeEnum.INTERMEDIATE

export const isOwner = (id: number | string): boolean => typeIdEnum[id] === typeEnum.OWNER

export const isAllowedEntity = (id: number | string, allowedTypes): boolean => {
  if (!isArray(allowedTypes) || (isArray(allowedTypes) && !allowedTypes.length)) return true
  if (!isNumber(id)) return false
  return allowedTypes.includes(typeIdEnum[id])
}

export const arrayMinTarget = (arr, target) => {
  if (!isArray(arr) || (isArray(arr) && !arr.length && isArray(target) && target.length))
    return false
  if (!isArray(target) || (isArray(target) && !target.length)) return true
  return target.every((v) => arr.includes(v))
}

export const permissionsExpand = (permissions) =>
  permissions
    .map((val) => permissionEnum[val] ?? val)
    .map((val) => (isPlainObject(val) && !!Object.keys(val).length ? Object.keys(val) : val))
    .flat(1)

export const isAllowedPermissions = (permissions, neededPermissions) => {
  if (!isArray(neededPermissions) || (isArray(neededPermissions) && !neededPermissions.length))
    return true
  return arrayMinTarget(
    permissionsExpand(permissions.map((val) => val.name)),
    permissionsExpand(neededPermissions)
  )
}

export const openHoursToArray = (hours) => {
  if (!isPlainObject(hours) || isObjectEmpty(hours)) return []
  return Object.keys(hours).map((key) => ({
    ...hours[key],
    weekday: daysEnum[key].weekday,
  }))
}

export const getNameInitials = (name = ''): string => {
  const normalized = name
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/\b[A-Za-z]\b/g, '')

  const matches = normalized.match(/(\b[A-Za-z])+/g)

  if (matches) return matches.join('').toUpperCase()
  return ''
}

export const timeout = (seconds: number) => new Promise((res) => setTimeout(res, seconds * 1000))

export const getPathArray = (polygon: Polygon) => polygon.getPath().getArray()

export const getPolygonBounds = (polygon) => {
  const polyBounds = new window.google.maps.LatLngBounds()
  polygon.getPath().forEach((polyPath) => polyBounds.extend(polyPath))
  return polyBounds
}

const getCenter = (polygon) => {
  const lat = getPolygonBounds(polygon).getCenter().lat()
  const lng = getPolygonBounds(polygon).getCenter().lng()
  return { lat, lng }
}

export const getCenterFromBounds = (bounds) => {
  const lat = bounds.getCenter().lat()
  const lng = bounds.getCenter().lng()
  return { lat, lng }
}

export const getGeoJSON = (polygon, callback?: (geojson: any, geocenter: LatLng) => void) => {
  const dataLayer = new window.google.maps.Data()
  const feature = new window.google.maps.Data.Feature({
    geometry: new window.google.maps.Data.Polygon([getPathArray(polygon)]),
  })

  dataLayer.add(feature)

  dataLayer.toGeoJson((geojson) => {
    if (callback) {
      callback(geojson, getCenter(polygon))
    }
  })
}

export const getCenterFromPoint = (point: PointCoords) => ({
  lat: point.coordinates[0],
  lng: point.coordinates[1],
})

export const getPolygonPath = (data: LatLngCoords) =>
  data && data?.coordinates && !!data.coordinates?.length
    ? data.coordinates[0].map((coord) => ({ lng: coord[0], lat: coord[1] }))
    : []

interface ColorOptions {
  saturation?: number
  lightness?: number
}

export const generateColor = (
  number: number,
  { saturation = 50, lightness = 60 }: ColorOptions = {}
) => {
  const hue = number * 137.508 // use golden angle approximation
  return `hsl(${hue},${saturation}%,${lightness}%)`
}

export const getArrayDepth = (arr) => {
  if (Array.isArray(arr)) return 1 + Math.max(...arr.map((t) => getArrayDepth(t)))
  return 0
}

export const fitCircleToBounds = (circle, center, bounds) => {
  const cor1 = bounds.getNorthEast()
  const cor2 = bounds.getSouthWest()
  const cor3 = new window.google.maps.LatLng(cor2.lat(), cor1.lng())

  const height = window.google.maps.geometry.spherical.computeDistanceBetween(cor1, cor3)
  circle.setRadius(height / 2)
  circle.setCenter(center)
}

export const numberToKM = (number) => `${Number((number / 1000).toFixed(1))} km`

export const middle = (max: number, min: number): number => Number(((max - min) / 2).toFixed(5))

export const parseQuery = (url: string) => {
  const query = url.trim().split('?')
  return query.length > 1 ? parse(query[1]).idFlorist : null
}

export const replaceByRules = (
  string: string,
  rules: string | { [char: string]: string } = 'A0B1C2D3E4'
) =>
  string
    .trim()
    .split('')
    .map((char) => (isArray(rules) ? entries(rules) : rules)[char])
    .join('')

export const getFloristProductId = (productId) => replaceByRules(productId.replace(/\D+/, ''))

export const getProductTitle = (imFlorist, productId, productName) => {
  if (imFlorist) return `#${getFloristProductId(productId)}`
  return `#${productId} · ${productName}`
}

export const copyTextToClipboard = async (text) =>
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  'clipboard' in navigator ? navigator.clipboard.writeText(text) : () => {}
