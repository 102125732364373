import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import translations from '@translations'

import { withRouteParams } from '@utils/helpers'
import { filterDataEnum } from '@utils/enums/orderEnums'

import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'

import { getOrdersAction } from '@actions/order'

import EmptyRecord from '@utils/components/EmptyTableRender'
import { CRow } from '@coreui/react'
import { Card, Button } from '@components/common/index'
import ImagesFeedbackCarousel from '@components/ImagesFeedbackCarousel'
import Col from '@utils/components/ResponsiveColumn'
import DataTable from '@components/common/Tables/PaginatedTable'

import OrderProductsList from '@views/orders/ABM/components/OrderProductsList'

import { successToast } from '@utils/toast'

import { routes } from '@/routes'

const ImagesDashboard = ({ userInfo, currentFlorist, currentIntermediate, loading, getImages }) => {
  const history = useHistory()
  dayjs.extend(LocalizedFormat)

  const { viewImages } = translations.form

  const [orderList, setOrderList] = useState([])
  const [modalOpen, setModalOpen] = useState(false)

  const openModal = () => setModalOpen(true)
  const closeModal = () => setModalOpen(false)

  const imFlorist = userInfo?.type?.id === 3
  const imOwner = userInfo?.type?.id === 1

  const requestProps = {
    setList: setOrderList,
    limit: 15,
  }

  const requestKeys = {
    intermediate: (intermediateId) =>
      getImages('itm', intermediateId, {
        status: filterDataEnum.IMAGE_UPLOADED?.param,
        ...requestProps,
      }),
    florist: (floristId) =>
      getImages('fl', floristId, {
        status: filterDataEnum.IMAGE_REJECTED?.param,
        ...requestProps,
      }),
  }

  useEffect(() => {
    if (imFlorist) requestKeys.florist(currentFlorist?.id)
    else requestKeys.intermediate(imOwner ? 1 : currentIntermediate?.id)
  }, [userInfo, currentFlorist, currentIntermediate])

  const goToFlorist = (id) => {
    if (id) history.push(withRouteParams(routes.Details, { type: 'fl', id }))
  }

  const successCallback = (id, _accepted) => {
    const currentList = [...orderList]
    const index = currentList.findIndex((order) => order.id === id)

    currentList.splice(index, 1)
    setOrderList(currentList)

    closeModal()
    successToast('sentImageFeedback', { toastId: 'IMAGE_FEEDBACK' })
  }

  if (!orderList || !orderList?.length) return null

  return (
    <CRow>
      {imOwner && (
        <Col xs={12} xl={[10, 12]} xxl={8}>
          <Card bodyClassName="overflow-hidden">
            <DataTable
              verticalScroll
              loading={loading}
              data={orderList}
              render={{
                florist: {
                  render: (_, { florist }) => (
                    <Button
                      onClick={() => goToFlorist(florist?.id)}
                      text={florist?.username}
                      link
                    />
                  ),
                },
                deliveryDate: {
                  render: (_, item) => dayjs(item.deliveryDate).format('ll [·] LT'),
                },
                itemCount: {
                  width: '10%',
                },
                images: {
                  width: '15%',
                  render: (_, { id, images, florist }) =>
                    images && !!images.length ? (
                      <>
                        <ImagesFeedbackCarousel
                          open={modalOpen}
                          closeModal={closeModal}
                          images={images}
                          id={id}
                          florist={florist.username}
                          successCallback={successCallback}
                        />

                        <Button
                          icon="cil-eye"
                          small
                          text={images.length}
                          color="secondary"
                          tooltip={viewImages}
                          onClick={openModal}
                          className="mb-0 h5"
                        />
                      </>
                    ) : (
                      <EmptyRecord />
                    ),
                },
              }}
              fields={['images', 'florist', 'deliveryDate', 'itemCount']}
              expandable={{
                expandedRowRender: ({ items }) => (
                  <OrderProductsList products={items.filter((item) => !!item?.product)} />
                ),
                rowExpandable: ({ items }) =>
                  !!items?.length && !!items?.filter((item) => !!item?.product).length,
              }}
            />
          </Card>
        </Col>
      )}
    </CRow>
  )
}

const mapStateToProps = (state) => ({
  orders: state.order.dashboard,
  loading: state.order.getDashOrdersRequesting,
  currentFlorist: state.auth.currentFlorist,
  currentIntermediate: state.auth.currentIntermediate,
  userInfo: state.auth.user,
  success: state.order.setImagesFeedbackSuccess,
})

const mapDispatchToProps = {
  getImages: getOrdersAction,
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(ImagesDashboard)
