const formKeys = {
  currentPassword: 'currentPassword',
  password: 'password',
  confirmPassword: 'confirmPassword',
}

const { password, confirmPassword, currentPassword } = formKeys

const initialValues = {
  [currentPassword]: '',
  [password]: '',
  [confirmPassword]: '',
}

export { formKeys, initialValues }
