/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { isArray, isPlainObject, uniq } from 'lodash'
import React, { Key } from 'react'

import { getDataFields, getSingleDataFields, isType, getPoolFields } from '@utils/helpers'

import { Tooltip } from '@components/common'
import EmptyRecord from '@utils/components/EmptyTableRender'

import { ObjectWithoutKey as Data, FieldsConfig } from '@utils/interfaces/react'
import { EmptyRenderType } from './interfaces'

export const renderEmpty = (
  emptyRecordRender: EmptyRenderType,
  key: Key,
  record: Data
): React.ReactElement => emptyRecordRender(key, record) ?? <EmptyRecord />

export const renderArray = (
  emptyRender: EmptyRenderType,
  key: Key,
  array: any[],
  record: Data
): React.ReactElement => {
  if (!array.length) return renderEmpty(emptyRender, key, record)
  const filteredArray = array?.filter((elem) => !isArray(elem))

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const renderEach = (list) => list.map((elem) => renderRow(emptyRender, key)(elem, record))

  return (
    <Tooltip tooltip={renderEach(filteredArray.slice(3)).join(',')}>
      `${renderEach(filteredArray.slice(0, 3)).join(',')}...`
    </Tooltip>
  )
}

export const renderRow =
  (emptyRender: EmptyRenderType, key: Key) => (text, record: Data, _index?: number) =>
    !!text && !isPlainObject(text)
      ? `${isArray(text) ? renderArray(emptyRender, key, text, record) : text}`
      : renderEmpty(emptyRender, key, record)

const getDataValue = (fields, data) => {
  const args = [data, fields || {}] as const
  if (fields?.pool) return getPoolFields(fields)
  return isArray(data) ? getDataFields<Data>(...args) : getSingleDataFields<Data>(...args)
}

export const getKeys = (fields, data) =>
  uniq(
    (!fields ||
    isType<FieldsConfig>(fields, 'include') ||
    isType<FieldsConfig>(fields, 'exclude') ||
    isType<FieldsConfig>(fields, 'pool')
      ? getDataValue(fields, data)
      : isArray(fields) && fields) || []
  )
