import React, { useEffect, useState } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'

import { ObjectWithoutKey as Data, Render } from '@utils/interfaces/react'

import clsx from 'clsx'

import { Icon } from '@components/common/index'
import { CInputGroup, CInputGroupPrepend, CInputGroupText } from '@coreui/react'
import MaxTagLabel from '@utils/components/MaxTagLabel'

import { Select as SelectAD, SelectProps } from 'antd'
import { OptionProps } from 'antd/lib/select'

import { isFunction } from 'lodash'

const { Option, OptGroup } = SelectAD

type Mode = 'multiple' | 'tags'
type TagCount = number | 'responsive'

interface Group {
  key: string
  label: Render
  className?: string
}

interface OptionData extends Omit<OptionProps, 'children' | 'label'> {
  children: Render
  tag?: Render
  group: Group | null
  label: string | number
}

interface PaginationOptions {
  getData: (page: number, callback: (options: Data[], next: number) => void) => void
  next: number | null
  page: number
}

interface Props extends WithTranslation, SelectProps<any> {
  name?: string
  data?: Data[]
  groups?: Group[]
  error?: string | false
  icon?: string
  onChange?: (value: any[] | any) => void
  renderOption?: (item: Data) => Render
  renderTag?: (item: Data) => Render
  getItemGroup?: (item: Data) => Group
  getItemDisabled?: (item: Data) => boolean
  renderTagDefault?: boolean
  valueKey?: string
  labelKey?: string
  multiple?: boolean
  allowInsert?: boolean
  responsiveTags?: boolean
  maxTags?: number
  searchable?: boolean
  loading?: boolean
  pagination?: PaginationOptions
}

const Select: React.FC<Props> = ({
  name,
  data: initialData = [],
  loading: initialLoading = false,
  groups = [],
  renderTag,
  renderOption,
  getItemGroup,
  getItemDisabled,
  renderTagDefault = true,
  valueKey = 'id',
  labelKey = 'name',
  error,
  icon,
  onChange,
  filterOption = (search: string, item: any) =>
    item?.name.toLowerCase().includes(search.toLowerCase()),
  multiple = false,
  allowInsert = false,
  allowClear = true,
  tokenSeparators = [','],
  responsiveTags = true,
  maxTags = 4,
  maxTagTextLength = 20,
  searchable = true,
  pagination,
  ...props
}) => {
  const [value, setValue] = useState()
  const [data, setData] = useState(initialData)

  const [page, setPage] = useState(pagination ? pagination.page : 1)
  const [hasNext, setHasNext] = useState(!!pagination && !!pagination?.next)
  const [loading, setLoading] = useState(initialLoading)

  useEffect(() => {
    if (loading && !initialLoading) setLoading(false)
  }, [initialLoading])

  useEffect(() => {
    if (!data.length) setData(initialData)
  }, [initialData])

  const handleChange = (currentValues) => {
    setValue(currentValues)
    if (!!onChange && isFunction(onChange)) onChange(currentValues)
  }

  const handleScroll = (e: { persist?: any; target?: any }) => {
    e.persist()
    const { target } = e

    if (!!target && !!pagination && hasNext) {
      if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
        setLoading(true)
        pagination.getData(page, (list, next) => {
          setData([...data, ...list])

          const hasNextNow = next !== null
          setHasNext(hasNextNow)
          if (hasNextNow) setPage(next)

          setTimeout(() => {
            setLoading(false)
          }, 350)
        })
      }
    }
  }

  const isGroup = (object: any): object is Group => 'label' in object && 'key' in object

  const itemGroup = (group: number | Group) => {
    if (!group) return null
    if (isGroup(group)) return group ?? null
    if (typeof group === 'number') return groups[group] ?? null
    return null
  }

  const options = !!data && !!data.length ? data : []
  const transformedOptions: OptionData[] = options.map((item, key) => {
    const label = item[labelKey]
    const children = (!!renderOption && renderOption(item)) || (label ?? key)

    return {
      ...item,
      key,
      label: label ?? children,
      children,
      value: item[valueKey] ?? key,
      tag: renderTag ? renderTag(item) : (!renderTagDefault && children) || undefined,
      group: itemGroup(getItemGroup ? getItemGroup(item) : item.group),
      disabled: getItemDisabled ? getItemDisabled(item) : item.disabled ?? false,
    }
  })

  const selectProps = {
    ...props,
    ...(searchable &&
      filterOption && {
        showSearch: true,
        filterOption,
      }),
    loading,
    value,
    allowClear,
    tokenSeparators,
    maxTagTextLength,
    maxTagCount: (responsiveTags ? 'responsive' : maxTags) as TagCount,
    maxTagPlaceholder: (hiddenValues) => <MaxTagLabel hidden={hiddenValues} />,
    optionLabelProp: 'label',
    mode: (multiple && ((allowInsert ? 'tags' : 'multiple') as Mode)) || undefined,
    className: clsx(props.className, 'form-control', {
      'is-invalid': !!error,
    }),
  }

  return (
    <CInputGroup className={`${error ? 'invalid' : 'valid'}`}>
      {!!icon && (
        <CInputGroupPrepend>
          <CInputGroupText>
            <Icon name={icon} />
          </CInputGroupText>
        </CInputGroupPrepend>
      )}

      <SelectAD
        {...selectProps}
        onChange={handleChange}
        onPopupScroll={pagination ? handleScroll : undefined}
      >
        {transformedOptions.map(({ value: val, children, group, ...option }) =>
          group ? (
            <OptGroup {...group}>
              <Option value={val} {...option}>
                {children}
              </Option>
            </OptGroup>
          ) : (
            <Option value={val} {...option}>
              {children}
            </Option>
          )
        )}
      </SelectAD>
    </CInputGroup>
  )
}

export default withTranslation()(Select)
