import React, { useEffect, useState } from 'react'

import { compose } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'

import { useHistory } from 'react-router-dom'
// import useDimensions from 'react-cool-dimensions'

import { isArray } from 'lodash'

import { ObjectWithoutKey as Data } from '@utils/interfaces/react'

import { withRouteParams } from '@utils/helpers'
import { daysEnum, weekdayTranslation } from '@utils/enums/weekdaysEnums'

import translations from '@translations'

import { getEntityListAction, updateEntityAction } from '@actions/entity'

// import { ResponsiveBadge } from '@utils/components/responsiveBadge'

import { Modal, Button, Switch, Select, Card } from '@components/common'
import StripedTable from '@components/common/Tables/StripedTable'
import RenderPhoneNumber from '@components/RenderPhoneNumber'
import { Menu, Dropdown } from 'antd'

import { CCol, CRow } from '@coreui/react'

import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import CustomParseFormat from 'dayjs/plugin/customParseFormat'

import DriversList from './components/DriversList'
import UsersList from './components/UsersList'

import { routes } from '@/routes'

import { EntityContext } from './components/context'

const { Item: MenuItem } = Menu

type PropsFromRedux = ConnectedProps<typeof connector>

interface Intermediate {
  username: string
  id: string
}

interface Country {
  name: string
  id: string
}

interface Zone {
  name: string
  id: string
}

interface Time {
  weekday: string
  startHour: string
  endHour: string
}

interface Florist {
  id: string
  phone: string
  username: string
  intermediate?: Intermediate
  intermediateId: Intermediate['id']
  // mainZoneId: string
  country: Country
  zones: Zone[]
  times: Time[]
}

interface Props extends PropsFromRedux, WithTranslation {
  details: Florist
  viewLists?: boolean
}

const FloristDetail: React.FC<Props> = ({
  details,
  intermediates,
  userInfo,
  requesting,
  success,
  getIntermediates,
  updateFlorist,
  viewLists = false,
}) => {
  const history = useHistory()
  dayjs.extend(LocalizedFormat)
  dayjs.extend(CustomParseFormat)

  const { id, keys, tab, currentPage, type, onPageChange } = EntityContext()

  const {
    newUser,
    newDriver,
    drivers,
    operators,
    setIntermediate,
    intermediate,
    florist,
    // country,
    // zone,
    endHour: endHourTranslation,
    startHour: startHourTranslation,
    editFlorist,
  } = translations.form

  const {
    directFloristTitle,
    detailsTitle,
    hoursTitle,
    day,
    daysInWeek,
    allDays,
    productsTitle,
    notDirectFloristTitle,
    // mainZone,
    anIntermediate,
    viewIntermediate,
  } = translations.generic

  const [userView, setUserView] = useState(tab !== 'drivers')
  const [hours, setHours] = useState<Time[]>([])
  const [modalOpen, setModalOpen] = useState(false)
  const [intermediateList, setIntermediateList] = useState([])
  const [intermediateId, setIntermediateId] = useState<string>()

  // const [showBadge, setShowBadge] = useState(false)

  const imOwner = (userInfo?.type?.id === 4 || userInfo?.type?.id === 1) ?? false

  const textOn = operators
  const textOff = drivers
  const directFlorist = !details?.intermediateId

  /*   const { observe } = useDimensions({
    breakpoints: { no: 60, yes: 398 },
    updateOnBreakpointChange: true,
    onResize: ({ currentBreakpoint }) => {
      setShowBadge(currentBreakpoint === 'yes')
    },
  }) */

  useEffect(() => {
    if (details) {
      if (!!details.times && isArray(details.times)) {
        setHours(
          details.times.map((elem) =>
            ['weekday', 'startHour', 'endHour'].reduce(
              (obj, key) => {
                const val = elem[key]
                obj[key] = key === 'weekday' ? weekdayTranslation(val) : val ?? '-'
                return obj
              },
              { weekday: '', startHour: '', endHour: '' }
            )
          )
        )
      }
    }
  }, [details])

  useEffect(() => {
    if (success && modalOpen) setModalOpen(false)
  }, [success])

  const getIntermediatesNextPage = async (page, callback) => {
    await getIntermediates('itm', { page: page + 1, setList: callback })
  }

  const goToNewUser = () => {
    history.push(withRouteParams(routes.NewUser, { type, id }))
  }

  const goToNewDriver = () => {
    history.push(withRouteParams(routes.NewDriver, { type, id }))
  }

  const goToFloristProducts = () => {
    history.push(withRouteParams(routes.FloristProducts, { type, id }), {
      username: details?.username,
    })
  }

  const goToEditFlorist = () => {
    history.push(withRouteParams(routes.EditEntity, { type, id }))
  }

  const goToIntermediate = (iId) => {
    if (iId) history.push(withRouteParams(routes.Details, { type: 'itm', id: iId }))
  }

  const openIntermediateModal = () => {
    setModalOpen(true)
    getIntermediates('itm', { page: 1, setList: setIntermediateList })
  }

  const toggleUserView = () => {
    setUserView(!userView)
  }

  const assignIntermediate = () => {
    if (intermediateId) {
      updateFlorist(type, { id, intermediateId })
    }
  }

  const AssignIntermediateButton = () => (
    <Button disabled={!intermediateId} onClick={assignIntermediate} text={setIntermediate} />
  )

  /*   const ZonesHeader = () => (
    <CRow className="justify-content-between align-items-center mx-0 w-100">
      {`${country}: ${details?.country?.name}`}
      <strong> {`${details?.zones?.length} ${zone}`} </strong>
    </CRow>
  ) */

  const HoursHeader = () => {
    const title =
      hours.length === Object.keys(daysEnum).length ? allDays : `${hours.length} ${daysInWeek}`
    return (
      <CRow className="justify-content-between align-items-center mx-0 w-100">
        {hoursTitle}
        <strong> {title} </strong>
      </CRow>
    )
  }

  const UserViewToggler = ({ ...props }) => (
    <CCol className="d-flex justify-content-end p-4" {...props}>
      <Switch
        onChange={toggleUserView}
        value={userView}
        disabled={requesting}
        textOn={textOn}
        textOff={textOff}
      />
    </CCol>
  )

  /*   const ZoneRow: React.FC<Zone> = ({ id: zoneId, name }) => (
    <tr ref={observe}>
      <td>
        <strong>{zoneId}</strong>
      </td>
      <td>{name}</td>
      {zoneId === details.mainZoneId && (
        <td>
          <ResponsiveBadge text={mainZone} show={showBadge} />
        </td>
      )}
    </tr>
  ) */

  return (
    <CCol>
      <Modal
        title={setIntermediate}
        open={modalOpen}
        closeModal={() => setModalOpen(false)}
        onDismiss={() => setModalOpen(false)}
        footerButtons={AssignIntermediateButton}
      >
        <CCol xl={12}>
          <Select
            data={intermediateList}
            placeholder={intermediate}
            labelKey="username"
            onChange={(value) => setIntermediateId(value)}
            pagination={{
              page: 1,
              getData: getIntermediatesNextPage,
              next: intermediates.next,
            }}
          />
        </CCol>
      </Modal>

      <CRow className="d-flex justify-content-between align-items-center">
        <CCol xl={4} className="d-flex align-items-center">
          <p className="mb-0 mr-2">
            {directFlorist
              ? directFloristTitle
              : notDirectFloristTitle({
                  intermediate: details?.intermediate?.username ?? anIntermediate.toLowerCase(),
                })}
          </p>

          {imOwner &&
            (directFlorist ? (
              <Button
                onClick={openIntermediateModal}
                icon="cil-user-follow"
                text={setIntermediate}
                link
              />
            ) : (
              <Button
                onClick={() =>
                  goToIntermediate(details?.intermediate?.id ?? details?.intermediateId)
                }
                text={viewIntermediate}
                link
              />
            ))}
        </CCol>

        <CCol xl={6} className="d-flex justify-content-end">
          <CRow>
            {imOwner && (
              <>
                <Button
                  onClick={goToFloristProducts}
                  icon="cil-flower"
                  className="mr-2"
                  color="secondary"
                  text={productsTitle}
                />
                <Button
                  onClick={goToEditFlorist}
                  icon="cil-pencil"
                  color="secondary"
                  className="mr-2"
                  text={editFlorist}
                />
              </>
            )}
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu>
                  <MenuItem onClick={goToNewUser}>
                    <div className={`mx-1 d-inline-flex align-items-center `}>{newUser}</div>
                  </MenuItem>
                  <MenuItem onClick={goToNewDriver}>
                    <div className="mx-1 d-inline-flex align-items-center">{newDriver}</div>
                  </MenuItem>
                </Menu>
              }
            >
              <Button text={translations.generic.new} icon="cil-user-follow" />
            </Dropdown>
          </CRow>
        </CCol>
      </CRow>

      <CRow>
        <CCol xl={6}>
          <h4>
            {florist} #{details.id} · {details.username}
          </h4>
        </CCol>
      </CRow>

      <CRow>
        <CCol xl={4} className="mb-3">
          <Card title={detailsTitle}>
            <StripedTable
              fields={{ pool: keys, exclude: ['times', 'zones'] }}
              data={details as any as Data}
              render={{
                phone: (_, item) => <RenderPhoneNumber florist={item} />,
              }}
            />
          </Card>

          <Card collapsible header={HoursHeader}>
            <table className="table table-striped table-hover">
              <tbody>
                <tr>
                  <td>{day}</td>
                  <td>{startHourTranslation}</td>
                  <td>{endHourTranslation}</td>
                </tr>

                {hours.map(({ weekday, startHour, endHour }) => (
                  <tr key={weekday}>
                    <td>
                      <strong>{translations.generic[weekday]}</strong>
                    </td>
                    <td>{dayjs(startHour, 'HH:mm:ss').format('LT')}</td>
                    <td>{dayjs(endHour, 'HH:mm:ss').format('LT')}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>

          {/* {!!details?.zones?.length && (
            <Card collapsible header={ZonesHeader}>
              <table className="table table-striped table-hover">
                <tbody>
                  {details?.zones.map(({ id: zoneId, name }) => (
                    <ZoneRow key={zoneId} id={zoneId} name={name} />
                  ))}
                </tbody>
              </table>
            </Card>
          )} */}
        </CCol>

        {viewLists && (
          <CCol xl={8} className="mb-3">
            <Card
              title={userView ? textOn : textOff}
              footer={imOwner ? UserViewToggler : undefined}
            >
              {userView ? (
                <UsersList
                  currentId={userInfo?.id}
                  entityId={id}
                  currentPage={currentPage}
                  onPageChange={onPageChange}
                  type="fl"
                  simplePagination
                />
              ) : (
                <DriversList
                  entityId={id}
                  currentPage={currentPage}
                  onPageChange={onPageChange}
                  simplePagination
                />
              )}
            </Card>
          </CCol>
        )}
      </CRow>
    </CCol>
  )
}

const mapStateToProps = (state) => ({
  requesting: state.entity.getFloristDetailRequesting,
  success: state.entity.updateFloristSuccess,
  userInfo: state.auth.user,
  intermediates: state.entity.list,
})

const mapDispatchToProps = {
  getIntermediates: getEntityListAction,
  updateFlorist: updateEntityAction,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default compose(withTranslation(), connector)(FloristDetail)
