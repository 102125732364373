import React, { useEffect, useState } from 'react'
import { Modal, Button, DataTable } from '@components/common'

import { compose } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'

import translations from '@translations'
import { setDriverOrderAction } from '@actions/order'
import { ObjectWithoutKey as Data, EntityWithId } from '@utils/interfaces/react'

import { getDriversAction } from '@actions/driver'
import { OrderModalContext } from './context'

type PropsFromRedux = ConnectedProps<typeof connector>

type Driver = EntityWithId
interface ComposeProps {
  loading: boolean
  data?: Driver[]
}

type Props = ComposeProps & WithTranslation & PropsFromRedux

const OrderDriverModal: React.FC<Props> = ({
  loading = false,
  data,
  setDriver,
  getDrivers,
  currentFlorist,
}) => {
  const { useDriverModal, selectedOrderId: orderId } = OrderModalContext()
  const { modalOpen, closeModal } = useDriverModal()

  const [driverList, setDriverList] = useState<Driver[]>()

  const currentFloristId = currentFlorist?.id

  useEffect(() => {
    if (!data) getDrivers(currentFloristId, setDriverList)
  }, [])

  const { setDriver: setDriverTranslation } = translations.form

  return (
    <Modal
      title={setDriverTranslation}
      open={modalOpen}
      closeModal={closeModal}
      onDismiss={closeModal}
      hideFooter
      size="lg"
    >
      <DataTable
        data={(data ?? driverList) as any as Data[]}
        fields={['name', 'lastname', 'identification', 'assign_driver']}
        loading={loading}
        render={{
          assign_driver: {
            render: (_, { id }) => (
              <Button
                onClick={() => setDriver(orderId, id)}
                icon="cil-user-follow"
                text={translations.form.assignFlorist}
              />
            ),
          },
        }}
      />
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  currentFlorist: state.auth.currentFlorist,
})

const mapDispatchToProps = {
  setDriver: setDriverOrderAction,
  getDrivers: getDriversAction,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default compose<React.FC<ComposeProps>>(withTranslation(), connector)(OrderDriverModal)
