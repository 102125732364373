import { baseService } from '@utils/authRequest'

export const createDriver = async (form) => {
  try {
    return await baseService.post('drivers', form)
  } catch (e) {
    return e.response?.data.error
  }
}

export const deleteDriver = async (driverId) => {
  try {
    return await baseService.delete(`drivers/${driverId}`)
  } catch (e) {
    return e.response?.data.error
  }
}

export const getDrivers = async (id, params) => {
  try {
    return await baseService.get(`/florists/${id}/drivers`, { params })
  } catch (e) {
    return e.response?.data.error
  }
}

export const getDriverDetail = async (id) => {
  try {
    return await baseService.get(`drivers/${id}`)
  } catch (e) {
    return e.response?.data.error
  }
}

export const updateDriver = async (form) => {
  try {
    return await baseService.put('drivers', form)
  } catch (e) {
    return e.response?.data.error
  }
}
