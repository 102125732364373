import React from 'react'
import CoreUIIcon, { CIcon } from '@coreui/icons-react'

import { merge, isArray } from 'lodash'

import sygnet from '@/assets/icons/favicon.svg'
import logo from '@/assets/icons/logo.svg'

const cilThumbUp = [
  '512 512',
  `<path fill='var(--ci-primary-color, currentColor)' d='M126.638,202.672H51.986a24.692,24.692,0,0,0-24.242,19.434,487.088,487.088,0,0,0-1.466,206.535l1.5,7.189a24.94,24.94,0,0,0,24.318,19.78h74.547a24.866,24.866,0,0,0,24.837-24.838V227.509A24.865,24.865,0,0,0,126.638,202.672ZM119.475,423.61H57.916l-.309-1.487a455.085,455.085,0,0,1,.158-187.451h61.71Z' class='ci-primary'/><path fill='var(--ci-primary-color, currentColor)' d='M494.459,277.284l-22.09-58.906a24.315,24.315,0,0,0-22.662-15.706H332V173.137l9.573-21.2A88.117,88.117,0,0,0,296.772,35.025a24.3,24.3,0,0,0-31.767,12.1L184.693,222.937V248h23.731L290.7,67.882a56.141,56.141,0,0,1,21.711,70.885l-10.991,24.341L300,169.692v48.98l16,16H444.3L464,287.2v9.272L396.012,415.962H271.07l-86.377-50.67v37.1L256.7,444.633a24.222,24.222,0,0,0,12.25,3.329h131.6a24.246,24.246,0,0,0,21.035-12.234L492.835,310.5A24.26,24.26,0,0,0,496,298.531V285.783A24.144,24.144,0,0,0,494.459,277.284Z' class='ci-primary'/>`,
]

const cilThumbDown = [
  '512 512',
  `<path fill='var(--ci-primary-color, currentColor)' d='M127.475,104H51.428a24.086,24.086,0,0,0-23.511,19.176l-1.5,7.31a503.561,503.561,0,0,0,1.477,210.663A24.143,24.143,0,0,0,51.334,360h76.141a24.028,24.028,0,0,0,24-24V128A24.027,24.027,0,0,0,127.475,104Zm-8,224H57.811a471.525,471.525,0,0,1-.046-191.082l.188-.918h61.522Z' class='ci-primary'/><path fill='var(--ci-primary-color, currentColor)' d='M492.835,218.474,421.583,93.246A24.247,24.247,0,0,0,400.547,81.01h-131.6a24.212,24.212,0,0,0-12.246,3.327l-72.012,42.244v37.1l86.376-50.671H396.012L464,232.5v9.271L444.3,294.3H316l-16,16v48.979l1.418,6.585,10.991,24.341A56.141,56.141,0,0,1,290.7,461.09L207.981,280H184v24l81.007,177.854a24.313,24.313,0,0,0,22.1,14.126,23.923,23.923,0,0,0,9.663-2.034,88.117,88.117,0,0,0,44.8-116.911L332,355.835V326.3h117.7a24.314,24.314,0,0,0,22.661-15.7l22.09-58.906a24.13,24.13,0,0,0,1.542-8.5V230.44A24.256,24.256,0,0,0,492.835,218.474Z' class='ci-primary'/>`,
]

const cilLeaf = [
  '512 512',
  `<path fill="var(--ci-primary-color, currentColor)" d="M389.053,126.3A302.909,302.909,0,0,0,280.012,18.15L272,13.516l-8.012,4.634A301.084,301.084,0,0,0,113.4,279.042c0,3.445.06,6.944.177,10.4,1.592,46.856,19.511,86.283,51.82,114.018,24.724,21.225,56.438,34.182,90.607,37.273V496h32V240H256V408.528c-54.064-6.263-107.873-44.455-110.444-120.174-.106-3.095-.16-6.228-.16-9.312A270.286,270.286,0,0,1,272,50.673,270.286,270.286,0,0,1,398.6,279.042c0,3.084-.054,6.217-.16,9.313-2.056,60.573-36.907,97.127-78.444,112.536v33.867a156.188,156.188,0,0,0,58.607-31.3c32.309-27.735,50.228-67.162,51.82-114.017.117-3.456.177-6.955.177-10.4A300.948,300.948,0,0,0,389.053,126.3Z" class="ci-primary"/>`,
]

const cilLocationPin = [
  '512 512',
  `<path fill='var(--ci-primary-color, currentColor)' d='M253.924,127.592a64,64,0,1,0,64,64A64.073,64.073,0,0,0,253.924,127.592Zm0,96a32,32,0,1,1,32-32A32.037,32.037,0,0,1,253.924,223.592Z' class='ci-primary'/><path fill='var(--ci-primary-color, currentColor)' d='M376.906,68.515A173.922,173.922,0,0,0,108.2,286.426L229.107,472.039a29.619,29.619,0,0,0,49.635,0L399.653,286.426A173.921,173.921,0,0,0,376.906,68.515Zm-4.065,200.444L253.925,451.509,135.008,268.959C98.608,213.08,106.415,138.3,153.571,91.142a141.92,141.92,0,0,1,200.708,0C401.435,138.3,409.241,213.08,372.841,268.959Z' class='ci-primary'/>`,
]

const cilLocationPinPlus = [
  '512 512',
  `<path fill='var(--ci-primary-color, currentColor)' d='M378.981,68.515a173.921,173.921,0,0,0-268.71,217.911L231.183,472.039a29.618,29.618,0,0,0,49.634,0L401.729,286.426A173.923,173.923,0,0,0,378.981,68.515Zm-4.064,200.444L256,451.51,137.083,268.959C100.684,213.08,108.49,138.3,155.646,91.142a141.92,141.92,0,0,1,200.708,0C403.51,138.3,411.316,213.08,374.917,268.959Z' class='ci-primary'/><polygon fill='var(--ci-primary-color, currentColor)' points='272 112 240 112 240 176 176 176 176 208 240 208 240 272 272 272 272 208 336 208 336 176 272 176 272 112' class='ci-primary'/>`,
]

const cilCash = [
  '512 512',
  `<path fill='var(--ci-primary-color, currentColor)' d='M432,64H16V384H432ZM400,352H48V96H400Z' class='ci-primary'/><polygon fill='var(--ci-primary-color, currentColor)' points='464 144 464 416 96 416 96 448 496 448 496 144 464 144' class='ci-primary'/><path fill='var(--ci-primary-color, currentColor)' d='M224,302.46c39.7,0,72-35.137,72-78.326s-32.3-78.326-72-78.326-72,35.136-72,78.326S184.3,302.46,224,302.46Zm0-124.652c22.056,0,40,20.782,40,46.326s-17.944,46.326-40,46.326-40-20.782-40-46.326S201.944,177.808,224,177.808Z' class='ci-primary'/><rect width='32' height='176' x='80' y='136' fill='var(--ci-primary-color, currentColor)' class='ci-primary'/><rect width='32' height='176' x='336' y='136' fill='var(--ci-primary-color, currentColor)' class='ci-primary'/>`,
]

const cilConversationBubbles = [
  '512 512',
  `<path fill='var(--ci-primary-color, currentColor)' d='M448.205,392.507c30.519-27.2,47.8-63.455,47.8-101.078,0-39.984-18.718-77.378-52.707-105.3C410.218,158.963,366.432,144,320,144s-90.218,14.963-123.293,42.131C162.718,214.051,144,251.445,144,291.429s18.718,77.378,52.707,105.3c33.075,27.168,76.861,42.13,123.293,42.13,6.187,0,12.412-.273,18.585-.816l10.546,9.141A199.849,199.849,0,0,0,480,496h16V461.943l-4.686-4.685A199.17,199.17,0,0,1,448.205,392.507ZM370.089,423l-21.161-18.341-7.056.865A180.275,180.275,0,0,1,320,406.857c-79.4,0-144-51.781-144-115.428S240.6,176,320,176s144,51.781,144,115.429c0,31.71-15.82,61.314-44.546,83.358l-9.215,7.071,4.252,12.035a231.287,231.287,0,0,0,37.882,67.817A167.839,167.839,0,0,1,370.089,423Z' class='ci-primary'/><path fill='var(--ci-primary-color, currentColor)' d='M60.185,317.476a220.491,220.491,0,0,0,34.808-63.023l4.22-11.975-9.207-7.066C62.918,214.626,48,186.728,48,156.857,48,96.833,109.009,48,184,48c55.168,0,102.767,26.43,124.077,64.3,3.957-.192,7.931-.3,11.923-.3q12.027,0,23.834,1.167c-8.235-21.335-22.537-40.811-42.2-56.961C270.072,30.279,228.3,16,184,16S97.928,30.279,66.364,56.206C33.886,82.885,16,118.63,16,156.857c0,35.8,16.352,70.295,45.25,96.243a188.4,188.4,0,0,1-40.563,60.729L16,318.515V352H32a190.643,190.643,0,0,0,85.231-20.125,157.3,157.3,0,0,1-5.071-33.645A158.729,158.729,0,0,1,60.185,317.476Z' class='ci-primary'/>`,
]

const cilGPSLocation = [
  '512 512',
  `<path fill='var(--ci-primary-color, currentColor)' d='M272,88.766V16H240V88.766C157.46,96.6,92.134,164.4,88.2,248H16v32H89.722C100.657,356.113,162.8,415.905,240,423.234V496h32V423.234C349.2,415.905,411.343,356.113,422.278,280H496V248H423.805C419.866,164.4,354.54,96.6,272,88.766ZM256,392c-74.99,0-136-61.01-136-136s61.01-136,136-136,136,61.009,136,136S330.99,392,256,392Z' class='ci-primary'/><path fill='var(--ci-primary-color, currentColor)' d='M256,160a96,96,0,1,0,96,96A96.108,96.108,0,0,0,256,160Zm0,160a64,64,0,1,1,64-64A64.072,64.072,0,0,1,256,320Z' class='ci-primary'/>`,
]

const cilPlusCircle = [
  '512 512',
  `  <path fill="var(--ci-primary-color, currentColor)" d="M256,16C123.452,16,16,123.452,16,256S123.452,496,256,496,496,388.548,496,256,388.548,16,256,16ZM403.078,403.078a207.253,207.253,0,1,1,44.589-66.125A207.332,207.332,0,0,1,403.078,403.078Z" class="ci-primary"/><polygon fill="var(--ci-primary-color, currentColor)" points="272 128 240 128 240 240 128 240 128 272 240 272 240 384 272 384 272 272 384 272 384 240 272 240 272 128" class="ci-primary"/>`,
]

const cilFlower = [
  '512 512',
  `<path fill='var(--ci-primary-color, currentColor)' d='M408,304a168.212,168.212,0,0,0-152,96.5A168.212,168.212,0,0,0,104,304H16v16c0,92.636,75.364,168,168,168H328c92.636,0,168-75.364,168-168V304ZM184,456c-69.581,0-127.124-52.519-135.064-120H104c69.581,0,127.124,52.519,135.064,120Zm144,0H272.936c7.94-67.481,65.483-120,135.064-120h55.064C455.124,403.481,397.581,456,328,456Z' class='ci-primary'/><path fill='var(--ci-primary-color, currentColor)' d='M169.227,262.773a87.36,87.36,0,0,0,24.547,47.453l4.687,4.686h6.627A87.354,87.354,0,0,0,256,298.716a87.356,87.356,0,0,0,50.912,16.2h6.627l4.687-4.686a87.36,87.36,0,0,0,24.547-47.453,87.36,87.36,0,0,0,47.453-24.547l4.686-4.687v-6.627A87.354,87.354,0,0,0,378.716,176a87.356,87.356,0,0,0,16.2-50.912v-6.627l-4.686-4.687a87.36,87.36,0,0,0-47.453-24.547,87.36,87.36,0,0,0-24.547-47.453l-4.687-4.686h-6.627A87.356,87.356,0,0,0,256,53.284a87.354,87.354,0,0,0-50.912-16.2h-6.627l-4.687,4.686a87.36,87.36,0,0,0-24.547,47.453,87.36,87.36,0,0,0-47.453,24.547l-4.686,4.687v6.627A87.356,87.356,0,0,0,133.284,176a87.354,87.354,0,0,0-16.2,50.912v6.627l4.686,4.687A87.36,87.36,0,0,0,169.227,262.773Zm-3.736-98.086a55.571,55.571,0,0,1-16-32.8A55.572,55.572,0,0,1,184,120h16V104a55.572,55.572,0,0,1,11.884-34.506,55.57,55.57,0,0,1,32.8,16L256,96.8l11.313-11.313a55.571,55.571,0,0,1,32.8-16A55.572,55.572,0,0,1,312,104v16h16a55.572,55.572,0,0,1,34.506,11.884,55.571,55.571,0,0,1-16,32.8L335.2,176l11.314,11.314a55.57,55.57,0,0,1,16,32.8A55.572,55.572,0,0,1,328,232H312v16a55.572,55.572,0,0,1-11.884,34.506,55.571,55.571,0,0,1-32.8-16L256,255.2,244.686,266.51a55.57,55.57,0,0,1-32.8,16A55.572,55.572,0,0,1,200,248V232H184a55.572,55.572,0,0,1-34.506-11.884,55.57,55.57,0,0,1,16-32.8L176.8,176Z' class='ci-primary'/>`,
]

const cilTags = [
  '512 512',
  `<path fill='var(--ci-primary-color, currentColor)' d='M511.974,271.891a47.744,47.744,0,0,0-14.706-33.008L311.57,57.98a29.9,29.9,0,0,0-21.2-8.731H257.228l217.754,212.6.092.088a15.907,15.907,0,0,1,.741,22.337l-156.4,173.355,21.953,21.356L499.447,305.85A47.748,47.748,0,0,0,511.974,271.891Z' class='ci-primary'/><path fill='var(--ci-primary-color, currentColor)' d='M426.9,283.161a23.924,23.924,0,0,0-5.231-24.742c-.106-.111-.2-.231-.306-.34L224.307,57.716l-.094-.094a31.791,31.791,0,0,0-22.628-9.373H60.194A44.244,44.244,0,0,0,16,92.443v141.1a32.121,32.121,0,0,0,10.045,23.28l210.32,200.2.077.073a23.817,23.817,0,0,0,16.409,6.508q.447,0,.9-.017a24.111,24.111,0,0,0,6.741-1.217,23.854,23.854,0,0,0,10.047-6.517L421.983,291.232A24.033,24.033,0,0,0,426.9,283.161ZM252.5,428.2,48.077,233.612,48,233.54V92.443A12.208,12.208,0,0,1,60.194,80.249h141.39l191.7,194.918Z' class='ci-primary'/><path fill='var(--ci-primary-color, currentColor)' d='M140,112a52,52,0,1,0,52,52A52.059,52.059,0,0,0,140,112Zm0,72a20,20,0,1,1,20-20A20.023,20.023,0,0,1,140,184Z' class='ci-primary'/>`,
]

const cilFilter = [
  '512 512',
  `<path fill='var(--ci-primary-color, currentColor)' d='M238.627,496H192V253.828l-168-200V16H480V53.612l-160,200V414.627ZM224,464h1.373L288,401.373V242.388L443.51,48H60.9L224,242.172Z' class='ci-primary'/>`,
]

const cilArrowRight = [
  '512 512',
  `<polygon fill='var(--ci-primary-color, currentColor)' points='359.873 121.377 337.246 144.004 433.243 240.001 16 240.001 16 240.002 16 272.001 16 272.002 433.24 272.002 337.246 367.996 359.873 390.623 494.498 256 359.873 121.377' class='ci-primary'/>`,
]

const cilLockUnlocked = [
  '512 512',
  `<path fill='var(--ci-primary-color, currentColor)' d='M384,200V144A128,128,0,0,0,166.418,52.57l22.4,22.855A96,96,0,0,1,352,144v56H88V328c0,92.636,75.364,168,168,168s168-75.364,168-168V200Zm8,128c0,74.99-61.009,136-136,136s-136-61.01-136-136V232H392Z' class='ci-primary'/>`,
]

const cilCompass = [
  '512 512',
  `<path fill='var(--ci-primary-color, currentColor)' d='M209.686,304.963l119.051,59.424a24,24,0,0,0,32.182-32.21l-59.5-118.948L182.333,153.584a24,24,0,0,0-32.216,32.189Zm67.88-67.892,44.006,87.975L233.535,281.1l-44.056-88.149Z' class='ci-primary'/><path fill='var(--ci-primary-color, currentColor)' d='M256,496c132.548,0,240-107.452,240-240S388.548,16,256,16,16,123.452,16,256,123.452,496,256,496ZM48.353,244C54.269,140.018,136.553,56.476,240,48.606V115h32V48.606C375.447,56.476,457.731,140.018,463.647,244H399.971v32h63.068C453.474,376.238,372.78,455.727,272,463.394V403H240v60.394C139.22,455.727,58.526,376.238,48.961,276h63.01V244Z' class='ci-primary'/>`,
]

const cilAt = [
  '512 512',
  `<path fill='var(--ci-primary-color, currentColor)' d='M495.826,232a206.644,206.644,0,0,0-18.882-78.412,227.033,227.033,0,0,0-51.61-71.261C379.708,39.555,319.571,16,256,16A240,240,0,0,0,86.294,425.706a240,240,0,0,0,337.671,1.722l-22.4-22.856A206.824,206.824,0,0,1,256,464C141.309,464,48,370.691,48,256S141.309,48,256,48c112.748,0,208,87.925,208,192v36c0,28.673-25.122,52-56,52s-56-23.327-56-52V160H320v26.751a99.988,99.988,0,1,0,12.55,132.437C347.956,343.62,376.01,360,408,360c48.523,0,88-37.682,88-84V232ZM252,328a68,68,0,1,1,68-68A68.077,68.077,0,0,1,252,328Z' class='ci-primary'/>`,
]

const cilHttps = [
  '512 512',
  `<path fill='var(--ci-primary-color, currentColor)' d='M400,200H388V144a128,128,0,0,0-256,0v56H120a24.028,24.028,0,0,0-24,24V472a24.028,24.028,0,0,0,24,24H400a24.028,24.028,0,0,0,24-24V224A24.028,24.028,0,0,0,400,200ZM164,144a96,96,0,0,1,192,0v56H164ZM392,464H128V232H392Z' class='ci-primary'/><rect width='40' height='40' x='240' y='328' fill='var(--ci-primary-color, currentColor)' class='ci-primary'/>`,
]

const cilDescription = [
  '512 512',
  `<path fill='var(--ci-primary-color, currentColor)' d='M334.627,16H48V496H472V153.373ZM440,166.627V168H320V48h1.373ZM80,464V48H288V200H440V464Z' class='ci-primary'/><rect width='224' height='32' x='136' y='296' fill='var(--ci-primary-color, currentColor)' class='ci-primary'/><rect width='224' height='32' x='136' y='376' fill='var(--ci-primary-color, currentColor)' class='ci-primary'/>`,
]

const cilSignLanguage = [
  '512 512',
  `<path fill='var(--ci-primary-color, currentColor)' d='M445.646,241.836V68a8,8,0,0,0-8-8,59.894,59.894,0,0,0-59.9,59.894v41.929l-8.639,8.64-69.928-69.929A22.751,22.751,0,0,0,272.733,96.4l-56.2-56.2a28,28,0,0,0-39.6,0h0a28,28,0,0,0-2.711,36.453v.434L154.306,57.172a28,28,0,0,0-39.6,39.6L137.334,119.4a28,28,0,0,0-39.6,39.6l28.284,28.283a28,28,0,0,0-39.6,0h0a28,28,0,0,0,0,39.6l14.142,14.142,2.553,2.553a42.93,42.93,0,0,0-28.278,40.3V285.9a42.751,42.751,0,0,0,1.524,11.348A42.927,42.927,0,0,0,40,339.619v2.031a42.927,42.927,0,0,0,36.365,42.367,42.751,42.751,0,0,0-1.524,11.348V397.4A42.906,42.906,0,0,0,117.7,440.254H131.98a42.777,42.777,0,0,0-1.392,10.857v2.032A42.905,42.905,0,0,0,173.444,496H322.551l139.672-31.038V256.906ZM430.223,439.292,319.037,464H174.46a11.886,11.886,0,0,1-11.872-11.854v-.038a11.886,11.886,0,0,1,11.872-11.854h90.588v-32H118.714a11.873,11.873,0,1,1,0-23.746H265.048v-32H83.873a11.873,11.873,0,0,1,0-23.746H265.048v-32H117.7A10.87,10.87,0,0,1,106.841,285.9v-2.031A10.869,10.869,0,0,1,117.7,273.016H334.223V224.265l-46.152-46.151a37.778,37.778,0,0,1-4.489-47.926l82.944,82.943.269.27,63.428,57.661Z' class='ci-primary'/>`,
]

const cilImageBroken = [
  '512 512',
  `<polygon fill='var(--ci-primary-color, currentColor)' points='40 472 472 472 472 312 440 312 440 440 72 440 72 312 40 312 40 472' class='ci-primary'></polygon> <path fill='var(--ci-primary-color, currentColor)' d='M40,265.755l49.373,25.437,53.82-46.829,56.159,50.528L256,247.052l56.648,47.839,56.159-50.528,53.82,46.829L472,265.755V40H40ZM72,72H440V246.244l-12.738,6.564-58.809-51.171-56.471,50.806L256,205.167l-55.982,47.276-56.471-50.806L84.738,252.808,72,246.244Z' class='ci-primary'/>`,
]

const cilImage = [
  '512 512',
  `<path fill='var(--ci-primary-color, currentColor)' d='M40,472H472V40H40ZM440,348.142,328.628,236.769l46.6-46.6L440,254.935ZM72,72H440V209.68l-64.769-64.77L306,214.142l-100-100-134,134Zm0,221.4,134-134,234,234V440H72Z' class='ci-primary'/>`,
]

const cilImagePlus = [
  '512 512',
  `<path fill='var(--ci-primary-color, currentColor)' d='M336,72V40H40V472H472V184H440v25.68l-64.769-64.77L306,214.142l-100-100-134,134V72Zm39.231,118.166L440,254.935v93.207L328.628,236.769ZM206,159.4l234,234V440H72V293.4Z' class='ci-primary'/>`,
]

const cilTruck = [
  '512 512',
  `<path fill='var(--ci-primary-color, currentColor)' d='M441.885,141.649A32.028,32.028,0,0,0,415.669,128H336V80a32.036,32.036,0,0,0-32-32H48A32.036,32.036,0,0,0,16,80V408H69.082a67.982,67.982,0,0,0,133.836,0H309.082a67.982,67.982,0,0,0,133.836,0H496V226.522a23.882,23.882,0,0,0-4.338-13.763ZM47.98,80H304V256H48ZM136,432a36,36,0,1,1,36-36A36.04,36.04,0,0,1,136,432Zm240,0a36,36,0,1,1,36-36A36.04,36.04,0,0,1,376,432Zm88-56H440.994a68,68,0,0,0-129.988,0H200.994a68,68,0,0,0-129.988,0H48V288H464Zm0-120H336V160h79.669L464,229.044Z' class='ci-primary'/>`,
]

const cilResizeHeight = [
  '512 512',
  `<path fill='var(--ci-primary-color, currentColor)' d='M256.236,504,120,367.764V329.608h72v-144H120V146.98L255.766,11.216,392,147.452v38.156H320v144h72v38.627ZM159.1,361.608l97.137,97.137,97.137-97.137H288v-208h64.9L255.766,56.471l-97.138,97.137H224v208Z' class='ci-primary'/>`,
]

const cilMenu = [
  '512 512',
  `<rect width='352' height='32' x='80' y='96' fill='var(--ci-primary-color, currentColor)' class='ci-primary'/><rect width='352' height='32' x='80' y='240' fill='var(--ci-primary-color, currentColor)' class='ci-primary'/><rect width='352' height='32' x='80' y='384' fill='var(--ci-primary-color, currentColor)' class='ci-primary'/>`,
]

const cilDrag = `<svg xmlns='http://www.w3.org/2000/svg' viewBox='64 64 896 896' class='c-icon' role='img'><path fill='var(--ci-primary-color, currentColor)' d='M300 276.5a56 56 0 1056-97 56 56 0 00-56 97zm0 284a56 56 0 1056-97 56 56 0 00-56 97zM640 228a56 56 0 10112 0 56 56 0 00-112 0zm0 284a56 56 0 10112 0 56 56 0 00-112 0zM300 844.5a56 56 0 1056-97 56 56 0 00-56 97zM640 796a56 56 0 10112 0 56 56 0 00-112 0z' class='ci-primary'/></svg>`

const cibWhatsApp = [
  '32 32',
  `<path fill='var(--ci-primary-color, currentColor)' d='M23.328 19.177c-0.401-0.203-2.354-1.156-2.719-1.292-0.365-0.13-0.63-0.198-0.896 0.203-0.26 0.391-1.026 1.286-1.26 1.547s-0.464 0.281-0.859 0.104c-0.401-0.203-1.682-0.62-3.203-1.984-1.188-1.057-1.979-2.359-2.214-2.76-0.234-0.396-0.026-0.62 0.172-0.818 0.182-0.182 0.401-0.458 0.604-0.698 0.193-0.24 0.255-0.401 0.396-0.661 0.13-0.281 0.063-0.5-0.036-0.698s-0.896-2.161-1.229-2.943c-0.318-0.776-0.651-0.677-0.896-0.677-0.229-0.021-0.495-0.021-0.76-0.021s-0.698 0.099-1.063 0.479c-0.365 0.401-1.396 1.359-1.396 3.297 0 1.943 1.427 3.823 1.625 4.104 0.203 0.26 2.807 4.26 6.802 5.979 0.953 0.401 1.693 0.641 2.271 0.839 0.953 0.302 1.823 0.26 2.51 0.161 0.76-0.125 2.354-0.964 2.688-1.901 0.339-0.943 0.339-1.724 0.24-1.901-0.099-0.182-0.359-0.281-0.76-0.458zM16.083 29h-0.021c-2.365 0-4.703-0.641-6.745-1.839l-0.479-0.286-5 1.302 1.344-4.865-0.323-0.5c-1.318-2.099-2.021-4.521-2.021-7.010 0-7.26 5.943-13.182 13.255-13.182 3.542 0 6.865 1.38 9.365 3.88 2.5 2.479 3.88 5.802 3.88 9.323-0.010 7.255-5.948 13.177-13.25 13.177zM27.359 4.599c-3.042-2.938-7.042-4.599-11.297-4.599-8.776 0-15.922 7.115-15.927 15.859 0 2.792 0.729 5.516 2.125 7.927l-2.26 8.214 8.448-2.203c2.328 1.255 4.948 1.927 7.615 1.932h0.005c8.781 0 15.927-7.115 15.932-15.865 0-4.234-1.651-8.219-4.661-11.214z' class='ci-primary c-icon-2xl' />`,
]

const cilBellExclamation = [
  '512 512',
  `
  <path fill='var(--ci-primary-color, currentColor)' d='M451.671,348.569,408,267.945V184c0-83.813-68.187-152-152-152S104,100.187,104,184v83.945L60.329,348.568A24,24,0,0,0,81.432,384h86.944c-.241,2.636-.376,5.3-.376,8a88,88,0,0,0,176,0c0-2.7-.135-5.364-.376-8h86.944a24,24,0,0,0,21.1-35.431ZM312,392a56,56,0,1,1-111.418-8H311.418A55.85,55.85,0,0,1,312,392ZM94.863,352,136,276.055V184a120,120,0,0,1,240,0v92.055L417.137,352Z' class='ci-primary'></path><rect width='32' height='136' x='240' y='112' fill='var(--ci-primary-color, currentColor)' class='ci-primary'></rect><rect width='32' height='32' x='240' y='280' fill='var(--ci-primary-color, currentColor)' class='ci-primary'></rect>
  `,
]

const cilSearch = [
  '512 512',
  `
  <path fill='var(--ci-primary-color, currentColor)' d='M479.6,399.716l-81.084-81.084-62.368-25.767A175.014,175.014,0,0,0,368,192c0-97.047-78.953-176-176-176S16,94.953,16,192,94.953,368,192,368a175.034,175.034,0,0,0,101.619-32.377l25.7,62.2L400.4,478.911a56,56,0,1,0,79.2-79.195ZM48,192c0-79.4,64.6-144,144-144s144,64.6,144,144S271.4,336,192,336,48,271.4,48,192ZM456.971,456.284a24.028,24.028,0,0,1-33.942,0l-76.572-76.572-23.894-57.835L380.4,345.771l76.573,76.572A24.028,24.028,0,0,1,456.971,456.284Z' class='ci-primary'></path>
  `,
]

const cilSmile = [
  '512 512',
  `
    <path fill='var(--ci-primary-color, currentColor)' d='M256,16C123.452,16,16,123.452,16,256S123.452,496,256,496,496,388.548,496,256,388.548,16,256,16ZM403.078,403.078a207.253,207.253,0,1,1,44.589-66.125A207.332,207.332,0,0,1,403.078,403.078Z' class='ci-primary'/>
    <rect width='40' height='40' x='152' y='200' fill='var(--ci-primary-color, currentColor)' class='ci-primary'/>
    <rect width='40' height='40' x='320' y='200' fill='var(--ci-primary-color, currentColor)' class='ci-primary'/>
    <path fill='var(--ci-primary-color, currentColor)' d='M338.289,307.2A83.6,83.6,0,0,1,260.3,360H251.7a83.6,83.6,0,0,1-77.992-52.8l-1.279-3.2H137.968L144,319.081A116,116,0,0,0,251.7,392H260.3A116,116,0,0,0,368,319.081L374.032,304H339.568Z' class='ci-primary'/>
  `,
]

const cilMotorbike = [
  '512 512',
  `
    <path fill='var(--ci-primary-color, currentColor)' d='M396,208a99.837,99.837,0,0,0-24.655,3.079L322.267,104H224v32h77.733l40.272,87.865A99.958,99.958,0,1,0,396,208Zm0,168a67.974,67.974,0,0,1-40.464-122.612l29.919,65.278,29.09-13.332L385.013,240.9A68,68,0,1,1,396,376Z' class='ci-primary'/>
    <path fill='var(--ci-primary-color, currentColor)' d='M16,308a100,100,0,0,0,199.277,12h41.591L304,244.589V208H120v.087c-1.328-.053-2.66-.087-4-.087A100.113,100.113,0,0,0,16,308Zm253.132-68-30,48H213.989a99.825,99.825,0,0,0-24.74-48ZM116,240a68.118,68.118,0,0,1,64.994,48H96v32h86.918A67.993,67.993,0,1,1,116,240Z' class='ci-primary'/>
    <rect width='104' height='32' x='48' y='152' fill='var(--ci-primary-color, currentColor)' class='ci-primary'/>
  `,
]

const cilEye = [
  '512 512',
  `
    <path fill='var(--ci-primary-color, currentColor)' d='M256,144.927A103.309,103.309,0,1,0,359.309,248.236,103.426,103.426,0,0,0,256,144.927Zm0,174.618a71.309,71.309,0,1,1,71.309-71.309A71.39,71.39,0,0,1,256,319.545Z' class='ci-primary'></path><path fill='var(--ci-primary-color, currentColor)' d='M397.222,131.1l-.218-.223c-77.75-77.749-204.258-77.749-282.008,0L16,233.79v28.893l98.778,102.689.218.222a199.409,199.409,0,0,0,282.008,0l99-102.911V233.79ZM464,249.79l-89.732,93.285a167.409,167.409,0,0,1-236.536,0L48,249.79v-3.107L137.729,153.4c65.247-65.13,171.3-65.13,236.542,0L464,246.683Z' class='ci-primary'></path><rect width='32' height='32' x='240' y='232' fill='var(--ci-primary-color, currentColor)' class='ci-primary'></rect>
  `,
]

const cilEyeSlash = [
  '512 512',
  `
    <path fill='var(--ci-primary-color, currentColor)' d='M397.222,131.1l-.218-.223C333.831,67.707,238.47,55.862,163.228,95.346l23.938,23.939c61.571-27.691,136.573-16.327,187.105,34.115L464,246.683v3.107l-71.744,74.585,22.63,22.63L496,262.683V233.79Z' class='ci-primary'></path><path fill='var(--ci-primary-color, currentColor)' d='M352.8,284.33A103.307,103.307,0,0,0,219.907,151.438L246.1,177.63a71.228,71.228,0,0,1,80.507,80.508Z' class='ci-primary'></path><path fill='var(--ci-primary-color, currentColor)' d='M369.9,347.268l-33.831-33.831c.088-.108.179-.212.266-.32l-22.805-22.806c-.083.113-.169.222-.253.334l-99.681-99.681c.112-.083.221-.17.334-.253L191.12,167.906c-.108.087-.213.179-.321.266L38.627,16H16V38.627l95.689,95.689L16,233.79v28.893l98.778,102.689.218.222A199.732,199.732,0,0,0,367.372,390l106,106H496V473.373L392.537,369.911Zm-177.157-131.9L288.871,311.5a71.28,71.28,0,0,1-96.133-96.133ZM137.729,343.073,48,249.79v-3.107l86.319-89.737,35.065,35.064A103.248,103.248,0,0,0,312.226,334.853l32.007,32.007C279.723,406.875,193.711,398.955,137.729,343.073Z' class='ci-primary'></path>
  `,
]

const cilCopy = [
  '512 512',
  ` <polygon fill="var(--ci-primary-color, currentColor)" points="408 432 376 432 376 464 112 464 112 136 144 136 144 104 80 104 80 496 408 496 408 432" class="ci-primary"/><path fill="var(--ci-primary-color, currentColor)" d="M176,16V400H496V153.373L358.627,16ZM464,368H208V48H312V200H464Zm0-200H344V48h1.372L464,166.627Z" class="ci-primary"/>`,
]

const cisXCircle = [
  '512 512',
  `<path xmlns="http://www.w3.org/2000/svg" fill="var(--ci-primary-color, currentColor)" d="M425.706,86.294A240,240,0,0,0,86.294,425.706,240,240,0,0,0,425.706,86.294ZM376.355,333.8,342.414,367.74l-82.106-82.106L178.2,367.74,144.26,333.8l82.106-82.107L144.26,169.586,178.2,135.645l82.107,82.106,82.106-82.106,33.941,33.941-82.106,82.106Z" class="ci-primary"/>`,
]

const iconEnums2 = {
  'cil-thumb-up': cilThumbUp,
  'cil-thumb-down': cilThumbDown,
  'cil-cash': cilCash,
  'cil-location-pin': cilLocationPin,
  'cil-location-pin-plus': cilLocationPinPlus,
  'cil-conversation-bubbles': cilConversationBubbles,
  'cil-flower': cilFlower,
  'cil-tags': cilTags,
  'cil-filter': cilFilter,
  'cil-arrow-right': cilArrowRight,
  'cil-lock-unlocked': cilLockUnlocked,
  'cil-compass': cilCompass,
  'cil-at': cilAt,
  'cil-https': cilHttps,
  'cil-description': cilDescription,
  'cil-eye': cilEye,
  'cil-eye-slash': cilEyeSlash,
  'cil-sign-language': cilSignLanguage,
  'cil-image-broken': cilImageBroken,
  'cil-image': cilImage,
  'cil-image-plus': cilImagePlus,
  'cil-truck': cilTruck,
  'cil-resize-height': cilResizeHeight,
  'cil-menu': cilMenu,
  'cil-drag': cilDrag,
  'cil-smile': cilSmile,
  'cil-bell-exclamation': cilBellExclamation,
  'cil-motorbike': cilMotorbike,
  'cil-search': cilSearch,
  'cil-gps-location': cilGPSLocation,
  'cil-leaf': cilLeaf,
  'cil-plus-circle': cilPlusCircle,
  'cil-copy': cilCopy,
  'cis-x-circle': cisXCircle,

  'cib-whatsapp': cibWhatsApp,

  logo,
  sygnet,
}

interface IconContent {
  content?: string | string[]
  src?: string
}

interface IconName {
  name: string
}

type IconConfig = IconContent | IconName

export const getIconSource = (name: string): IconConfig => {
  const icon = iconEnums2[name]
  return icon
    ? {
        [isArray(icon) || icon.includes('<svg') ? 'content' : 'src']: icon,
      }
    : {
        name,
      }
}

export interface Props extends CIcon {
  color?: string
  name: string
}

const Icon: React.FC<Props> = ({ name, onClick = null, color, className = '', ...props }) => {
  const source = getIconSource(name)

  const clickable = onClick
    ? {
        onClick,
        style: { ...props.style, cursor: 'pointer' },
      }
    : {}

  const colorIsHex = !!color && !!color.match(/\d+/g)

  const style = colorIsHex
    ? {
        ...props.style,
        color,
      }
    : props.style

  return (
    <CoreUIIcon
      {...merge(
        source,
        clickable,
        props,
        {
          className: `${className} ${color && !colorIsHex ? `text-${color}` : ''}`,
        },
        { style }
      )}
    />
  )
}

export default Icon
