export const formKeys = {
  name: 'name',
  ingredientId: 'ingredientId',
}

const { name, ingredientId } = formKeys

export const initialValues = {
  [name]: '',
  [ingredientId]: null,
}
