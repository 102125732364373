import { createStore, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'
import { loadingBarMiddleware } from 'react-redux-loading-bar'

import { errorToast } from '@utils/toast'
import rootReducer from './reducers'
import { LOGOUT } from './constants/auth'

import { constantsSuffixes } from './constants/constantsCreator'

const { REQUEST, ERROR, SUCCESS } = constantsSuffixes

const key = 'root'
const persistConfig = {
  key,
  storage,
  blacklist: ['generic'],
}

const appReducer = (state, action) => {
  if (action.type === LOGOUT) {
    storage.removeItem(`persist:${key}`)
    return rootReducer(undefined, action)
  }

  return rootReducer(state, action)
}

const toastify = (_store) => (next) => (action) => {
  const suffix = action.type?.split('_').pop() ?? ''
  if (suffix === ERROR) {
    errorToast(undefined, {
      toastId: 'abcde',
      containerId: ERROR,
    })
  }

  const result = next(action)
  return result
}

const persistedReducer = persistReducer(persistConfig, appReducer)
const store = createStore(
  persistedReducer,
  applyMiddleware(
    thunk,
    toastify,
    loadingBarMiddleware({
      promiseTypeSuffixes: [REQUEST, SUCCESS, ERROR],
    })
  )
)
const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
// export type AppDispatch = typeof store.dispatch

export { store, persistor }
