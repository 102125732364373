import React, { useEffect, useState } from 'react'
import { useHistory, RouteComponentProps } from 'react-router-dom'

import { compose } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'

import { getIngredientsAction, deleteIngredientAction } from '@actions/ingredient'

import { withRouteParams } from '@utils/helpers'
import { CCol, CRow } from '@coreui/react'
import { Card, Button } from '@components/common'
import { TableButtons } from '@utils/components/tableActions'
import DataTable from '@components/common/Tables/PaginatedTable'

import translations from '@translations'
import { ObjectWithoutKey as Data } from '@utils/interfaces/react'
import { routes } from '@/routes'

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = WithTranslation & PropsFromRedux & RouteComponentProps

interface Ingredient {
  id: string
}

const IngredientsABM: React.FC<Props> = ({
  ingredients,
  requesting,
  location,
  getIngredients,
  deleteIngredient,
}) => {
  const history = useHistory()

  const { newIngredient } = translations.form
  const { detailsTitle, deleteTitle } = translations.generic

  const queryPage = location.search.match(/page=([0-9]+)/)
  const currentPage = Number((!!queryPage && queryPage[1]) || 1)

  const [ingredientList, setIngredientList] = useState<Ingredient[]>([])

  const initialRequest = (queries = {}) => {
    getIngredients({ page: currentPage, ...queries }, setIngredientList)
  }

  const onPageChange = (newPage) => {
    if (currentPage !== newPage) {
      history.push(`${routes.Ingredients}?page=${newPage}`)
    }
  }

  const onSearchChange = (queries, isClear) => {
    if (isClear) initialRequest()
    else initialRequest(queries)
  }

  const goToNewIngredient = () => {
    history.push(routes.NewIngredient)
  }

  const goToDetails = (id) => {
    history.push(withRouteParams(routes.IngredientDetails, { id }))
  }

  const onIngredientDelete = (id) => {
    deleteIngredient(id, () => {
      const currentList = [...ingredientList]
      const index = currentList.findIndex((poly) => poly.id === id)

      currentList.splice(index, 1)
      setIngredientList(currentList)
    })
  }

  useEffect(() => {
    initialRequest()
  }, [currentPage])

  const IngredientActions = ({ item }: { item: any }) => (
    <TableButtons
      buttons={[
        {
          onClick: () => goToDetails(item.id),
          icon: 'cil-description',
          text: detailsTitle,
        },
        {
          onClick: () => onIngredientDelete(item.id),
          icon: 'cil-x',
          color: 'danger',
          text: deleteTitle,
        },
      ]}
    />
  )

  const customFields = [
    {
      title: '',
      width: '10%',
      key: 'actions',
      dataIndex: 'actions',
      fixed: true,
      render: (_, item) => <IngredientActions item={item} />,
      sorter: false,
    },
  ]

  return (
    <CCol>
      <CRow className="d-flex justify-content-end">
        <Button
          className="mx-2"
          onClick={goToNewIngredient}
          color="primary"
          icon="cil-plus-circle"
          text={newIngredient}
        />
      </CRow>
      <CRow>
        <Card className="my-3">
          <DataTable
            loading={requesting}
            data={(ingredientList as any as Data[]) || []}
            queryFields={ingredients?.queryFields}
            pagination={{ current: currentPage, total: ingredients?.count }}
            onPageChange={onPageChange}
            onSearchChange={onSearchChange}
            customFields={customFields}
            fieldsOrder={{ actions: 0 }}
          />
        </Card>
      </CRow>
    </CCol>
  )
}

const mapStateToProps = (state) => ({
  ingredients: state.ingredient.list,
  requesting: state.ingredient.getIngredientsRequesting,
})

const mapDispatchToProps = {
  getIngredients: getIngredientsAction,
  deleteIngredient: deleteIngredientAction,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default compose(withTranslation(), connector)(IngredientsABM)
