import {
  DRIVER_INIT,
  CREATE_DRIVER,
  GET_DRIVERS,
  GET_DRIVER,
  DELETE_DRIVER,
  UPDATE_DRIVER,
} from '../constants/driver'

const driverReducer = (state = DRIVER_INIT, action) => {
  switch (action.type) {
    // CREATE_DRIVER
    case CREATE_DRIVER.REQUEST:
      return {
        ...state,
        createDriverRequesting: true,
      }

    case CREATE_DRIVER.SUCCESS:
      return {
        ...state,
        createDriverSuccess: true,
        createDriverRequesting: false,
      }

    case CREATE_DRIVER.ERROR:
      return {
        ...state,
        createDriverRequesting: false,
        createDriverErrorMessage: action.payload,
      }

    case CREATE_DRIVER.RESET:
      return {
        ...state,
        createDriverRequesting: false,
        createDriverSuccess: false,
        createDriverErrorMessage: '',
      }

    // GET_DRIVERS
    case GET_DRIVERS.REQUEST:
      return {
        ...state,
        getDriversRequesting: true,
      }

    case GET_DRIVERS.SUCCESS:
      return {
        ...state,
        getDriversSuccess: true,
        getDriversRequesting: false,
        list: action.payload,
      }

    case GET_DRIVERS.ERROR:
      return {
        ...state,
        getDriversRequesting: false,
        getDriversErrorMessage: action.payload,
      }

    case GET_DRIVERS.RESET:
      return {
        ...state,
        getDriversRequesting: false,
        getDriversSuccess: false,
        getDriversErrorMessage: '',
      }

    // GET_DRIVER
    case GET_DRIVER.REQUEST:
      return {
        ...state,
        getDriverDetailRequesting: true,
      }

    case GET_DRIVER.SUCCESS:
      return {
        ...state,
        getDriverDetailSuccess: true,
        getDriverDetailRequesting: false,
        detail: action.payload,
      }

    case GET_DRIVER.ERROR:
      return {
        ...state,
        getDriverDetailRequesting: false,
        getDriverDetailErrorMessage: action.payload,
      }

    case GET_DRIVER.RESET:
      return {
        ...state,
        getDriverDetailRequesting: false,
        getDriverDetailSuccess: false,
        getDriverDetailErrorMessage: '',
      }

    // DELETE_DRIVER
    case DELETE_DRIVER.REQUEST:
      return {
        ...state,
        deleteDriverRequesting: true,
      }

    case DELETE_DRIVER.SUCCESS:
      return {
        ...state,
        deleteDriverSuccess: true,
        deleteDriverRequesting: false,
      }

    case DELETE_DRIVER.ERROR:
      return {
        ...state,
        deleteDriverRequesting: false,
        deleteDriverErrorMessage: action.payload,
      }

    case DELETE_DRIVER.RESET:
      return {
        ...state,
        deleteDriverRequesting: false,
        deleteDriverSuccess: false,
        deleteDriverErrorMessage: '',
      }

    // UPDATE_DRIVER
    case UPDATE_DRIVER.REQUEST:
      return {
        ...state,
        updateDriverRequesting: true,
      }

    case UPDATE_DRIVER.SUCCESS:
      return {
        ...state,
        updateDriverSuccess: true,
        updateDriverRequesting: false,
      }

    case UPDATE_DRIVER.ERROR:
      return {
        ...state,
        updateDriverRequesting: false,
        updateDriverErrorMessage: action.payload,
      }

    case UPDATE_DRIVER.RESET:
      return {
        ...state,
        updateDriverRequesting: false,
        updateDriverSuccess: false,
        updateDriverErrorMessage: '',
      }

    default:
      return state
  }
}

export default driverReducer
