import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { successToast, errorToast } from '@utils/toast'
import translations from '@translations'
import { withRouteParams } from '@utils/helpers'

import { addUserAction } from '@actions/user'

import UserForm from '@forms/userForm'
import { Card, Button } from '@components/common/index'

import { CCol } from '@coreui/react'
import { routes } from '@/routes'

const NewUserForm = ({
  match,
  location,
  addUser,
  successIntermediate,
  successFlorist,
  errorIntermediate,
  errorFlorist,
  createdUser,
}) => {
  const history = useHistory()

  const entityType = match?.params?.type ?? location?.state?.type
  const entityId = match?.params?.id ?? location?.state?.id
  const fromDashboard = !match?.params?.id

  const { createUserSuccess, infoUpdatePermissions } = translations.toasts

  const onFormSubmit = (form) => {
    addUser(entityType, entityId, form)
  }

  const routeParams = !fromDashboard && { type: entityType, entityId }

  const goToPermissions = () => {
    const { id, typeId } = createdUser

    const route = fromDashboard ? routes.UserPermissions : routes.EntityUserPermissions
    history.push(
      withRouteParams(route, {
        id,
        ...routeParams,
      }),
      {
        type: typeId,
      }
    )
  }

  const goToDetails = () => {
    const { id } = createdUser

    const route = fromDashboard ? routes.UserDetails : routes.EntityUserDetails
    history.push(withRouteParams(route, { id, ...routeParams }))
  }

  const OperatorCreatedToast = () => (
    <div>
      <p>{`${createUserSuccess}.`}</p>
      <Button link onClick={goToPermissions} text={`${infoUpdatePermissions}.`} />
    </div>
  )

  useEffect(() => {
    if (successFlorist || successIntermediate) {
      successToast(OperatorCreatedToast, { toastId: 'ADD_USER_FLORIST' })
      goToDetails()
    }
  }, [successFlorist, successIntermediate])

  useEffect(() => {
    if (errorIntermediate) errorToast(errorIntermediate, { toastId: 'ADD_USER_FLORIST' })
    if (errorFlorist) errorToast(errorFlorist, { toastId: 'ADD_USER_INTERMEDIATE' })
  }, [errorIntermediate, errorFlorist])

  return (
    <CCol xl={6}>
      <Card className="my-3">
        <UserForm onSubmit={onFormSubmit} />
      </Card>
    </CCol>
  )
}

const mapStateToProps = (state) => ({
  successIntermediate: state.user.addUserIntermediateSuccess,
  successFlorist: state.user.addUserFloristSuccess,
  errorIntermediate: state.user.addUserIntermediateErrorMessage,
  errorFlorist: state.user.addUserFloristErrorMessage,
  createdUser: state.user.created,
})

const mapDispatchToProps = {
  addUser: addUserAction,
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(NewUserForm)
