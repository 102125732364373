import React from 'react'
import { useHistory } from 'react-router-dom'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { Button, Card } from '@components/common/index'

import translations from '@translations'

import { shortTypeEnum } from '@store/constants/entity'

import { CCol, CRow } from '@coreui/react'
import { routes } from '@/routes'
import UsersList from '../entities/EntityDetails/components/UsersList'

const UserABM = ({ location, userInfo, currentFlorist }) => {
  const history = useHistory()

  const { newUser } = translations.form

  const queryPage = location.search.match(/page=([0-9]+)/)
  const currentPage = Number((queryPage && queryPage[1]) ?? 1)

  const isFlorist = userInfo?.type?.id === 3
  const id = isFlorist ? currentFlorist?.id : userInfo?.intermediates[0]?.id

  const type = userInfo?.type?.id ? shortTypeEnum[userInfo?.type?.id] : 'itm'
  const onPageChange = (newPage) => {
    if (currentPage !== newPage) {
      history.push(`${routes.Users}?page=${newPage}`)
    }
  }

  const goToNewUser = () => {
    history.push(routes.AddUser, {
      id,
      type,
    })
  }

  return (
    <CCol xl={12}>
      <CRow className="d-flex justify-content-end">
        <Button onClick={goToNewUser} color="primary" icon="cil-user-follow" text={newUser} />
      </CRow>

      <CRow>
        <Card className="my-3">
          <UsersList
            currentId={userInfo?.id}
            type={type}
            entityId={id}
            currentPage={currentPage}
            onPageChange={onPageChange}
            fromDashboard
          />
        </Card>
      </CRow>
    </CCol>
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.auth.user,
  currentFlorist: state.auth.currentFlorist,
})

export default compose(withTranslation(), connect(mapStateToProps))(UserABM)
