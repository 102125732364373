import React from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import { WithTranslation, withTranslation } from 'react-i18next'

import Col from '@utils/components/ResponsiveColumn'
import { Button, Icon } from '@components/common'

import { Menu, Dropdown } from 'antd'
import { CRow } from '@coreui/react'

import translations from '@translations'

const { Item: MenuItem } = Menu

type PropsFromRedux = ConnectedProps<typeof connector>

interface Action {
  text: string
  onClick: () => void
  icon?: string
}

interface ComposeProps {
  actions: Action[]
}

type Props = ComposeProps & PropsFromRedux & WithTranslation

const OrderActions: React.FC<Props> = ({ actions }) => {
  const { orderActions: orderActionsTranslation } = translations.generic

  return (
    <CRow className="d-flex justify-content-end align-items-center mb-3">
      <Col xl={6} className="d-flex justify-content-end">
        {!!actions?.length && (
          <Dropdown
            trigger={['click']}
            overlay={
              <Menu>
                {actions.map(({ text, onClick, ...rest }) => (
                  <MenuItem
                    key={text}
                    onClick={onClick}
                    icon={rest?.icon && <Icon name={rest.icon} className="mr-1" />}
                  >
                    <div className="mx-1 d-inline-flex align-items-center">{text}</div>
                  </MenuItem>
                ))}
              </Menu>
            }
          >
            <Button text={orderActionsTranslation} icon="cil-star" />
          </Dropdown>
        )}
      </Col>
    </CRow>
  )
}

const mapStateToProps = (state) => ({
  florists: state.order.florists,
  floristRequesting: state.order.getFlNearOrderRequesting,
  driverRequesting: state.driver.getDriversRequesting,
})

const connector = connect(mapStateToProps)

export default compose<React.FC<ComposeProps>>(withTranslation(), connector)(OrderActions)
