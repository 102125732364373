import React from 'react'

import { ToastContainer, toast } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'

import { containerIdEnum } from '../enums/toastEnums'

const ToastContainers = () => {
  const { ERROR, SUCCESS } = containerIdEnum

  const commonProps = {
    autoClose: 5000,
    limit: 6,
    hideProgressBar: true,
    newestOnTop: true,
    closeOnClick: false,
    pauseOnFocusLoss: true,
    draggable: false,
    pauseOnHover: false,
    enableMultiContainer: true,
  }

  return (
    <>
      <ToastContainer {...commonProps} position={toast.POSITION.TOP_CENTER} containerId={ERROR} />

      <ToastContainer
        {...commonProps}
        position={toast.POSITION.BOTTOM_RIGHT}
        containerId={SUCCESS}
      />
    </>
  )
}

export default ToastContainers
