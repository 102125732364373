import { createGenericContext } from '@utils/createGenericContext'
import React from 'react'

export interface EntityDetails {
  id: string
  keys: string[]
  tab?: 'drivers' | 'florists'
  currentPage: number
  onPageChange: (page: number) => void
  type: 'itm' | 'fl'
}

export const [EntityContext, Provider] = createGenericContext<EntityDetails>()

interface ContextProps {
  // value: Partial<EntityDetails>
  value: EntityDetails
}

export const EntityProvider: React.FC<ContextProps> = ({ value, ...props }) => (
  <Provider value={{ ...value }} {...props} />
)
