import React from 'react'
import { withRouter } from 'react-router-dom'

import TheHeader from './TheHeader'
import TheFooter from './TheFooter'
import TheContent from './TheContent'
import TheSidebar from './TheSidebar'

const TheLayout: React.FC = () => (
  <div className="c-app c-default-layout">
    <TheSidebar />
    <div className="c-wrapper">
      <TheHeader />
      <div className="c-body">
        <TheContent />
      </div>
      <TheFooter />
    </div>
  </div>
)

export default withRouter(TheLayout)
