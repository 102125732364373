import React, { memo } from 'react'

import translations from '@translations'

import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'

import { firstToLowercase, isAllowedEntity, isAllowedPermissions } from '@utils/helpers'

import { setSidebar as setSidebarAction } from '@actions/generic'

import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
} from '@coreui/react'

import Icon, { getIconSource } from '../components/common/Icon'
import { protectedRoutes } from '../routes'

const TheSidebar = ({ show, user, setSidebar }) => {
  const { type, permissions } = user ?? { type: {}, permissions: [] }

  const isAllowed = (accessTypes, accessPermissions) =>
    isAllowedEntity(type?.id, accessTypes) && isAllowedPermissions(permissions, accessPermissions)

  const renderNavItem = (routesList, isTemplate = false) => {
    const list = routesList
      .filter((val) => !val.hidden && isAllowed(val.types, val.permissions))
      .map(({ name, path, ...props }) => ({
        _tag: 'CSidebarNavItem',
        name:
          translations.routes[props.prettyName ?? firstToLowercase(name)] ??
          props.prettyName ??
          name,
        to: path,
        icon: getIconSource(props.icon ?? (isTemplate ? 'cil-menu' : 'cil-flower')),
      }))
    return list
  }

  const navigation = [
    ...renderNavItem(protectedRoutes),
    {
      _tag: 'CSidebarNavDivider',
      className: 'm-2',
    },
  ]

  return (
    <CSidebar minimize show={show} onShowChange={(val) => setSidebar(val)}>
      <CSidebarBrand className="d-md-down-none" to="/">
        <Icon className="c-sidebar-brand-full" name="logo" height={35} />
        <Icon className="c-sidebar-brand-minimized" name="sygnet" height={35} />
      </CSidebarBrand>
      <CSidebarNav>
        <CCreateElement
          items={navigation}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle,
          }}
        />
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  )
}

const mapStateToProps = (state) => ({
  show: state.generic.sidebarShow,
  user: state.auth.user,
})

const mapDispatchToProps = {
  setSidebar: setSidebarAction,
}
const connector = connect(mapStateToProps, mapDispatchToProps)

export default compose(connector, withTranslation())(memo(TheSidebar))
