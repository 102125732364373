import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import translations from '@translations'

import { getDriverAction, updateDriverAction } from '@actions/driver'

import DriverForm from '@forms/driver'
import { withRouteParams } from '@utils/helpers'

import { successToast, errorToast } from '@utils/toast'

import { CCol, CLink, CRow } from '@coreui/react'

import { Card } from '@components/common'
import { routes } from '@/routes'

const NewDriverForm = ({ match, success, error, values, getDriver, update, userInfo }) => {
  const history = useHistory()

  const [phoneNumber, setPhoneNumber] = useState('')

  const { id } = match.params
  const { idFlorist } = match.params
  const isFlorist = userInfo?.typeId === 3

  const { editDriver } = translations.form
  const { whatsappButtonDriver } = translations.generic

  const onFormSubmit = (form) => {
    const driver = {
      name: form.firstName,
      lastname: form.lastName,
      phone: form.phoneNumber,
      identification: form.identification,
      id,
    }
    if (driver?.phoneNumber) setPhoneNumber(driver.phoneNumber)
    update(driver)
  }

  useEffect(() => {
    if (success) {
      successToast('updateDriverSuccess', { toastId: 'CREATE_DRIVER' })
      if (isFlorist) {
        history.push(routes.Drivers)
      } else {
        history.push(withRouteParams(routes.Details, { type: 'fl', id: idFlorist }))
      }
    }
  }, [success])

  useEffect(() => {
    if (error) {
      errorToast('createUserError', { toastId: 'CREATE_DRIVER' })
    }
  }, [error])

  const initialRequest = () => {
    getDriver(id)
  }

  useEffect(() => {
    initialRequest()
  }, [])

  const FormHeader = () => (
    <CRow className="justify-content-between align-items-center mx-0">
      {editDriver}
      {!!phoneNumber && (
        <CLink href="" target="_blank">
          <strong>{whatsappButtonDriver}</strong>
        </CLink>
      )}
    </CRow>
  )

  return (
    <CCol xl={12}>
      <Card className="my-3" header={(props) => <FormHeader {...props} />}>
        <DriverForm onSubmit={onFormSubmit} prevValues={values} />
      </Card>
    </CCol>
  )
}

const mapStateToProps = (state) => ({
  success: state.driver.updateDriverSuccess,
  error: state.driver.createDriverError,
  values: state.driver.detail,
  userInfo: state.auth.user,
})

const mapDispatchToProps = {
  getDriver: getDriverAction,
  update: updateDriverAction,
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(NewDriverForm)
