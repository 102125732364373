import React, { useEffect, useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { successToast, errorToast } from '@utils/toast'
import translations from '@translations'
import { isArray } from 'lodash'

import { sendOfferAction } from '@actions/offer'

import { Button, Card, Icon, Image, Tooltip } from '@components/common'
import { CRow } from '@coreui/react'

import { getFloristProductId, getProductTitle, copyTextToClipboard } from '@utils/helpers'
import Gallery from './components/productImagesGallery'
import ProductOffer from './productOffer'

const ProductCard = ({
  id,
  userInfo,
  floristId,
  name,
  image,
  variants,
  success,
  error,
  sendOffer,
  zoneId,
}) => {
  const imFlorist = userInfo?.type.id === 3

  const { sentOffer, errorSendingOffer } = translations.form
  const { viewFloristProductId, copied } = translations.generic

  const [hasCopied, setHasCopied] = useState(false)

  const [selectedVariant, setSelectedVariant] = useState(variants ? variants[0] : null)
  const [selected, setSelected] = useState(0)
  const [variant, setVariant] = useState('')

  const [successfulOffers, setSuccessfulOffers] = useState([])

  let images = []
  if (!!variants && isArray(variants)) {
    images = variants.map((v) => ({
      src: v.image ? v.image : image,
      caption: v.name,
    }))
  }

  useEffect(() => {
    if (variant) {
      if (success) {
        successToast(`${sentOffer} ${variant.name}`, { toastId: 'successSendingOffer' })
        setSuccessfulOffers([...successfulOffers, variant.id])
      }
      if (error)
        errorToast(`${errorSendingOffer} ${variant.name}`, { toastId: 'errorSendingOffer' })
    }
  }, [error, success])

  const onSlideChange = ({ currentIndex }) => {
    if (!!variants && variants[currentIndex]) setSelectedVariant(variants[currentIndex])
  }

  const onSubmitOffer = (val) => {
    const { variantName, variantId } = val
    setVariant({ name: variantName, id: variantId })

    sendOffer({ ...val, zoneId })
  }

  const focusOnVariant = (vId) => {
    const currentIndex = variants.findIndex((v) => v.id === vId)
    if (selected !== currentIndex) setSelected(currentIndex)
  }

  const imageStyle = { height: '170px', width: '100%', objectFit: 'contain' }

  const floristProductId = `#${getFloristProductId(id)}`

  const handleCopyCode = () => {
    copyTextToClipboard(floristProductId).then(() => {
      setHasCopied(true)
      setTimeout(() => {
        setHasCopied(false)
      }, 800)
    })
  }

  const ProductTitle = () =>
    imFlorist ? (
      <p>{getProductTitle(imFlorist, id, name)}</p>
    ) : (
      <div className="d-flex align-items-center justify-content-between" style={{ width: '100%' }}>
        <p style={{ margin: 0 }}>{getProductTitle(imFlorist, id, name)}</p>
        <Tooltip tooltip={viewFloristProductId}>
          <Button onClick={handleCopyCode} link style={{ padding: 0 }}>
            {hasCopied ? copied : floristProductId}
            <Icon name="cil-copy" className="ml-1" />
          </Button>
        </Tooltip>
      </div>
    )

  return (
    <Card header={ProductTitle} className="ml-2 mb-4" bodyClassName="px-0">
      <CRow className="px-3">
        {images.length > 1 ? (
          <Gallery
            images={images}
            onTransitionEnd={onSlideChange}
            imageStyle={imageStyle}
            selected={selected}
            url="https://pf01.cdn.imgeng.in/foto_producto/"
          />
        ) : (
          <div style={imageStyle}>
            <Image
              src={`https://pf01.cdn.imgeng.in/foto_producto/${images[0]?.src ?? image}`}
              style={imageStyle}
            />
          </div>
        )}
      </CRow>

      <CRow className="mt-3">
        {!!variants && variants.length && (
          <ProductOffer
            id={id}
            successList={successfulOffers}
            floristId={floristId}
            variants={variants}
            onSelectVariant={focusOnVariant}
            onSubmit={onSubmitOffer}
            selected={selectedVariant}
            zoneId={zoneId}
          />
        )}
      </CRow>
    </Card>
  )
}

const mapDispatchToProps = {
  sendOffer: sendOfferAction,
}

const mapStateToProps = (state) => ({
  userInfo: state.auth.user,
  success: state.offer.sendOfferSuccess,
  error: state.offer.sendOfferErrorMessage,
})

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(ProductCard)
