import React from 'react'
import { CTooltip } from '@coreui/react'

interface Props extends CTooltip {
  tooltip: CTooltip['content']
  show?: boolean
  auto?: boolean
}

const TooltipWrapper: React.FC<Props> = ({
  children,
  auto = false,
  show = true,
  tooltip,
  ...props
}) => {
  if (!show || (!tooltip && auto)) return <>{children}</>
  return (
    <CTooltip {...props} content={tooltip}>
      {children}
    </CTooltip>
  )
}

export default TooltipWrapper
