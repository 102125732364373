import React from 'react'

import VisibilitySensor from 'react-visibility-sensor'
import { Img } from 'react-image'

const Image = ({ src, ...props }) => {
  const fallback =
    'https://www.redlatambiocultural.org/cosmovisionyritos/wp-content/themes/koji/assets/images/default-fallback-image.png'

  return (
    <VisibilitySensor>
      <Img src={[src, fallback]} {...props} />
    </VisibilitySensor>
  )
}

export default Image
