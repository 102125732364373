import React, { ReactNode } from 'react'
import { Input, Form, InputProps, FormItemProps } from 'antd'

import { isBoolean, isString, partition } from 'lodash'

import { PasswordProps, SearchProps, TextAreaProps } from 'antd/lib/input'
import { Ref } from '@utils/interfaces/react'

import { TableButtons } from '@utils/components/tableActions'
import clsx from 'clsx'
import SearchInput from './SearchInput'
import PasswordInput from './PasswordInput'
import TextAreaInput from './TextAreaInput'

import { Loading, Rows, AutoSize } from './types'

import Icon, { Props as IconProps } from '../Icon'
import { ButtonProps } from '../Button'

const { Item: FormItem } = Form
const { Group: InputGroup } = Input

type ValidationValue = 'success' | 'warning' | 'error' | 'validating'

interface ValidationProps {
  label?: ReactNode
  colon?: boolean
  showIcon?: boolean
  extra?: ReactNode
  labelAlign?: 'right' | 'left'
  required?: boolean
}

interface ShowCountArgs {
  count: number
  maxLength?: number
}

type CustomTextAreaProps = Omit<TextAreaProps, 'autoSize' | 'rows'> & AutoSize & Rows

interface InputTypes {
  textarea?: boolean | CustomTextAreaProps
  search?: boolean
  password?: boolean | PasswordProps
}

type CommonInputProps = InputProps & Omit<SearchProps, 'loading'> & InputTypes

type CustomInputProps = CommonInputProps &
  Rows & {
    inputRef?: Ref<any | null>
  }

const RenderInput: React.FC<CustomInputProps & Loading> = ({
  search,
  textarea,
  password,
  onChange,
  onPressEnter,
  onSearch,
  loading,
  inputRef,
  ...props
}) => {
  const eventProps = {
    onChange,
    onPressEnter,
  }

  const refProp = inputRef ? { ref: inputRef } : {}

  if (search)
    return (
      <SearchInput onSearch={onSearch} loading={loading} {...props} {...eventProps} {...refProp} />
    )

  if (textarea) {
    type InputType = HTMLTextAreaElement
    return (
      <TextAreaInput
        onChange={onChange as any as React.ChangeEventHandler<InputType>}
        onPressEnter={onPressEnter as any as React.KeyboardEventHandler<InputType>}
        {...textarea}
        {...refProp}
        {...props}
      />
    )
  }

  if (password) return <PasswordInput {...props} {...password} {...eventProps} {...refProp} />

  return <Input {...props} {...eventProps} {...refProp} />
}

type HelpMessage = ReactNode | false

interface ButtonGroupType extends ButtonProps {
  before?: boolean
}

interface Props extends CustomInputProps {
  name: string
  id?: string
  max?: number
  validation?: ValidationProps
  error?: HelpMessage
  warning?: HelpMessage
  success?: HelpMessage
  loading?: HelpMessage
  tooltip?: FormItemProps['tooltip']
  lengthCount?: boolean | ((args: ShowCountArgs) => string)
  allowResize?: boolean
  icon?: string | IconProps
  searching?: Loading['loading']
  isDefault?: boolean
  groupBefore?: React.ReactNode
  groupAfter?: React.ReactNode
  showAddon?: boolean
  showGroup?: boolean
  small?: boolean
  buttons?: ButtonGroupType[]
}

type SizeType = 'small' | 'large'

const TextInput: React.FC<Props> = ({
  name,
  id,
  textarea = false,
  search = false,
  password = false,
  error = false,
  warning = false,
  success = false,
  loading = false,
  max,
  lengthCount,
  allowClear = true,
  isDefault = true,
  validation = {
    showIcon: true,
    required: true,
  },
  tooltip,
  rows,
  icon,
  searching = false,
  onSearch,
  groupBefore,
  groupAfter,
  addonAfter,
  addonBefore,
  showAddon = false,
  showGroup = false,
  small,
  size,
  buttons,
  ...props
}) => {
  const commonProps = {
    ...props,
    addonAfter: (!validation?.showIcon || showAddon) && addonAfter,
    addonBefore:
      (!!icon && (isString(icon) ? <Icon name={icon} color="#768192" /> : <Icon {...icon} />)) ||
      addonBefore,
    name,
    id,
    maxLength: max,
    allowClear,
    showCount:
      !password &&
      (isBoolean(lengthCount) ? lengthCount : !!lengthCount && { formatter: lengthCount }),
    defaultValue: isDefault ? props.value : undefined,
    size: small ? ('small' as SizeType) : size,
  }

  const getHelpText = () => {
    if (loading && !isBoolean(loading)) return loading
    if (error && !isBoolean(error)) return error
    if (warning && !isBoolean(warning)) return warning
    if (success && !isBoolean(success)) return success
    return undefined
  }

  const statusText = ((!!loading && 'validating') ||
    (!!error && 'error') ||
    (!!warning && 'warning') ||
    (!!success && 'success') ||
    '') as ValidationValue

  const validationProps = {
    ...validation,
    validateStatus: statusText,
    help: getHelpText(),
    hasFeedback: validation?.showIcon,
    tooltip,
    name,
    id,
  }

  const searchProps = {
    onSearch,
    loading: searching,
  }

  const [buttonsBefore, buttonsAfter] = buttons ? partition(buttons, (b) => !!b.before) : [[], []]

  return (
    <InputGroup compact>
      {(!commonProps.addonBefore || showGroup) && buttonsBefore.length ? (
        <TableButtons
          noPadding
          buttons={buttonsBefore}
          classes={{
            [buttonsBefore.length - 1]: clsx('border-rad-unset', {
              'rounded-start': buttonsBefore.length === 1,
            }),
          }}
        />
      ) : (
        groupBefore
      )}
      <FormItem {...validationProps}>
        <RenderInput
          {...{ search, password }}
          {...commonProps}
          {...searchProps}
          textarea={!isBoolean(textarea) ? { ...textarea, rows } : textarea}
        />
      </FormItem>
      {buttonsAfter.length ? (
        <TableButtons
          noPadding
          keepText
          buttons={buttonsAfter}
          classes={{ 0: clsx('border-rad-unset', { 'rounded-end': buttonsAfter.length === 1 }) }}
        />
      ) : (
        groupAfter
      )}
    </InputGroup>
  )
}

export default TextInput
