import {
  AUTH_INIT,
  LOGIN,
  FORGOT,
  CHANGE,
  VALIDATE_TOKEN,
  LOGOUT,
  SET_USER,
  SET_FLORISTS,
  SET_REDIRECT,
  UNSET_REDIRECT,
  SET_CURRENT_FLORIST,
} from '../constants/auth'

const authReducer = (state = AUTH_INIT, action) => {
  switch (action.type) {
    // LOGIN
    case LOGIN.REQUEST:
      return {
        ...state,
        loginRequesting: true,
      }

    case LOGIN.SUCCESS:
      return {
        ...state,
        loginSuccess: true,
        loginRequesting: false,
        token: action.payload,
      }

    case LOGIN.ERROR:
      return {
        ...state,
        loginRequesting: false,
        loginErrorMessage: action.payload,
      }

    case LOGIN.RESET:
      return {
        ...state,
        loginRequesting: false,
        loginSuccess: false,
        loginErrorMessage: '',
      }

    // FORGOT
    case FORGOT.REQUEST:
      return {
        ...state,
        forgotRequesting: true,
      }

    case FORGOT.SUCCESS:
      return {
        ...state,
        forgotSuccess: true,
        forgotRequesting: false,
      }

    case FORGOT.ERROR:
      return {
        ...state,
        forgotRequesting: false,
        forgotErrorMessage: action.payload,
      }

    case FORGOT.RESET:
      return {
        ...state,
        forgotRequesting: false,
        forgotSuccess: false,
        forgotErrorMessage: '',
      }

    // CHANGE
    case CHANGE.REQUEST:
      return {
        ...state,
        changeRequesting: true,
      }

    case CHANGE.SUCCESS:
      return {
        ...state,
        changeSuccess: true,
        changeRequesting: false,
      }

    case CHANGE.ERROR:
      return {
        ...state,
        changeRequesting: false,
        changeErrorMessage: action.payload,
      }

    case CHANGE.RESET:
      return {
        ...state,
        changeRequesting: false,
        changeSuccess: false,
        changeErrorMessage: '',
      }

    // VALIDATE_TOKEN
    case VALIDATE_TOKEN.REQUEST:
      return {
        ...state,
        validateTokenRequesting: true,
      }

    case VALIDATE_TOKEN.SUCCESS:
      return {
        ...state,
        validateTokenSuccess: true,
        validateTokenRequesting: false,
        token: action.payload,
      }

    case VALIDATE_TOKEN.ERROR:
      return {
        ...state,
        validateTokenRequesting: false,
        validateTokenErrorMessage: action.payload,
      }

    case VALIDATE_TOKEN.RESET:
      return {
        ...state,
        validateTokenRequesting: false,
        validateTokenSuccess: false,
        validateTokenErrorMessage: '',
      }

    case SET_USER:
      return {
        ...state,
        user: action.payload,
      }

    case SET_REDIRECT:
      return {
        ...state,
        redirect: action.payload,
      }

    case UNSET_REDIRECT:
      return {
        ...state,
        redirect: null,
      }

    case SET_FLORISTS:
      return {
        ...state,
        florists: action.payload,
      }

    case SET_CURRENT_FLORIST:
      return {
        ...state,
        currentFlorist: action.payload,
      }

    case LOGOUT:
      return {
        ...state,
        user: {},
        redirect: null,
        token: null,
        florists: {},
        currentFlorist: {},
      }

    default:
      return state
  }
}

export default authReducer
