import {
  addUser,
  deleteUser,
  getPermissions,
  updatePermissions,
  updateUser,
  getUser,
} from '../services/user'

import { entityEnum, entityIdEnum } from '../constants/entity'

import {
  entityConstants,
  GET_USER_PERMISSIONS,
  UPDATE_USER_PERMISSIONS,
  UPDATE_USER,
  GET_USER,
} from '../constants/user'

const addUserAction = (type, id, form) => async (dispatch) => {
  const endpoint = entityEnum[type]
  const idKey = entityIdEnum[type]

  const newForm = {
    user: form,
    [idKey]: id,
  }

  try {
    dispatch({ type: entityConstants.addUser[endpoint].REQUEST })
    const { content, success } = await addUser(endpoint, newForm)

    if (success) dispatch({ type: entityConstants.addUser[endpoint].SUCCESS, payload: content })
  } catch (e) {
    dispatch({
      type: entityConstants.addUser[endpoint].ERROR,
      payload: e.message,
    })
  } finally {
    dispatch({ type: entityConstants.addUser[endpoint].RESET })
  }
}

const deleteUserAction = (type, id, form) => async (dispatch) => {
  const endpoint = entityEnum[type]
  const idKey = entityIdEnum[type]

  const deleteForm = {
    [idKey]: id,
    userId: form,
  }

  try {
    dispatch({ type: entityConstants.deleteUser[endpoint].REQUEST })
    const { content, success } = await deleteUser(endpoint, deleteForm)

    if (success) dispatch({ type: entityConstants.deleteUser[endpoint].SUCCESS, payload: content })
  } catch (e) {
    dispatch({
      type: entityConstants.deleteUser[endpoint].ERROR,
      payload: e.message,
    })
  } finally {
    dispatch({ type: entityConstants.deleteUser[endpoint].RESET })
  }
}

const getPermissionsAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_USER_PERMISSIONS.REQUEST })
    const { content, success } = await getPermissions(id)

    if (success) dispatch({ type: GET_USER_PERMISSIONS.SUCCESS, payload: content })
  } catch (e) {
    dispatch({
      type: GET_USER_PERMISSIONS.ERROR,
      payload: e.message,
    })
  } finally {
    dispatch({ type: GET_USER_PERMISSIONS.RESET })
  }
}

const updateUserAction = (form) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_USER.REQUEST })
    const { content, success } = await updateUser(form)

    if (success) dispatch({ type: UPDATE_USER.SUCCESS, payload: content })
  } catch (e) {
    dispatch({
      type: UPDATE_USER.ERROR,
      payload: e.message,
    })
  } finally {
    dispatch({ type: UPDATE_USER.RESET })
  }
}

const updatePermissionsAction = (id, permissions) => async (dispatch) => {
  const form = { permissions }
  try {
    dispatch({ type: UPDATE_USER_PERMISSIONS.REQUEST })
    const { content, success } = await updatePermissions(id, form)

    if (success) dispatch({ type: UPDATE_USER_PERMISSIONS.SUCCESS, payload: content })
  } catch (e) {
    dispatch({
      type: UPDATE_USER_PERMISSIONS.ERROR,
      payload: e.message,
    })
  } finally {
    dispatch({ type: UPDATE_USER_PERMISSIONS.RESET })
  }
}

const getUserAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_USER.REQUEST })
    const { data, success } = await getUser(id)

    if (success) dispatch({ type: GET_USER.SUCCESS, payload: data })
  } catch (e) {
    dispatch({
      type: GET_USER.ERROR,
      payload: e.message,
    })
  } finally {
    dispatch({ type: GET_USER.RESET })
  }
}

export {
  addUserAction,
  deleteUserAction,
  getPermissionsAction,
  updatePermissionsAction,
  updateUserAction,
  getUserAction,
}
