import React, { useEffect, useState } from 'react'

import { compose } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'

import translations from '@translations'
import { ObjectWithoutKey } from '@utils/interfaces/react'

import { getDriversAction, deleteDriverAction } from '@actions/driver'

import PaginatedTable, { Props as TableProps } from '@components/common/Tables/PaginatedTable'
import { TableButtons } from '@utils/components/tableActions'
import { withRouteParams } from '@utils/helpers'
import { useHistory } from 'react-router-dom'
import { routes } from '@/routes'

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends Partial<TableProps> {
  entityId: string
  currentPage: number
  fromDashboard?: boolean
}

type ExtendedProps = Props & WithTranslation & PropsFromRedux

interface Driver extends ObjectWithoutKey {
  id: string
}

const DriversList: React.FC<ExtendedProps> = ({
  entityId,
  currentPage,
  onPageChange,
  drivers,
  requesting,
  getDrivers,
  deleteDriver,
  deleteSuccess,
  fromDashboard = false,
  ...tableProps
}) => {
  const history = useHistory()
  const [driverList, setDriverList] = useState<Driver[]>([])
  const excludedKeys = ['type', 'createdAt', 'updatedAt', 'deletedAt']

  const { drivers: driversTranslation } = translations.form

  const initialRequest = (overrides = {}) => {
    getDrivers(entityId, setDriverList, { page: currentPage, ...overrides })
  }

  const onSearchChange = (queries, isClear) => {
    if (isClear) initialRequest()
    else initialRequest(queries)
  }

  useEffect(() => {
    initialRequest()
  }, [currentPage])

  useEffect(() => {
    if (deleteSuccess) {
      window.location.reload()
    }
  }, [deleteSuccess])

  const onDriverDeleteClick = (driverId) => {
    deleteDriver(driverId, () => {
      const currentList = [...driverList]
      const index = currentList.findIndex((driver) => driver.id === driverId)

      currentList.splice(index, 1)
      setDriverList(currentList)
    })
  }

  const onDriverEditClick = (id) => {
    history.push(withRouteParams(routes.EditDriver, { idFlorist: entityId, id, type: 'fl' }))
  }

  const customFields = [
    {
      dataIndex: 'driver_actions',
      key: 'driver_actions',
      title: '',
      width: `${fromDashboard ? '10' : '15'}%`,
      fixed: true,
      sorter: false,
      render: (_, item) => (
        <TableButtons
          buttons={[
            {
              onClick: () => onDriverEditClick(item.id),
              icon: 'cil-pencil',
            },
            {
              onClick: () => onDriverDeleteClick(item.id),
              icon: 'cil-user-unfollow',
              color: 'danger',
            },
          ]}
        />
      ),
    },
  ]

  return (
    <PaginatedTable
      {...tableProps}
      loading={requesting}
      data={driverList || []}
      customFields={customFields}
      pagination={{ current: currentPage, total: drivers?.count }}
      onPageChange={onPageChange}
      onSearchChange={onSearchChange}
      verticalScroll={!fromDashboard}
      fixed={['identification']}
      queryFields={drivers?.queryFields ?? []}
      sorter={['lastname', 'name', 'phone', 'identification']}
      fields={{ exclude: excludedKeys, include: ['identification'] }}
      fieldsOrder={{ driver_actions: 0, identification: 0 }}
      emptyList={driversTranslation}
    />
  )
}

const mapStateToProps = (state) => ({
  drivers: state.driver.list,
  requesting: state.driver.getDriversRequesting,
  deleteSuccess: state.driver.deleteDriverSuccess,
})

const mapDispatchToProps = {
  getDrivers: getDriversAction,
  deleteDriver: deleteDriverAction,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default compose<React.FC<Props>>(withTranslation(), connector)(DriversList)
