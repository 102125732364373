import React from 'react'

import CurrencyInput from 'react-currency-input-field'

import { CLabel, CInputGroup, CInputGroupPrepend, CInputGroupText } from '@coreui/react'

import { Icon } from '@components/common/index'

const PriceInput = ({
  label,
  placeholder,
  name,
  error,
  className,
  inputClassName,
  onChange,
  icon,
  intlConfig,
  id,
  inputStyle,
  ...props
}) => (
  <CInputGroup
    className={`${className} 
      ${!!error && 'is-invalid'}`}
  >
    {!!label && <CLabel htmlFor={id}>{label}</CLabel>}

    {!!icon && (
      <CInputGroupPrepend>
        <CInputGroupText>
          <Icon name={icon} />
        </CInputGroupText>
      </CInputGroupPrepend>
    )}

    <CurrencyInput
      id={id}
      name={name}
      placeholder={placeholder}
      decimalScale={2}
      groupSeparator=","
      decimalSeparator="."
      className={`${inputClassName} form-control ${!!error && 'is-invalid'}`}
      onValueChange={onChange}
      intlConfig={intlConfig}
      style={inputStyle}
      {...props}
    />

    {!!error && <div className="invalid-feedback d-inline">{error}</div>}
  </CInputGroup>
)

PriceInput.defaultProps = {
  success: '',
  label: '',
  className: '',
  inputStyle: {},
  name: '',
  icon: 'cil-tags',
  inputClassName: '',
  intlConfig: { locale: 'en-US', currency: 'USD' },
}

export default PriceInput
