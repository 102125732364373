import * as Yup from 'yup'

const productFilterSchema = Yup.object().shape({
  categories: Yup.array().nullable().of(Yup.number().integer().strict()),
  productLines: Yup.array().nullable().of(Yup.number().integer().strict()),
})

export const ZoneSelectorSchema = Yup.number().nullable().integer().strict()

export default productFilterSchema
