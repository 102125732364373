import mapStyles from '@styles/mapStyles'

export const defaultCenter = {
  lat: -34.620414,
  lng: -58.4572541,
}

export const defaultZoom = 11.65

export const defaultRadius = 10000

export const mapOptions = {
  styles: mapStyles,
  disableDefaultUI: true,
  clickableIcons: false,
  keyboardShortcuts: false,
  zoomControl: true,
} as google.maps.MapOptions
