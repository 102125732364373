import {
  OFFER_INIT,
  START_OFFER,
  SEND_OFFER,
  GET_PRODUCTS,
  GET_DASH_OFFERS,
} from '../constants/offer'

const dataReducer = (state = OFFER_INIT, action) => {
  switch (action.type) {
    // START_OFFER
    case START_OFFER.REQUEST:
      return {
        ...state,
        startOfferRequesting: true,
      }

    case START_OFFER.SUCCESS:
      return {
        ...state,
        startOfferSuccess: true,
        startOfferRequesting: false,
      }

    case START_OFFER.ERROR:
      return {
        ...state,
        startOfferRequesting: false,
        startOfferErrorMessage: action.payload,
      }

    case START_OFFER.RESET:
      return {
        ...state,
        startOfferRequesting: false,
        startOfferSuccess: false,
        startOfferErrorMessage: '',
      }

    // SEND_OFFER
    case SEND_OFFER.REQUEST:
      return {
        ...state,
        sendOfferRequesting: true,
      }

    case SEND_OFFER.SUCCESS:
      return {
        ...state,
        sendOfferSuccess: true,
        sendOfferRequesting: false,
      }

    case SEND_OFFER.ERROR:
      return {
        ...state,
        sendOfferRequesting: false,
        sendOfferErrorMessage: action.payload,
      }

    case SEND_OFFER.RESET:
      return {
        ...state,
        sendOfferRequesting: false,
        sendOfferSuccess: false,
        sendOfferErrorMessage: '',
      }

    // GET_PRODUCTS
    case GET_PRODUCTS.REQUEST:
      return {
        ...state,
        getProductsRequesting: true,
      }

    case GET_PRODUCTS.SUCCESS:
      return {
        ...state,
        getProductsSuccess: true,
        getProductsRequesting: false,
        products: action.payload,
      }

    case GET_PRODUCTS.ERROR:
      return {
        ...state,
        getProductsRequesting: false,
        getProductsErrorMessage: action.payload,
      }

    case GET_PRODUCTS.RESET:
      return {
        ...state,
        getProductsRequesting: false,
        getProductsSuccess: false,
        getProductsErrorMessage: '',
      }

    // GET_DASH_OFFERS
    case GET_DASH_OFFERS.REQUEST:
      return {
        ...state,
        getDashOffersRequesting: true,
      }

    case GET_DASH_OFFERS.SUCCESS:
      return {
        ...state,
        getDashOffersSuccess: true,
        getDashOffersRequesting: false,
        [action.getCount ? 'count' : 'dashboard']: action.payload,
      }

    case GET_DASH_OFFERS.ERROR:
      return {
        ...state,
        getDashOffersRequesting: false,
        getDashOffersErrorMessage: action.payload,
      }

    case GET_DASH_OFFERS.RESET:
      return {
        ...state,
        getDashOffersRequesting: false,
        getDashOffersSuccess: false,
        getDashOffersErrorMessage: '',
      }

    default:
      return state
  }
}

export default dataReducer
