import React, { useState } from 'react'

import { Button, Icon } from '@components/common/index'

import {
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupAppend,
  CInputGroupText,
  CInvalidFeedback,
} from '@coreui/react'

const TextInput = ({
  value,
  placeholder,
  name,
  error = '',
  success = '',
  onChangeText,
  extraData = {},
  className = '',
  inputStyle = {},
  disabled = false,
  maxValue = undefined,
  minValue = undefined,
  append: Append = null,
  size = '',
  small = false,
  ...props
}) => {
  const defaultData = {
    icon: null,
    type: 'text',
    autoComplete: 'false',
  }

  const { icon, type, autoComplete } = {
    ...defaultData,
    ...(name && extraData[name] ? extraData[name] : extraData),
  }

  const [inputType, setInputType] = useState(type)
  const [passwordIcon, setPasswordIcon] = useState('cil-lock-locked')

  const isPassword = type === 'password'

  const togglePassword = () => {
    const newType = inputType === 'password' ? 'text' : 'password'
    setInputType(newType)
    const newIcon = passwordIcon === 'cil-lock-locked' ? 'cil-lock-unlocked' : 'cil-lock-locked'
    setPasswordIcon(newIcon)
  }

  return (
    <CInputGroup
      className={`${className} 
        ${error ? 'invalid' : 'valid'}`}
    >
      {!!icon && (
        <CInputGroupPrepend>
          <CInputGroupText>
            <Icon name={icon} />
          </CInputGroupText>
        </CInputGroupPrepend>
      )}

      <CInput
        style={inputStyle}
        id={name}
        invalid={!!error}
        valid={!!success}
        type={inputType}
        value={value}
        placeholder={placeholder}
        autoComplete={autoComplete}
        onChange={onChangeText}
        max={maxValue}
        min={minValue}
        disabled={disabled}
        size={small ? 'sm' : size}
        {...props}
      />

      {isPassword && (
        <CInputGroupAppend>
          <Button color="secondary" onClick={togglePassword} icon={passwordIcon} />
        </CInputGroupAppend>
      )}

      {!!Append && !isPassword && (
        <CInputGroupAppend>
          <Append />
        </CInputGroupAppend>
      )}

      {!!error && <CInvalidFeedback>{error}</CInvalidFeedback>}
    </CInputGroup>
  )
}

TextInput.defaultProps = {
  success: '',
  className: '',
  inputStyle: {},
  extraData: {},
  name: '',
}

export default TextInput
