import React from 'react'

import { compose } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { withTranslation } from 'react-i18next'

import translations from '@translations'
import { ObjectWithoutKey as Data } from '@utils/interfaces/react'

import { CContainer, CRow } from '@coreui/react'

import { TableBadge } from '@utils/components/tableActions'
import Col from '@utils/components/ResponsiveColumn'

import StripedTable from '@components/common/Tables/StripedTable'
import { Button, Card } from '@components/common'

import { useHistory } from 'react-router-dom'
import { routes } from '@/routes'

type PropsFromRedux = ConnectedProps<typeof connector>

const Profile: React.FC<PropsFromRedux> = ({ userInfo }) => {
  const { changePassword } = translations.form

  const history = useHistory()
  const goToChangePassword = () => {
    history.push(routes.ChangePassword)
  }

  return (
    <CContainer>
      <CRow className="justify-content-center">
        <Col xl={4}>
          <CRow>
            <Card>
              <StripedTable
                data={userInfo as any as Data}
                render={{
                  active: (_, item) => <TableBadge statusId={item.active} />,
                }}
                fields={{ exclude: ['permissions', 'intermediates', 'florists', 'type'] }}
              />
              <Button link icon="cil-pencil" onClick={goToChangePassword} text={changePassword} />
            </Card>
          </CRow>
        </Col>
      </CRow>
    </CContainer>
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.auth.user,
})

const connector = connect(mapStateToProps)

export default compose(withTranslation(), connector)(Profile)
