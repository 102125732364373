import { createConstants, initConstant } from './constantsCreator'

const LOGIN = createConstants('LOGIN')
const FORGOT = createConstants('FORGOT')
const CHANGE = createConstants('CHANGE')
const VALIDATE_TOKEN = createConstants('VALIDATE_TOKEN')

const LOGOUT = 'LOGOUT'
const SET_USER = 'SET_USER'

const SET_REDIRECT = 'SET_REDIRECT'
const UNSET_REDIRECT = 'UNSET_REDIRECT'

const SET_FLORISTS = 'SET_FLORISTS'
const SET_CURRENT_FLORIST = 'SET_CURRENT_FLORIST'
const SET_INTERMEDIATES = 'SET_INTERMEDIATES'
const SET_CURRENT_INTERMEDIATE = 'SET_CURRENT_INTERMEDIATE'

const LOGIN_INIT = {
  ...initConstant(LOGIN),
  token: '',
  redirect: null,
  user: {},
  florists: [],
  currentFlorist: {},
  intermediates: [],
  currentIntermediate: {},
}

const AUTH_INIT = {
  ...LOGIN_INIT,
  ...initConstant(FORGOT),
  ...initConstant(CHANGE),
  ...initConstant(VALIDATE_TOKEN),
}

export {
  LOGIN,
  FORGOT,
  CHANGE,
  VALIDATE_TOKEN,
  LOGOUT,
  SET_USER,
  SET_FLORISTS,
  SET_CURRENT_FLORIST,
  SET_INTERMEDIATES,
  SET_CURRENT_INTERMEDIATE,
  SET_REDIRECT,
  UNSET_REDIRECT,
  AUTH_INIT,
}
