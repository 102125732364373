/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Key, useState, useEffect } from 'react'
import { first, last, isFunction, isArray, compact } from 'lodash'

import { compose } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { getZonesByCountryAction, getCountriesAction } from '@actions/data'

import clsx from 'clsx'

import { Cascader as CascaderAD, CascaderProps } from 'antd'

import MaxTagLabel from '@utils/components/MaxTagLabel'
import { Icon } from '@components/common/index'
import { CInputGroup, CInputGroupPrepend, CInputGroupText } from '@coreui/react'

import { ArrayType, EntityWithId, RealRequired } from '@utils/interfaces/react'

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends Omit<CascaderProps, 'onChange'> {
  error?: string | false
  icon?: string
  getZoneValue?: (zone: EntityWithId) => string
  maxTags?: number
  responsiveTags?: boolean
  onChange: (values: string[] | string) => void
  name: string
}

type ValueType = Key[] | Key[][]

type DataNode = ArrayType<RealRequired<CascaderProps['options']>>

interface CascaderData extends DataNode {
  id?: string
  isZone?: boolean
  checked?: boolean
  loading?: boolean
  children?: CascaderData[]
}

type OptionsWithId = (CascaderData & EntityWithId)[]

const countryPrefix = 'country'

const ZonesSelector: React.FC<Props & PropsFromRedux> = ({
  getZones,
  getCountries,
  countries,
  onChange,
  error,
  icon,
  getZoneValue = (z) => z.id,
  maxTags = 3,
  multiple = false,
  responsiveTags = false,
  className,
  defaultValue,
}) => {
  const [value, setValue] = useState<ValueType>()
  const [data, setData] = useState<CascaderData[]>([])

  useEffect(() => {
    getCountries()
  }, [])

  useEffect(() => {
    if (!!countries && isArray(countries)) {
      setData(
        countries.map((c) => ({
          ...c,
          value: `${countryPrefix}_${c.id}`,
          label: c.name,
          isLeaf: false,
          isZone: false,
          checked: false,
        }))
      )
    }
  }, [countries])

  const getValue = (zone: EntityWithId) => (isFunction(getZoneValue) ? getZoneValue(zone) : zone.id)

  const handleChange = (valuePath, selectedOptions: any[]) => {
    if (multiple && isArray(selectedOptions) && isArray(last(selectedOptions))) {
      const multipleOptions = selectedOptions as OptionsWithId[]
      const zones = compact(
        multipleOptions
          .map((s) => {
            const selected = last(s)
            if (!selected) return null
            if (selected.isZone) return selected

            selected.checked = !selected.checked
            return selected.children as OptionsWithId
          })
          .flat(1)
      )
      onChange(zones.map((z) => getValue(z)))
    } else {
      const simpleOptions = selectedOptions as OptionsWithId
      const selected = last(simpleOptions)
      if (selected) onChange(getValue(selected))
    }

    setValue(valuePath)
  }

  const loadData = async (selectedPath: CascaderData[]) => {
    const country = first(selectedPath)
    if (
      country &&
      country.id &&
      (!country.children || (isArray(country.children) && !country.children.length))
    ) {
      country.loading = true

      await getZones({
        countryId: country.id,
        setList: (list) => {
          if (!!list && isArray(list)) {
            const children = list.map((z) => ({
              ...z,
              label: z.name,
              value: z.id,
              isLeaf: true,
              isZone: true,
            }))

            country.children = children
            setData([...data])
            if (country.checked) setValue(value)
          }

          setTimeout(() => {
            country.loading = false
          }, 350)
        },
      })
    }
  }

  type ResponsiveType = 'responsive'

  const cascaderProps = {
    value: !!value && !!value.length ? value : undefined,
    showSearch: true,
    options: !!data && !!data.length ? data : [],
    maxTagCount: responsiveTags ? ('responsive' as ResponsiveType) : maxTags,
    maxTagPlaceholder: (hiddenValues) => <MaxTagLabel hidden={hiddenValues} />,
    multiple,
  }

  return (
    <CInputGroup className={`${error ? 'invalid' : 'valid'}`}>
      {!!icon && (
        <CInputGroupPrepend>
          <CInputGroupText>
            <Icon name={icon} />
          </CInputGroupText>
        </CInputGroupPrepend>
      )}

      <CascaderAD
        {...cascaderProps}
        loadData={loadData}
        onChange={handleChange}
        className={clsx('form-control', className)}
      />
    </CInputGroup>
  )
}

const mapStateToProps = (state) => ({
  countries: state.data.countries,
})

const mapDispatchToProps = {
  getCountries: getCountriesAction,
  getZones: getZonesByCountryAction,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default compose<React.FC<Props>>(withTranslation(), connector)(ZonesSelector)
