const SET_SIDEBAR = 'SET_SIDEBAR'
const SET_CURRENT_LANGUAGE = 'SET_CURRENT_LANGUAGE'

type AvailableLanguages = 'en' | 'es'

interface LanguageData {
  nativeName: string
  flagIcon: string
  locale: AvailableLanguages
  deepLocale: string
}

export type Languages = {
  [code in AvailableLanguages]: LanguageData
}

const languages: Languages = {
  en: { nativeName: 'English', flagIcon: 'us', locale: 'en', deepLocale: 'en-us' },
  es: { nativeName: 'Español', flagIcon: 'es', locale: 'es', deepLocale: 'es-ar' },
}

const currentLanguage = languages.en

const GENERIC_INIT = {
  sidebarShow: 'responsive',
  languages,
  currentLanguage,
}

export { SET_SIDEBAR, SET_CURRENT_LANGUAGE, GENERIC_INIT }
