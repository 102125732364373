import React, { useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { useHistory } from 'react-router-dom'

import { Formik } from 'formik'

import translations from '@translations'
import { successToast } from '@utils/toast'

import { getPermissionsByTypeAction } from '@actions/entity'
import { getPermissionsAction, updatePermissionsAction } from '@actions/user'

import { Button, Card } from '@components/common/index'

import { CCol, CRow, CForm } from '@coreui/react'
import PermissionSection from './permissionSection'

const UserPermissions = ({
  match,
  location,
  permissions,
  currentPermissions,
  getPermissions,
  getUserPermissions,
  update,
}) => {
  const history = useHistory()

  const { permissionsTitle, cancel } = translations.generic

  const { id } = match.params
  const type = location?.state?.type ?? null

  const initialRequest = () => {
    if (type) getPermissions(type)
    getUserPermissions(id)
  }

  useEffect(() => {
    initialRequest()
  }, [])

  return (
    <CRow>
      <CCol xl={12} className="my-3">
        <Card title={permissionsTitle}>
          <Formik
            initialValues={[]}
            onSubmit={(values) => {
              update(id, values)
              successToast('updatePermissionsSuccess', { toastId: 'ADD_USER_FLORIST' })
            }}
          >
            {({ handleSubmit, setValues, dirty }) => (
              <CForm className="px-3 py-2">
                <PermissionSection
                  title="Permisos florista"
                  permissions={permissions}
                  userPermissions={currentPermissions}
                  onChange={(val) => setValues(val)}
                />

                <CRow className="justify-content-end">
                  <Button
                    color="secondary"
                    className="px-4 mr-2"
                    disabled={!dirty}
                    onClick={history.goBack}
                    text={cancel}
                  />
                  <Button
                    color="primary"
                    className="px-4"
                    disabled={!dirty}
                    onClick={handleSubmit}
                    text="Update"
                  />
                </CRow>
              </CForm>
            )}
          </Formik>
        </Card>
      </CCol>
    </CRow>
  )
}

const mapStateToProps = (state) => ({
  permissions: state.entity.permissions,
  currentPermissions: state.user.userPermissions,
})

const mapDispatchToProps = {
  getPermissions: getPermissionsByTypeAction,
  getUserPermissions: getPermissionsAction,
  update: updatePermissionsAction,
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(UserPermissions)
