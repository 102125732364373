import { baseService } from '@utils/authRequest'

export const getZones = async (id, params) => {
  try {
    return await baseService.get(`/countries/${id}/zones`, { params })
  } catch (e) {
    return e.response?.data.error
  }
}

export const getCountries = async () => {
  try {
    return await baseService.get('countries')
  } catch (e) {
    return e.response?.data.error
  }
}

export const getSites = async () => {
  try {
    return await baseService.get('sites')
  } catch (e) {
    return e.response?.data.error
  }
}

export const getLines = async () => {
  try {
    return await baseService.get('products/lines')
  } catch (e) {
    return e.response?.data.error
  }
}

export const getCategories = async (params) => {
  try {
    return await baseService.get('products/categories', { params })
  } catch (e) {
    return e.response?.data.error
  }
}

export const getIngredientsParents = async () => {
  try {
    return await baseService.get('ingredients/parents')
  } catch (e) {
    return e.response?.data.error
  }
}

export const getIngredientsChildren = async (id) => {
  try {
    return await baseService.get(`ingredients/${id}/children`)
  } catch (e) {
    return e.response?.data.error
  }
}

export const getProducts = async (params) => {
  try {
    return await baseService.get('products', { params })
  } catch (e) {
    return e.response?.data.error
  }
}

export const getProduct = async (id) => {
  try {
    return await baseService.get(`products/${id}`)
  } catch (e) {
    return e.response?.data.error
  }
}

export const getProductIngredients = async (id) => {
  try {
    return await baseService.get(`products/${id}/ingredients`)
  } catch (e) {
    return e.response?.data.error
  }
}
