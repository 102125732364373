/* eslint-disable react/no-array-index-key */
import React from 'react'

import { ButtonProps } from '@components/common/Button'

import { Button, Tooltip, Icon } from '@components/common'
import { Menu, Dropdown } from 'antd'

import { CButtonGroup, CBadge, CLink } from '@coreui/react'

import { isArray } from 'lodash'
import clsx from 'clsx'
import { getStatusColor, getStatus } from '../enums/accountEnums'

const { Item: MenuItem } = Menu

interface ButtonType extends ButtonProps {
  href?: string
}

interface TableButtonType extends ButtonProps {
  showText?: boolean
}

interface Props {
  buttons: ButtonType[]
  align?: string
  tooltip?: false | string
  disabled?: boolean
  classes?: {
    [index: number]: string
  }
  noPadding?: boolean
  keepText?: boolean
}

export const TableButtons: React.FC<Props> = ({
  buttons,
  disabled = false,
  tooltip = '',
  align = 'flex-end',
  classes = {},
  noPadding = false,
  keepText = false,
}) => {
  const TableButton: React.FC<TableButtonType> = ({
    text = '',
    color = 'secondary',
    className = '',
    showText = false,
    ...button
  }) => (
    <Button
      {...button}
      onClick={button.onClick}
      color={color}
      className={`align-items-center w-100 border-rad-unset ${className}`}
      icon={button.icon}
      text={showText && !button.icon && text}
      tooltip={!tooltip && (button.tooltip || text)}
      disabled={disabled || button.disabled}
    />
  )

  const TableButtonDropdown = ({ buttons: dropdownOptions }) => (
    <Dropdown
      disabled={disabled}
      trigger={['click' /* , 'contextMenu' */]}
      overlay={
        <Menu>
          {dropdownOptions.map((button, index) => (
            <MenuItem
              key={index}
              onClick={button.onClick}
              disabled={disabled || button.disabled}
              icon={
                button.icon && <Icon name={button.icon} color={button.color} className="mr-1" />
              }
            >
              <div className={`mx-1 d-inline-flex align-items-center text-${button.color}`}>
                {button.text}
              </div>
            </MenuItem>
          ))}
        </Menu>
      }
      placement="bottomCenter"
    >
      <Button color="secondary" className="border-rad-unset rounded-end" icon="cil-options" />
    </Dropdown>
  )

  const RoundedButton = ({ button, index }: { button: ButtonProps; index: number }) => {
    const disableBorder = classes[index]?.includes('border-rad-unset') ?? false
    return (
      <TableButton
        {...button}
        showText={keepText && index === 0}
        className={clsx(
          {
            'rounded-start': index === 0 && !disableBorder,
            'rounded-end': index === buttons.length - 1 && !disableBorder,
          },
          button?.className,
          classes[index]
        )}
      />
    )
  }

  const ButtonGroup = () =>
    isArray(buttons) && !!buttons.length && buttons.length > 2 ? (
      <>
        <TableButton {...buttons[0]} className="rounded-start" />
        <TableButtonDropdown buttons={buttons.slice(1)} />
      </>
    ) : (
      <>
        {buttons.map((button, index) =>
          // eslint-disable-next-line react/prop-types
          button.href ? (
            // eslint-disable-next-line react/prop-types
            <CLink href={button.href} target="_blank" key={index}>
              <RoundedButton button={button} index={index} />
            </CLink>
          ) : (
            <RoundedButton key={index} button={button} index={index} />
          )
        )}
      </>
    )

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: align,
        ...(noPadding && { padding: 0 }),
      }}
    >
      <Tooltip auto tooltip={tooltip}>
        <CButtonGroup>
          <ButtonGroup />
        </CButtonGroup>
      </Tooltip>
    </div>
  )
}

export const TableBadge = ({ statusId }: { statusId: boolean | number }): React.ReactElement => {
  const status = getStatus(statusId)
  return <CBadge color={getStatusColor(status)}>{status}</CBadge>
}
