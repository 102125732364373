import Geocode from 'react-geocode'

import { Bounds } from '@utils/interfaces/maps'
import { StringCenter } from '@views/orders/ABM/components/OrderAddressMap'

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_KEY)
Geocode.setLocationType('ROOFTOP')

export interface AddressConfig {
  address: string
  bounds: Bounds
}

export const getAddressFromLatLng = async ({
  lat,
  lng,
}: StringCenter): Promise<AddressConfig | null> => {
  try {
    const { results } = await Geocode.fromLatLng(lat, lng)
    const addressData = results[0]

    const parseLatLng = ({ lat: latitude, lng: longitude }) => ({
      lat: Number(parseFloat(latitude)),
      lng: Number(parseFloat(longitude)),
    })

    const bounds = addressData.geometry.viewport
    const parsedBounds = {
      southwest: parseLatLng(bounds.southwest),
      northeast: parseLatLng(bounds.northeast),
    }

    const address = addressData.address_components
      .filter((c) => !c.types.includes('plus_code'))
      .reduce((l, a) => [...l, a.long_name], [])
      .join(', ')

    return { address, bounds: parsedBounds }
  } catch (error) {
    return null
  }
}
