import { baseService } from '@utils/authRequest'

export const addUser = async (endpoint, form) => {
  try {
    return await baseService.post(`${endpoint}/add-user`, form)
  } catch (e) {
    return e.response?.data.error
  }
}

export const getPermissions = async (id) => {
  try {
    return await baseService.get(`users/${id}/permissions`)
  } catch (e) {
    return e.response?.data.error
  }
}

export const updateUser = async (form) => {
  try {
    return await baseService.put('users/', form)
  } catch (e) {
    return e.response?.data.error
  }
}

export const getUser = async (id) => {
  try {
    return await baseService.get(`users/${id}`)
  } catch (e) {
    return e.response?.data.error
  }
}

export const updatePermissions = async (id, form) => {
  try {
    return await baseService.put(`users/${id}/permissions`, form)
  } catch (e) {
    return e.response?.data.error
  }
}

export const deleteUser = async (endpoint, form) => {
  try {
    return await baseService.put(`${endpoint}/remove-user`, form)
  } catch (e) {
    return e.response?.data.error
  }
}
