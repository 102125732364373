import React from 'react'

import { CSwitch, CRow } from '@coreui/react'

interface Props extends Omit<CSwitch, 'value'> {
  textOn?: string
  textOff?: string
  iconOn?: CSwitch['labelOn']
  iconOff?: CSwitch['labelOff']
  value?: boolean
}

const Switch: React.FC<Props> = ({
  onChange,
  value,
  color = 'secondary',
  textOn,
  textOff,
  iconOn,
  iconOff,
  ...props
}) => (
  <CRow>
    <p className={`mr-2 mb-0 ${!value ? 'text-primary' : 'text-secondary'}`}>
      <strong> {textOff} </strong>
    </p>
    <CSwitch
      {...props}
      shape="pill"
      color={color}
      checked={!!value}
      onChange={onChange}
      labelOn={iconOn}
      labelOff={iconOff}
    />
    <p className={`ml-2 mb-0 ${value ? 'text-primary' : 'text-secondary'}`}>
      <strong> {textOn} </strong>
    </p>
  </CRow>
)

export default Switch
