import React, { useEffect } from 'react'
import useStateRef from 'react-usestateref'

import { withTranslation, WithTranslation } from 'react-i18next'

import translations from '@translations'

import { Upload } from 'antd'
import { InboxOutlined } from '@ant-design/icons'

import { UploadFile } from 'antd/lib/upload/interface'

const { Dragger } = Upload

type FileType = 'image' | 'video' | 'audio'

interface Props extends WithTranslation {
  name: string
  max?: number
  accept?: FileType[]
  clear?: boolean
  setClear?: (clear: boolean) => void
  onChange?: (files: UploadFile[]) => void
  onUpload: (file: UploadFile, base64: string) => Promise<string>
  onRemove?: (file: UploadFile) => void | Promise<void>
}

const FileUploader: React.FC<Props> = ({
  onChange,
  onUpload,
  onRemove,
  setClear,
  clear,
  name,
  max,
  accept = ['image'],
}) => {
  const { clickAndDrag, multipleFilesSupport } = translations.form

  const [_, setFileList, fileList] = useStateRef<UploadFile[]>([])

  useEffect(() => {
    if (clear) {
      setFileList([])
      if (setClear) setClear(false)
    }
  }, [clear])

  const fileToBase64 = ({ file, ...options }) =>
    new Promise<string>((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => {
        if (reader.result) {
          resolve(reader.result.toString())
        } else {
          reject()
        }
      }
      reader.readAsDataURL(file)
    }).then((base64) => {
      onUpload(file, base64).then((url) => {
        if (url) {
          options.onSuccess(url)
        } else options.onError(null, 'aa')
      })
    })

  const changeList = (currentList, file) => {
    const list = [...currentList]
    const index = currentList.findIndex((f) => f.uid === file.uid)

    if (file.status === 'removed') {
      list.splice(index, 1)
      if (onRemove) onRemove(file)
    } else {
      list.splice(index, 1, file)
    }

    return list
  }

  const handleChange = ({ file }) => {
    const currentList = [...fileList.current]
    let list: UploadFile[] = []

    const { status } = file
    if (status === 'uploading') {
      list = [...currentList, file]
    } else {
      list = changeList(currentList, file)

      if ((status === 'done' || status === 'removed') && onChange) {
        onChange(list)
      }
    }

    setFileList(list)
  }

  const fileTypes = {
    image: 'image/*',
    video: 'video/*',
    audio: 'audio/*',
    word: '.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  }

  return (
    <div className="upload-dragger-wrapper">
      <Dragger
        name={name}
        accept={accept.map((t) => (t.length > 1 && t[0] === '.' ? t : fileTypes[t])).join(',')}
        multiple={!(max === 1)}
        maxCount={max}
        height={200}
        customRequest={fileToBase64}
        fileList={fileList.current}
        onChange={handleChange}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">{clickAndDrag}</p>
        <p className="ant-upload-hint">{multipleFilesSupport}</p>
      </Dragger>
    </div>
  )
}

export default withTranslation()(FileUploader)
