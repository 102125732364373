/* eslint-disable func-names */
import * as Yup from 'yup'
import { isNaN } from 'lodash'
import { PhoneTypes } from '../formTypes'
import EntityUserSchema from './entityUserSchema'

export const ZonesSchema = Yup.object().shape({
  zones: Yup.array().of(Yup.number().integer().strict()).min(1),
})

// ZonesSchema.shape() to create second schema on top of first, adding up fields and second schema replacing repeated ones
const FloristSchema = Yup.object().shape({
  username: Yup.string().required('Name is required'),
  openHours: Yup.object()
    .required('Hours are required')
    .test('hours-set', 'Hours are required', function (value) {
      if (!value) {
        return this.createError({
          path: 'openHours',
          message: 'Hours are required',
        })
      }
      return true
    }),
  setIntermediate: Yup.boolean(),
  intermediateId: Yup.number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .when('setIntermediate', {
      is: true,
      then: Yup.number().required('You shall choose an intermediate'),
    }),
  setOperator: Yup.boolean(),
  user: Yup.object().when('setOperator', {
    is: true,
    then: EntityUserSchema,
  }),
  // mainZoneId: Yup.number().integer().strict().required('Zone is required').nullable(),
  phoneNumber: Yup.string()
    .matches(PhoneTypes?.regex, 'Phone number is not valid')
    .required('Phone number is required'),
})

const FloristDataSchema = FloristSchema.concat(ZonesSchema)

export default FloristDataSchema
