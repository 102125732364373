/* eslint-disable func-names */
import * as Yup from 'yup'
import EntityUserSchema from './entityUserSchema'

const IntermediateDataSchema = Yup.object().shape({
  username: Yup.string().required('Name is required'),
  setOperator: Yup.boolean(),
  user: Yup.object().when('setOperator', {
    is: true,
    then: EntityUserSchema,
  }),
  zones: Yup.array()
    .of(Yup.number().integer().strict())
    .test('zone-set', 'At least a zone or a site is required', function (value) {
      const { sites } = this.parent
      if ((!value || !value.length) && (!sites || !sites.length)) {
        return this.createError({
          path: 'zones',
          message: 'At least a zone or a site is required',
        })
      }
      return true
    }),
  sites: Yup.array()
    .of(Yup.number().integer().strict())
    .test('site-set', 'At least a zone or a site is required', function (sites) {
      const { zones } = this.parent
      if ((!zones || !zones.length) && (!sites || !sites.length)) {
        return this.createError({
          path: 'sites',
          message: 'At least a zone or a site is required',
        })
      }
      return true
    }),
})

export default IntermediateDataSchema
