import React, { useEffect } from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import { WithTranslation, withTranslation } from 'react-i18next'

import Col from '@utils/components/ResponsiveColumn'

import translations from '@translations'

import OrderDriverModal from '../ABM/components/OrderDriverModal'
import OrderFloristModal from '../ABM/components/OrderFloristModal'
import OrderImageModal from '../ABM/components/OrderImageModal'
import OrderImagesModal from '../ABM/components/OrderImagesModal'

import { OrderModalContext } from '../ABM/components/context'
import { ActionButtons, useStatusButtons } from '../helpers'

type PropsFromRedux = ConnectedProps<typeof connector>

interface ComposeProps {
  setButtons: (buttons: ActionButtons) => void
}

type Props = ComposeProps & PropsFromRedux & WithTranslation

const OrderModals: React.FC<Props> = ({
  florists,
  floristRequesting,
  driverRequesting,
  setButtons,
}) => {
  const { useDriverModal, setSelectedOrderId, useImageModal, useFloristModal } = OrderModalContext()

  const { openModal: openDriverModal } = useDriverModal()
  const { openModal: openImageModal } = useImageModal()
  const { openModal: openFloristModal } = useFloristModal()

  const buttons = useStatusButtons({
    openFloristModal,
    setSelectedOrderId,
    openDriverModal,
    openImageModal,
    translations: translations.form,
  })

  useEffect(() => {
    if (buttons) setButtons(buttons)
  }, [])

  return (
    <Col>
      <OrderFloristModal loading={floristRequesting} data={florists} />
      {/* <OrderRatingModal loading={floristRequesting} data={floristRating} /> */}
      <OrderDriverModal loading={driverRequesting} />
      <OrderImageModal />
      <OrderImagesModal />
    </Col>
  )
}

const mapStateToProps = (state) => ({
  florists: state.order.florists,
  floristRequesting: state.order.getFlNearOrderRequesting,
  driverRequesting: state.driver.getDriversRequesting,
})

const connector = connect(mapStateToProps)

export default compose<React.FC<ComposeProps>>(withTranslation(), connector)(OrderModals)
