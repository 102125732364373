import React, { useState, useEffect } from 'react'
import { StyleSheet, css, StyleDeclaration } from 'aphrodite'

import { CCard, CCardBody, CCardHeader, CCardFooter, CCollapse } from '@coreui/react'

import styles from '@styles/modules/card.module.css'
import { isFunction } from 'lodash'

interface Props extends CCard {
  bodyClassName?: string
  icon?: string
  footer?: React.ComponentType
  header?: React.ComponentType
  collapsible?: boolean
  showCollapseArrow?: boolean
  close?: boolean
  onOpen?(): void
  arrowPlacement?: 'left' | 'right'
  headerStyle?: StyleDeclaration
  hideBody?: boolean
}

const Card: React.FC<Props> = ({
  className = '',
  collapsible,
  showCollapseArrow,
  title,
  header: Header,
  footer: Footer,
  children,
  headerStyle,
  style,
  open,
  close,
  onOpen,
  arrowPlacement = 'right',
  bodyClassName = '',
  hideBody,
  ...props
}) => {
  const [collapseOpen, setCollapseOpen] = useState(open)

  const classes = StyleSheet.create({
    header: {
      ...headerStyle,
      cursor: 'pointer',
      ':hover': {
        backgroundColor: '#ebedef',
        backgroundClip: 'border-box',
      },
    },
  })

  const toggleCollapse = () => {
    setCollapseOpen(!collapseOpen)
    if (!collapseOpen && !!onOpen && isFunction(onOpen)) onOpen()
  }

  const collapsibleHeaderProps = collapsible
    ? {
        onClick: () => toggleCollapse(),
        className: css(classes.header),
      }
    : { style: headerStyle }

  const mainHeaderProps = !showCollapseArrow && collapsibleHeaderProps

  useEffect(() => {
    if (open) setCollapseOpen(true)
  }, [open])

  useEffect(() => {
    if (close) setCollapseOpen(false)
  }, [close])

  const CollapseArrow = () => {
    if (!showCollapseArrow || !collapsible) return null

    return (
      <svg
        width="18"
        viewBox="0 0 512 512"
        {...collapsibleHeaderProps}
        className={`${styles.arrowTransition} ${collapseOpen ? styles.rotateDown : ''}`}
      >
        <polygon
          fill="var(--ci-primary-color, currentColor)"
          points="390.624 150.625 256 16 121.376 150.625 144.004 173.252 240.001 77.254 240.001 495.236 272.001 495.236 272.001 77.257 367.996 173.252 390.624 150.625"
          className="ci-primary"
        />
      </svg>
    )
  }

  return (
    <CCard className={`w-100 ${className}`} style={style} {...props}>
      {(!!Header || !!title) && (
        <CCardHeader {...mainHeaderProps}>
          <div className="align-items-center justify-content-between d-flex py-2">
            {arrowPlacement === 'left' && <CollapseArrow />}
            {Header ? <Header /> : title}
            {arrowPlacement === 'right' && <CollapseArrow />}
          </div>
        </CCardHeader>
      )}

      {!hideBody &&
        (collapsible ? (
          <CCollapse show={collapseOpen}>
            <CCardBody className={bodyClassName}>{children}</CCardBody>
          </CCollapse>
        ) : (
          <CCardBody className={bodyClassName}>{children}</CCardBody>
        ))}

      {!!Footer && (
        <CCardFooter>
          <Footer />
        </CCardFooter>
      )}
    </CCard>
  )
}

export default Card
