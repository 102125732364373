import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import translations from '@translations'

import useDimensions from 'react-cool-dimensions'

import { PriceWithStatus } from '@components/common/index'

import { badgeStatusEnum, badgeStatusDataEnum } from '@utils/enums/offerEnums'

import Col from '@utils/components/ResponsiveColumn'
import { CRow } from '@coreui/react'

import { ResponsiveTag } from '@utils/components/ResponsiveTag'

interface Props {
  title: string
  priceStatus: string
  offerStatus?: string
  price: number
  newPrice: number | null
  shouldOffer: boolean
}

const VariantHeader: React.FC<Props> = ({
  title,
  priceStatus,
  offerStatus,
  price,
  newPrice,
  shouldOffer,
}) => {
  const genericTranslations = translations.generic
  const { noStockInfo, noStock, youShouldOffer, noOfferTurn } = genericTranslations

  const [showBadges, setShowBadges] = useState(false)

  const { observe } = useDimensions({
    breakpoints: { no: 60, yes: 100 },
    updateOnBreakpointChange: true,
    onResize: ({ currentBreakpoint }) => {
      setShowBadges(currentBreakpoint === 'yes')
    },
  })

  const badgeData = badgeStatusDataEnum[offerStatus] || {}
  const badgeText =
    offerStatus === badgeStatusEnum.PENDING && (shouldOffer ? youShouldOffer : noOfferTurn)
  const badgeProps = {
    ...badgeData,
    text: badgeText || genericTranslations[badgeData.text],
  }

  return (
    <CRow className="align-items-center w-100">
      <Col xl={6} md={6} xs={4} className="px-0">
        <p className="mb-0"> {title} </p>
      </Col>

      <Col className="d-flex align-items-center px-0 ml-2">
        <PriceWithStatus
          status={priceStatus}
          price={price}
          newPrice={newPrice}
          translations={{ noStockInfo, noStock }}
        />
      </Col>

      <Col className="d-flex px-0 justify-content-center" innerRef={observe}>
        {offerStatus && (
          <ResponsiveTag {...badgeProps} status={badgeProps?.color} show={showBadges} />
        )}
        {/* {isDefault && <ResponsiveBadge text={badgeText} show={showBadges} />} */}
      </Col>
    </CRow>
  )
}

export default withTranslation()(VariantHeader)
