import React, { Children } from 'react'

import { isArray } from 'lodash'

import { CCol, CRow } from '@coreui/react'

const TheRow = ({ children }) => {
  const columnWidth = isArray(children) && !!children.length ? 12 / children.length : 12

  return (
    <CRow className="my-2 align-items-center">
      {isArray(children) ? (
        Children.map(children, (item) => columnWidth && <CCol xl={columnWidth}>{item}</CCol>)
      ) : (
        <CCol xl={columnWidth}>{children}</CCol>
      )}
    </CRow>
  )
}

export default TheRow
