import {
  formKeys as passwordKeys,
  initialValues as passwordInitialValues,
} from '../passwordForm/formKeys'

const formKeys = {
  email: 'email',
  passwordRequired: 'passwordRequired',
  firstName: 'firstName',
  lastName: 'lastName',
  ...passwordKeys,
}

const { email, passwordRequired, firstName, lastName } = formKeys

const initialValues = {
  [email]: '',
  [passwordRequired]: false,
  [firstName]: '',
  [lastName]: '',
  ...passwordInitialValues,
}

export { formKeys, initialValues }
