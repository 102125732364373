/* eslint-disable func-names */
import React, { useState, useEffect } from 'react'
import { Modal, Stars, Card, Button, DataTable } from '@components/common'
import { CRow } from '@coreui/react'

import { isArray, isPlainObject } from 'lodash'

import { compose } from 'redux'
import { connect, ConnectedProps } from 'react-redux'

import { withTranslation, WithTranslation } from 'react-i18next'

import { useHistory } from 'react-router-dom'
import translations from '@translations'
import { numberToKM, withRouteParams } from '@utils/helpers'

import { TableButtons } from '@utils/components/tableActions'
import Col from '@utils/components/ResponsiveColumn'
import { successToast } from '@utils/toast'

import {
  assignFloristAction,
  createOrderFloristPriceAction,
  sendMailToFloristsAction,
  getOrderFloristsPricesAction,
} from '@actions/order'

import NewTextInput from '@components/common/NewTextInput'
import { ObjectWithoutKey as Data } from '@utils/interfaces/react'
import { routes } from '@/routes'

import { OrderModalContext } from './context'

interface Florists {
  florists: Record<string, unknown>[]
  otherFlorists: Record<string, unknown>[]
  prices: Record<string, unknown>[]
}

type PropsFromRedux = ConnectedProps<typeof connector>
interface ComposeProps {
  data: Florists
  loading: boolean
}

type Props = ComposeProps & WithTranslation & PropsFromRedux

const OrderFloristModal: React.FC<Props> = ({
  data,
  loading = false,
  assign,
  createOrderFloristPrice,
  sendMailToFlorists,
  successFloristPrice,
  successSendEmail,
  prices,
  succesGetOrderFloristPrices,
  getOrderFloristsPrices,
}) => {
  const [mainFlorists, setMainFlorists] = useState<Florists['florists']>([])
  const [otherFlorists, setOtherFlorists] = useState<Florists['otherFlorists']>([])
  const [finalPrices, setFinalPrices] = useState<Florists['prices']>([])

  const {
    useFloristModal,
    useRatingModal,
    selectedOrderId: orderId,
    setSelectedFloristName,
  } = OrderModalContext()
  const { openModal: openRatingModal } = useRatingModal()
  const { modalOpen, closeModal } = useFloristModal()

  const {
    setFlorist,
    cost,
    saveCost,
    sendEmail,
    inviteFlorist,
    assignFlorist: assignFloristTranslation,
  } = translations.form

  const {
    floristsWithRange,
    anotherFloristsNear,
    floristOrderWhatsapp,
    whatsappButtonFlorist,
    floristInDemand,
    viewNegotiatedCosts,
  } = translations.generic

  const { createOrderFloristPriceSuccess, sendMailToFloristsSuccess } = translations.toasts

  interface FloristData {
    id: string
    username: string
    phone?: string | number
  }

  const history = useHistory()

  const goToFloristProducts = (id, username) => {
    history.push(withRouteParams(routes.FloristProducts, { type: 'fl', id }), {
      username,
    })
  }

  const AssignFloristField = ({ id, username, phone }: FloristData) => (
    <TableButtons
      buttons={[
        {
          onClick: () => assign(orderId, id),
          icon: 'cil-user-follow',
          text: assignFloristTranslation,
          className: 'ml-2',
          color: 'primary',
        },
        {
          onClick: () => goToFloristProducts(id, username),
          icon: 'cil-cash',
          color: 'secondary',
          text: viewNegotiatedCosts,
          className: 'ml-2',
        },
        {
          href: `https://api.whatsapp.com/send?phone=+${
            phone || '5401120202020'
          }&text=${floristOrderWhatsapp({ username })}`,
          target: '_blank',
          text: whatsappButtonFlorist,
          icon: 'cib-whatsapp',
          color: 'success',
        },
      ]}
    />
  )

  const SendCostField = ({ id }: Partial<FloristData>) => {
    const params = { price: null, id: orderId, floristId: id }

    const handleChange = (e) => {
      params.price = e.target.value
    }

    return (
      <td className="py-2">
        <NewTextInput
          name={`${id}-cost`}
          placeholder={`${cost}...`}
          type="number"
          onChange={handleChange}
          buttons={[
            {
              onClick: () => createOrderFloristPrice(params),
              text: saveCost,
            },
          ]}
        />
      </td>
    )
  }

  useEffect(() => {
    if (!!data && isPlainObject(data)) {
      if (isArray(data?.florists)) setMainFlorists(data.florists)
      if (isArray(data?.otherFlorists)) setOtherFlorists(data.otherFlorists)
      getOrderFloristsPrices(orderId)
    }
  }, [data])

  useEffect(() => {
    if (successSendEmail) {
      successToast(sendMailToFloristsSuccess, { toastId: 'sendMailToFlorists' })
    }
  }, [successSendEmail])

  useEffect(() => {
    if (successFloristPrice) {
      successToast(createOrderFloristPriceSuccess, { toastId: 'createOrderFloristPrice' })
      getOrderFloristsPrices(orderId)
    }
  }, [successFloristPrice])

  useEffect(() => {
    if (succesGetOrderFloristPrices) {
      setFinalPrices(prices.content)
    }
  }, [succesGetOrderFloristPrices])

  const goToNewFlorist = () => {
    history.push(routes.NewFlorist)
  }

  const assignFloristRender = {
    assign_florist: {
      title: '',
      width: '5%',
      sorter: false,
      render: (_, item) => <AssignFloristField {...item} />,
    },
  }

  const tableProps = {
    fields: ['username', 'distance', 'rating', 'assign_florist'],
    loading,
    render: {
      rating: {
        width: '15%',
        sorter: false,
        render: (_, item) => (
          <Button
            color="secondary"
            icon="cil-star"
            onClick={() => {
              openRatingModal()
              setSelectedFloristName(item?.username)
            }}
          />
        ),
      },
      distance: {
        render: (_, item) => <p>{`${numberToKM(item.distance)}`}</p>,
      },
      ...assignFloristRender,
    },
  }

  return (
    <Modal
      size="lg"
      title={setFlorist}
      open={modalOpen}
      closeModal={closeModal}
      onDismiss={closeModal}
    >
      <Col>
        <Card title={floristsWithRange}>
          <DataTable scrollable={false} data={mainFlorists as any as Data[]} {...tableProps} />
        </Card>

        <Card title={anotherFloristsNear}>
          <DataTable scrollable={false} data={otherFlorists as any as Data[]} {...tableProps} />
        </Card>

        <Card title={floristInDemand}>
          <DataTable
            scrollable={false}
            data={finalPrices as any as Data[]}
            loading={loading}
            fields={['rating', 'distance', 'username', 'price']}
            render={{
              distance: {
                render: (_, { distance }) => <p>{`${numberToKM(distance)}`}</p>,
              },
              rating: {
                render: (_, { florist }) => (
                  <Button
                    color="secondary"
                    onClick={() => {
                      openRatingModal()
                      setSelectedFloristName(florist)
                    }}
                  >
                    <Stars value={4.5} readonly />
                  </Button>
                ),
              },
            }}
          />
        </Card>

        <Card>
          <DataTable
            scrollable={false}
            data={otherFlorists as any as Data[]}
            fields={['username', 'distance', 'send_cost', 'send_email', 'assign_florist']}
            loading={loading}
            render={{
              distance: {
                render: (_, { distance }) => <p>{`${numberToKM(distance)}`}</p>,
              },
              send_cost: {
                title: saveCost,
                sorter: false,
                render: (_, { id }) => <SendCostField id={id} />,
              },
              send_email: {
                title: sendEmail,
                sorter: false,
                render: (_, { id }) => (
                  <Button
                    text={sendEmail}
                    link
                    onClick={() => {
                      sendMailToFlorists(orderId, id)
                    }}
                  />
                ),
              },
              ...assignFloristRender,
            }}
          />
        </Card>

        <Col>
          <CRow className="mb-1 justify-content-end">
            <Button onClick={goToNewFlorist} link text={inviteFlorist} />
          </CRow>
        </Col>
      </Col>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  successFloristPrice: state.order.setOrderFloristPriceSuccess,
  successSendEmail: state.order.setMailToFloristsSuccess,
  loadingPrice: state.order.setOrderFloristPriceRequesting,
  prices: state.order.prices,
  succesGetOrderFloristPrices: state.order.getOrderFloristsPricesSuccess,
})

const mapDispatchToProps = {
  assign: assignFloristAction,
  createOrderFloristPrice: createOrderFloristPriceAction,
  sendMailToFlorists: sendMailToFloristsAction,
  getOrderFloristsPrices: getOrderFloristsPricesAction,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default compose<React.FC<ComposeProps>>(withTranslation(), connector)(OrderFloristModal)
