import { isFunction, isArray, uniq, map as lodashMap } from 'lodash'

import {
  getOrders,
  getOrderDetail,
  getFlNearOrder,
  setOrderFlorist,
  setOrderStatus,
  setOrderDriver,
  setOrderImagesFeedback,
  setOrderImages,
  uploadImage,
  createOrderFloristPrice,
  sendMailToFlorists,
  getOrderFloristsPrices,
} from '../services/order'

import {
  GET_ORDER,
  GET_ORDERS,
  SET_ORDER_FLORIST,
  GET_FL_NEAR_ORDER,
  GET_ORDER_FLORISTS_PRICES,
  SET_ORDER_STATUS,
  SET_ORDER_DRIVER,
  SET_IMAGES_FEEDBACK,
  SET_IMAGES,
  SET_ORDER_FLORIST_PRICE,
  SET_MAIL_TO_FLORISTS,
} from '../constants/order'

import { entityEnum } from '../constants/entity'

const getOrdersAction =
  (type, id, { setList, limit, ...requestParams }) =>
  async (dispatch) => {
    try {
      dispatch({ type: GET_ORDERS.REQUEST })

      const endpoint = entityEnum[type]
      const params = {
        ...requestParams,
        id,
        limit: limit || 20,
      }

      const { content, success } = await getOrders(endpoint, params)

      if (success) {
        dispatch({
          type: GET_ORDERS.SUCCESS,
          payload: content,
        })
        if (isFunction(setList) && isArray(content.results)) setList(content.results)
      }
    } catch (e) {
      dispatch({
        type: GET_ORDERS.ERROR,
        payload: e.message,
      })
    } finally {
      dispatch({ type: GET_ORDERS.RESET })
    }
  }

const getOrderAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_ORDER.REQUEST })
    const { content, success } = await getOrderDetail(id)

    if (success)
      dispatch({
        type: GET_ORDER.SUCCESS,
        payload: content,
      })
  } catch (e) {
    dispatch({
      type: GET_ORDER.ERROR,
      payload: e.message,
    })
  } finally {
    dispatch({ type: GET_ORDER.RESET })
  }
}

const assignFloristAction = (id, idFlorist) => async (dispatch) => {
  try {
    dispatch({ type: SET_ORDER_FLORIST.REQUEST })
    const { content, success } = await setOrderFlorist(id, idFlorist)

    if (success)
      dispatch({
        type: SET_ORDER_FLORIST.SUCCESS,
        payload: content,
      })
  } catch (e) {
    dispatch({
      type: SET_ORDER_FLORIST.ERROR,
      payload: e.message,
    })
  } finally {
    dispatch({ type: SET_ORDER_FLORIST.RESET })
  }
}

const getFlNearOrderAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_FL_NEAR_ORDER.REQUEST })
    const { content, success } = await getFlNearOrder(id)

    if (success)
      dispatch({
        type: GET_FL_NEAR_ORDER.SUCCESS,
        payload: content,
      })
  } catch (e) {
    dispatch({
      type: GET_FL_NEAR_ORDER.ERROR,
      payload: e.message,
    })
  } finally {
    dispatch({ type: GET_FL_NEAR_ORDER.RESET })
  }
}

const getOrderFloristsPricesAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_ORDER_FLORISTS_PRICES.REQUEST })
    const { content, success } = await getOrderFloristsPrices(id)
    if (success) {
      // TODO: Back calcule ultimo precio por florista
      const prices = uniq(lodashMap(content, 'florist.id')).reduce((o, floristId, index) => {
        o[index] = content.filter((item) => item.florist.id === floristId).pop()
        return o
      }, [])

      dispatch({
        type: GET_ORDER_FLORISTS_PRICES.SUCCESS,
        payload: prices,
      })
    }
  } catch (e) {
    dispatch({
      type: GET_ORDER_FLORISTS_PRICES.ERROR,
      payload: e.message,
    })
  } finally {
    dispatch({ type: GET_ORDER_FLORISTS_PRICES.RESET })
  }
}

const setStatusOrderAction = (id, status) => async (dispatch) => {
  try {
    dispatch({ type: SET_ORDER_STATUS.REQUEST })
    const { content, success } = await setOrderStatus(id, status)

    if (success)
      dispatch({
        type: SET_ORDER_STATUS.SUCCESS,
        payload: content,
      })
  } catch (e) {
    dispatch({
      type: SET_ORDER_STATUS.ERROR,
      payload: e.message,
    })
  } finally {
    dispatch({ type: SET_ORDER_STATUS.RESET })
  }
}

const setDriverOrderAction = (orderId, driverId) => async (dispatch) => {
  try {
    dispatch({ type: SET_ORDER_DRIVER.REQUEST })
    const { content, success } = await setOrderDriver(orderId, driverId)

    if (success)
      dispatch({
        type: SET_ORDER_DRIVER.SUCCESS,
        payload: content,
      })
  } catch (e) {
    dispatch({
      type: SET_ORDER_DRIVER.ERROR,
      payload: e.message,
    })
  } finally {
    dispatch({ type: SET_ORDER_DRIVER.RESET })
  }
}

const setImagesFeedbackAction = (orderId, accepted, successCallback) => async (dispatch) => {
  try {
    dispatch({ type: SET_IMAGES_FEEDBACK.REQUEST })
    const { content, success } = await setOrderImagesFeedback(orderId, accepted)

    if (success) {
      dispatch({
        type: SET_IMAGES_FEEDBACK.SUCCESS,
        payload: content,
      })
      if (successCallback) successCallback()
    }
  } catch (e) {
    dispatch({
      type: SET_IMAGES_FEEDBACK.ERROR,
      payload: e.message,
    })
  } finally {
    dispatch({ type: SET_IMAGES_FEEDBACK.RESET })
  }
}

const setOrderImagesAction = (orderId, ids) => async (dispatch) => {
  try {
    dispatch({ type: SET_IMAGES.REQUEST })
    const { content, success } = await setOrderImages(orderId, ids)

    if (success)
      dispatch({
        type: SET_IMAGES.SUCCESS,
        payload: content,
      })
  } catch (e) {
    dispatch({
      type: SET_IMAGES.ERROR,
      payload: e.message,
    })
  } finally {
    dispatch({ type: SET_IMAGES.RESET })
  }
}

const uploadOrderImage = async (id, image) => uploadImage(id, image)

const createOrderFloristPriceAction =
  ({ id, price, floristId }) =>
  async (dispatch) => {
    try {
      const params = { id, price, floristId }
      dispatch({ type: SET_ORDER_FLORIST_PRICE.REQUEST })
      const { content, success } = await createOrderFloristPrice(params)

      if (success)
        dispatch({
          type: SET_ORDER_FLORIST_PRICE.SUCCESS,
          payload: content,
        })
    } catch (e) {
      dispatch({
        type: SET_ORDER_FLORIST_PRICE.ERROR,
        payload: e.message,
      })
    } finally {
      dispatch({ type: SET_ORDER_FLORIST_PRICE.RESET })
    }
  }

const sendMailToFloristsAction = (id, floristId) => async (dispatch) => {
  try {
    const params = { id, floristId }
    dispatch({ type: SET_MAIL_TO_FLORISTS.REQUEST })
    const { content, success } = await sendMailToFlorists(params)

    if (success)
      dispatch({
        type: SET_MAIL_TO_FLORISTS.SUCCESS,
        payload: content,
      })
  } catch (e) {
    dispatch({
      type: SET_MAIL_TO_FLORISTS.ERROR,
      payload: e.message,
    })
  } finally {
    dispatch({ type: SET_MAIL_TO_FLORISTS.RESET })
  }
}

export {
  getOrdersAction,
  getOrderAction,
  assignFloristAction,
  getFlNearOrderAction,
  getOrderFloristsPricesAction,
  setStatusOrderAction,
  setDriverOrderAction,
  setImagesFeedbackAction,
  uploadOrderImage,
  setOrderImagesAction,
  createOrderFloristPriceAction,
  sendMailToFloristsAction,
}
