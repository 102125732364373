import React, { useEffect } from 'react'

import { compose } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'

import { useHistory, useLocation } from 'react-router-dom'

import translations from '@translations'

import { forgotPasswordAction } from '@actions/auth'

import { CContainer, CRow } from '@coreui/react'

import Col from '@utils/components/ResponsiveColumn'
import { Card, Button } from '@components/common'

import { routes } from '@/routes'

import ForgotPasswordForm from './forgotForm'

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & WithTranslation

interface WithEmail {
  email?: string
}

const ForgotPassword: React.FC<Props> = ({ forgotPassword, success }) => {
  const { forgotPasswordTitle } = translations.generic
  const { resetPassword, backToSignIn } = translations.form
  const history = useHistory()

  useEffect(() => {
    if (success) {
      history.push(routes.Login)
    }
  }, [success])

  const { state } = useLocation<WithEmail>()
  const email = state?.email || ''

  const goToSignIn = () => {
    history.push(routes.Login, {
      email,
    })
  }

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <Col lg={6} md={8} sm={12}>
            <Card
              className="p-4"
              header={() => <h1>{resetPassword}</h1>}
              footer={() => (
                <CRow className="justify-content-end">
                  <Button link text={backToSignIn} onClick={goToSignIn} />
                </CRow>
              )}
            >
              <p className="text-muted">{forgotPasswordTitle}</p>

              <ForgotPasswordForm onSubmit={forgotPassword} defaultValues={{ email }} />
            </Card>
          </Col>
        </CRow>
      </CContainer>
    </div>
  )
}

const mapStateToProps = (state) => ({
  success: state.auth.forgotSuccess,
  error: state.auth.forgotErrorMessage,
})

const mapDispatchToProps = {
  forgotPassword: forgotPasswordAction,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default compose<React.FC>(withTranslation(), connector)(ForgotPassword)
