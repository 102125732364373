import React, { useRef } from 'react'

import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { connect, ConnectedProps } from 'react-redux'

import { Formik } from 'formik'

import translations from '@translations'

import { getGeoJSON, getCenterFromPoint } from '@utils/helpers'
import { PointCoords, Polygon as PolygonType } from '@utils/interfaces/maps'

import { PolygonSchema } from '@utils/schemas/polygonSchema'

import { Button } from '@components/common'
import Col from '@utils/components/ResponsiveColumn'

import PolygonMap from '@components/GoogleMaps/EditablePolygonMap'

import { CRow } from '@coreui/react'

import NewTextInput from '@components/common/NewTextInput'
import { formKeys } from './formKeys'

type PropsFromRedux = ConnectedProps<typeof connector>

interface BackendPolygon {
  id: string
  name: string
  description: string
  data: any
  center: PointCoords | null
}

interface Props {
  onSubmit: (values: any) => void
}

const EditPolygonForm: React.FC<Props & PropsFromRedux> = ({ onSubmit, details: pDetails }) => {
  const { update } = translations.generic
  const { polygonDescription, drawMap, drawMapHint } = translations.form

  const polygonRef = useRef<PolygonType | null>(null)

  const details = pDetails as BackendPolygon

  return (
    <Formik
      initialValues={{
        [formKeys.description]: details.description,
        [formKeys.geojson]: details.data,
        [formKeys.center]: details.center ? getCenterFromPoint(details.center) : null,
      }}
      validationSchema={PolygonSchema}
      onSubmit={onSubmit}
    >
      {({
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
        values,
        errors,
        touched,
        dirty,
      }) => {
        const disabled = !dirty || !!Object.keys(errors).length

        const handlePolygonChange = () => {
          getGeoJSON(polygonRef.current, (geojson, center) => {
            setFieldValue(formKeys.geojson, geojson)
            setFieldValue(formKeys.center, center)
          })
        }

        const handleCenterChange = (center) => {
          setFieldValue(formKeys.center, center)
        }

        return (
          <>
            <CRow className="d-flex">
              <Col xl={8}>
                <h5>{drawMap}</h5>
                <p>{drawMapHint}</p>
              </Col>
            </CRow>

            <CRow>
              <Col xl={8} xxl={6} className="my-3">
                <PolygonMap
                  polygonRef={polygonRef}
                  data={values[formKeys.geojson]}
                  initialCenter={values[formKeys.center]}
                  onPolygonChanged={handlePolygonChange}
                  onCenterChanged={handleCenterChange}
                  width="100%"
                />
              </Col>

              <Col xl={4} className="mt-3">
                <CRow>
                  <Col xl={12}>
                    <NewTextInput
                      isDefault
                      value={values[formKeys.description] as string}
                      placeholder={polygonDescription}
                      name={formKeys.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched[formKeys.description] && !!errors[formKeys.description]
                          ? (errors[formKeys.description] as string)
                          : undefined
                      }
                      icon="cil-description"
                    />
                  </Col>
                </CRow>
              </Col>
            </CRow>

            <CRow className="justify-content-end mt-3 mx-0">
              <Button disabled={disabled} onClick={handleSubmit} text={update} />
            </CRow>
          </>
        )
      }}
    </Formik>
  )
}

const mapStateToProps = (state) => ({
  details: state.polygon.detail,
})

const connector = connect(mapStateToProps)

export default compose<React.FC<Props>>(withTranslation(), connector)(EditPolygonForm)
