export const formKeys = {
  description: 'description',
  setIntermediate: 'setIntermediate',
  idIntermediate: 'idIntermediate',
  orderNumber: 'orderNumber',
  productsLine: 'productsLine',
}

const { description, setIntermediate, idIntermediate, orderNumber, productsLine } = formKeys

export const initialValues = {
  [description]: '',
  [idIntermediate]: -1,
  [setIntermediate]: false,
  [productsLine]: '',
}

export const formTypes = {
  [orderNumber]: {
    icon: 'cil-at',
    type: 'number',
  },
}
