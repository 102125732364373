import {
  DATA_INIT,
  GET_COUNTRIES,
  GET_ZONES,
  GET_SITES,
  GET_LINES,
  GET_CATEGORIES,
  GET_INGREDIENTS_PARENTS,
  GET_INGREDIENTS_CHILDREN,
  GET_PRODUCTS,
  GET_PRODUCT,
  GET_PRODUCT_INGREDIENTS,
} from '../constants/data'

const dataReducer = (state = DATA_INIT, action) => {
  switch (action.type) {
    // GET_ZONES
    case GET_ZONES.REQUEST:
      return {
        ...state,
        getZonesRequesting: true,
      }

    case GET_ZONES.SUCCESS:
      return {
        ...state,
        getZonesSuccess: true,
        getZonesRequesting: false,
        zones: action.payload,
      }

    case GET_ZONES.ERROR:
      return {
        ...state,
        getZonesRequesting: false,
        getZonesErrorMessage: action.payload,
      }

    case GET_ZONES.RESET:
      return {
        ...state,
        getZonesRequesting: false,
        getZonesSuccess: false,
        getZonesErrorMessage: '',
      }

    // GET_COUNTRIES
    case GET_COUNTRIES.REQUEST:
      return {
        ...state,
        getCountriesRequesting: true,
      }

    case GET_COUNTRIES.SUCCESS:
      return {
        ...state,
        getCountriesSuccess: true,
        getCountriesRequesting: false,
        countries: action.payload,
      }

    case GET_COUNTRIES.ERROR:
      return {
        ...state,
        getCountriesRequesting: false,
        getCountriesErrorMessage: action.payload,
      }

    case GET_COUNTRIES.RESET:
      return {
        ...state,
        getCountriesRequesting: false,
        getCountriesSuccess: false,
        getCountriesErrorMessage: '',
      }

    // GET_SITES
    case GET_SITES.REQUEST:
      return {
        ...state,
        getSitesRequesting: true,
      }

    case GET_SITES.SUCCESS:
      return {
        ...state,
        getSitesSuccess: true,
        getSitesRequesting: false,
        sites: action.payload,
      }

    case GET_SITES.ERROR:
      return {
        ...state,
        getSitesRequesting: false,
        getSitesErrorMessage: action.payload,
      }

    case GET_SITES.RESET:
      return {
        ...state,
        getSitesRequesting: false,
        getSitesSuccess: false,
        getSitesErrorMessage: '',
      }

    // GET_LINES
    case GET_LINES.REQUEST:
      return {
        ...state,
        getLinesRequesting: true,
      }

    case GET_LINES.SUCCESS:
      return {
        ...state,
        getLinesSuccess: true,
        getLinesRequesting: false,
        lines: action.payload,
      }

    case GET_LINES.ERROR:
      return {
        ...state,
        getLinesRequesting: false,
        getLinesErrorMessage: action.payload,
      }

    case GET_LINES.RESET:
      return {
        ...state,
        getLinesRequesting: false,
        getLinesSuccess: false,
        getLinesErrorMessage: '',
      }

    // GET_CATEGORIES
    case GET_CATEGORIES.REQUEST:
      return {
        ...state,
        getCategoriesRequesting: true,
      }

    case GET_CATEGORIES.SUCCESS:
      return {
        ...state,
        getCategoriesSuccess: true,
        getCategoriesRequesting: false,
        categories: action.payload,
      }

    case GET_CATEGORIES.ERROR:
      return {
        ...state,
        getCategoriesRequesting: false,
        getCategoriesErrorMessage: action.payload,
      }

    case GET_CATEGORIES.RESET:
      return {
        ...state,
        getCategoriesRequesting: false,
        getCategoriesSuccess: false,
        getCategoriesErrorMessage: '',
      }

    // GET_INGREDIENTS_PARENTS
    case GET_INGREDIENTS_PARENTS.REQUEST:
      return {
        ...state,
        getIngredientsParentsRequesting: true,
      }

    case GET_INGREDIENTS_PARENTS.SUCCESS:
      return {
        ...state,
        getIngredientsParentsSuccess: true,
        getIngredientsParentsRequesting: false,
        ingredientsParents: action.payload,
      }

    case GET_INGREDIENTS_PARENTS.ERROR:
      return {
        ...state,
        getIngredientsParentsRequesting: false,
        getIngredientsParentsErrorMessage: action.payload,
      }

    case GET_INGREDIENTS_PARENTS.RESET:
      return {
        ...state,
        getIngredientsParentsRequesting: false,
        getIngredientsParentsSuccess: false,
        getIngredientsParentsErrorMessage: '',
      }

    // GET_INGREDIENTS_CHILDREN
    case GET_INGREDIENTS_CHILDREN.REQUEST:
      return {
        ...state,
        getIngredientsChildrenRequesting: true,
      }

    case GET_INGREDIENTS_CHILDREN.SUCCESS:
      return {
        ...state,
        getIngredientsChildrenSuccess: true,
        getIngredientsChildrenRequesting: false,
        ingredientsChildren: action.payload,
      }

    case GET_INGREDIENTS_CHILDREN.ERROR:
      return {
        ...state,
        getIngredientsChildrenRequesting: false,
        getIngredientsChildrenErrorMessage: action.payload,
      }

    case GET_INGREDIENTS_CHILDREN.RESET:
      return {
        ...state,
        getIngredientsChildrenRequesting: false,
        getIngredientsChildrenSuccess: false,
        getIngredientsChildrenErrorMessage: '',
      }

    // GET_PRODUCTS
    case GET_PRODUCTS.REQUEST:
      return {
        ...state,
        getProductsRequesting: true,
      }

    case GET_PRODUCTS.SUCCESS:
      return {
        ...state,
        getProductsSuccess: true,
        getProductsRequesting: false,
        products: action.payload,
      }

    case GET_PRODUCTS.ERROR:
      return {
        ...state,
        getProductsRequesting: false,
        getProductsErrorMessage: action.payload,
      }

    case GET_PRODUCTS.RESET:
      return {
        ...state,
        getProductsRequesting: false,
        getProductsSuccess: false,
        getProductsErrorMessage: '',
      }

    // GET_PRODUCT
    case GET_PRODUCT.REQUEST:
      return {
        ...state,
        getProductRequesting: true,
      }

    case GET_PRODUCT.SUCCESS:
      return {
        ...state,
        getProductSuccess: true,
        getProductRequesting: false,
        product: action.payload,
      }

    case GET_PRODUCT.ERROR:
      return {
        ...state,
        getProductRequesting: false,
        getProductErrorMessage: action.payload,
      }

    case GET_PRODUCT.RESET:
      return {
        ...state,
        getProductRequesting: false,
        getProductSuccess: false,
        getProductErrorMessage: '',
      }

    // GET_PRODUCT_INGREDIENTS
    case GET_PRODUCT_INGREDIENTS.REQUEST:
      return {
        ...state,
        getProductIngredientsRequesting: true,
      }

    case GET_PRODUCT_INGREDIENTS.SUCCESS:
      return {
        ...state,
        getProductIngredientsSuccess: true,
        getProductIngredientsRequesting: false,
        productIngredients: action.payload,
      }

    case GET_PRODUCT_INGREDIENTS.ERROR:
      return {
        ...state,
        getProductIngredientsRequesting: false,
        getProductIngredientsErrorMessage: action.payload,
      }

    case GET_PRODUCT_INGREDIENTS.RESET:
      return {
        ...state,
        getProductIngredientsRequesting: false,
        getProductIngredientsSuccess: false,
        getProductIngredientsErrorMessage: '',
      }

    default:
      return state
  }
}

export default dataReducer
