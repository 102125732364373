import React from 'react'
import { Formik } from 'formik'

import ForgotPasswordSchema from '@utils/schemas/forgotPasswordSchema'

import translations from '@translations'

import { NewTextInput } from '@components/common'

import { formKeys } from './forgotFormKeys'

interface Props {
  defaultValues?: any
  onSubmit: (values: any) => void
}

const ForgotForm: React.FC<Props> = ({ onSubmit, defaultValues }) => {
  const { email, sendEmail } = translations.form

  return (
    <Formik
      initialValues={defaultValues}
      validationSchema={ForgotPasswordSchema}
      onSubmit={onSubmit}
    >
      {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
        <>
          <NewTextInput
            icon="cil-user"
            value={values[formKeys.email]}
            placeholder={email}
            name={formKeys.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched[formKeys.email] && errors[formKeys.email] && errors[formKeys.email]}
            buttons={[
              {
                onClick: () => handleSubmit(),
                text: sendEmail,
                className: 'px-4',
                disabled: !values.email || !!Object.keys(errors).length,
                color: 'primary',
              },
            ]}
          />
        </>
      )}
    </Formik>
  )
}

export default ForgotForm
