import React, { useEffect, useState } from 'react'

import { compose } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'

import { useLocation, useHistory } from 'react-router-dom'

import translations from '@translations'

import { loginAction } from '@actions/auth'

import { CContainer, CRow } from '@coreui/react'

import Col from '@utils/components/ResponsiveColumn'
import { Button, Card } from '@components/common'

import { routes } from '@/routes'

import LoginForm from './loginForm'

interface State {
  email?: string
  redirectUrl?: string
}

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & WithTranslation

const Login: React.FC<Props> = ({ login }) => {
  const [currentEmail, setCurrentEmail] = useState('')

  const { signIn, forgotPassword } = translations.form
  const { signInTitle } = translations.generic

  const { state } = useLocation<State>()
  const email = state?.email || ''
  const redirectUrl = state?.redirectUrl || ''

  useEffect(() => {
    if (!!email && !currentEmail) {
      setCurrentEmail(email)
    }
  }, [email])

  const history = useHistory()
  const goToForgotPassword = () => {
    history.push(routes.ForgotPassword, {
      email: currentEmail,
    })
  }

  const handleLogin = (values) => {
    login(values, { redirectUrl })
  }

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <Col lg={6} md={8} sm={12}>
            <Card
              className="p-4"
              header={() => <h1>{signIn}</h1>}
              footer={() => (
                <CRow className="justify-content-end">
                  <Button link text={forgotPassword} onClick={goToForgotPassword} />
                </CRow>
              )}
            >
              <p className="text-muted">{signInTitle}</p>

              <LoginForm
                onEmailChange={setCurrentEmail}
                onSubmit={handleLogin}
                defaultValues={{ email }}
              />
            </Card>
          </Col>
        </CRow>
      </CContainer>
    </div>
  )
}

const mapDispatchToProps = {
  login: loginAction,
}

const connector = connect(null, mapDispatchToProps)

export default compose<React.FC>(withTranslation(), connector)(Login)
