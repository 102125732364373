import React, { useState } from 'react'

import { CCol, CRow } from '@coreui/react'
import { Button, Card, Icon, Image, Tooltip } from '@components/common/index'
import { connect, ConnectedProps } from 'react-redux'
import { copyTextToClipboard, getFloristProductId, getProductTitle } from '@utils/helpers'
import translations from '@translations'

interface Variant {
  description: string
  name: string
}

interface OrderProduct {
  id: string
  name: string
  image: string
  variants: Variant[]
}

interface OrderItem {
  price: number
  product: OrderProduct
}

interface Props {
  products: OrderItem[]
  hidePrice?: boolean
}

type PropsFromRedux = ConnectedProps<typeof connector>

const OrderProductsList: React.FC<Props & PropsFromRedux> = ({
  products,
  hidePrice = false,
  userInfo,
}) => {
  const imFlorist = userInfo?.type?.id === 3
  const { viewFloristProductId, copied } = translations.generic

  const [copiedCode, setCopiedCode] = useState<string | null>(null)

  const handleCopyCode = (productId) => {
    const text = getFloristProductId(productId)
    copyTextToClipboard(`#${text}`).then(() => {
      setCopiedCode(text)
      setTimeout(() => {
        if (copiedCode === text) setCopiedCode(null)
      }, 800)
    })
  }

  const ProductTitle = ({ productId, name }: { productId: string; name: string }) => {
    const floristCode = getFloristProductId(productId)

    return imFlorist ? (
      <p>{getProductTitle(imFlorist, productId, name)}</p>
    ) : (
      <div className="d-flex align-items-center justify-content-between" style={{ width: '100%' }}>
        <p style={{ margin: 0 }}>{getProductTitle(imFlorist, productId, name)}</p>
        <Tooltip tooltip={viewFloristProductId}>
          <Button onClick={() => handleCopyCode(productId)} link style={{ padding: 0 }}>
            {floristCode === copiedCode ? copied : `#${floristCode}`}
            <Icon name="cil-copy" className="ml-1" />
          </Button>
        </Tooltip>
      </div>
    )
  }

  return (
    <CRow className="d-flex my-3">
      {products.map(({ price, product: { id: productId, name, image, variants } }) => (
        <CCol xl={4} key={productId}>
          <Card
            header={() => <ProductTitle productId={productId} name={name} />}
            className="ml-2 mb-4"
          >
            <CRow className="px-3">
              <Image
                src={image}
                style={{
                  height: '50px',
                  width: '35%',
                  objectFit: 'contain',
                }}
              />
            </CRow>

            {variants && !!variants.length && (
              <CCol className="px-0 mt-2">
                <CRow>
                  <CCol>
                    <strong>Description: </strong>
                    {variants[0].description}
                  </CCol>
                </CRow>

                {!hidePrice && (
                  <CRow>
                    <CCol>
                      <strong>Price: </strong>
                      {price}
                    </CCol>
                  </CRow>
                )}

                <CRow>
                  <CCol>
                    <strong>Variant: </strong>
                    {variants[0].name}
                  </CCol>
                </CRow>
              </CCol>
            )}
          </Card>
        </CCol>
      ))}
    </CRow>
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.auth.user,
})

const connector = connect(mapStateToProps)

export default connector(OrderProductsList)
