const formKeys = {
  name: 'name',
  description: 'description',
  geojson: 'geoJson',
  center: 'center',
}

const { name, description, geojson, center } = formKeys

const initialValues = {
  [name]: '',
  [description]: '',
  [geojson]: null,
  [center]: null,
}

export { formKeys, initialValues }
