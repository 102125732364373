import i18n from './i18n'

type GenericTranslations = ReturnType<typeof genericTranslations>
export type FormTranslations = ReturnType<typeof formTranslations>
type ToastTranslations = ReturnType<typeof toastTranslations>
type TableTranslations = ReturnType<typeof tableTranslations>
type RouteTranslations = ReturnType<typeof routeTranslations>

export type Translations = {
  generic: GenericTranslations
  form: FormTranslations
  toasts: ToastTranslations
  table: TableTranslations
  routes: RouteTranslations
}

const genericTranslations = () => ({
  sent: i18n.t('generic.sent', { ns: 'generic' }),
  uploading: i18n.t('generic.uploading', { ns: 'generic' }),
  loading: i18n.t('generic.loading', { ns: 'generic' }),
  update: i18n.t('generic.update', { ns: 'generic' }),
  create: i18n.t('generic.create', { ns: 'generic' }),
  add: i18n.t('generic.add', { ns: 'generic' }),
  edit: i18n.t('generic.edit', { ns: 'generic' }),
  obtainPolygon: i18n.t('generic.get-polygon', { ns: 'generic' }),
  priority: i18n.t('generic.priority', { ns: 'generic' }),
  wait: i18n.t('generic.wait', { ns: 'generic' }),
  welcome: i18n.t('generic.welcome', { ns: 'generic' }),
  error: i18n.t('generic.error', { ns: 'generic' }),
  coordinates: i18n.t('generic.coordinates', { ns: 'generic' }),
  startDate: i18n.t('generic.start-date', { ns: 'generic' }),
  languageSwitch: i18n.t('generic.switch-language', { ns: 'generic' }),
  orders: i18n.t('generic.orders', { ns: 'generic' }),
  noMessage: i18n.t('generic.order.no-message', { ns: 'generic' }),
  addressDisabled: i18n.t('generic.order.address-disabled', { ns: 'generic' }),
  noPhone: i18n.t('generic.florist.no-phone', { ns: 'generic' }),
  orderActions: i18n.t('generic.order.actions', { ns: 'generic' }),
  offers: i18n.t('generic.title.offers', { ns: 'generic' }),
  viewingProducts: i18n.t('generic.title.viewing-products', { ns: 'generic' }),
  new: i18n.t('generic.new', { ns: 'generic' }),
  profile: i18n.t('generic.profile', { ns: 'generic' }),
  logout: i18n.t('generic.logout', { ns: 'generic' }),
  home: i18n.t('generic.home-screen', { ns: 'generic' }),
  operateAs: i18n.t('generic.user.operate-florist', { ns: 'generic' }),
  day: i18n.t('generic.days.day', { ns: 'generic' }),
  monday: i18n.t('generic.days.day-1', { ns: 'generic' }),
  tuesday: i18n.t('generic.days.day-2', { ns: 'generic' }),
  wednesday: i18n.t('generic.days.day-3', { ns: 'generic' }),
  thursday: i18n.t('generic.days.day-4', { ns: 'generic' }),
  friday: i18n.t('generic.days.day-5', { ns: 'generic' }),
  saturday: i18n.t('generic.days.day-6', { ns: 'generic' }),
  sunday: i18n.t('generic.days.day-7', { ns: 'generic' }),
  holidays: i18n.t('generic.days.holidays', { ns: 'generic' }),
  driverWhatsapp: i18n.t('generic.whatsapp.driver', { ns: 'generic' }),
  hello: i18n.t('generic.whatsapp.hello', { ns: 'generic' }),
  viewProducts: i18n.t('generic.title.view-products', { ns: 'generic' }),
  directFloristTitle: i18n.t('generic.title.direct-florist', { ns: 'generic' }),
  changePasswordTitle: i18n.t('generic.title.change', { ns: 'generic' }),
  detailsTitle: i18n.t('generic.title.details', { ns: 'generic' }),
  recipientDetailsTitle: i18n.t('generic.title.recipient-details', { ns: 'generic' }),
  floristsWithRange: i18n.t('generic.title.florists-with-range', { ns: 'generic' }),
  anotherFloristsNear: i18n.t('generic.title.another-near-florists', { ns: 'generic' }),
  floristInDemand: i18n.t('generic.title.florist-in-demand', { ns: 'generic' }),
  permissionsTitle: i18n.t('generic.title.permissions', { ns: 'generic' }),
  productsTitle: i18n.t('generic.title.products', { ns: 'generic' }),
  productsCountTitle: (count: number) =>
    i18n.t('generic.title.products-count', { count, ns: 'generic' }),
  deleteTitle: i18n.t('generic.title.delete', { ns: 'generic' }),
  forgotPasswordTitle: i18n.t('generic.title.forgot', { ns: 'generic' }),
  signInTitle: i18n.t('generic.title.signin', { ns: 'generic' }),
  hoursTitle: i18n.t('generic.title.hours', { ns: 'generic' }),
  daysInWeek: i18n.t('generic.days.in-week', { ns: 'generic' }),
  allDays: i18n.t('generic.days.all', { ns: 'generic' }),
  suggestedCost: i18n.t('generic.products.suggested-cost', { ns: 'generic' }),
  viewFloristProductId: i18n.t('generic.products.florist-product-code', { ns: 'generic' }),
  copied: i18n.t('generic.products.copied-code', { ns: 'generic' }),
  thisProduct: i18n.t('generic.products.this-product', { ns: 'generic' }),
  noStock: i18n.t('generic.offers.no-stock', { ns: 'generic' }),
  setNoStock: i18n.t('generic.offers.set-no-stock', { ns: 'generic' }),
  confirm: i18n.t('generic.offers.confirm', { ns: 'generic' }),
  agreedCost: i18n.t('generic.offers.agreed-cost', { ns: 'generic' }),
  acceptOffer: i18n.t('generic.offers.accept-offer', { ns: 'generic' }),
  noOfferTurn: i18n.t('generic.offers.not-offer-turn', { ns: 'generic' }),
  youShouldOffer: i18n.t('generic.offers.offer-turn', { ns: 'generic' }),
  alreadySetCost: i18n.t('generic.offers.not-set-cost', { ns: 'generic' }),
  cantOffer: i18n.t('generic.offers.cant-offer', { ns: 'generic' }),
  startOffer: i18n.t('generic.offers.start', { ns: 'generic' }),
  startOffering: i18n.t('generic.offers.start-now', { ns: 'generic' }),
  setFirstCost: i18n.t('generic.offers.set-variant-cost', { ns: 'generic' }),
  startWithCost: i18n.t('generic.offers.start-with-cost', { ns: 'generic' }),
  startNegotiatingZone: i18n.t('generic.offers.start-with-zone', { ns: 'generic' }),
  viewMore: i18n.t('generic.view-more', { ns: 'generic' }),
  recommendedCost: i18n.t('generic.default-cost', { ns: 'generic' }),
  yourSetCost: i18n.t('generic.owner-set-cost', { ns: 'generic' }),
  confirmNoStock: i18n.t('generic.offers.confirm-no-stock', { ns: 'generic' }),
  confirmAccepted: i18n.t('generic.offers.confirm-accepted', { ns: 'generic' }),
  accept: i18n.t('generic.accept', { ns: 'generic' }),
  reject: i18n.t('generic.reject', { ns: 'generic' }),
  counteroffer: i18n.t('generic.offers.counteroffer', { ns: 'generic' }),
  forCost: i18n.t('generic.offers.for-cost', { ns: 'generic' }),
  openNegotiations: (interpolation: { count?: number }) =>
    i18n.t('generic.offers.open-negotiations', { ...interpolation, ns: 'generic' }),
  openNegotiation: (interpolation: { count?: number }) =>
    i18n.t('generic.offers.open-negotiation', { ...interpolation, ns: 'generic' }),
  noOffers: i18n.t('generic.empty.offers', { ns: 'generic' }),
  noProducts: i18n.t('generic.empty.products', { ns: 'generic' }),
  noFilters: i18n.t('generic.empty.filters', { ns: 'generic' }),
  viewDetails: i18n.t('generic.view-details', { ns: 'generic' }),
  expand: i18n.t('generic.expand', { ns: 'generic' }),
  from: i18n.t('generic.from', { ns: 'generic' }),
  noStockInfo: i18n.t('generic.info.has-no-stock', { ns: 'generic' }),
  viewNegotiatedCosts: i18n.t('generic.view-negotiated-costs', { ns: 'generic' }),
  searchFrom: i18n.t('generic.search-from', { ns: 'generic' }),
  searchTo: i18n.t('generic.search-to', { ns: 'generic' }),
  // mainZone: i18n.t('generic.main-zone', { ns: 'generic' }),
  anIntermediate: i18n.t('generic.an-intermediate', { ns: 'generic' }),
  viewIntermediate: i18n.t('generic.view-intermediate', { ns: 'generic' }),
  cancel: i18n.t('generic.cancel', { ns: 'generic' }),
  rating: i18n.t('generic.rating', { ns: 'generic' }),
  whatsappButtonFlorist: i18n.t('generic.whatsapp.florist-button', { ns: 'generic' }),
  whatsappButtonDriver: i18n.t('generic.whatsapp.driver-button', { ns: 'generic' }),
  imagesFeedbackAlert: i18n.t('generic.order.all-images-alert', { ns: 'generic' }),
  orderImages: (interpolation: { florist: string }) =>
    i18n.t('generic.order.order-images', { ...interpolation, ns: 'generic' }),
  addressNotAvailable: i18n.t('generic.order.no-available-address', { ns: 'generic' }),
  noAvailableActions: (interpolation: { status: string }) =>
    i18n.t('generic.order.no-actions', { ...interpolation, ns: 'generic' }),
  notDirectFloristTitle: ({ intermediate }: { intermediate: string }) =>
    i18n.t('generic.title.not-direct-florist', { intermediate, ns: 'generic' }),
  floristOrderWhatsapp: (interpolation: { username: string }) =>
    i18n.t('generic.whatsapp.florist-order', { ...interpolation, ns: 'generic' }),
  floristWhatsapp: (interpolation: { username: string }) =>
    i18n.t('generic.whatsapp.florist', { ...interpolation, ns: 'generic' }),
})

const formTranslations = () => ({
  recommendedZones: (selected: number, count: number) =>
    i18n.t('form.florist.zones-recommended', { selected, count, ns: 'form' }),
  confirmFlorist: i18n.t('form.florist.confirm-zones', { ns: 'form' }),
  confirmAddZones: (count: number, title: string) =>
    i18n.t('form.florist.confirm-add-zones', { count, title, ns: 'form' }),
  noRecommendedZones: i18n.t('form.florist.no-zones-recommended', { ns: 'form' }),
  drawMap: i18n.t('form.polygon.draw-in-map', { ns: 'form' }),
  drawMapHint: i18n.t('form.polygon.draw-in-map-hint', { ns: 'form' }),
  missingPolygonHint: i18n.t('form.polygon.submit-hint', { ns: 'form' }),
  polygonDescription: i18n.t('form.polygon.placeholder.description', { ns: 'form' }),
  polygonName: i18n.t('form.polygon.placeholder.name', { ns: 'form' }),
  clear: i18n.t('form.clear', { ns: 'form' }),
  newPolygon: i18n.t('form.new-polygon', { ns: 'form' }),
  assignIngredientsToProducts: i18n.t('form.assignIngredientsToProducts', { ns: 'form' }),
  relatedIngredient: i18n.t('form.ingredient.placeholder.choose-ingredient', { ns: 'form' }),
  ingredientName: i18n.t('form.ingredient.placeholder.name', { ns: 'form' }),
  newIngredient: i18n.t('form.new-ingredient', { ns: 'form' }),
  editIngredient: i18n.t('form.edit-ingredient', { ns: 'form' }),
  confirmDeleteIngredient: i18n.t('form.confirm-delete-ingredient', { ns: 'form' }),
  deleteIngredient: i18n.t('form.delete-ingredient', { ns: 'form' }),
  // clearSearch: i18n.t('form.clear-search', { ns: 'form' }),
  email: i18n.t('form.placeholder.email', { ns: 'form' }),
  filterData: i18n.t('form.placeholder.filter', { ns: 'form' }),
  ruleDescription: i18n.t('form.placeholder.rule-description', { ns: 'form' }),
  name: i18n.t('form.placeholder.name', { ns: 'form' }),
  phone: i18n.t('form.placeholder.phone', { ns: 'form' }),
  cost: i18n.t('form.placeholder.cost', { ns: 'form' }),
  costHistory: i18n.t('form.placeholder.cost-history', { ns: 'form' }),

  standBy: i18n.t('form.filters.stand-by', { ns: 'form' }),
  identification: i18n.t('form.placeholder.identification', { ns: 'form' }),
  site: i18n.t('form.placeholder.sites', { ns: 'form' }),
  zone: i18n.t('form.placeholder.zones', { ns: 'form' }),
  radius: i18n.t('form.placeholder.radius', { ns: 'form' }),
  available: i18n.t('form.placeholder.available', { ns: 'form' }),
  intermediate: i18n.t('form.placeholder.intermediate', { ns: 'form' }),
  categories: i18n.t('form.placeholder.categories', { ns: 'form' }),
  florist: i18n.t('form.placeholder.florist', { ns: 'form' }),
  order: i18n.t('form.placeholder.order', { ns: 'form' }),
  setIntermediate: i18n.t('form.placeholder.set-intermediate', { ns: 'form' }),
  setOperator: i18n.t('form.placeholder.set-operator', { ns: 'form' }),
  setFlorist: i18n.t('form.placeholder.set-florist', { ns: 'form' }),
  setDriver: i18n.t('form.placeholder.set-driver', { ns: 'form' }),
  place: i18n.t('form.placeholder.place', { ns: 'form' }),
  country: i18n.t('form.placeholder.country', { ns: 'form' }),
  lines: i18n.t('form.placeholder.lines', { ns: 'form' }),
  lastName: i18n.t('form.placeholder.lastname', { ns: 'form' }),
  password: i18n.t('form.placeholder.password', { ns: 'form' }),
  currentPassword: i18n.t('form.placeholder.current-password', { ns: 'form' }),
  createPassword: i18n.t('form.placeholder.create-password', { ns: 'form' }),
  newPassword: i18n.t('form.placeholder.new-password', { ns: 'form' }),
  confirmPassword: i18n.t('form.placeholder.confirm-password', { ns: 'form' }),
  mapSearch: i18n.t('form.placeholder.map-search', { ns: 'form' }),
  numberSearch: i18n.t('form.placeholder.number-search', { ns: 'form' }),
  startHour: i18n.t('form.placeholder.start-hour', { ns: 'form' }),
  endHour: i18n.t('form.placeholder.end-hour', { ns: 'form' }),
  signIn: i18n.t('form.login-screen', { ns: 'form' }),
  resetPassword: i18n.t('form.reset-password-screen', { ns: 'form' }),
  backToSignIn: i18n.t('form.back-to-signin', { ns: 'form' }),
  sendEmail: i18n.t('form.send-email', { ns: 'form' }),
  changePassword: i18n.t('form.change-password', { ns: 'form' }),
  editProfile: i18n.t('form.edit-profile', { ns: 'form' }),
  setPassword: i18n.t('form.set-password', { ns: 'form' }),
  forgotPassword: i18n.t('form.forgot-password', { ns: 'form' }),
  newUser: i18n.t('form.new-user', { ns: 'form' }),
  newIntermediate: i18n.t('form.new-intermediate', { ns: 'form' }),
  editIntermediate: i18n.t('form.edit-intermediate', { ns: 'form' }),
  addDeliveryZones: i18n.t('form.florist.add-delivery-zones', { ns: 'form' }),
  deliveryZones: (count: number) => i18n.t('form.florist.delivery-zones', { count, ns: 'form' }),
  addZones: i18n.t('form.florist.add-zones', { ns: 'form' }),
  newRule: i18n.t('form.new-rule', { ns: 'form' }),
  addNewUser: i18n.t('form.add-new-user', { ns: 'form' }),
  newFlorist: i18n.t('form.new-florist', { ns: 'form' }),
  saveCost: i18n.t('form.save-cost', { ns: 'form' }),
  editFlorist: i18n.t('form.edit-florist', { ns: 'form' }),
  createFlorist: i18n.t('form.create-florist', { ns: 'form' }),
  editPolygon: i18n.t('form.edit-polygon', { ns: 'form' }),
  deletePolygon: i18n.t('form.delete-polygon', { ns: 'form' }),
  confirmDeletePolygon: i18n.t('form.confirm-delete-polygon', { ns: 'form' }),
  editDriver: i18n.t('form.edit-driver', { ns: 'form' }),
  newDriver: i18n.t('form.new-driver', { ns: 'form' }),
  operators: i18n.t('form.operators', { ns: 'form' }),
  drivers: i18n.t('form.drivers', { ns: 'form' }),
  florists: i18n.t('form.florists', { ns: 'form' }),
  clickAndDrag: i18n.t('form.upload.click-drag', { ns: 'form' }),
  multipleFilesSupport: i18n.t('form.upload.multiple-files-support', { ns: 'form' }),
  all: i18n.t('form.all', { ns: 'form' }),
  alls: i18n.t('form.placeholder.all', { ns: 'form' }),
  removeAll: i18n.t('form.remove-all', { ns: 'form' }),
  submit: i18n.t('form.submit', { ns: 'form' }),
  selectZone: i18n.t('form.filters.main-zone', { ns: 'form' }),
  allProducts: i18n.t('form.filters.all-products', { ns: 'form' }),
  pendingFilter: i18n.t('form.filters.pending-negotiation', { ns: 'form' }),
  noCostFilter: i18n.t('form.filters.no-cost', { ns: 'form' }),
  agreedFilter: i18n.t('form.filters.agreed-price', { ns: 'form' }),
  noStockFilter: i18n.t('form.filters.no-stock', { ns: 'form' }),
  removeFilters: i18n.t('form.filters.remove', { ns: 'form' }),
  allOrders: i18n.t('form.filters.all-orders', { ns: 'form' }),
  assignedIntermediate: i18n.t('form.filters.assigned-intermediate', { ns: 'form' }),
  assignedFlorist: i18n.t('form.filters.assigned-florist', { ns: 'form' }),
  assignedDelivery: i18n.t('form.filters.assigned-delivery', { ns: 'form' }),
  ended: i18n.t('form.filters.ended', { ns: 'form' }),
  cancelled: i18n.t('form.filters.cancelled', { ns: 'form' }),
  received: i18n.t('form.filters.received', { ns: 'form' }),
  making: i18n.t('form.filters.making', { ns: 'form' }),
  imageUploaded: i18n.t('form.filters.image-uploaded', { ns: 'form' }),
  imageRejected: i18n.t('form.filters.image-rejected', { ns: 'form' }),
  imageAccepted: i18n.t('form.filters.image-accepted', { ns: 'form' }),
  selectDriver: i18n.t('form.filters.select-driver', { ns: 'form' }),
  assignedToDriver: i18n.t('form.filters.assigned-to-driver', { ns: 'form' }),
  delivering: i18n.t('form.filters.delivering', { ns: 'form' }),
  delivered: i18n.t('form.filters.delivered', { ns: 'form' }),
  rated: i18n.t('form.filters.rated', { ns: 'form' }),
  changeFlorist: i18n.t('form.filters.change-florist', { ns: 'form' }),
  applyFilters: i18n.t('form.filters.apply', { ns: 'form' }),
  extraFilters: i18n.t('form.filters.extra', { ns: 'form' }),
  filterByZones: i18n.t('form.filters.by-zones', { ns: 'form' }),
  filterByCategory: i18n.t('form.filters.by-category', { ns: 'form' }),
  setFirstPrice: i18n.t('form.negotiation.set-first', { ns: 'form' }),
  sentOffer: i18n.t('form.negotiation.sent-offer', { ns: 'form' }),
  startedOffer: i18n.t('form.negotiation.started-offer', { ns: 'form' }),
  errorSendingOffer: i18n.t('form.negotiation.error-offer', { ns: 'form' }),
  sendCost: i18n.t('form.negotiation.send-cost', { ns: 'form' }),
  sendStatus: i18n.t('form.negotiation.send-status', { ns: 'form' }),
  setCounteroffer: i18n.t('form.negotiation.set-counteroffer-cost', { ns: 'form' }),
  setOfferStatus: i18n.t('form.negotiation.set-status', { ns: 'form' }),
  submitForOffer: i18n.t('form.negotiation.submit', { ns: 'form' }),
  availability: i18n.t('form.week-availability', { ns: 'form' }),
  mainData: i18n.t('form.florist.main-data', { ns: 'form' }),
  deliveryArea: i18n.t('form.delivery-area', { ns: 'form' }),
  rules: i18n.t('form.rules', { ns: 'form' }),
  orders: i18n.t('form.orders', { ns: 'form' }),
  califications: i18n.t('form.califications', { ns: 'form' }),
  uploadImages: i18n.t('form.order.upload-images', { ns: 'form' }),
  floristDetailsTitle: i18n.t('form.order.florist-details', { ns: 'form' }),

  noImages: i18n.t('form.order.no-images', { ns: 'form' }),
  assignFlorist: i18n.t('form.order.assign-florist', { ns: 'form' }),
  assignIntermediate: i18n.t('form.order.assign-intermediate', { ns: 'form' }),
  startMaking: i18n.t('form.order.start-making', { ns: 'form' }),
  setDelivering: i18n.t('form.order.set-delivering', { ns: 'form' }),
  setDelivered: i18n.t('form.order.set-delivered', { ns: 'form' }),
  rate: i18n.t('form.order.rate', { ns: 'form' }),
  viewImages: i18n.t('form.order.view-images', { ns: 'form' }),
  inviteFlorist: i18n.t('form.order.invite-florist', { ns: 'form' }),
  browse: i18n.t('form.placeholder.browse', { ns: 'form' }),
  searchField: (interpolation: { field: string }) =>
    i18n.t('form.search-table-field', { ...interpolation, ns: 'form' }),
  searchingByFields: (interpolation: { fields: string }) =>
    i18n.t('form.searched-fields', { ...interpolation, ns: 'form' }),
  totalItems: (interpolation: { range: string; total: string }) =>
    i18n.t('form.table.total-items', { ...interpolation, ns: 'form' }),
})

const toastTranslations = () => ({
  infoUpdatePermissions: i18n.t('toasts.info.update.permissions', { ns: 'toasts' }),

  loginError: i18n.t('toasts.error.login', { ns: 'toasts' }),

  createDriverError: i18n.t('toasts.error.create.driver', { ns: 'toasts' }),

  createUserSuccess: i18n.t('toasts.success.create.user', { ns: 'toasts' }),
  createFloristSuccess: i18n.t('toasts.success.create.florist', { ns: 'toasts' }),
  createRuleSuccess: i18n.t('toasts.success.create.rule', { ns: 'toasts' }),
  createIntermediateSuccess: i18n.t('toasts.success.create.intermediate', { ns: 'toasts' }),
  createDriverSuccess: i18n.t('toasts.success.create.driver', { ns: 'toasts' }),
  createPolygonSuccess: i18n.t('toasts.success.create.polygon', { ns: 'toasts' }),
  createIngredientSuccess: i18n.t('toasts.success.create.ingredient', { ns: 'toasts' }),

  changePasswordSuccess: i18n.t('toasts.success.change-password', { ns: 'toasts' }),
  updateUserSuccess: i18n.t('toasts.success.update.user', { ns: 'toasts' }),
  updatePermissionsSuccess: i18n.t('toasts.success.update.permissions', { ns: 'toasts' }),
  updateFloristSuccess: i18n.t('toasts.success.update.florist', { ns: 'toasts' }),
  updateDriverSuccess: i18n.t('toasts.success.update.driver', { ns: 'toasts' }),
  updateIntermediateSuccess: i18n.t('toasts.success.update.intermediate', { ns: 'toasts' }),
  updatePolygonSuccess: i18n.t('toasts.success.update.polygon', { ns: 'toasts' }),
  updateIngredientSuccess: i18n.t('toasts.success.update.ingredient', { ns: 'toasts' }),

  assignOrderSuccess: i18n.t('toasts.success.order.assign-order', { ns: 'toasts' }),
  sentImageFeedback: i18n.t('toasts.success.order.sent-image-feedback', { ns: 'toasts' }),
  uploadOrderImagesSuccess: i18n.t('toasts.success.order.upload-images', { ns: 'toasts' }),
  createOrderFloristPriceSuccess: i18n.t('toasts.success.order.create-order-florist-price', {
    ns: 'toasts',
  }),
  sendMailToFloristsSuccess: i18n.t('toasts.success.order.send-mail-to-florists', { ns: 'toasts' }),
})

const tableTranslations = () => ({
  deliveryDate: i18n.t('table.order.delivery-date', { ns: 'table' }),
  message: i18n.t('table.order.message', { ns: 'table' }),
  images: i18n.t('table.order.images', { ns: 'table' }),
  itemCount: i18n.t('table.order.item-count', { ns: 'table' }),
  active: i18n.t('table.user.active', { ns: 'table' }),
  lng: i18n.t('table.lng', { ns: 'table' }),
  lat: i18n.t('table.lat', { ns: 'table' }),
  phone: i18n.t('table.phone', { ns: 'table' }),
  radius: i18n.t('table.florist.radius', { ns: 'table' }),
  username: i18n.t('table.username', { ns: 'table' }),
  country: i18n.t('table.country', { ns: 'table' }),
  name: i18n.t('table.name', { ns: 'table' }),
  firstName: i18n.t('table.first-name', { ns: 'table' }),
  lastName: i18n.t('table.last-name', { ns: 'table' }),
  address: i18n.t('table.address', { ns: 'table' }),
  description: i18n.t('table.description', { ns: 'table' }),
  order: i18n.t('table.rule.order', { ns: 'table' }),
  intermediate: i18n.t('table.rule.intermediate', { ns: 'table' }),
  florist: i18n.t('table.order.florist', { ns: 'table' }),
  parent: i18n.t('table.ingredient.parent', { ns: 'table' }),
  idInvoice: i18n.t('table.order.invoice', { ns: 'table' }),
  internalId: i18n.t('table.order.internal-id', { ns: 'table' }),

  locale: {
    filterTitle: 'Filter menu',
    filterConfirm: 'OK',
    filterReset: 'Reset',
    filterEmptyText: 'No filters',
    filterCheckall: 'Select all items',
    filterSearchPlaceholder: 'Search in filters',
    selectAll: 'Select current page',
    selectInvert: 'Invert current page',
    selectNone: 'Clear all data',
    selectionAll: 'Select all data',
    sortTitle: 'Sort',
    expand: 'Expand row',
    collapse: 'Collapse row',
    triggerDesc: 'Click to sort descending',
    triggerAsc: 'Click to sort ascending',
    cancelSort: 'Click to cancel sorting',
  },

  data: i18n.t('table.data', { ns: 'table' }),
  empty: (interpolation: { list: string }) =>
    i18n.t('table.empty', { ...interpolation, ns: 'table' }),
})

const routeTranslations = () => ({
  myOperators: i18n.t('route.my-operators', { ns: 'routes' }),
  myDrivers: i18n.t('route.my-drivers', { ns: 'routes' }),
  details: i18n.t('route.details', { ns: 'routes' }),
  edit: i18n.t('route.edit', { ns: 'routes' }),
  new: i18n.t('route.new', { ns: 'routes' }),
  user: i18n.t('route.user', { ns: 'routes' }),
  orders: i18n.t('route.orders', { ns: 'routes' }),
  rules: i18n.t('route.rules', { ns: 'routes' }),
  operators: i18n.t('route.operators', { ns: 'routes' }),
  florists: i18n.t('route.florists', { ns: 'routes' }),
  intermediates: i18n.t('route.intermediates', { ns: 'routes' }),
  permissions: i18n.t('route.permissions', { ns: 'routes' }),
  products: i18n.t('route.negotiation.products', { ns: 'routes' }),
  productNegotiation: i18n.t('route.negotiation.product-costs', { ns: 'routes' }),
  newFlorist: i18n.t('route.new-florist', { ns: 'routes' }),
  newDriver: i18n.t('route.new-driver', { ns: 'routes' }),
  newUser: i18n.t('route.new-operator', { ns: 'routes' }),
  polygons: i18n.t('route.polygons', { ns: 'routes' }),
  ingredients: i18n.t('route.ingredients', { ns: 'routes' }),
  assignToProduct: i18n.t('route.ingredient.product-assign', { ns: 'routes' }),
})

const translations: Translations = {
  generic: genericTranslations(),
  form: formTranslations(),
  toasts: toastTranslations(),
  table: tableTranslations(),
  routes: routeTranslations(),
}

function fill() {
  translations.generic = genericTranslations()
  translations.form = formTranslations()
  translations.toasts = toastTranslations()
  translations.table = tableTranslations()
  translations.routes = routeTranslations()
}

fill()
i18n.on('languageChanged init', () => {
  fill()
})

export default translations
