import React from 'react'
import { Formik } from 'formik'

import { withTranslation } from 'react-i18next'

import { pick } from 'lodash'

import LoginSchema from '@utils/schemas/loginSchema'

import { Button, NewTextInput } from '@components/common/index'
import { CRow } from '@coreui/react'

import translations from '@translations'

import { initialValues, formKeys } from './loginFormKeys'

const LoginForm = ({ onEmailChange, onSubmit, defaultValues = {} }) => {
  const { email: emailTranslation, password, signIn: loginTranslation } = translations.form

  return (
    <Formik
      initialValues={{ ...initialValues, ...defaultValues }}
      validationSchema={LoginSchema}
      onSubmit={(values) => onSubmit(pick(values, Object.keys(formKeys)))}
    >
      {({ handleChange, handleBlur, handleSubmit, values, errors, touched, dirty }) => {
        const handleEmailChange = (e) => {
          if (onEmailChange) onEmailChange(e.target.value)
          handleChange(e)
        }

        return (
          <>
            <NewTextInput
              icon="cil-envelope-closed"
              value={values[formKeys.email]}
              placeholder={emailTranslation}
              name={formKeys.email}
              onChange={handleEmailChange}
              onBlur={handleBlur}
              error={touched[formKeys.email] && errors[formKeys.email] && errors[formKeys.email]}
            />

            <NewTextInput
              icon="cil-asterisk"
              password
              value={values[formKeys.password]}
              placeholder={password}
              name={formKeys.password}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched[formKeys.password] && errors[formKeys.password] && errors[formKeys.password]
              }
            />

            <CRow className="w-100 mx-0 mt-2 justify-content-between">
              <Button
                className="px-4"
                disabled={!dirty || !!Object.keys(errors)?.length}
                onClick={handleSubmit}
                type="submit"
                text={loginTranslation}
              />
            </CRow>
          </>
        )
      }}
    </Formik>
  )
}

export default withTranslation()(LoginForm)
