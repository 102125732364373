import React from 'react'
import RLoadingOverlay, { LoadingOverLayProps } from 'react-loading-overlay-ts'
import { CRow, CSpinner } from '@coreui/react'

export interface Props extends LoadingOverLayProps {
  showOverlay?: boolean
}

const LoadingOverlay: React.FC<Props> = ({ active, children, showOverlay = false }) => (
  <CRow style={{ height: '300px' }} className={!showOverlay ? 'hide-overlay' : ''}>
    <RLoadingOverlay active={active} spinner={<CSpinner color="primary" grow />}>
      {children}
    </RLoadingOverlay>
  </CRow>
)

export default LoadingOverlay
