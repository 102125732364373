import React, { useState, useEffect } from 'react'
import { Formik } from 'formik'

import { compose } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { getEntityListAction } from '@actions/entity'

import { openHoursToArray } from '@utils/helpers'
import { OpenHours, getOpenHoursFromTimes } from '@utils/enums/weekdaysEnums'

import FloristDataSchema from '@utils/schemas/floristDataSchema'

import OpenHoursSelector from '@components/openHoursSelector'
import { Button, Select, Checkbox, PhoneInput } from '@components/common'
import NewTextInput from '@components/common/NewTextInput'
import Col from '@utils/components/ResponsiveColumn'

import { CRow } from '@coreui/react'
import translations from '@translations'

// import ZonesSelector from '@components/ZonesSelector'
import { omit } from 'lodash'
import { initialValues, formKeys } from './formKeys'
import UserForm from '../userForm'
import DeliveryRadiusModal from './DeliveryRadiusModal'

type PropsFromRedux = ConnectedProps<typeof connector>

interface FloristValues {
  username: string
  phone?: number | null
  zones?: {
    id: string
    idCountry: string
  }[]
  times?: any[]
}

interface Props {
  onSubmit: (values: any) => void
  link?: boolean
  currentIntermediate?: string
  values?: FloristValues
}

const FloristForm: React.FC<Props & PropsFromRedux> = ({
  onSubmit,
  userInfo,
  getIntermediates,
  intermediates,
  currentIntermediate,
  link = false,
  values: floristValues,
}) => {
  const {
    setIntermediate,
    setOperator,
    intermediate,
    availability,
    addDeliveryZones,
    numberSearch,
    name,
    mainData,
    createFlorist,
  } = translations.form

  // const { mainZone } = translations.generic

  const [intermediateList, setIntermediateList] = useState([])
  const [modalOpen, setModalOpen] = useState(false)

  const imOwner = (userInfo?.typeId === 4 || userInfo?.typeId === 1) ?? false
  const intermediateId =
    userInfo?.typeId === 2 ? userInfo?.intermediates[0].id : currentIntermediate

  const openModal = () => setModalOpen(true)
  const closeModal = () => setModalOpen(false)

  const formKeyName = {
    phone: [formKeys.phoneNumber],
    times: [formKeys.openHours, getOpenHoursFromTimes],
  }

  const transformValuesToForm = () =>
    floristValues
      ? Object.keys(floristValues)
          .filter((key) => [...Object.keys(formKeys), ...Object.keys(formKeyName)].includes(key))
          .reduce((o, key) => {
            const formKey = (formKeyName[key] && formKeyName[key][0]) ?? key
            const value = floristValues[key] ?? o[key]

            o[formKey] =
              (formKeyName[key] && formKeyName[key][1] && formKeyName[key][1](value)) ?? value
            return o
          }, initialValues)
      : initialValues

  useEffect(() => {
    getIntermediates('itm', { page: 1, setList: setIntermediateList })
  }, [])

  const getIntermediatesNextPage = async (page, callback) => {
    await getIntermediates('itm', { page: page + 1, setList: callback })
  }

  const getIntermediateId = (values) => {
    if (intermediateId) {
      return {
        ...values,
        [formKeys.intermediateId]: intermediateId,
        [formKeys.setIntermediate]: true,
      }
    }
    return values
  }

  return (
    <Formik
      initialValues={transformValuesToForm()}
      validationSchema={FloristDataSchema}
      onSubmit={(values) => {
        const currentValues = link ? { ...values } : getIntermediateId(values)
        const hours = currentValues.openHours
        if (hours) currentValues.openHours = openHoursToArray(hours)

        if (link) {
          currentValues.intermediateId = 1
          currentValues.isFromLogin = true
        }

        onSubmit(currentValues)
      }}
    >
      {({
        handleChange,
        handleSubmit,
        setFieldValue,
        handleBlur,
        setValues,
        values,
        errors,
        touched,
        dirty,
      }) => {
        const onUserFormChange = (userValues) => {
          setValues({
            ...values,
            user: userValues,
          })
        }

        const handleZonesSubmit = async (ids) => {
          await setFieldValue(formKeys.zones, ids)
          await setFieldValue(formKeys.zones, ids)
        }

        return (
          <>
            <CRow>
              <Col xxl={4} xl={[4, 6]} md={12}>
                <h5 className="mb-4">{mainData}</h5>
                <CRow className="mb-4">
                  {/* Name */}
                  <Col xl={12}>
                    <NewTextInput
                      icon="cil-at"
                      value={values[formKeys.username] as string}
                      placeholder={name}
                      name={formKeys.username}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched[formKeys.username] &&
                        errors[formKeys.username] &&
                        (errors[formKeys.username] as string)
                      }
                    />
                  </Col>

                  {/* Phone */}
                  <Col xl={12}>
                    <PhoneInput
                      name={formKeys.phoneNumber}
                      value={values.phoneNumber}
                      onChange={(val) => setFieldValue(formKeys.phoneNumber, val)}
                      onBlur={handleBlur}
                      searchPlaceholder={numberSearch}
                      error={
                        !!errors[formKeys.phoneNumber] &&
                        touched[formKeys.phoneNumber] &&
                        errors[formKeys.phoneNumber]
                      }
                    />
                  </Col>

                  <Col xl={12} className="mt-3">
                    <Button onClick={openModal} text={addDeliveryZones} link />
                    <DeliveryRadiusModal
                      open={modalOpen}
                      closeModal={closeModal}
                      onSubmit={handleZonesSubmit}
                    />
                  </Col>

                  {/* Add intermediate */}
                  <Col xl={12}>
                    <CRow className="mt-3">
                      <Col>
                        {imOwner && (
                          <Checkbox
                            id={formKeys.setIntermediate}
                            onChange={handleChange}
                            name={formKeys.setIntermediate}
                            value={!!values[formKeys.setIntermediate]}
                            label={setIntermediate}
                          />
                        )}

                        {touched[formKeys.intermediateId] && !!errors[formKeys.intermediateId] && (
                          <p className="mb-0 text-danger">{errors[formKeys.intermediateId]}</p>
                        )}

                        {!!values.setIntermediate && (
                          <Select
                            data={intermediateList}
                            placeholder={intermediate}
                            labelKey="username"
                            onChange={(value) => setFieldValue(formKeys.intermediateId, value)}
                            onBlur={handleBlur}
                            error={
                              touched[formKeys.intermediateId] && !!errors[formKeys.intermediateId]
                                ? (errors[formKeys.intermediateId] as string)
                                : undefined
                            }
                            pagination={{
                              page: 1,
                              getData: getIntermediatesNextPage,
                              next: intermediates.next,
                            }}
                          />
                        )}
                      </Col>
                    </CRow>
                  </Col>

                  {/* Add operator */}
                  {!floristValues && (
                    <Col xl={12}>
                      <CRow className="mt-3">
                        <Col>
                          <Checkbox
                            id={formKeys.setOperator}
                            onChange={handleChange}
                            name={formKeys.setOperator}
                            value={!!values[formKeys.setOperator]}
                            label={setOperator}
                          />

                          {!!values.setOperator && (
                            <UserForm className="mt-3" onFormChange={onUserFormChange} />
                          )}
                        </Col>
                      </CRow>
                    </Col>
                  )}
                </CRow>
              </Col>

              <Col xxl={8} xl={[8, 6]} md={12}>
                <h5 className="mb-4">{availability}</h5>
                <OpenHoursSelector
                  onChange={(hours) => setFieldValue(formKeys.openHours, hours)}
                  values={values[formKeys.openHours] as any as OpenHours}
                />
              </Col>
            </CRow>

            <Col>
              <CRow className="justify-content-end">
                <Button
                  className="px-4"
                  type="submit"
                  onClick={handleSubmit}
                  text={createFlorist}
                  disabled={!dirty || !!Object.keys(errors).length}
                />
              </CRow>
            </Col>
          </>
        )
      }}
    </Formik>
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.auth.user,
  intermediates: state.entity.list,
})

const mapDispatchToProps = {
  getIntermediates: getEntityListAction,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default compose<React.FC<Props>>(withTranslation(), connector)(FloristForm)
