import { GENERIC_INIT, SET_SIDEBAR, SET_CURRENT_LANGUAGE } from '../constants/generic'

const genericReducer = (state = GENERIC_INIT, action) => {
  switch (action.type) {
    case SET_SIDEBAR:
      return {
        ...state,
        sidebarShow: action.payload,
      }
    case SET_CURRENT_LANGUAGE:
      return {
        ...state,
        currentLanguage: action.payload,
      }
    default:
      return state
  }
}

export default genericReducer
