import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(Backend)
  .init({
    debug: false,
    saveMissing: true,
    fallbackLng: 'es',
    ns: ['generic', 'form', 'routes', 'table', 'toasts'],
    detection: {
      order: ['cookie', 'htmlTag', 'localStorage', 'subdomain', 'path'],
      caches: ['cookie'],
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      bindI18n: 'languageChanged',
      useSuspense: true,
    },
  })

export default i18next
