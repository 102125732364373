import React from 'react'

import { Tooltip, Tag, Badge } from '@components/common'
import { Props as TagProps } from '@components/common/Tag'
import { BadgeProps } from 'antd'

interface Props extends TagProps {
  show?: boolean
  text: React.ReactNode
  icon: React.ReactNode
  status?: BadgeProps['status']
}

export const ResponsiveTag: React.FC<Props> = ({ text, show = true, color, status, ...props }) => {
  if (show)
    return (
      <Tag {...props} color={color}>
        {text}
      </Tag>
    )

  return (
    <Tooltip tooltip={text}>
      <Badge status={status} dot />
    </Tooltip>
  )
}
