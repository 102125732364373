import React from 'react'
import { withTranslation } from 'react-i18next'
import translations from '@translations'

import usePlacesAutocomplete, {
  RequestOptions,
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete'

import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from '@reach/combobox'

// import { CInputGroup, CInputGroupPrepend, CInputGroupText } from '@coreui/react'
// import { Icon } from '@components/common'

import { LatLngLit, LatLngBounds } from '@utils/interfaces/maps'

interface Props {
  panTo?: (center: LatLngLit, bounds: LatLngBounds) => void
  requestOptions?: RequestOptions
  styles?: string
  disabled?: boolean
}

const Search: React.FC<Props> = ({ styles, panTo, requestOptions = {}, disabled = false }) => {
  const { mapSearch } = translations.form

  const {
    ready,
    value,
    suggestions: { data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions,
  })

  const handleSelect = async (address) => {
    setValue(address, false)
    clearSuggestions()

    if (!disabled) {
      try {
        const results = await getGeocode({ address })
        const currentResult = results[0]

        const { lat, lng } = await getLatLng(currentResult)
        if (panTo) panTo({ lat, lng }, currentResult.geometry.viewport)
        // eslint-disable-next-line no-empty
      } catch (error) {}
    }
  }

  const handleInput = (e) => {
    setValue(e.target.value)
  }

  return (
    <div className={styles}>
      <Combobox onSelect={handleSelect}>
        <ComboboxInput
          className="form-control"
          placeholder={mapSearch}
          onChange={handleInput}
          value={value}
          disabled={!ready || disabled}
        />

        {!!data && (
          <ComboboxPopover portal={false}>
            <ComboboxList>
              {!!data &&
                !!data.length &&
                data.map(({ place_id: id, description }) => (
                  <ComboboxOption key={id} value={description} />
                ))}
            </ComboboxList>
          </ComboboxPopover>
        )}
      </Combobox>
    </div>
  )
}

export default withTranslation()(Search)
