import React from 'react'

import AwesomeSlider from 'react-awesome-slider'
import AwesomeSliderStyles from 'react-awesome-slider/src/styles'
import styles from '@styles/modules/carousel.module.scss'

import Image from '@components/common/image'

const ProductImages = ({ images, imageStyle, url = '', ...props }) => (
  <AwesomeSlider
    cssModule={AwesomeSliderStyles}
    className={styles.awesomeSlider}
    style={{ height: '170px' }}
    bullets={false}
    {...props}
  >
    {images.map(({ src, caption }, index) => (
      <div key={index} style={imageStyle}>
        {!!caption && <div className={styles.captionContainer}>{caption}</div>}
        <Image src={`${url}${src}`} style={imageStyle} />
      </div>
    ))}
  </AwesomeSlider>
)

export default ProductImages
