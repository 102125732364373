import React, { Suspense, memo } from 'react'
import { Switch } from 'react-router-dom'
import { CContainer, CFade } from '@coreui/react'

import PrivilegeRoute from '../utils/components/privilegeRoute'

import { allRoutes } from '../routes'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
)

const TheContent = () => (
  <main className="c-main">
    <CContainer fluid>
      <Suspense fallback={loading}>
        <Switch>
          {allRoutes.map(
            (route) =>
              route.component && (
                <PrivilegeRoute
                  accessTypes={route.types}
                  accessPermissions={route.permissions}
                  key={route.name}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  component={(props) => (
                    <CFade>
                      <route.component {...props} />
                    </CFade>
                  )}
                />
              )
          )}
        </Switch>
      </Suspense>
    </CContainer>
  </main>
)

export default memo(TheContent)
