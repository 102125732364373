import React from 'react'

import { ObjectWithoutKey as Data } from '@utils/interfaces/react'
import { withTranslation, WithTranslation } from 'react-i18next'

import parsePhoneNumber from 'libphonenumber-js'

import translations from '@translations'

import { CLink } from '@coreui/react'
import { Icon, Tooltip } from '@components/common'
import EmptyRecord from '@utils/components/EmptyTableRender'

const { floristWhatsapp } = translations.generic

interface Props extends WithTranslation {
  florist: Data
  text?: (interpolation: { username: string }) => string
}

const RenderPhoneNumber: React.FC<Props> = ({ florist, text = floristWhatsapp }) => {
  const { whatsappButtonFlorist, noPhone } = translations.generic

  const { username, phone } = florist
  if (!phone) return <EmptyRecord text={noPhone} />

  const parsedPhone = parsePhoneNumber(`${phone[0] === '+' ? '' : '+'}${phone}`, { extract: false })

  return (
    <Tooltip tooltip={whatsappButtonFlorist}>
      <CLink
        target="_blank"
        href={`https://api.whatsapp.com/send?phone=${phone}&text=${text({
          username,
        })}`}
      >
        <Icon className="mr-2" name="cib-whatsapp" />
        {!parsedPhone ? phone : parsedPhone.formatInternational()}
      </CLink>
    </Tooltip>
  )
}

export default withTranslation()(RenderPhoneNumber)
