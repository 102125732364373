import React, { useEffect, useState } from 'react'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { Formik } from 'formik'

import translations from '@translations'

import { RuleSchema } from '@utils/schemas/ruleSchema'

import { getEntityListAction } from '@actions/entity'
import { getProductLinesAction, getSitesAction } from '@actions/data'
import { getRuleListAction } from '@actions/rule'

import { NewTextInput, TextInput, Button, Checkbox, Select } from '@components/common'
import Col from '@utils/components/ResponsiveColumn'

import { CForm, CRow } from '@coreui/react'
import IntermediateDataForm from '../intermediate'
import { initialValues, formKeys, formTypes } from './formKeys'

const NewRuleForm = ({
  onSubmit,
  intermediates,
  getIntermediates,
  rules,
  getProductLines,
  getSites,
  getRules,
  linesProducts,
  loadingIntermediates,
}) => {
  const { ruleDescription, setIntermediate, intermediate, lines } = translations.form
  const { priority, create } = translations.generic

  const { count: orderNumber } = rules

  const [intermediateList, setIntermediateList] = useState([])

  useEffect(() => {
    getIntermediates('itm', { page: 1, setList: setIntermediateList })
    getSites()
    getProductLines()
    getRules()
  }, [])

  const getIntermediatesNextPage = async (page, callback) => {
    await getIntermediates('itm', { page: page + 1, setList: callback })
  }

  return (
    <Formik
      initialValues={{ ...initialValues, orderNumber: orderNumber > 0 ? orderNumber : 1 }}
      validationSchema={RuleSchema}
      onSubmit={onSubmit}
    >
      {({
        setValues,
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
        values,
        errors,
        touched,
        dirty,
      }) => (
        <CForm>
          <CRow>
            <Col xl={12}>
              <NewTextInput
                icon="cil-description"
                value={values[formKeys.description]}
                placeholder={ruleDescription}
                name={formKeys.description}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched[formKeys.description] &&
                  errors[formKeys.description] &&
                  errors[formKeys.description]
                }
              />
            </Col>
          </CRow>

          <div className="mb-4">
            <IntermediateDataForm
              noPadding
              onFormChange={(intermediateValues) =>
                setValues({
                  ...values,
                  ...intermediateValues,
                })
              }
            />
            <CRow>
              <Col xl={8} className="px-0">
                <Select
                  multiple
                  data={linesProducts}
                  placeholder={lines}
                  onChange={(value) => setFieldValue(formKeys.productsLine, value)}
                  onBlur={handleBlur}
                  error={
                    touched[formKeys.productsLine] && !!errors[formKeys.productsLine]
                      ? errors[formKeys.productsLine]
                      : undefined
                  }
                  loading={loadingIntermediates}
                  name={formKeys.productsLine}
                />
              </Col>
            </CRow>
          </div>

          <CRow>
            <Col xl={8} className="my-2 mb-4 pr-0">
              <Checkbox
                id={formKeys.setIntermediate}
                className="mt-2 mb-3"
                onChange={handleChange}
                name={formKeys.setIntermediate}
                value={values.setIntermediate}
                label={setIntermediate}
              />

              {!!values.setIntermediate && (
                <Select
                  data={intermediateList}
                  placeholder={intermediate}
                  labelKey="username"
                  onChange={(value) => setFieldValue(formKeys.idIntermediate, value)}
                  onBlur={handleBlur}
                  error={
                    touched[formKeys.idIntermediate] && !!errors[formKeys.idIntermediate]
                      ? errors[formKeys.idIntermediate]
                      : undefined
                  }
                  pagination={{
                    page: 1,
                    getData: getIntermediatesNextPage,
                    next: intermediates.next,
                  }}
                />
              )}
            </Col>
          </CRow>

          <CRow className="justify-content-end">
            <Col xl={4} className="my-2">
              <TextInput
                value={values.orderNumber}
                placeholder={priority}
                name={formKeys.orderNumber}
                maxValue={orderNumber}
                minValue={1}
                onChangeText={handleChange}
                onBlur={handleBlur}
                error={!!errors.orderNumber && touched.orderNumber && errors.orderNumber}
                extraData={formTypes}
              />
            </Col>
          </CRow>

          <CRow className="justify-content-end">
            <Button
              color="primary"
              className="px-4"
              disabled={!dirty || !!Object.keys(errors).length}
              onClick={handleSubmit}
              text={create}
            />
          </CRow>
        </CForm>
      )}
    </Formik>
  )
}

const mapStateToProps = (state) => ({
  intermediates: state.entity.list,
  loadingIntermediates: state.entity.getIntermediatesRequesting,
  linesProducts: state.data.lines,
  sites: state.data.sites,
  rules: state.rule.list,
})

const mapDispatchToProps = {
  getIntermediates: getEntityListAction,
  getProductLines: getProductLinesAction,
  getSites: getSitesAction,
  getRules: getRuleListAction,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default compose(withTranslation(), connector)(NewRuleForm)
