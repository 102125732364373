import React, { useEffect } from 'react'

import { useHistory } from 'react-router-dom'
import { routes } from '@/routes'

const Home: React.FC = () => {
  const history = useHistory()
  useEffect(() => {
    history.push(routes.Dashboard)
  }, [])

  return <></>
}

export default Home
