import React from 'react'

import { CButton, CTooltip, CButtonGroup } from '@coreui/react'

import { Icon, Tooltip } from '@components/common'
import { isFunction } from 'lodash'

export interface ButtonProps extends CButton {
  text?: string | false
  iconClassName?: string
  icon?: string | false
  iconPosition?: 'left' | 'right'
  tooltipPlacement?: CTooltip['placement']
  tooltip?: CTooltip['content'] | false
  small?: boolean
  link?: boolean
  fake?: boolean
}

const Button: React.FC<ButtonProps> = ({
  text,
  icon,
  iconPosition = 'left',
  tooltipPlacement = 'right',
  className,
  tooltip,
  children,
  iconClassName = '',
  small = false,
  link = false,
  fake = false,
  color = 'primary',
  size,
  onClick,
  style = {},
  type,
  ...props
}) => {
  const isLeft = iconPosition === 'left'
  const iconClass = `m${isLeft ? 'r' : 'l'}-1 ${iconClassName}`

  const ButtonIcon = () => {
    if (!icon) return <></>
    return <Icon name={icon} className={iconClass} />
  }

  const buttonProps = {
    size: small ? 'sm' : size,
    color: link ? 'link' : color,
  }

  const handleClick = (e) => {
    e.stopPropagation()
    if (onClick && isFunction(onClick) && !fake) onClick()
  }

  const CustomButton = () => (
    <CButton
      {...props}
      onClick={handleClick}
      {...buttonProps}
      className={`d-flex align-items-center justify-content-center ${className} ${
        fake ? 'fake-btn' : ''
      }`}
      style={{ ...style, ...(fake && { cursor: 'unset' }) }}
      type={type}
    >
      {isLeft && <ButtonIcon />}
      {children ?? text}
      {!isLeft && <ButtonIcon />}
    </CButton>
  )

  return (
    <Tooltip tooltip={tooltip} auto placement={tooltipPlacement}>
      <CButtonGroup>
        <CustomButton />
      </CButtonGroup>
    </Tooltip>
  )
}

export default Button
