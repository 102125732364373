import React from 'react'

import { Input } from 'antd'
import { PasswordProps } from 'antd/lib/input'

import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'

const { Password } = Input

type Props = PasswordProps

const PasswordInput: React.FC<Props> = ({ ...props }) => (
  <Password
    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
    {...props}
  />
)

export default PasswordInput
