import React, { useEffect, useState } from 'react'

import { compose } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'

import { useHistory } from 'react-router-dom'
import { withRouteParams } from '@utils/helpers'

import translations from '@translations'

import { getFloristsByIntermediateAction } from '@actions/entity'

import PaginatedTable, { Props as TableProps } from '@components/common/Tables/PaginatedTable'
import { TableButtons } from '@utils/components/tableActions'
import RenderPhoneNumber from '@components/RenderPhoneNumber'

import { routes } from '@/routes'

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends Partial<TableProps> {
  entityId: string
  currentPage: number
}

type ExtendedProps = Props & WithTranslation & PropsFromRedux

const FloristsList: React.FC<ExtendedProps> = ({
  entityId,
  currentPage,
  onPageChange,
  florists,
  requesting,
  getFlorists,
  ...tableProps
}) => {
  const { detailsTitle } = translations.generic
  const history = useHistory()

  const [floristList, setFloristList] = useState([])

  const initialRequest = () => {
    getFlorists({ id: entityId, page: currentPage, setList: setFloristList })
  }

  useEffect(() => {
    initialRequest()
  }, [])

  const goToDetails = (id) => {
    history.push(withRouteParams(routes.Details, { type: 'fl', id }))
  }

  const customFields = [
    {
      dataIndex: 'user_actions',
      key: 'user_actions',
      title: '',
      width: '15%',
      fixed: true,
      sorter: false,
      render: (_, item) => (
        <TableButtons
          buttons={[
            {
              onClick: () => goToDetails(item.id),
              icon: 'cil-description',
              text: detailsTitle,
            },
          ]}
        />
      ),
    },
  ]

  return (
    <PaginatedTable
      {...tableProps}
      loading={requesting}
      data={floristList || []}
      customFields={customFields}
      render={{
        phone: {
          render: (_, item) => <RenderPhoneNumber florist={item} />,
        },
      }}
      pagination={{ current: currentPage, total: florists?.count }}
      onPageChange={onPageChange}
      fixed={['username']}
      fields={['username', 'radius', 'phone', 'active']}
      fieldsOrder={{ user_actions: 0, username: 0 }}
    />
  )
}

const mapStateToProps = (state) => ({
  florists: state.entity.florist,
  requesting: state.driver.getDriversRequesting,
})

const mapDispatchToProps = {
  getFlorists: getFloristsByIntermediateAction,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default compose<React.FC<Props>>(withTranslation(), connector)(FloristsList)
