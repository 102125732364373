import React, { useEffect } from 'react'
import { Formik } from 'formik'

import { withTranslation } from 'react-i18next'

import EntityUserSchema from '@utils/schemas/entityUserSchema'

import { Button, Checkbox } from '@components/common'
import NewTextInput from '@components/common/NewTextInput'

import { isFunction } from 'lodash'

import { CRow } from '@coreui/react'
import Col from '@utils/components/ResponsiveColumn'

import translations from '@translations'

import PasswordForm from '../passwordForm'
import { initialValues, formKeys } from './formKeys'

const UserForm = ({ onSubmit, onFormChange, defaultValues, ...props }) => {
  const { email, name, lastName, createPassword } = translations.form
  const { create, update } = translations.generic

  return (
    <Formik
      initialValues={{ ...initialValues, ...defaultValues }}
      validationSchema={EntityUserSchema}
      onSubmit={(values) => {
        const currentValues = { ...values }
        currentValues.passwordRequired = undefined
        onSubmit(currentValues)
      }}
    >
      {({
        setValues,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        values,
        errors,
        touched,
        dirty,
      }) => {
        useEffect(() => {
          if (!values?.passwordRequired) {
            setFieldValue(formKeys.password, '')
            setFieldValue(formKeys.confirmPassword, '')
          }

          if (isFunction(onFormChange) && !!dirty) {
            onFormChange(values)
          }
        }, [values])

        return (
          <div {...props}>
            <NewTextInput
              value={values.email}
              placeholder={email}
              name={formKeys.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!errors.email && touched.email && errors.email}
              icon="cil-envelope-open"
              disabled={!!values?.id}
            />

            <NewTextInput
              value={values.firstName}
              placeholder={name}
              name={formKeys.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!errors.firstName && touched.firstName && errors.firstName}
              icon="cil-user"
            />

            <NewTextInput
              value={values.lastName}
              placeholder={lastName}
              name={formKeys.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!errors.lastName && touched.lastName && errors.lastName}
              icon="cil-user"
            />

            <Col>
              {!values?.id && (
                <Checkbox
                  id={formKeys.passwordRequired}
                  className="mb-4"
                  onChange={handleChange}
                  name={formKeys.passwordRequired}
                  value={values.passwordRequired}
                  label={createPassword}
                />
              )}

              {values.passwordRequired && (
                <PasswordForm
                  column
                  onFormChange={(passwordValues) => {
                    setValues({
                      ...values,
                      ...passwordValues,
                    })
                  }}
                />
              )}
            </Col>

            {onSubmit && (
              <CRow className="justify-content-end">
                <Button
                  color="primary"
                  className="px-4"
                  disabled={!dirty || !!Object.keys(errors).length}
                  onClick={handleSubmit}
                  text={!values?.id ? create : update}
                  type="submit"
                />
              </CRow>
            )}
          </div>
        )
      }}
    </Formik>
  )
}

UserForm.defaultProps = {
  onFormChange: null,
}

export default withTranslation()(UserForm)
