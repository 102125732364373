import { Status, StatusData } from '@utils/interfaces/order'

export const filterEnum: { [key in Status]: Status } = {
  ALL: 'ALL',
  RECEIVED: 'RECEIVED',
  ASSIGNED_TO_INTERMEDIATE: 'ASSIGNED_TO_INTERMEDIATE',
  ASSIGNED_TO_FLORIST: 'ASSIGNED_TO_FLORIST',
  MAKING: 'MAKING',
  IMAGE_UPLOADED: 'IMAGE_UPLOADED',
  IMAGE_REJECTED: 'IMAGE_REJECTED',
  IMAGE_ACCEPTED: 'IMAGE_ACCEPTED',
  SELECT_DRIVER: 'SELECT_DRIVER',
  ASSIGNED_TO_DRIVER: 'ASSIGNED_TO_DRIVER',
  DELIVERING: 'DELIVERING',
  DELIVERED: 'DELIVERED',
  QUALIFIED: 'QUALIFIED',
  CANCELED: 'CANCELED',
  CHANGE_FLORIST: 'CHANGE_FLORIST',
  STAND_BY: 'STAND_BY',
}

export const filterDataEnum: StatusData = {
  [filterEnum.ALL]: {
    name: 'allOrders',
  },
  [filterEnum.RECEIVED]: {
    param: 1,
    name: 'received',
    color: 'info',
    userTypeAction: 'itm',
  },
  [filterEnum.ASSIGNED_TO_INTERMEDIATE]: {
    param: 2,
    name: 'assignedIntermediate',
    color: 'info',
    userTypeAction: 'itm',
  },
  [filterEnum.ASSIGNED_TO_FLORIST]: {
    param: 3,
    name: 'assignedFlorist',
    color: 'warning',
    userTypeAction: 'itm',
  },
  [filterEnum.MAKING]: {
    param: 4,
    name: 'making',
    color: 'warning',
    userTypeAction: 'fl',
  },
  [filterEnum.IMAGE_UPLOADED]: {
    param: 5,
    name: 'imageUploaded',
    color: 'info',
    userTypeAction: 'fl',
  },
  [filterEnum.IMAGE_REJECTED]: {
    param: 6,
    name: 'imageRejected',
    color: 'danger',
    userTypeAction: 'itm',
  },
  [filterEnum.IMAGE_ACCEPTED]: {
    param: 7,
    name: 'imageAccepted',
    color: 'warning',
    userTypeAction: 'itm',
  },
  [filterEnum.SELECT_DRIVER]: {
    param: 8,
    name: 'selectDriver',
    color: 'dark',
    userTypeAction: 'fl',
  },
  [filterEnum.ASSIGNED_TO_DRIVER]: {
    param: 9,
    name: 'assignedToDriver',
    color: 'dark',
    userTypeAction: 'fl',
  },
  [filterEnum.DELIVERING]: {
    param: 10,
    name: 'delivering',
    color: 'dark',
    userTypeAction: 'fl',
  },
  [filterEnum.DELIVERED]: {
    param: 11,
    name: 'delivered',
    color: 'dark',
    userTypeAction: 'fl',
  },
  [filterEnum.QUALIFIED]: {
    param: 12,
    name: 'rated',
    color: 'success',
    userTypeAction: 'fl',
  },
  [filterEnum.CANCELED]: {
    param: 13,
    name: 'cancelled',
    color: 'danger',
  },
  [filterEnum.CHANGE_FLORIST]: {
    param: 14,
    name: 'changeFlorist',
    color: 'danger',
  },
  [filterEnum.STAND_BY]: {
    param: 15,
    name: 'standBy',
    color: 'danger',
  },
}
