import React from 'react'
import { withTranslation } from 'react-i18next'

import translations from '@translations'

import { CRow, CCol } from '@coreui/react'

const EmptyProducts = () => {
  const { noProducts } = translations.generic

  return (
    <CRow className="d-flex justify-content-center">
      <CCol xl={4} className="align-items-center">
        <div className="mt-3">
          <p className="text-center font-weight-bold">{noProducts}</p>
        </div>
      </CCol>
    </CRow>
  )
}

export default withTranslation()(EmptyProducts)
