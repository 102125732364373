import React, { useEffect, useState } from 'react'
import { capitalize, isString, isArray, isFunction, isPlainObject, get } from 'lodash'

import translations from '@translations'

import { Table, TableProps, TablePaginationConfig, TableColumnType, Menu } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

import { CRow } from '@coreui/react'
import { NewTextInput, Button } from '@components/common'
import EmptyRecord from '@utils/components/EmptyTableRender'
import Col from '@utils/components/ResponsiveColumn'

import { compare } from '@utils/helpers'
import {
  ObjectWithoutKey as Data,
  ObjectWithValue,
  FieldsConfig,
  FixedType,
} from '@utils/interfaces/react'
import { getKeys, renderRow } from './helpers'
import { Render, FixedColumn, CommonColumn, EmptyRenderType } from './interfaces'

import { DragHandle, DraggableWrapper, DraggableRow } from './components/Draggable'

type Key = React.Key
interface DraggableConfig {
  indexKey: string
  onOrderChange?: (data: unknown) => void
}

interface ColumnConfig extends Omit<TableColumnType<Data>, 'render'> {
  render?: string | Render<Data>
}

export interface Props extends TableProps<Data> {
  idKey?: string
  loading?: boolean
  pagination?: (TablePaginationConfig & { simple?: boolean }) | false
  data: Data[]
  customFields?: CommonColumn<Data>[]
  render?: ObjectWithValue<ColumnConfig>
  onPageChange?: (pagination: number) => void
  pageSize?: number
  paginationPosition?: TablePaginationConfig['position']
  fields?: Key[] | FieldsConfig
  sorter?: boolean | Key[]
  cancelSort?: Key[]
  fixed?: Key[] | FixedColumn
  fieldsOrder?: Record<string, number>
  expandMultiple?: boolean
  draggable?: DraggableConfig
  verticalScroll?: boolean
  emptyRecordRender?: EmptyRenderType
  expandIcons?:
    | [typeof React.Component, typeof React.Component, typeof React.Component]
    | [typeof React.Component, typeof React.Component]
    | [typeof React.Component]
  queryFields?: Key[] | boolean
  onSearchChange?: (fieldQuery: Record<string, string>, clear: boolean) => void
  hideSearchStats?: boolean
  emptyList?: string
  simplePagination?: boolean
  scrollable?: boolean
}

const PaginatedTable: React.FC<Props> = ({
  data,
  customFields = [],
  idKey = 'id',
  render = {},
  onPageChange,
  pagination = false,
  scrollable = true,
  pageSize = 20,
  paginationPosition = ['topLeft', 'bottomRight'],
  fields = null,
  sorter = true,
  cancelSort = [],
  fixed = {},
  fieldsOrder,
  scroll,
  expandable,
  expandMultiple = false,
  expandIcons,
  rowClassName,
  draggable,
  verticalScroll = false,
  emptyRecordRender = (_) => <EmptyRecord />,
  onSearchChange,
  queryFields = false,
  hideSearchStats = false,
  emptyList,
  simplePagination = false,
  ...props
}) => {
  const { searchField, searchingByFields, clear, totalItems } = translations.form
  const { empty, data: dataTranslation } = translations.table

  const getKeyTitle = (key: Key) =>
    isString(key) ? translations.table[key] || capitalize(key) : ''

  const isSimplePagination =
    simplePagination || (!!pagination && isPlainObject(pagination) && pagination?.simple)
  const hasQueryFields = queryFields === true || (isArray(queryFields) && !!queryFields.length)

  const [tableData, setData] = useState(data)
  const [keys, setKeys] = useState<Key[]>([])

  const [last, setLast] = useState((pagination && pagination.current) || 1)
  const [expandedKey, setExpandedKey] = useState(null)

  const resetQueries = () => {
    if (!queryFields) return {}
    return isArray(queryFields)
      ? queryFields.reduce((o, field) => {
          o[field] = ''
          return o
        }, {})
      : keys
  }
  const [fieldQuery, setFieldQuery] = useState<Record<string, string>>(resetQueries())
  const resetSearch = () => setFieldQuery(resetQueries())

  const { indexKey = 'index', onOrderChange } = draggable || {}
  const disableDrag = !!tableData && !(tableData.length > 1)

  const rowKey = draggable ? indexKey : idKey

  const handleFieldQueryChange = (e, key) => {
    setFieldQuery({
      ...fieldQuery,
      [key]: e.target.value.toLowerCase(),
    })
  }

  const renderSearchTitle = (key: Key) => (
    <Menu className="p-2">
      <NewTextInput
        small
        placeholder={searchField({ field: getKeyTitle(key).toLowerCase() })}
        value={fieldQuery[key]}
        name={`search-${key}`}
        onChange={(e) => handleFieldQueryChange(e, key)}
      />
    </Menu>
  )

  const renderExpandIcon = ({ expandable: isExpandable, expanded, onExpand, record }) => {
    if (!expandIcons || !isArray(expandIcons)) return null
    if (!isExpandable) {
      const NotExpandableIcon = expandIcons[2]
      return NotExpandableIcon ? (
        <NotExpandableIcon record={record} expanded={expanded} style={{ fontSize: '16px' }} />
      ) : null
    }

    const ExpandIcon = expanded ? expandIcons[0] : expandIcons[1] ?? expandIcons[0]
    return (
      <ExpandIcon
        record={record}
        expanded={expanded}
        onClick={(e) => onExpand(record, e)}
        style={{ fontSize: '16px' }}
      />
    )
  }

  const shouldSort = (key: Key) =>
    isArray(sorter) && sorter.includes(key) && !cancelSort.includes(key)

  const getSort = (key: Key) =>
    sorter === true || shouldSort(key) ? (a, b) => compare(a[key], b[key]) : false

  const getFilter = (key: Key) =>
    (queryFields === true && {}) ||
    (isArray(queryFields) &&
      queryFields.includes(key) && {
        filterIcon: () => (
          <SearchOutlined style={{ color: fieldQuery[key] ? '#1890ff' : undefined }} />
        ),
        filterDropdown: () => renderSearchTitle(key),
      })

  const shouldBeFixed = (key: Key) =>
    fixed[key] || (isArray(fixed) && (fixed as Key[]).includes(key)) || (key === idKey && 'left')

  const sortColumns = (coums: CommonColumn<Data>[]) =>
    coums.reduce((arr: CommonColumn<Data>[], c, index) => {
      const order = !!fieldsOrder && fieldsOrder[c.key]
      if (order || order === 0) {
        arr.splice(order, 0, c)
      } else {
        arr[index] = c
      }
      return arr
    }, [])

  const getKeyData = (key: Key, isCustom = false) => {
    const keyRender = render[key]?.render

    return {
      dataIndex: key,
      key,
      title: getKeyTitle(key),
      fixed: shouldBeFixed(key),
      sorter: !isCustom && getSort(key),
      ...getFilter(key),
      ...render[key],
      render: (value, record, index) =>
        ((isFunction(keyRender) && keyRender) || renderRow(emptyRecordRender, key))(
          isString(keyRender) ? get(record, `${key}.${keyRender}`) : value,
          record,
          index
        ),
    }
  }

  const columns = keys.map((key) => getKeyData(key))
  const customColumns = customFields.map(({ key, ...field }) => ({
    ...getKeyData(key, true),
    ...field,
  }))

  const sortedColumns = fieldsOrder
    ? sortColumns([...columns, ...customColumns])
    : [...columns, ...customColumns]

  useEffect(() => {
    if (data && isArray(data)) {
      if (data.length) setKeys(getKeys(fields, data))
      setData(data)
    }
  }, [data])

  useEffect(() => {
    if (hasQueryFields && !!onSearchChange) {
      onSearchChange(fieldQuery, !Object.keys(fieldQuery).some((key) => !!fieldQuery[key].length))
    }
  }, [fieldQuery])

  const handleTableChange = (paginationConfig, _filters, _sorter) => {
    const current = paginationConfig.current || 1
    if (current !== last) {
      if (onPageChange && current != null) onPageChange(current)
      setLast(current)
    }
  }

  const handleRowExpand = (expanded, record) => {
    setExpandedKey(expanded ? record[idKey] : null)
    if (!!expandable && !!expandable.onExpand && !!isFunction(expandable.onExpand))
      expandable.onExpand(expanded, record)
  }

  const handleRowClass = (record, index, indent) => {
    const className = isFunction(rowClassName) ? rowClassName(record, index, indent) : rowClassName
    return `${!!expandable && !(expandable.expandRowByClick === false) ? 'cursor-pointer' : ''} ${
      rowClassName ? className : ''
    }`
  }

  const filteredFields = Object.keys(fieldQuery).filter((key) => !!fieldQuery[key].length)

  const SearchStatsHeader = () => (
    <CRow className="justify-content-end align-items-center">
      <Col xl={6} md={8}>
        <CRow className="justify-content-end align-items-center">
          <Col className="d-flex justify-content-end">
            <small>
              {searchingByFields({
                fields: filteredFields.map((f) => getKeyTitle(f).toLowerCase()).join(', '),
              })}
            </small>
          </Col>

          <Button text={clear} icon="cis-x-circle" link onClick={resetSearch} />
        </CRow>
      </Col>
    </CRow>
  )

  const tableTranslations = {
    ...translations.table.locale,
    emptyText: empty({ list: (emptyList ?? dataTranslation).toLowerCase() }),
  }

  const columnsDraggable = [
    ...(draggable
      ? [
          {
            title: '',
            key: 'sort',
            dataIndex: 'sort',
            width: 80,
            fixed: true as FixedType,
            className: 'drag-visible',
            render: () => <DragHandle disabled={disableDrag} />,
          },
        ]
      : []),
    ...sortedColumns,
  ]

  return (
    <Table
      dataSource={tableData}
      columns={columnsDraggable}
      onChange={handleTableChange}
      components={
        draggable
          ? {
              body: {
                wrapper: ({ ...props }) => (
                  <DraggableWrapper<Data>
                    data={tableData}
                    setData={setData}
                    idKey={idKey}
                    indexKey={indexKey}
                    onOrderChange={onOrderChange}
                    {...props}
                  />
                ),
                row: (props) => (
                  <DraggableRow<Data>
                    data={tableData}
                    indexKey={indexKey}
                    disabled={disableDrag}
                    {...props}
                  />
                ),
              },
            }
          : undefined
      }
      pagination={
        pagination &&
        isPlainObject(pagination) && {
          pageSize,
          position: isSimplePagination ? ['bottomRight'] : paginationPosition,
          ...(isSimplePagination
            ? {}
            : {
                showTotal: (total, range) =>
                  totalItems({ range: `${range[0]}-${range[1]}`, total: total.toString() }),
              }),
          ...pagination,
          hideOnSinglePage: true,
          showSizeChanger: false,
        }
      }
      rowKey={rowKey}
      scroll={{
        ...(verticalScroll && { y: 340 }),
        x: scrollable ? 800 : '100%',
        ...scroll,
      }}
      expandable={{
        expandRowByClick: true,
        ...(expandIcons && { expandIcon: renderExpandIcon }),
        ...expandable,
        ...(!expandMultiple && {
          defaultExpandedRowKeys: [],
          onExpand: handleRowExpand,
          expandedRowKeys: expandedKey ? [expandedKey] : [],
        }),
      }}
      rowClassName={handleRowClass}
      locale={tableTranslations}
      {...props}
      // Is set globally
      // loading={loading && { indicator: <LoadingOverlay active /> }}
      {...(!hideSearchStats && hasQueryFields && !!filteredFields.length
        ? { title: SearchStatsHeader }
        : {})}
    />
  )
}

export default PaginatedTable
