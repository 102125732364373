import React, { ReactNode } from 'react'
import { isArray, isString, max as lodashMax } from 'lodash'

import { Input } from 'antd'
import { AutoSize, Rows } from './types'

const { TextArea } = Input

interface Props extends Rows, AutoSize {
  prefix?: ReactNode
  onResize?: (size: { width: number; height: number }) => void
}

const TextAreaInput: React.FC<Props> = ({ rows, autoSize, prefix, ...props }) => {
  const shouldAutoSize = isArray(rows) || autoSize

  const getAutoSize = () => {
    if (!!rows && isArray(rows)) {
      const minRows = lodashMax([rows[0], 1])
      return rows.length > 1
        ? {
            minRows,
            maxRows: lodashMax([minRows, rows[1]]),
          }
        : { maxRows: minRows }
    }
    return false
  }

  const textAreaProps = {
    ...(isString(prefix) && { prefix }),
    rows: !shouldAutoSize ? rows : undefined,
    autoSize: shouldAutoSize || getAutoSize(),
  }

  return <TextArea {...textAreaProps} {...props} />
}

export default TextAreaInput
