export const groupEnum = {
  VIEW: 'VIEW',
  CREATE: 'CREATE',
  REMOVE: 'REMOVE',
}

export const permissionEnum = {
  [groupEnum.VIEW]: {
    VIEW_OPERATORS: 'VIEW_OPERATORS',
    VIEW_DRIVERS: 'VIEW_DRIVERS',
    VIEW_PRODUCTS: 'VIEW_PRODUCTS',
  },
  [groupEnum.CREATE]: {
    CREATE_OPERATOR: 'CREATE_OPERATOR',
    CREATE_DRIVER: 'CREATE_DRIVER',
  },
  [groupEnum.REMOVE]: {
    REMOVE_OPERATOR: 'REMOVE_OPERATOR',
    REMOVE_DRIVER: 'REMOVE_DRIVER',
  },
}
