import React, { useEffect } from 'react'

import { compose } from 'redux'
import { withTranslation } from 'react-i18next'

import translations from '@translations'

import { Formik } from 'formik'

import ProductSearchSchema from '@utils/schemas/product/productSearchSchema'

import { TextInput, Button } from '@components/common/index'

import { CForm, CRow, CButtonGroup } from '@coreui/react'
import { initialValues, formKeys } from './formKeys'

const SearchProductsForm = ({ onSubmit, onFormChange }) => {
  const { browse: browsePlaceholder, clear } = translations.form

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ProductSearchSchema}
      onSubmit={onSubmit}
    >
      {({ setFieldValue, handleSubmit, handleChange, values, errors, dirty }) => {
        useEffect(() => {
          if (!!onFormChange && dirty) onFormChange(values, errors)
        }, [values])

        const disableSearch = !dirty || !!Object.keys(errors).length
        const disableClear = !dirty || !values[formKeys.query]

        const handleClear = async () => {
          await setFieldValue(formKeys.query, '')
          handleSubmit()
        }

        const handleBlur = (e) => {
          const { relatedTarget } = e
          if (relatedTarget && relatedTarget.getAttribute('type') === 'submit') {
            e.preventDefault()
          }
        }

        const SearchButtons = () => (
          <CButtonGroup size="sm">
            <Button
              color="secondary"
              onClick={handleClear}
              icon="cil-x"
              tooltip={!disableClear && clear}
              disabled={disableClear}
            />
            <Button
              color="primary"
              onClick={handleSubmit}
              icon="cil-search"
              tooltip={!disableSearch && browsePlaceholder}
              disabled={disableSearch}
              type="submit"
            />
          </CButtonGroup>
        )

        return (
          <CForm>
            <CRow>
              <TextInput
                value={values[formKeys.query]}
                name={formKeys.query}
                placeholder={browsePlaceholder}
                onBlur={handleBlur}
                append={SearchButtons}
                onChangeText={handleChange}
              />
            </CRow>
          </CForm>
        )
      }}
    </Formik>
  )
}

export default compose(withTranslation())(SearchProductsForm)
