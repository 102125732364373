import React, { useEffect } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'

import { compose } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { successToast } from '@utils/toast'

import { getCountriesAction } from '@actions/data'
import { getPolygonAction, createPolygonAction, updatePolygonAction } from '@actions/polygon'

import NewPolygonForm from '@forms/polygon'
import EditPolygonForm from '@forms/polygon/EditForm'

import Col from '@utils/components/ResponsiveColumn'
import { Card } from '@components/common'

import translations from '@translations'
import { withRouteParams } from '@utils/helpers'
import { routes } from '@/routes'

interface MatchParams {
  id?: string
}

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & RouteComponentProps<MatchParams>

const PolygonForm: React.FC<Props> = ({
  match,
  getCountries,
  createPolygon,
  updatePolygon,
  getDetails,
  createSuccess,
  updateSuccess,
}) => {
  const history = useHistory()

  const id = match.params?.id
  const isCreating = id == null

  const { createPolygonSuccess, updatePolygonSuccess } = translations.toasts

  useEffect(() => {
    if (isCreating) {
      if (createSuccess) {
        successToast(createPolygonSuccess, {
          toastId: 'CREATE_POLYGON',
        })
        history.push(routes.Polygons)
      }
    } else if (updateSuccess) {
      successToast(updatePolygonSuccess, {
        toastId: 'UPDATE_POLYGON',
      })
      history.push(withRouteParams(routes.PolygonDetails, { id }))
    }
  }, [createSuccess, updateSuccess])

  useEffect(() => {
    if (isCreating) {
      getCountries()
    } else {
      getDetails(id)
    }
  }, [])

  return (
    <Col xl={12}>
      <Card className="my-3">
        {isCreating ? (
          <NewPolygonForm onSubmit={createPolygon} />
        ) : (
          <EditPolygonForm onSubmit={(form) => updatePolygon(id, form)} />
        )}
      </Card>
    </Col>
  )
}

const mapStateToProps = (state) => ({
  createSuccess: state.polygon.createPolygonSuccess,
  updateSuccess: state.polygon.updatePolygonSuccess,
})

const mapDispatchToProps = {
  getCountries: getCountriesAction,
  createPolygon: createPolygonAction,
  updatePolygon: updatePolygonAction,
  getDetails: getPolygonAction,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default compose(withTranslation(), connector)(PolygonForm)
