import React, { useEffect } from 'react'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { successToast } from '@utils/toast'
import { createRuleAction } from '@actions/rule'

import NewRuleForm from '@forms/ruleForm'

import { Card } from '@components/common/index'

import { CCol } from '@coreui/react'

const RuleForm = ({ createRule, successRule }) => {
  useEffect(() => {
    if (successRule) {
      successToast('createRuleSuccess', {
        toastId: 'CREATE_RULE',
      })
    }
  }, [successRule])

  const onFormSubmit = (form) => {
    createRule(form)
  }
  return (
    <CCol xxl={6} xl={8} lg={8} md={8} sm={12}>
      <Card className="my-3">
        <NewRuleForm onSubmit={onFormSubmit} />
      </Card>
    </CCol>
  )
}

const mapStateToProps = (state) => ({
  successRule: state.rule.createRuleSuccess,
})

const mapDispatchToProps = {
  createRule: createRuleAction,
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(RuleForm)
