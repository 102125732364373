import { createConstants, initConstant } from './constantsCreator'

const GET_ORDERS = createConstants('GET_ORDERS')
const GET_ORDER = createConstants('GET_ORDER')
const GET_FL_NEAR_ORDER = createConstants('GET_FL_NEAR_ORDER')
const GET_ORDER_FLORISTS_PRICES = createConstants('GET_ORDER_FLORISTS_PRICES')

const SET_ORDER_STATUS = createConstants('SET_ORDER_STATUS')
const SET_ORDER_DRIVER = createConstants('SET_ORDER_DRIVER')
const SET_ORDER_FLORIST = createConstants('SET_ORDER_FLORIST')

const SET_IMAGES_FEEDBACK = createConstants('SET_IMAGES_FEEDBACK')
const SET_IMAGES = createConstants('SET_IMAGES')

const SET_ORDER_FLORIST_PRICE = createConstants('SET_ORDER_FLORIST_PRICE')
const SET_MAIL_TO_FLORISTS = createConstants('SET_ORDER_EMAIL')
const GET_ORDER_INIT = {
  ...initConstant(GET_ORDER),
  detail: {},
}

const GET_ORDERS_INIT = {
  ...initConstant(GET_ORDERS),
  list: [],
}

const GET_FL_NEAR_ORDER_INIT = {
  ...initConstant(GET_FL_NEAR_ORDER),
  florists: [],
}

const GET_ORDER_FLORISTS_PRICES_INIT = {
  ...initConstant(GET_ORDER_FLORISTS_PRICES),
  prices: [],
}

const ORDER_INIT = {
  ...GET_ORDER_INIT,
  ...GET_ORDERS_INIT,
  ...GET_FL_NEAR_ORDER_INIT,
  ...GET_ORDER_FLORISTS_PRICES_INIT,
  ...initConstant(SET_ORDER_FLORIST),
  ...initConstant(SET_ORDER_STATUS),
  ...initConstant(SET_ORDER_DRIVER),
  ...initConstant(SET_IMAGES_FEEDBACK),
  ...initConstant(SET_IMAGES),
  ...initConstant(SET_ORDER_FLORIST_PRICE),
  ...initConstant(SET_MAIL_TO_FLORISTS),
}

export {
  GET_ORDER,
  GET_ORDERS,
  GET_FL_NEAR_ORDER,
  GET_ORDER_FLORISTS_PRICES,
  SET_ORDER_FLORIST,
  SET_ORDER_STATUS,
  SET_ORDER_DRIVER,
  SET_IMAGES_FEEDBACK,
  SET_IMAGES,
  SET_ORDER_FLORIST_PRICE,
  SET_MAIL_TO_FLORISTS,
  ORDER_INIT,
}
