import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { compose } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'

import translations from '@translations'
import { withRouteParams } from '@utils/helpers'

import { Button, Switch, Card } from '@components/common/index'
import { CRow } from '@coreui/react'
import Col from '@utils/components/ResponsiveColumn'

import StripedTable from '@components/common/Tables/StripedTable'
import { ObjectWithoutKey as Data } from '@utils/interfaces/react'

import FloristsList from './components/FloristsList'
import UsersList from './components/UsersList'

import { routes } from '@/routes'

import { EntityContext } from './components/context'

type PropsFromRedux = ConnectedProps<typeof connector>

interface Intermediate {
  username: string
  id: string
}

interface Props extends PropsFromRedux, WithTranslation {
  details: Intermediate
}

const IntermediateDetail: React.FC<Props> = ({ details, userInfo, requesting }) => {
  const history = useHistory()
  const { id, keys, tab, currentPage, type, onPageChange } = EntityContext()

  const [userView, setUserView] = useState(tab !== 'florists')

  const { newUser, florists, operators, intermediate } = translations.form
  const { detailsTitle } = translations.generic

  const imOwner = (userInfo?.type?.id === 4 || userInfo?.type?.id === 1) ?? false

  const textOn = operators
  const textOff = florists

  const goToNewUser = () => {
    history.push(withRouteParams(routes.NewUser, { type, id }))
  }

  const toggleUserView = () => {
    setUserView(!userView)
  }

  const UserViewToggler = ({ ...props }) => (
    <Col className="d-flex justify-content-end p-4" {...props}>
      <Switch
        onChange={toggleUserView}
        value={userView}
        disabled={requesting}
        textOn={textOn}
        textOff={textOff}
      />
    </Col>
  )

  return (
    <Col>
      <CRow className="d-flex justify-content-between align-items-center">
        <Col xl={6}>
          <h4>
            {intermediate} #{details.id} · {details.username}
          </h4>
        </Col>
        <Col xl={4} className="d-flex justify-content-end">
          <Button onClick={goToNewUser} icon="cil-user-follow" text={newUser} />
        </Col>
      </CRow>

      <CRow className="my-3">
        <Col xl={4}>
          <CRow>
            <Card title={detailsTitle}>
              <StripedTable fields={keys} data={details as any as Data} />
            </Card>
          </CRow>
        </Col>

        <Col xl={8}>
          <Card title={userView ? textOn : textOff} footer={imOwner ? UserViewToggler : undefined}>
            {userView ? (
              <UsersList
                currentId={userInfo?.id}
                entityId={id}
                currentPage={currentPage}
                onPageChange={onPageChange}
                type="itm"
                simplePagination
              />
            ) : (
              <FloristsList
                entityId={id}
                currentPage={currentPage}
                onPageChange={onPageChange}
                simplePagination
                verticalScroll
              />
            )}
          </Card>
        </Col>
      </CRow>
    </Col>
  )
}

const mapStateToProps = (state) => ({
  requesting: state.entity.getIntermediateDetailRequesting,
  userInfo: state.auth.user,
})

const connector = connect(mapStateToProps)

export default compose(withTranslation(), connector)(IntermediateDetail)
