import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withRouteParams } from '@utils/helpers'

import translations from '@translations'
import { successToast } from '@utils/toast'
import { messageEnums } from '@utils/enums/toastEnums'

import { updateEntityAction, getEntityAction } from '@actions/entity'

import EntityForm from '@forms/entity'

import { Card } from '@components/common/index'

import { CCol } from '@coreui/react'
import { routes } from '@/routes'

const EditEntityForm = ({
  match,
  update,
  getDetails,
  successFlorist,
  successIntermediate,
  successFloristDetails,
  successIntermediateDetails,
  details,
}) => {
  const history = useHistory()

  const entityType = match.params.type
  const { id } = match.params

  const [values, setValues] = useState()

  const isIntermediate = entityType === 'itm'

  const { editFlorist, editIntermediate } = translations.form

  const { CREATE_FLORIST, CREATE_INTERMEDIATE } = messageEnums

  useEffect(() => {
    getDetails(entityType, id)
  }, [])

  useEffect(() => {
    if (successFlorist) {
      successToast('updateFloristSuccess', { toastId: CREATE_FLORIST })
      history.push(withRouteParams(routes.Details, { type: entityType, id }))
    }
    if (successIntermediate) {
      successToast('updateIntermediateSuccess', {
        toastId: CREATE_INTERMEDIATE,
      })
      history.push(withRouteParams(routes.Details, { type: entityType, id }))
    }
  }, [successFlorist, successIntermediate])

  useEffect(() => {
    if ((successFloristDetails || successIntermediateDetails) && !!details) setValues(details)
  }, [details, successFloristDetails, successIntermediateDetails])

  const onFormSubmit = (form) => {
    const intermediateId = !form.setIntermediate ? null : form.intermediateId
    update(entityType, { ...form, intermediateId, id })
  }

  if (!values) return null

  return (
    <CCol xl={12}>
      <Card className="my-3" title={isIntermediate ? editIntermediate : editFlorist}>
        <EntityForm onSubmit={onFormSubmit} entityType={entityType} values={values} />
      </Card>
    </CCol>
  )
}

const mapStateToProps = (state) => ({
  successFlorist: state.entity.updateFloristSuccess,
  successIntermediate: state.entity.updateIntermediateSuccess,
  details: state.entity.detail,
  successFloristDetails: state.entity.getFloristDetailSuccess,
  successIntermediateDetails: state.entity.getIntermediateDetailSuccess,
})

const mapDispatchToProps = {
  update: updateEntityAction,
  getDetails: getEntityAction,
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(EditEntityForm)
