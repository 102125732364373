export const formKeys = {
  firstName: 'firstName',
  lastName: 'lastName',
  phoneNumber: 'phoneNumber',
  identification: 'identification',
}

const { firstName, lastName, phoneNumber, identification } = formKeys

export const initialValues = {
  [firstName]: '',
  [lastName]: '',
  [phoneNumber]: '',
  [identification]: '',
}
