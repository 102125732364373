/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react'

import { badgeStatusEnum, actionsEnum, actionsDataEnum } from '@utils/enums/offerEnums'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import translations from '@translations'

import { Formik } from 'formik'
import OfferSchema from '@utils/schemas/product/offerSchema'

import { OfferButtonList } from '@utils/components/offerActions'

import { Card, Button, Modal } from '@components/common/index'

import { CRow, CCol } from '@coreui/react'
import { isArray } from 'lodash'
import { formKeys, initialValues } from '../formKeys'

import VariantHeader from './VariantHeader'

const { NO_STOCK, CONFIRM, COUNTEROFFER } = actionsEnum

const VariantOffer = ({
  disabled = false,
  variant,
  price,
  floristId,
  onStatus,
  onOpen,
  onSubmit,
  userInfo,
  setCostModal,
}) => {
  const genericTranslations = translations.generic

  const {
    setNoStock,
    acceptOffer,
    noOfferTurn,
    cantOffer,
    forCost,
    startOffer,
    setFirstCost,
    startWithCost,
    alreadySetCost,
  } = genericTranslations

  const imOwner = userInfo?.type.id === 1
  const { id, name, description, prices, negotiation_offers: offers } = variant
  const lastOffer = isArray(offers) && offers?.length ? offers[0] : null

  const { shouldOffer, status, price: lastPrice } = lastOffer || {}

  const defaultPrice = isArray(prices) ? prices[0]?.defaultPrice : null

  const initialPrice = lastPrice || defaultPrice

  const [modalOpen, setModalOpen] = useState(false)
  const [priceStatus, setPriceStatus] = useState(initialValues?.status ?? null)

  const openModal = () => setModalOpen(true)
  const closeModal = () => setModalOpen(false)

  const shouldStartOffer = (!lastOffer && !defaultPrice) || !floristId
  const hideOfferActions = [badgeStatusEnum.AGREED, badgeStatusEnum.NO_STOCK].includes(status)
  const disableOfferActions =
    disabled || shouldOffer === 0 || (imOwner && !lastOffer && !!defaultPrice)

  const disableStartOffer = !floristId && !!defaultPrice

  const hasBody = !!description || !hideOfferActions

  return (
    <>
      <Formik
        validationSchema={OfferSchema}
        initialValues={{
          ...initialValues,
          [formKeys.id]: id,
          [formKeys.florist]: floristId,
          [formKeys.initialPrice]: initialPrice,
        }}
        onSubmit={(values) => {
          onSubmit(values)
          setPriceStatus(values.status)
          closeModal()
        }}
      >
        {({ setFieldValue, handleSubmit, values, errors }) => {
          const submitCounterOffer = async (val) => {
            await setFieldValue(formKeys.newPrice, val)
            handleSubmit()
          }

          useEffect(() => {
            if (price && values.status === COUNTEROFFER) {
              submitCounterOffer(price)
            }
          }, [price])

          const handleStatusChange = async (variantStatus, negotiationNotStarted) => {
            if (variantStatus === NO_STOCK || variantStatus === CONFIRM) {
              openModal()
            }
            if (variantStatus === NO_STOCK && negotiationNotStarted) {
              await setFieldValue(formKeys.initialPrice, null)
            }
            setFieldValue(formKeys.status, variantStatus)
            onStatus(id, variantStatus)
          }

          const resetStatus = () => {
            setFieldValue(formKeys.status, null)
          }

          const dismissModal = () => {
            resetStatus()
            closeModal()
          }

          const StartOfferButton = () => {
            const startText = imOwner ? startOffer : startWithCost
            const buttonText = floristId ? startText : setFirstCost

            return (
              <Button
                small
                text={buttonText}
                tooltipPlacement="top"
                tooltip={disableStartOffer ? alreadySetCost : null}
                disabled={disableStartOffer}
                onClick={setCostModal}
              />
            )
          }

          const modalCallToAction = values.status === NO_STOCK ? setNoStock : acceptOffer

          const StatusButton = () => (
            <Button
              text={modalCallToAction}
              disabled={values.status === COUNTEROFFER && !!Object.keys(errors).length}
              onClick={handleSubmit}
            />
          )

          return (
            <>
              <Modal
                title={modalCallToAction}
                open={modalOpen}
                closeModal={dismissModal}
                onDismiss={dismissModal}
                footerButtons={StatusButton}
              >
                {!!values.status && (
                  <CRow>
                    <p>{genericTranslations[actionsDataEnum[values.status]?.confirm]}</p>
                    {values.status === CONFIRM && (
                      <small>
                        {forCost} ${values.initialPrice}
                      </small>
                    )}
                  </CRow>
                )}
              </Modal>

              <Card
                collapsible={hasBody}
                hideBody={!hasBody}
                close={status === NO_STOCK}
                className="w-100"
                bodyClassName="px-1"
                style={{ border: 'none' }}
                headerStyle={{ padding: 0 }}
                onOpen={() => onOpen(id)}
                header={(props) => (
                  <VariantHeader
                    title={name}
                    priceStatus={priceStatus ?? status}
                    price={initialPrice}
                    newPrice={price}
                    /* isDefault={!!defaultPrice && !hasOfferPrice && !price}
                    badgeText={imOwner ? yourSetCost : recommendedCost} */
                    offerStatus={
                      !shouldStartOffer ? status : !disableStartOffer && badgeStatusEnum.START_OFFER
                    }
                    shouldOffer={shouldOffer}
                    {...props}
                  />
                )}
              >
                <CCol className="px-0">
                  {!hideOfferActions && (
                    <CCol className="d-flex justify-content-end align-items-center mb-3 px-0">
                      {shouldStartOffer ? (
                        <StartOfferButton />
                      ) : (
                        <OfferButtonList
                          disabled={disableOfferActions}
                          disabledText={disableOfferActions ? noOfferTurn : cantOffer}
                          onClick={handleStatusChange}
                        />
                      )}
                    </CCol>
                  )}

                  <p>{description}</p>

                  {!imOwner && !hideOfferActions && !!floristId && (
                    <CRow className="justify-content-end mt-3">
                      <Button
                        link
                        disabled={disableOfferActions}
                        text={setNoStock}
                        onClick={() => handleStatusChange(NO_STOCK, shouldStartOffer)}
                      />
                    </CRow>
                  )}
                </CCol>
              </Card>
            </>
          )
        }}
      </Formik>
    </>
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.auth.user,
})

export default compose(withTranslation(), connect(mapStateToProps))(VariantOffer)
