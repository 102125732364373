import { actionsDataEnum } from '@utils/enums/offerEnums'
import { isArray, isPlainObject } from 'lodash'

import { startVariantOffer, sendVariantOffer, getProducts, getOffers } from '../services/offer'

import { START_OFFER, SEND_OFFER, GET_PRODUCTS, GET_DASH_OFFERS } from '../constants/offer'

const startOfferAction = (form) => async (dispatch) => {
  try {
    dispatch({ type: START_OFFER.REQUEST })
    const { success, error, content } = await startVariantOffer(form)

    const dispatchData = {
      type: success ? START_OFFER.SUCCESS : START_OFFER.ERROR,
      payload: success ? content : error,
    }
    dispatch(dispatchData)
  } catch (e) {
    dispatch({
      type: START_OFFER.ERROR,
      payload: e.message,
    })
  } finally {
    dispatch({ type: START_OFFER.RESET })
  }
}

const sendOfferAction = (form) => async (dispatch) => {
  try {
    const { param } = actionsDataEnum[form?.status]

    const offerForm = Object.keys(form)
      .filter(
        (key) =>
          ['floristId', 'variantId', 'accepted', 'noStock', 'newPrice'].includes(key) && !!form[key]
      )
      .reduce((obj, key) => {
        obj[key] = form[key]
        return obj
      }, {})

    dispatch({ type: SEND_OFFER.REQUEST })
    const { success, error, content } = await sendVariantOffer({ ...offerForm, [param]: true })

    const dispatchData = {
      type: success ? SEND_OFFER.SUCCESS : SEND_OFFER.ERROR,
      payload: success ? content : error,
    }
    dispatch(dispatchData)
  } catch (e) {
    dispatch({
      type: SEND_OFFER.ERROR,
      payload: e.message,
    })
  } finally {
    dispatch({ type: SEND_OFFER.RESET })
  }
}

const getProductsByAction =
  ({ param, pageNumber, ...overrides }) =>
  async (dispatch) => {
    try {
      dispatch({ type: GET_PRODUCTS.REQUEST })

      const filters = Object.keys(overrides).reduce((o, key) => {
        const value = overrides[key]
        if (!!value && !isPlainObject(value)) o[key] = isArray(value) ? value.join() : value
        return o
      }, {})

      const params = {
        ...filters,
        [param]: !!param && !isPlainObject(param) ? true : undefined,
        page: pageNumber,
      }

      const { content, success } = await getProducts(params)

      if (success) dispatch({ type: GET_PRODUCTS.SUCCESS, payload: content })
    } catch (e) {
      dispatch({
        type: GET_PRODUCTS.ERROR,
        payload: e.message,
      })
    } finally {
      dispatch({ type: GET_PRODUCTS.RESET })
    }
  }

const getDashboardOffersAction =
  ({ ...params }) =>
  async (dispatch) => {
    try {
      dispatch({ type: GET_DASH_OFFERS.REQUEST })
      const { content, success } = await getOffers({ ...params, limit: 5 })
      if (success) dispatch({ type: GET_DASH_OFFERS.SUCCESS, payload: content })
    } catch (e) {
      dispatch({
        type: GET_DASH_OFFERS.ERROR,
        payload: e.message,
      })
    } finally {
      dispatch({ type: GET_DASH_OFFERS.RESET })
    }
  }

const getDashboardCountAction =
  (floristId, { ...params }) =>
  async (dispatch) => {
    try {
      dispatch({ type: GET_DASH_OFFERS.REQUEST })
      const { content, success } = await getOffers({ ...params, floristId })
      if (success)
        dispatch({ type: GET_DASH_OFFERS.SUCCESS, payload: content.count, getCount: true })
    } catch (e) {
      dispatch({
        type: GET_DASH_OFFERS.ERROR,
        payload: e.message,
      })
    } finally {
      dispatch({ type: GET_DASH_OFFERS.RESET })
    }
  }

export {
  startOfferAction,
  sendOfferAction,
  getProductsByAction,
  getDashboardOffersAction,
  getDashboardCountAction,
}
