import React, { useState } from 'react'
import { isArray } from 'lodash'

import { Checkbox } from '@components/common/index'

import { CCol, CRow } from '@coreui/react'

const PermissionSection = ({ permissions, userPermissions, onChange }) => {
  const [values, setValues] = useState(
    isArray(userPermissions) && !!userPermissions.length ? userPermissions.map((val) => val.id) : []
  )

  const togglePermission = (e) => {
    const { checked } = e.target
    const id = parseInt(e.target.name, 10)
    let newValues = [...values]

    if (checked && !newValues.includes(id)) {
      newValues = [...newValues, id]
    }

    if (!checked && newValues.includes(id)) {
      newValues = newValues.filter((val) => val !== id)
    }

    setValues(newValues)
    onChange(newValues)
  }

  return (
    // <Card collapsible title={title}>
    <CRow className="mb-4">
      {!!permissions?.length &&
        permissions?.map(({ name, id }) => (
          <CCol xl={3} md={4} xs={6} key={id} className="py-2">
            <Checkbox
              id={id}
              className="mt-1 mr-1"
              onChange={togglePermission}
              name={id}
              value={!!values && values.includes(id)}
              label={name}
              labelClassName="h6"
            />
          </CCol>
        ))}
    </CRow>
    // </Card>
  )
}

export default PermissionSection
