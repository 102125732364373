import React, { useEffect, useState } from 'react'

import { CLabel, CFormGroup, CInputCheckbox } from '@coreui/react'
import { Render } from '@utils/interfaces/react'
import { isString } from 'lodash'

interface Props extends Omit<CInputCheckbox, 'value' | 'label'> {
  labelClassName?: string
  rtl?: boolean
  value?: boolean
  label?: Render
  id: string
}

const Checkbox: React.FC<Props> = ({
  id,
  label,
  name,
  value = false,
  className = '',
  labelClassName = '',
  onChange,
  rtl = false,
  ...props
}) => {
  const [checked, setChecked] = useState<boolean>(value)

  const handleChange = (e) => {
    setChecked(e.target.checked)
    if (onChange) onChange(e)
  }

  useEffect(() => {
    setChecked(value)
  }, [value])

  const labelValue = (!!label && label) ?? name

  const CheckLabel = () => (
    <CLabel className="mb-0" variant="custom-checkbox" htmlFor={id}>
      {isString(labelValue) ? (
        <p className={`${labelClassName} mb-0 mr-1`}>{labelValue}</p>
      ) : (
        labelValue
      )}
    </CLabel>
  )

  return (
    <CFormGroup className={`${className} align-items-center`} variant="custom-checkbox">
      {rtl && <CheckLabel />}

      <CInputCheckbox
        custom
        id={id}
        name={name}
        className="mx-2"
        checked={checked}
        onChange={handleChange}
        {...props}
      />

      {!rtl && <CheckLabel />}
    </CFormGroup>
  )
}

export default Checkbox
