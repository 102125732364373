import * as Yup from 'yup'
import { PasswordTypes, EmailTypes } from '../formTypes'

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .matches(EmailTypes?.regex, 'Email contains invalid characters')
    .max(EmailTypes?.maxLength, 'emailMaxLength')
    .required('Email is required'),
  password: Yup.string()
    .matches(PasswordTypes?.regex, 'Password contains invalid characters')
    .min(PasswordTypes?.minLength, 'passwordMinLength')
    .max(PasswordTypes?.maxLength, 'passwordMaxLength')
    .required('Password is required'),
})

export default LoginSchema
