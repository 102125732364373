import { createConstants, initConstant } from './constantsCreator'

const CREATE_RULE = createConstants('CREATE_RULE')
const GET_RULES = createConstants('GET_RULES')
const UPDATE_RULE = createConstants('UPDATE_RULE')
const GET_RULE = createConstants('GET_RULE')
const DELETE_RULE = createConstants('DELETE_RULE')
const UPDATE_RULES_ORDER = createConstants('UPDATE_RULES_ORDER')

const GET_RULES_INIT = {
  ...initConstant(GET_RULES),
  list: [],
}

const GET_RULE_INIT = {
  ...initConstant(GET_RULE, 'getRuleDetail'),
  detail: {},
}

const RULE_INIT = {
  ...GET_RULES_INIT,
  ...GET_RULE_INIT,
  ...initConstant(CREATE_RULE),
  ...initConstant(UPDATE_RULE),
  ...initConstant(DELETE_RULE),
  ...initConstant(UPDATE_RULES_ORDER, 'updateRules'),
}

export { CREATE_RULE, GET_RULES, UPDATE_RULE, UPDATE_RULES_ORDER, GET_RULE, RULE_INIT, DELETE_RULE }
