import { toast } from 'react-toastify'
import translations from '@translations'
import { containerIdEnum } from './enums/toastEnums'

export const errorToast = (message = translations.generic.error, { ...props }) => {
  toast.error(message, {
    ...props,
    containerId: containerIdEnum.ERROR,
  })
}

export const successToast = (message, { ...props }) => {
  toast(message, {
    ...props,
    containerId: containerIdEnum.SUCCESS,
  })
}

export const infoToast = (message, { ...props }) => {
  toast(message, {
    ...props,
    containerId: containerIdEnum.INFO,
  })
}
