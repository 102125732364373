import { unsetRedirectAction } from '@actions/auth'
import React from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom'

type PropsFromRedux = ConnectedProps<typeof connector>

export interface Props extends PropsFromRedux, RouteProps {
  reversed?: boolean
}

const ProtectedRoute: React.FC<Props> = ({
  reversed = false,
  authToken,
  redirectUrl,
  unsetRedirect,
  ...props
}) => {
  const currentLocation = useLocation()
  const currentUrl = currentLocation.pathname

  if (currentUrl === redirectUrl) unsetRedirect()

  if (currentUrl.split('/')[1] === 'reset-password') return <Route {...props} />

  if (reversed !== !!authToken) {
    return !!authToken && !!redirectUrl ? (
      <Redirect from={currentUrl} to={redirectUrl} />
    ) : (
      <Route {...props} />
    )
  }

  return (
    <Redirect
      from={reversed ? currentUrl : '/'}
      to={{
        pathname: reversed ? '/' : '/login',
        ...(!reversed && { state: { redirectUrl: currentUrl } }),
      }}
    />
  )
}

const mapDispatchToProps = {
  unsetRedirect: unsetRedirectAction,
}

const mapStateToProps = (state) => ({
  authToken: state.auth.token,
  redirectUrl: state.auth.redirect,
})

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(ProtectedRoute)
