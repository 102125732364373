import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import { isAllowedEntity, isAllowedPermissions } from '@utils/helpers'
import { routes } from '../../routes'

const PrivilegeRoute = ({ user, location, accessTypes, accessPermissions, ...props }) => {
  const { type, permissions } = user ?? { type: {}, permissions: [] }

  const isAllowed =
    isAllowedEntity(type?.id, accessTypes) && isAllowedPermissions(permissions, accessPermissions)

  if (isAllowed) return <Route {...props} />
  return <Redirect from={location.pathname} to={routes.Page403} />
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
})

export default connect(mapStateToProps)(PrivilegeRoute)
