export const formKeys = {
  email: 'email',
  password: 'password',
}

const { email, password } = formKeys

export const initialValues = {
  [email]: '',
  [password]: '',
}
