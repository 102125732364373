import React, { useState, useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { errorToast, successToast } from '@utils/toast'

import translations from '@translations'

import { Formik } from 'formik'
import PriceSchema from '@utils/schemas/product/priceSchema'
import FirstPriceSchema from '@utils/schemas/product/firstPriceSchema'

import { startOfferAction } from '@actions/offer'

import { actionsEnum } from '@utils/enums/offerEnums'

import { Button, PriceInput, Modal } from '@components/common/index'
import { CRow, CCol, CForm } from '@coreui/react'
import { priceFormKeys, firstPriceFormKeys, priceInitialValues as initialValues } from './formKeys'
import VariantOffer from './components/variantOffer'

const { COUNTEROFFER } = actionsEnum

const ProductOffer = ({
  id,
  successList,
  variants,
  onSelectVariant,
  floristId,
  onSubmit,
  startOffer,
  zoneId,
  success,
  error,
}) => {
  const { sendCost, setCounteroffer, startedOffer, errorSendingOffer } = translations.form

  const { startOffer: start, startWithCost } = translations.generic

  const [modal, setModal] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  const [selected, setSelected] = useState({})
  const [offer, setOffer] = useState({})
  const [submittedVariant, setSubmittedVariant] = useState({})

  const [formKeys, setFormKeys] = useState(priceFormKeys)
  const [schema, setSchema] = useState(null)

  useEffect(() => {
    setFormKeys(!modal.isFirst ? priceFormKeys : firstPriceFormKeys)
    setSchema(!modal.isFirst ? PriceSchema : FirstPriceSchema)
  }, [modal])

  useEffect(() => {
    if (submittedVariant && submittedVariant.id) {
      if (success) {
        successToast(`${startedOffer} ${submittedVariant.name}`, {
          toastId: 'successStartingOffer',
        })
      }
      if (error)
        errorToast(`${errorSendingOffer} ${submittedVariant.name}`, {
          toastId: 'errorStartingOffer',
        })
    }
  }, [error, success, submittedVariant])

  const openModal = () => setModalOpen(true)
  const closeModal = () => {
    setSelected({})
    setModalOpen(false)
  }

  const startVariantOffer = (vId) => {
    const selectedVariant = variants.find((v) => v.id === vId)
    setSelected({ ...selectedVariant, floristId, zoneId })
    setModal({
      title: startWithCost,
      button: start,
      isFirst: true,
    })
    openModal()
  }

  const setCounterofferPrice = (vId, status) => {
    if (status === COUNTEROFFER) {
      setSelected(variants.find((v) => v.id === vId))
      setModal({
        title: setCounteroffer,
        button: sendCost,
      })
      openModal()
    }
  }

  const onSubmitOffer = (offerValues) => {
    const variant = variants.find((v) => v.id === offerValues.variantId)
    onSubmit({ ...offerValues, variantName: variant.name })
    setSubmittedVariant(variant)
  }

  return (
    <CCol>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={(values) => {
          setOffer({ ...values, variantId: values.id ?? values.variantId })

          if (modal.isFirst) {
            startOffer(values)
            const variant = variants.find((v) => v.id === values.variantId)
            setSubmittedVariant(variant)
          }
          closeModal()
        }}
      >
        {({ setFieldValue, setFieldTouched, handleSubmit, values, errors, touched, dirty }) => {
          useEffect(() => {
            if (modalOpen && !!selected?.id && !!formKeys) {
              const currentKeys = !modal.isFirst ? priceFormKeys : firstPriceFormKeys
              setFieldValue(currentKeys.variantId, selected.id)

              if (!modal.isFirst) {
                setFieldValue(formKeys.initialPrice, selected.defaultPrice ?? null)
              } else if (selected.zoneId) {
                if (selected.floristId) setFieldValue(currentKeys.floristId, selected.floristId)
                setFieldValue(currentKeys.zoneId, selected.zoneId)
              }
            }
          }, [modalOpen, selected, formKeys])

          const dismissModal = () => {
            setFieldValue(formKeys.price, null)
            closeModal()
          }

          const handlePriceChange = (value) => {
            setFieldTouched(formKeys.price)
            setFieldValue(formKeys.price, value)
          }

          const PriceButton = () => (
            <Button
              text={modal?.button}
              onClick={handleSubmit}
              type="submit"
              disabled={!dirty || !!Object.keys(errors)?.length}
            />
          )

          return (
            <Modal
              title={modal?.title}
              open={modalOpen}
              closeModal={dismissModal}
              onDismiss={dismissModal}
              footerButtons={PriceButton}
            >
              <CCol xl="12">
                <CForm className="w-100 d-flex" style={{ flexDirection: 'column' }}>
                  <PriceInput
                    id={id}
                    placeholder={values.initialPrice}
                    name={formKeys.price}
                    value={values.price || undefined}
                    allowNegativeValue={false}
                    onChange={(val) => handlePriceChange(val)}
                    error={!!errors.price && touched.price && errors.price}
                  />
                </CForm>
              </CCol>
            </Modal>
          )
        }}
      </Formik>

      <CRow className="px-2 py-2">
        {variants.map((v) => (
          <VariantOffer
            disabled={successList.includes(v.id)}
            key={v.id}
            variant={v}
            floristId={floristId}
            price={!!offer && offer.variantId === v.id ? offer.price : null}
            onOpen={onSelectVariant}
            onStatus={setCounterofferPrice}
            onSubmit={onSubmitOffer}
            setCostModal={() => startVariantOffer(v.id)}
          />
        ))}
      </CRow>
    </CCol>
  )
}

const mapDispatchToProps = {
  startOffer: startOfferAction,
}

const mapStateToProps = (state) => ({
  success: state.offer.startOfferSuccess,
  error: state.offer.startOfferErrorMessage,
})

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(ProductOffer)
