import React from 'react'

import { Badge as BadgeAD, BadgeProps } from 'antd'

interface Props extends Omit<BadgeProps, 'count'> {
  small?: boolean
  value?: BadgeProps['count']
}

const Badge: React.FC<Props> = ({
  children,
  small = false,
  size,
  value,
  status,
  color = '#321FDB',
  ...props
}) => {
  const badgeProps = {
    overflowCount: small ? 9 : undefined,
    ...(!status && { color }),
    ...(!!status && { status }),
    ...props,
    count: value,
    size: small ? 'small' : size,
  }

  return children ? (
    <BadgeAD {...badgeProps}>{children}</BadgeAD>
  ) : (
    <BadgeAD offset={[0, 0]} {...badgeProps}>
      {' '}
    </BadgeAD>
  )
}

export default Badge
