/* eslint-disable @typescript-eslint/no-empty-interface */
import React from 'react'
import { Slider as SliderAD, SliderSingleProps as SliderProps } from 'antd'
import { Props as IconProps } from '@components/common/Icon'

import { middle } from '@utils/helpers'
import { isNumber, isArray } from 'lodash'

interface SliderMarks {
  [key: number]:
    | React.ReactNode
    | {
        style: React.CSSProperties
        label: React.ReactNode
      }
}

interface IconType extends Omit<IconProps, 'name'> {
  active: boolean
}

type Mark = number | SliderMarks

interface Props extends Omit<SliderProps, 'marks'> {
  stepPercentage?: number
  markPercentage?: number
  markFormatter?: (markValue: number) => React.ReactNode
  IconMin?: React.ComponentType<IconType>
  IconMax?: React.ComponentType<IconType>
  marks?: SliderProps['marks'] | Mark[]
}

const Slider: React.FC<Props> = ({
  markFormatter,
  markPercentage = 10,
  stepPercentage = 10,
  max = 100,
  min = 0,
  IconMin,
  IconMax,
  value,
  marks,
  ...props
}) => {
  const mid = middle(max, min)

  const getMark = (number: number) => (!!markFormatter && markFormatter(number)) ?? number

  const getMarkRange = () => {
    const markRange = {}
    const markStep = Math.max(0, (max * markPercentage) / 100)
    let lastNumber = markStep

    if (markStep) {
      while (lastNumber <= max) {
        markRange[lastNumber] = getMark(lastNumber)
        lastNumber += markStep
      }
    }
    return markRange
  }

  const reduceMarks = (ogMarks) =>
    ogMarks.reduce((o, key: Mark) => {
      if (key) {
        if (isNumber(key)) {
          o[key] = getMark(key)
        } else {
          const number = Object.keys(key)[0]
          o[number] = key[number]
        }
      }
      return o
    }, {})

  const sliderProps = {
    value,
    step: stepPercentage ? Math.max(1, (max * stepPercentage) / 100) : undefined,
    max,
    min,
    ...props,
    trackStyle: {
      backgroundColor: '#321FDB',
      ...props?.trackStyle,
    },
    handleStyle: {
      borderColor: '#321FDB',

      ...props?.handleStyle,
    },
    marks: { ...getMarkRange(), ...(isArray(marks) ? reduceMarks(marks) : marks) },
  }

  return (
    <div style={{ position: 'relative' }}>
      {!!IconMin && <IconMin active={value !== undefined && value < mid} />}
      <SliderAD {...sliderProps} />
      {!!IconMax && <IconMax active={value !== undefined && value >= mid} />}
    </div>
  )
}

export default Slider
