import { isFunction } from 'lodash'

import {
  getPolygons,
  createPolygon,
  updatePolygon,
  getPolygonDetail,
  deletePolygon,
  getPolygonZones,
} from '../services/polygon'

import {
  GET_POLYGONS,
  CREATE_POLYGON,
  UPDATE_POLYGON,
  GET_POLYGON,
  DELETE_POLYGON,
  GET_ZONES,
} from '../constants/polygon'

const getPolygonsAction = (params, setList) => async (dispatch) => {
  try {
    dispatch({ type: GET_POLYGONS.REQUEST })
    const { content, success } = await getPolygons(params)

    if (success) {
      if (isFunction(setList)) {
        setList(content?.results)
      }
      dispatch({ type: GET_POLYGONS.SUCCESS, payload: content })
    }
  } catch (e) {
    dispatch({
      type: GET_POLYGONS.ERROR,
      payload: e.message,
    })
  } finally {
    dispatch({ type: GET_POLYGONS.RESET })
  }
}

const createPolygonAction = (form) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_POLYGON.REQUEST })
    const { content, success } = await createPolygon(form)

    if (success) {
      dispatch({ type: CREATE_POLYGON.SUCCESS, payload: content })
    }
  } catch (e) {
    dispatch({
      type: CREATE_POLYGON.ERROR,
      payload: e.message,
    })
  } finally {
    dispatch({ type: CREATE_POLYGON.RESET })
  }
}

const updatePolygonAction = (id, form) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_POLYGON.REQUEST })
    const { content, success } = await updatePolygon(id, form)

    if (success) {
      dispatch({ type: UPDATE_POLYGON.SUCCESS, payload: content })
    }
  } catch (e) {
    dispatch({
      type: UPDATE_POLYGON.ERROR,
      payload: e.message,
    })
  } finally {
    dispatch({ type: UPDATE_POLYGON.RESET })
  }
}

const getPolygonAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_POLYGON.REQUEST })
    const { content, success } = await getPolygonDetail(id)

    if (success) {
      dispatch({ type: GET_POLYGON.SUCCESS, payload: content })
    }
  } catch (e) {
    dispatch({
      type: GET_POLYGON.ERROR,
      payload: e.message,
    })
  } finally {
    dispatch({ type: GET_POLYGON.RESET })
  }
}

const deletePolygonAction = (id, successCallback) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_POLYGON.REQUEST })
    const { success } = await deletePolygon(id)

    if (success) {
      dispatch({ type: DELETE_POLYGON.SUCCESS })
      if (successCallback) successCallback()
    }
  } catch (e) {
    dispatch({
      type: DELETE_POLYGON.ERROR,
      payload: e.message,
    })
  } finally {
    dispatch({ type: DELETE_POLYGON.RESET })
  }
}

const getPolygonZonesAction = (center, radius, setList) => async (dispatch) => {
  try {
    dispatch({ type: GET_ZONES.REQUEST })
    const { content, success } = await getPolygonZones({ radius, ...center })

    if (success) {
      dispatch({ type: GET_ZONES.SUCCESS, payload: content })
      if (setList) setList(content)
    }
  } catch (e) {
    dispatch({
      type: GET_ZONES.ERROR,
      payload: e.message,
    })
  } finally {
    dispatch({ type: GET_ZONES.RESET })
  }
}

export {
  getPolygonsAction,
  createPolygonAction,
  updatePolygonAction,
  getPolygonAction,
  deletePolygonAction,
  getPolygonZonesAction,
}
