import React, { useRef, useEffect, useState } from 'react'
import { useHistory, RouteComponentProps } from 'react-router-dom'

import { compose } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'

import translations from '@translations'
import { withRouteParams } from '@utils/helpers'

import { Polygon } from '@utils/interfaces/maps'

import { getPolygonAction, deletePolygonAction } from '@actions/polygon'

import { Card, Button, Modal } from '@components/common'
import { CCol, CRow } from '@coreui/react'
import PolygonMap from '@components/GoogleMaps/EditablePolygonMap'

import StripedTable from '@components/common/Tables/StripedTable'
import { routes } from '@/routes'

type PropsFromRedux = ConnectedProps<typeof connector>
interface MatchParams {
  id: string
}

type Props = WithTranslation & PropsFromRedux & RouteComponentProps<MatchParams>

const PolygonDetail: React.FC<Props> = ({
  match,
  details,
  userInfo,
  getDetails,
  deletePolygon,
}) => {
  const history = useHistory()
  const { id } = match.params

  const [modalOpen, setModalOpen] = useState<boolean>()

  const openDeleteModal = () => setModalOpen(true)
  const closeDeleteModal = () => setModalOpen(false)

  const { detailsTitle, deleteTitle } = translations.generic
  const {
    editPolygon,
    confirmDeletePolygon,
    deletePolygon: deletePolygonTranslation,
  } = translations.form

  const imOwner = (userInfo?.type?.id === 4 || userInfo?.type?.id === 1) ?? false

  const polygonRef = useRef<Polygon | null>(null)

  useEffect(() => {
    getDetails(id)
  }, [])

  const goToEditPolygon = () => {
    history.push(withRouteParams(routes.EditPolygon, { id }))
  }

  const handleDeletePolygon = () => {
    deletePolygon(id, () => {
      closeDeleteModal()
      history.push(routes.Polygons)
    })
  }

  const DeleteButton = () => (
    <Button onClick={handleDeletePolygon} color="danger" text={deleteTitle} />
  )

  return (
    <CCol>
      <Modal
        size="sm"
        title={deletePolygonTranslation}
        open={modalOpen}
        closeModal={closeDeleteModal}
        onDismiss={closeDeleteModal}
        footerButtons={DeleteButton}
      >
        <p>{confirmDeletePolygon}</p>
      </Modal>

      <CRow className="d-flex justify-content-end align-items-center">
        {imOwner && (
          <CCol xl={6} className="d-flex justify-content-end">
            <CRow>
              <Button
                onClick={goToEditPolygon}
                icon="cil-pencil"
                color="secondary"
                className="mr-2"
                text={editPolygon}
              />
              <Button
                onClick={openDeleteModal}
                icon="cil-x"
                color="danger"
                className="mr-2"
                text={deletePolygonTranslation}
              />
            </CRow>
          </CCol>
        )}
      </CRow>

      <CRow className="my-3">
        <CCol xl={4}>
          <Card title={detailsTitle}>
            <StripedTable
              data={details}
              fields={{ exclude: ['data', 'center'] }}
              render={{ country: 'name' }}
            />
          </Card>
        </CCol>
        {details?.data && (
          <CCol xl={8}>
            <PolygonMap
              polygonRef={polygonRef}
              editable={false}
              data={details?.data ?? undefined}
              initialCenter={details?.center ?? undefined}
              width="100%"
            />
          </CCol>
        )}
      </CRow>
    </CCol>
  )
}

const mapStateToProps = (state) => ({
  details: state.polygon.detail,
  userInfo: state.auth.user,
})

const mapDispatchToProps = {
  getDetails: getPolygonAction,
  deletePolygon: deletePolygonAction,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default compose(withTranslation(), connector)(PolygonDetail)
