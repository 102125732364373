import React from 'react'

import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { connect, ConnectedProps } from 'react-redux'

import { Formik } from 'formik'

import translations from '@translations'

import { Button } from '@components/common'
import Col from '@utils/components/ResponsiveColumn'
import NewTextInput from '@components/common/NewTextInput'

import { CForm, CRow } from '@coreui/react'

import { IngredientDataSchema } from '@utils/schemas/ingredientSchema'
import { formKeys } from './formKeys'

type PropsFromRedux = ConnectedProps<typeof connector>

interface BackendIngredient {
  id: string
  name: string
  parent?: string
}

interface Props {
  onSubmit: (values: any) => void
}

const EditIngredientForm: React.FC<Props & PropsFromRedux> = ({ onSubmit, details: iDetails }) => {
  const { update } = translations.generic
  const { ingredientName, editIngredient } = translations.form

  const details = iDetails as BackendIngredient

  return (
    <Formik
      initialValues={{
        [formKeys.name]: details.name,
      }}
      validationSchema={IngredientDataSchema}
      onSubmit={onSubmit}
    >
      {({ handleChange, handleBlur, handleSubmit, values, errors, touched, dirty }) => (
        <CForm>
          <CRow className="d-flex">
            <Col xl={8}>
              <h5>{editIngredient}</h5>
            </Col>
          </CRow>

          <CRow>
            <Col xl={12} className="mt-3">
              <NewTextInput
                isDefault
                value={values[formKeys.name]}
                placeholder={ingredientName}
                name={formKeys.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched[formKeys.name] && !!errors[formKeys.name]
                    ? (errors[formKeys.name] as string)
                    : undefined
                }
              />
            </Col>
          </CRow>

          <CRow className="justify-content-end mt-3 mx-0">
            <Button
              color="primary"
              disabled={!dirty || !!Object.keys(errors).length}
              onClick={handleSubmit}
              text={update}
            />
          </CRow>
        </CForm>
      )}
    </Formik>
  )
}

const mapStateToProps = (state) => ({
  details: state.ingredient.detail,
})

const connector = connect(mapStateToProps)

export default compose<React.FC<Props>>(withTranslation(), connector)(EditIngredientForm)
