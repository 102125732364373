import React, { useEffect, useState } from 'react'
import { useHistory, RouteComponentProps } from 'react-router-dom'

import { compose } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'

import { withRouteParams, isObjectEmpty } from '@utils/helpers'
import { successToast } from '@utils/toast'
import { messageEnums } from '@utils/enums/toastEnums'
import { createEntityAction } from '@actions/entity'

import EntityForm from '@forms/entity'
import { Card, Button } from '@components/common/index'
import { CCol } from '@coreui/react'

import translations from '@translations'
import { routes } from '@/routes'

type PropsFromRedux = ConnectedProps<typeof connector>
interface MatchParams {
  type: 'itm' | 'fl'
}

type Props = WithTranslation & PropsFromRedux & RouteComponentProps<MatchParams>

const NewEntityForm: React.FC<Props> = ({
  match,
  createEntity,
  successFlorist,
  successIntermediate,
  createdEntity,
}) => {
  const history = useHistory()

  const [createdWithUser, setCreatedWithUser] = useState(false)

  const entityType = match.params.type
  const isIntermediate = entityType === 'itm'

  const { createIntermediateSuccess, createFloristSuccess, infoUpdatePermissions } =
    translations.toasts

  const { CREATE_FLORIST, CREATE_INTERMEDIATE } = messageEnums

  const goToPermissions = () => {
    if (!!createdEntity && !!createdEntity.users && !!createdEntity.users.length) {
      const { users, typeId, id } = createdEntity
      history.push(
        withRouteParams(routes.EntityUserPermissions, {
          type: entityType,
          entityId: id,
          id: users[0].id,
        }),
        {
          type: typeId,
        }
      )
    }
  }

  const EntityCreatedToast = () => (
    <div>
      <p>{`${isIntermediate ? createIntermediateSuccess : createFloristSuccess}!`}</p>
      {createdWithUser && (
        <Button link onClick={goToPermissions} text={`${infoUpdatePermissions}.`} />
      )}
    </div>
  )

  useEffect(() => {
    if (successFlorist) {
      successToast(EntityCreatedToast, {
        toastId: CREATE_FLORIST,
      })
      history.push(routes.Florists)
    }
    if (successIntermediate) {
      successToast(EntityCreatedToast, {
        toastId: CREATE_INTERMEDIATE,
      })
      history.push(routes.Intermediates)
    }
  }, [successFlorist, successIntermediate])

  const onFormSubmit = (form) => {
    // eslint-disable-next-line no-param-reassign
    if (!form.setIntermediate) form.intermediateId = undefined
    const type = form.isFromLogin ? 'fl' : entityType
    setCreatedWithUser(!!form.newUser && !!form.user && !isObjectEmpty(form.user))
    createEntity(type, form)
  }

  return (
    <CCol xl={isIntermediate ? 6 : 12}>
      <Card className="my-3">
        <EntityForm onSubmit={onFormSubmit} entityType={entityType} />
      </Card>
    </CCol>
  )
}

const mapStateToProps = (state) => ({
  successFlorist: state.entity.createFloristSuccess,
  successIntermediate: state.entity.createIntermediateSuccess,
  createdEntity: state.entity.created,
})

const mapDispatchToProps = {
  createEntity: createEntityAction,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default compose(withTranslation(), connector)(NewEntityForm)
