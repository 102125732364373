import React from 'react'
import { Tooltip } from '@components/common'

interface TreeNode {
  label: string
}

interface Props {
  hidden: TreeNode[]
}

const MaxTagLabel: React.FC<Props> = ({ hidden }) => {
  const label = hidden.length
  const content = hidden.map((h) => h.label).join(',\n')

  return (
    <Tooltip tooltip={content}>
      <p className="mb-0">{`${label}...`}</p>
    </Tooltip>
  )
}

export default MaxTagLabel
