import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'

import translations from '@translations'

import { Button, Tooltip } from '@components/common'

import {
  CButtonGroup,
  CDropdownMenu,
  CDropdownToggle,
  CDropdown,
  CDropdownItem,
} from '@coreui/react'
import { statusDataEnum, statusEnum } from '../enums/productEnums'
import { actionsDataEnum, actionsEnum } from '../enums/offerEnums'

const getTranslation = (text) => translations.generic[text] ?? translations.form[text] ?? text

export const ProductFilterDropdown = withTranslation()(
  ({ onChange, placeholder, initialValue }) => {
    const initialKey = initialValue || statusEnum.ALL

    const defaultFilter = {
      key: initialKey,
      ...statusDataEnum[initialKey],
    }

    const [selected, setSelected] = useState(defaultFilter)

    const handleSelect = (key, data) => {
      setSelected({ key, ...data })
      onChange(key, data.param)
    }

    return (
      <CDropdown className="w-100">
        <CDropdownToggle
          className="d-flex justify-content-between align-items-center w-100"
          style={{ minWidth: '100px' }}
          color={selected?.color ?? 'secondary'}
        >
          {getTranslation(selected?.name) ?? placeholder}
        </CDropdownToggle>

        <CDropdownMenu>
          {Object.entries(statusDataEnum).map(([key, data]) => (
            <CDropdownItem
              key={key}
              className={`text-${data?.color}`}
              onClick={() => handleSelect(key, data)}
              disabled={selected && key === selected.key}
            >
              {getTranslation(data?.name)}
            </CDropdownItem>
          ))}
        </CDropdownMenu>
      </CDropdown>
    )
  }
)

export const OfferButtonDropdown = withTranslation()(({ onChange, placeholder }) => {
  const [selected, setSelected] = useState({})

  const handleSelect = (key, data) => {
    setSelected(data)
    onChange(key, data)
  }

  return (
    <CDropdown>
      <CDropdownToggle
        className="d-flex justify-content-between align-items-center"
        style={{ minWidth: '100px' }}
        color={selected?.color ?? 'secondary'}
        size="sm"
      >
        {getTranslation(selected?.name) ?? placeholder}
      </CDropdownToggle>

      <CDropdownMenu>
        {Object.entries(actionsDataEnum).map(([key, data]) => (
          <CDropdownItem key={key} onClick={() => handleSelect(key, data)}>
            {getTranslation(data?.name)}
          </CDropdownItem>
        ))}
      </CDropdownMenu>
    </CDropdown>
  )
})

export const OfferButtonList = withTranslation()(({ onClick, disabled, disabledText }) => (
  <Tooltip tooltip={disabledText} show={disabled}>
    <CButtonGroup size="sm">
      {Object.entries(actionsDataEnum)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .filter(([key, _]) => key !== actionsEnum.NO_STOCK)
        .map(([key, { color, name, icon }]) => (
          <Button
            onClick={() => onClick(key)}
            color={color || 'secondary'}
            className="align-items-center w-100"
            tooltip={!disabled ? getTranslation(name) : null}
            icon={icon}
            key={key}
            small
            disabled={disabled}
          />
        ))}
    </CButtonGroup>
  </Tooltip>
))
