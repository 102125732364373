import React from 'react'

import translations from '@translations'

import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'

import { setSidebar as setSidebarAction } from '@actions/generic'

import { firstToLowercase } from '@utils/helpers'

import LanguageSwitcher from '@components/header/LanguageSwitcher'
import FloristSwitcher from '@components/header/FloristSwitcher'

import {
  CHeader,
  CToggler,
  CHeaderBrand,
  CHeaderNav,
  CHeaderNavItem,
  CHeaderNavLink,
  CSubheader,
  CBreadcrumbRouter,
} from '@coreui/react'

import { Icon } from '@components/common/index'

import TheHeaderDropdown from './TheHeaderDropdown'

import { routes, allRoutes } from '@/routes'

const TheHeader = ({ sidebarShow, setSidebar, userInfo }) => {
  const imFlorist = userInfo?.type?.id === 3

  const toggleSidebar = () => {
    const val = [true, 'responsive'].includes(sidebarShow) ? false : 'responsive'
    setSidebar(val)
  }

  const toggleSidebarMobile = () => {
    const val = [false, 'responsive'].includes(sidebarShow) ? true : 'responsive'
    setSidebar(val)
  }

  return (
    <CHeader withSubheader>
      <CToggler inHeader className="ml-md-3 d-lg-none" onClick={toggleSidebarMobile} />
      <CToggler inHeader className="ml-3 d-md-down-none" onClick={toggleSidebar} />
      <CHeaderBrand className="mx-auto d-lg-none" to="/">
        <Icon name="logo" height="48" alt="Logo" />
      </CHeaderBrand>

      <CHeaderNav className="d-md-down-none mr-auto">
        <CHeaderNavItem className="px-3">
          <CHeaderNavLink to={routes.Dashboard}>Dashboard</CHeaderNavLink>
        </CHeaderNavItem>
      </CHeaderNav>

      <CHeaderNav className="px-3">
        <TheHeaderDropdown />
      </CHeaderNav>

      <CSubheader className="px-3 justify-content-between">
        <CBreadcrumbRouter
          className="border-0 c-subheader-nav m-0 px-0 px-md-3"
          routes={allRoutes.map((route) => ({
            ...route,
            name:
              translations.routes[route.prettyName ?? firstToLowercase(route.name)] ??
              route.prettyName ??
              route.name,
          }))}
        />
        <div className="d-md-down-none mfe-2 c-subheader-nav">
          {imFlorist && <FloristSwitcher />}
          <LanguageSwitcher />
        </div>
      </CSubheader>
    </CHeader>
  )
}

const mapStateToProps = (state) => ({
  sidebarShow: state.generic.sidebarShow,
  userInfo: state.auth.user,
})

const mapDispatchToProps = {
  setSidebar: setSidebarAction,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default compose(connector, withTranslation())(TheHeader)
