import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'

import { Modal, Stars, DataTable } from '@components/common'

import translations from '@translations'
import { ObjectWithoutKey as Data } from '@utils/interfaces/react'

import { OrderModalContext } from './context'

interface Props extends WithTranslation {
  data: Record<string, unknown>[]
  loading: boolean
}

const OrderRatingModal: React.FC<Props> = ({ data, loading = false }) => {
  const { useRatingModal, selectedFloristName } = OrderModalContext()
  const { modalOpen, closeModal } = useRatingModal()

  const { califications } = translations.form

  return (
    <Modal
      title={`${califications} ${selectedFloristName}`}
      open={modalOpen}
      closeModal={closeModal}
      onDismiss={closeModal}
      hideFooter
      size="lg"
    >
      <DataTable
        data={data as any as Data[]}
        fields={['date', 'comment', 'rating']}
        loading={loading}
        render={{
          rating: {
            render: (_, { rating: value }) => <Stars value={value} readonly />,
          },
        }}
      />
    </Modal>
  )
}

export default withTranslation()(OrderRatingModal)
