import React, { useEffect, useState } from 'react'
import { debounce } from 'lodash'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { useHistory } from 'react-router-dom'
import translations from '@translations'

import { Chip } from '@components/common/index'
import { Pagination } from 'antd'
import { statusDataEnum } from '@utils/enums/productEnums'

import { getProductsByAction } from '@actions/offer'
// import { getFloristMainZoneAction, clearFloristMainZoneAction } from '@actions/eny'

import SearchProductsForm from '@forms/productFilters/searchForm'
import ProductLinesSelector from '@forms/productFilters/productLinesForm'
// import ZoneSelectorForm from '@forms/productFilters/zoneSelectorForm'

import { ProductFilterDropdown } from '@utils/components/offerActions'
import Col from '@utils/components/ResponsiveColumn'

import { CRow } from '@coreui/react'
import LoadingOverlay from '@utils/components/LoadingOverlay'
import EmptyProducts from './components/emptyProducts'
import ProductCard from './productCard'

const Products = ({
  match,
  location,
  userInfo,
  currentFlorist,
  products,
  requesting,
  // floristMainZone,
  getProducts,
  // getMainZone,
  // clearMainZone,
}) => {
  const history = useHistory()
  const initialStatus = location?.state?.filter ?? null

  const [selectedZone, setSelectedZone] = useState(null)
  const [filters, setFilters] = useState(null)
  const [status, setStatus] = useState(
    initialStatus
      ? {
          key: initialStatus,
          param: statusDataEnum[initialStatus].param,
        }
      : null
  )
  const [searchQuery, setSearchQuery] = useState()

  const zoneSelected = !!selectedZone && !!selectedZone?.name

  const { filterData, florist } = translations.form
  const { viewingProducts } = translations.generic

  const queryPage = location.search.match(/page=([0-9]+)/)
  const currentPage = Number((queryPage && queryPage[1]) ?? 1)

  const imFlorist = userInfo?.type.id === 3
  const imOwner = userInfo?.type.id === 1
  const floristId =
    imFlorist && currentFlorist
      ? currentFlorist?.id
      : (match.params.id && parseInt(match.params.id, 10)) ?? null
  const username = location?.state?.username ?? null

  const initialRequest = ({ id: zoneId, status: statusOverride, ...overrides } = {}) => {
    let zones = (selectedZone && selectedZone?.id) || zoneId ? [selectedZone?.id ?? zoneId] : null
    const param = statusOverride?.key === 'ALL' ? undefined : statusOverride?.param ?? status?.param
    const name = overrides?.query ?? searchQuery
    // eslint-disable-next-line no-param-reassign
    delete overrides?.query

    if (!zones) {
      zones = [1]
    }

    getProducts({
      floristId,
      ...((param && { param }) || {}),
      pageNumber: currentPage,
      zones, // pedir a back que se cambie a zoneId a secas
      ...filters,
      ...overrides,
      name,
    })
  }

  useEffect(() => {
    // MEXICO
    const id = 1
    const capitalizedName = 'México'
    setSelectedZone({ readonly: true, id, name: capitalizedName })
    initialRequest({ id: 1 })
  }, [currentPage])

  /*   const setZoneWithName = ({ name, id }, readonly) => {
    const capitalizedName = name
    // .split(/(^|-|\s)/g)
    // .map(capitalize)
    // .join('')
    setSelectedZone({ readonly, id, name: capitalizedName })
    initialRequest({ id })
  } */

  /* useEffect(() => {
    const mainZoneData = imOwner && floristId && floristMainZone ? floristMainZone : {}
    const mainZone = imFlorist ? currentFlorist?.mainZone : mainZoneData

    setZoneWithName({ ...mainZone }, true)
  }, [currentFlorist, floristMainZone]) */

  /*   useEffect(() => {
    if (floristId) {
      if (imOwner) getMainZone(floristId)
    } else {
      clearMainZone()
    }
  }, [currentPage]) */

  const onStatusChange = debounce((data) => {
    setStatus(data)
    initialRequest({ status: data })
  }, 300)

  const onPageChange = (newPage) => {
    if (currentPage !== newPage) {
      history.push(`${location.pathname}?page=${newPage}`)
    }
  }

  const paginationProps = {
    showSizeChanger: false,
    hideOnSinglePage: true,
    pageSize: 60,
    total: products?.count ?? 60,
    current: currentPage,
    onChange: onPageChange,
  }

  const onFiltersChange = (values) => {
    setFilters(values)
    initialRequest(values)
  }

  const handleSearch = (values) => {
    setSearchQuery(values.query)
    initialRequest(values)
  }

  return (
    <Col>
      {/* {!floristId && (
        <ZoneSelectorForm
          onSubmit={(id, name) => setZoneWithName({ id, name })}
          open={!selectedZone?.id}
        />
      )} */}
      <CRow className="mb-4">
        {zoneSelected && (
          <Col xxl={4} xl={6} md={6}>
            {floristId && imOwner ? (
              <h4 className="my-0">
                {florist} #{floristId} {username && `· ${username}`}
              </h4>
            ) : (
              <h4 className="my-0"> {viewingProducts} </h4>
            )}
          </Col>
        )}

        <CRow className="justify-content-between w-100 mt-2">
          <Col xxl={3} xl={4} md={6}>
            {zoneSelected && (
              <Chip
                text={selectedZone?.name}
                id={selectedZone.id}
                disabled={selectedZone?.readonly}
                onClose={() => setSelectedZone({})}
              />
            )}
          </Col>

          <Col xxl={6} xl={8} md={12} sm={12} xs={12}>
            <CRow className="d-flex justify-content-end">
              <Col md={7} xs={12} className="px-0">
                <SearchProductsForm onSubmit={handleSearch} />
              </Col>
            </CRow>

            <CRow className="d-flex justify-content-end">
              {(imFlorist || (imOwner && !!floristId)) && (
                <Col md={5} xs={12} className="d-flex px-0 mt-2 pr-2">
                  <ProductFilterDropdown
                    initialValue={initialStatus}
                    placeholder={filterData}
                    onChange={(key, param) => onStatusChange({ key, param })}
                  />
                </Col>
              )}

              <Col md={7} xs={12} className="d-flex px-0 mt-2">
                <ProductLinesSelector onFormChange={onFiltersChange} />
              </Col>
            </CRow>
          </Col>
        </CRow>
      </CRow>

      <CRow className="mt-5 mb-4 justify-content-center">
        <Pagination {...paginationProps} />
      </CRow>

      {/* eslint-disable-next-line no-nested-ternary */}
      {requesting ? (
        <LoadingOverlay active />
      ) : !!products?.results && !!products?.results.length ? (
        <CRow xl={12} className="d-flex justify-content-start my-3">
          {products.results.map((product) => (
            <Col xl={4} md={6} key={product.id} className="pl-0 pr-1">
              <ProductCard floristId={floristId} zoneId={selectedZone?.id} {...product} />
            </Col>
          ))}
        </CRow>
      ) : (
        <EmptyProducts />
      )}

      <CRow className="my-4 justify-content-center">
        <Pagination {...paginationProps} />
      </CRow>
    </Col>
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.auth.user,
  currentFlorist: state.auth.currentFlorist,
  products: state.offer.products,
  requesting: state.offer.getProductsRequesting,
  // floristMainZone: state.entity.mainZone,
})

const mapDispatchToProps = {
  getProducts: getProductsByAction,
  // getMainZone: getFloristMainZoneAction,
  // clearMainZone: clearFloristMainZoneAction,
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(Products)
