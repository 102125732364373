import React, { useEffect, useState } from 'react'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { useHistory } from 'react-router-dom'
import { debounce } from 'lodash'

import { getOrdersAction } from '@actions/order'

import { CCol, CRow } from '@coreui/react'
import { DateRange } from '@components/common'

import { OrderFilterDropdown } from '@utils/components/orderActions'

import translations from '@translations'

import OrderRatingModal from './components/OrderRatingModal'
import OrderFloristModal from './components/OrderFloristModal'
import OrderDriverModal from './components/OrderDriverModal'
import OrderImageModal from './components/OrderImageModal'
import OrderImagesModal from './components/OrderImagesModal'

import OrderList from './OrderList'

import { OrderModalProvider } from './components/context'

const OrderABM = ({
  location,
  getOrders,
  userInfo,
  floristRequesting,
  driverRequesting,
  florists,
  setOrderFloristSuccess,
  getOrdersRequesting,
  getOrdersByFlRequesting,
  currentFlorist,
  currentIntermediate,
}) => {
  const history = useHistory()
  const loading = getOrdersRequesting || getOrdersByFlRequesting

  const { searchFrom, searchTo } = translations.generic

  const [filter, setFilter] = useState({})
  const [driverList, setDriverList] = useState([])
  const [orderList, setOrderList] = useState()

  const [dateRange, setDateRange] = useState(null)

  const [floristRating, _setFloristRating] = useState([
    { date: '10/1/2020', rating: 4.5, comment: 'Nice flowers!' },
    { date: '25/11/2020', rating: 3.5, comment: 'Great service' },
    { date: '14/6/2021', rating: 4, comment: 'Everything okay' },
  ])

  const queryPage = location.search.match(/page=([0-9]+)/)
  const currentPage = Number((queryPage && queryPage[1]) ?? 1)

  const initialRequest = (overrides = {}) => {
    const dateRangeProps = !!dateRange &&
      (dateRange?.startDate || dateRange?.endDate) && {
        dateFrom: dateRange.startDate && dateRange.startDate.toISOString(),
        dateTo: dateRange.endDate && dateRange.endDate.toISOString(),
      }

    const requestProps = {
      page: currentPage,
      status: filter?.param,
      setList: setOrderList,
      ...dateRangeProps,
      ...overrides,
    }

    const requestKeys = {
      intermediate: (intermediateId) =>
        getOrders('itm', intermediateId, {
          ...requestProps,
        }),
      florist: (floristId) =>
        getOrders('fl', floristId, {
          ...requestProps,
        }),
    }

    const imOwner = userInfo?.typeId === 1
    if (userInfo?.typeId === 3) requestKeys.florist(currentFlorist?.id)
    else requestKeys.intermediate(imOwner ? 1 : currentIntermediate?.id)
  }

  useEffect(() => {
    initialRequest()
  }, [filter, dateRange, currentPage])

  const onFilterChange = debounce((data) => {
    setFilter(data)
  }, 300)

  const onPageChange = (newPage) => {
    if (currentPage !== newPage) history.push(`/dashboard/orders?page=${newPage}`)
  }

  const onSearchChange = (queries, isClear) => {
    if (isClear) initialRequest()
    else initialRequest(queries)
  }

  useEffect(() => {
    if (setOrderFloristSuccess) window.location.reload()
  }, [setOrderFloristSuccess])

  return (
    <OrderModalProvider>
      <CCol xl={12}>
        <CCol>
          <OrderFloristModal loading={floristRequesting} data={florists} />
          <OrderRatingModal loading={floristRequesting} data={floristRating} />
          <OrderDriverModal loading={driverRequesting} data={driverList} />
          <OrderImageModal />
          <OrderImagesModal />
        </CCol>

        <CRow>
          <OrderFilterDropdown
            placeholder="filterData"
            onChange={(key, param) => onFilterChange({ key, param })}
            isFlorist={userInfo?.typeId === 3}
          />

          <CCol xl={4} className="d-flex">
            <DateRange
              value={dateRange}
              onChange={(val) => setDateRange(val)}
              startPlaceholder={searchFrom}
              endPlaceholder={searchTo}
            />
          </CCol>
        </CRow>
        <CRow />

        <CRow>
          <OrderList
            loading={loading}
            currentPage={currentPage}
            data={orderList || []}
            setDriverList={setDriverList}
            onPageChange={onPageChange}
            onSearchChange={onSearchChange}
          />
        </CRow>
      </CCol>
    </OrderModalProvider>
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.auth.user,
  floristRequesting: state.order.getFlNearOrderRequesting,
  driverRequesting: state.driver.getDriversRequesting,
  florists: state.order.florists,
  orders: state.order.orders,
  setOrderFloristSuccess: state.order.setOrderFloristSuccess,
  getOrdersRequesting: state.order.getOrdersRequesting,
  getOrdersByFlRequesting: state.order.getOrdersByFlRequesting,
  currentFlorist: state.auth.currentFlorist,
  currentIntermediate: state.auth.currentIntermediate,
})

const mapDispatchToProps = {
  getOrders: getOrdersAction,
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(OrderABM)
