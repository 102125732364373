import React from 'react'

import { Tag as TagAD, TagProps } from 'antd'

export interface Props extends TagProps {
  small?: boolean
  children?: React.ReactNode
}

const Tag: React.FC<Props> = ({ children = null, color, ...props }) => (
  <TagAD
    {...props}
    color={color ?? '#321FDB60'}
    {...(!color && { style: { borderColor: '#321FDB60' } })}
  >
    {children}
  </TagAD>
)

export default Tag
