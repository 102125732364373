import React, { useEffect, useState } from 'react'
import { useHistory, RouteComponentProps } from 'react-router-dom'

import { compose } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'

import translations from '@translations'
import { withRouteParams } from '@utils/helpers'
import { ObjectWithoutKey as Data } from '@utils/interfaces/react'

import { getIngredientAction, deleteIngredientAction } from '@actions/ingredient'

import { Card, Button, Modal } from '@components/common'
import { CCol, CRow } from '@coreui/react'

import StripedTable from '@components/common/Tables/StripedTable'
import { routes } from '@/routes'

type PropsFromRedux = ConnectedProps<typeof connector>
interface MatchParams {
  id: string
}

type Props = WithTranslation & PropsFromRedux & RouteComponentProps<MatchParams>

const IngredientDetail: React.FC<Props> = ({
  match,
  details,
  userInfo,
  getDetails,
  deleteIngredient,
}) => {
  const history = useHistory()
  const { id } = match.params

  const [modalOpen, setModalOpen] = useState<boolean>()

  const openDeleteModal = () => setModalOpen(true)
  const closeDeleteModal = () => setModalOpen(false)

  const { detailsTitle, deleteTitle } = translations.generic
  const {
    editIngredient,
    confirmDeleteIngredient,
    deleteIngredient: deleteIngredientTranslation,
  } = translations.form

  const imOwner = (userInfo?.type?.id === 4 || userInfo?.type?.id === 1) ?? false

  useEffect(() => {
    getDetails(id)
  }, [])

  const goToEditIngredient = () => {
    history.push(withRouteParams(routes.EditIngredient, { id }))
  }

  const handleDeleteIngredient = () => {
    deleteIngredient(id, () => {
      closeDeleteModal()
      history.push(routes.Ingredients)
    })
  }

  const DeleteButton = () => (
    <Button onClick={handleDeleteIngredient} color="danger" text={deleteTitle} />
  )

  return (
    <CCol>
      <Modal
        size="sm"
        title={deleteIngredientTranslation}
        open={modalOpen}
        closeModal={closeDeleteModal}
        onDismiss={closeDeleteModal}
        footerButtons={DeleteButton}
      >
        <p>{confirmDeleteIngredient}</p>
      </Modal>

      <CRow className="d-flex justify-content-end align-items-center">
        {imOwner && (
          <CCol xl={6} className="d-flex justify-content-end">
            <CRow>
              <Button
                onClick={goToEditIngredient}
                icon="cil-pencil"
                color="secondary"
                className="mr-2"
                text={editIngredient}
              />
              <Button
                onClick={openDeleteModal}
                icon="cil-x"
                color="danger"
                className="mr-2"
                text={deleteIngredientTranslation}
              />
            </CRow>
          </CCol>
        )}
      </CRow>

      <CRow className="my-3">
        <CCol xl={4}>
          <Card title={detailsTitle}>
            <StripedTable data={details as any as Data} />
          </Card>
        </CCol>
      </CRow>
    </CCol>
  )
}

const mapStateToProps = (state) => ({
  details: state.ingredient.detail,
  userInfo: state.auth.user,
})

const mapDispatchToProps = {
  getDetails: getIngredientAction,
  deleteIngredient: deleteIngredientAction,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default compose(withTranslation(), connector)(IngredientDetail)
