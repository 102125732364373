import React, { useEffect, useState } from 'react'
import { useHistory, RouteComponentProps } from 'react-router-dom'

import { compose } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'

import { getPolygonsAction, deletePolygonAction } from '@actions/polygon'

import { withRouteParams } from '@utils/helpers'
import { CCol, CRow } from '@coreui/react'
import { Card, Button } from '@components/common'
import { TableButtons } from '@utils/components/tableActions'
import DataTable from '@components/common/Tables/PaginatedTable'

import translations from '@translations'
import { ObjectWithoutKey as Data } from '@utils/interfaces/react'
import { routes } from '@/routes'

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = WithTranslation & PropsFromRedux & RouteComponentProps

interface Polygon {
  id: string
}

const PolygonsABM: React.FC<Props> = ({
  location,
  polygons,
  requesting,
  getPolygons,
  deletePolygon,
}) => {
  const history = useHistory()

  const { newPolygon } = translations.form
  const { detailsTitle, deleteTitle } = translations.generic

  const queryPage = location.search.match(/page=([0-9]+)/)
  const currentPage = Number((!!queryPage && queryPage[1]) || 1)

  const [polygonList, setPolygonList] = useState<Polygon[]>([])

  const initialRequest = (queries = {}) => {
    getPolygons({ page: currentPage, ...queries }, setPolygonList)
  }

  const onPageChange = (newPage) => {
    if (currentPage !== newPage) {
      history.push(`${routes.Polygons}?page=${newPage}`)
    }
  }

  const onSearchChange = (queries, isClear) => {
    if (isClear) initialRequest()
    else initialRequest(queries)
  }

  const goToNewPolygon = () => {
    history.push(routes.NewPolygon)
  }

  const goToDetails = (id) => {
    history.push(withRouteParams(routes.PolygonDetails, { id }))
  }

  const onPolygonDelete = (id) => {
    deletePolygon(id, () => {
      const currentList = [...polygonList]
      const index = currentList.findIndex((poly) => poly.id === id)

      currentList.splice(index, 1)
      setPolygonList(currentList)
    })
  }

  useEffect(() => {
    initialRequest()
  }, [currentPage])

  const PolygonActions = ({ item }: { item: any }) => (
    <TableButtons
      buttons={[
        {
          onClick: () => goToDetails(item.id),
          icon: 'cil-description',
          text: detailsTitle,
        },
        {
          onClick: () => onPolygonDelete(item.id),
          icon: 'cil-x',
          color: 'danger',
          text: deleteTitle,
        },
      ]}
    />
  )

  const customFields = [
    {
      title: '',
      width: '10%',
      key: 'actions',
      dataIndex: 'actions',
      fixed: true,
      render: (_, item) => <PolygonActions item={item} />,
      sorter: false,
    },
  ]

  return (
    <CCol>
      <CRow className="d-flex justify-content-end">
        <Button
          onClick={goToNewPolygon}
          color="primary"
          icon="cil-location-pin-plus"
          text={newPolygon}
        />
      </CRow>

      <CRow>
        <Card className="my-3">
          <DataTable
            loading={requesting}
            data={(polygonList as any as Data[]) || []}
            queryFields={polygons?.queryFields}
            pagination={{ current: currentPage, total: polygons?.count }}
            onPageChange={onPageChange}
            onSearchChange={onSearchChange}
            customFields={customFields}
            render={{ country: { render: 'name' } }}
            fields={{ exclude: ['data', 'center'] }}
            fieldsOrder={{ actions: 0 }}
          />
        </Card>
      </CRow>
    </CCol>
  )
}

const mapStateToProps = (state) => ({
  polygons: state.polygon.list,
  requesting: state.polygon.getPolygonsRequesting,
})

const mapDispatchToProps = {
  getPolygons: getPolygonsAction,
  deletePolygon: deletePolygonAction,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default compose(withTranslation(), connector)(PolygonsABM)
