import * as Yup from 'yup'
import IntermediateDataSchema from './intermediateDataSchema'

const auxSchema = Yup.object().shape({
  description: Yup.string().required('Rule description is required'),
  setIntermediate: Yup.boolean(),
  idIntermediate: Yup.number().when('setIntermediate', {
    is: true,
    then: Yup.number()
      .integer('Id of intermediate must be an integer number')
      .strict()
      .required('Intermediate is required'),
  }),
  productsLine: Yup.array().of(Yup.number().integer().strict()),
  orderNumber: Yup.number()
    .integer('Radius must be an integer number')
    .strict()
    .test('is_positive', 'Order must be greater than 0', (value) => value > 0)
    .required('Order is required'),
})

export const RuleSchema = auxSchema.concat(IntermediateDataSchema)
