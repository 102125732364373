import React from 'react'
import { StyleSheet, css } from 'aphrodite'

import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'

import { arrayMoveImmutable } from 'array-move'
import { Icon } from '@components/common'
import { isFunction } from 'lodash'

export const DragHandle = SortableHandle(({ disabled }) => {
  const classes = StyleSheet.create({
    handle: {
      cursor: 'grab',
      padding: '1em',
      color: '#999',
      borderRadius: '0.5em',
      ':hover': {
        backgroundColor: '#f0f0f0',
        transition: 'background 0.3s',
      },
    },
    disabled: {
      cursor: 'unset',
      color: '#DDD',
      ':hover': {
        backgroundColor: 'unset',
      },
    },
  })

  return (
    <div className={css(classes.handle, disabled && classes.disabled)}>
      <Icon name="cil-drag" />
    </div>
  )
})

interface RowProps<T> {
  data: T[]
  indexKey: string
  disabled: boolean
  ['data-row-key']: number
}

export const DraggableRow: <T>(p: RowProps<T>) => React.ReactElement = ({
  data,
  indexKey,
  disabled,
  ...props
}) => {
  const DragItem = SortableElement((props) => <tr className="dragger" {...props} />)

  const index = data.findIndex((x) => x[indexKey] === props['data-row-key'])
  return <DragItem index={index} disabled={disabled} {...props} />
}

interface WrapperProps<T> {
  data: T[]
  setData: (data: T[]) => void
  onOrderChange?: (data: unknown) => void
  idKey: string
  indexKey: string
}

export const DraggableWrapper: <T>(p: WrapperProps<T>) => React.ReactElement = ({
  data,
  setData,
  onOrderChange,
  idKey,
  indexKey,
  ...props
}) => {
  const DragContainer = SortableContainer((props) => <tbody {...props} />)

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const sortedData = arrayMoveImmutable(data, oldIndex, newIndex).filter((item) => !!item)
      setData(sortedData)
      if (isFunction(onOrderChange))
        onOrderChange(
          sortedData.map((item, index) => ({
            [indexKey]: index,
            [idKey]: item[idKey],
          }))
        )
    }
  }

  return (
    <DragContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  )
}
