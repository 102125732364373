import {
  createRule,
  getRules,
  getRule,
  updateRule,
  deleteRule,
  updateRules,
} from '../services/rule'

import {
  CREATE_RULE,
  GET_RULES,
  UPDATE_RULE,
  GET_RULE,
  UPDATE_RULES_ORDER,
  DELETE_RULE,
} from '../constants/rule'

const createRuleAction = (form) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_RULE.REQUEST })
    const { content, success } = await createRule(form)

    if (success)
      dispatch({
        type: CREATE_RULE.SUCCESS,
        payload: content,
      })
  } catch (e) {
    dispatch({
      type: CREATE_RULE.ERROR,
      payload: e.message,
    })
  } finally {
    dispatch({ type: CREATE_RULE.RESET })
  }
}

const getRuleListAction = () => async (dispatch) => {
  try {
    dispatch({ type: GET_RULES.REQUEST })
    const { content, success } = await getRules()

    if (success)
      dispatch({
        type: GET_RULES.SUCCESS,
        payload: content,
      })
  } catch (e) {
    dispatch({
      type: GET_RULES.ERROR,
      payload: e.message,
    })
  } finally {
    dispatch({ type: GET_RULES.RESET })
  }
}

const getRuleAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_RULE.REQUEST })
    const { content, success } = await getRule(id)

    if (success)
      dispatch({
        type: GET_RULE.SUCCESS,
        payload: content,
      })
  } catch (e) {
    dispatch({
      type: GET_RULE.ERROR,
      payload: e.message,
    })
  } finally {
    dispatch({ type: GET_RULE.RESET })
  }
}

const updateRuleAction = (form) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_RULE.REQUEST })
    const { content, success } = await updateRule(form)

    if (success)
      dispatch({
        type: UPDATE_RULE.SUCCESS,
        payload: content,
      })
  } catch (e) {
    dispatch({
      type: UPDATE_RULE.ERROR,
      payload: e.message,
    })
  } finally {
    dispatch({ type: UPDATE_RULE.RESET })
  }
}

const updateRulesOrderAction = (rules) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_RULES_ORDER.REQUEST })
    const { content, success } = await updateRules({ rules })

    if (success) dispatch({ type: UPDATE_RULES_ORDER.SUCCESS, payload: content })
  } catch (e) {
    dispatch({
      type: UPDATE_RULES_ORDER.ERROR,
      payload: e.message,
    })
  } finally {
    dispatch({ type: UPDATE_RULES_ORDER.RESET })
  }
}

const deleteRuleAction = (form, successCallback) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_RULE.REQUEST })
    const { content, success } = await deleteRule(form)

    if (success) {
      dispatch({ type: DELETE_RULE.SUCCESS, payload: content })
      if (successCallback) successCallback()
    }
  } catch (e) {
    dispatch({
      type: DELETE_RULE.ERROR,
      payload: e.message,
    })
  } finally {
    dispatch({ type: DELETE_RULE.RESET })
  }
}

export {
  createRuleAction,
  getRuleListAction,
  getRuleAction,
  updateRuleAction,
  updateRulesOrderAction,
  deleteRuleAction,
}
