import React, { useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import translations from '@translations'
import { successToast } from '@utils/toast'

import { addUserAction, updateUserAction, getUserAction } from '@actions/user'

import UserForm from '@forms/userForm'

import { CCol } from '@coreui/react'

import { Card } from '@components/common/index'

const EditProfile = ({ success, userInfo, updateUser, getUser, profileInfo }) => {
  const { editProfile } = translations.form

  const onFormSubmit = ({ id, firstName, lastName }) => {
    updateUser({ id, firstName, lastName })
  }

  useEffect(() => {
    if (success) {
      successToast('updateUserSuccess', { toastId: 'Updated users' })
    }
  }, [success])

  useEffect(() => {
    getUser(userInfo.id)
  }, [])

  return (
    <CCol xl={12}>
      <Card className="my-3" title={editProfile}>
        <UserForm
          translations={translations.form}
          onSubmit={onFormSubmit}
          defaultValues={profileInfo}
        />
      </Card>
    </CCol>
  )
}

const mapStateToProps = (state) => ({
  success: state.user.updateUserSuccess,
  userInfo: state.auth.user,
  profileInfo: state.user.userDetail,
})

const mapDispatchToProps = {
  addUserAction,
  updateUser: updateUserAction,
  getUser: getUserAction,
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(EditProfile)
