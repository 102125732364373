import React, { useCallback, useEffect, useRef, useState } from 'react'

import { map as propertyMap } from 'lodash'
import useGeolocation from 'react-hook-geolocation'

import { compose } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { getPolygonZonesAction } from '@actions/polygon'

import translations from '@translations'

import { CRow } from '@coreui/react'
import { Button, Modal, Checkbox, Slider } from '@components/common'
import Col from '@utils/components/ResponsiveColumn'

import RadiusPolygonsMap from '@components/GoogleMaps/RadiusPolygonsMap'

import { Circle, PolygonWithId } from '@utils/interfaces/maps'
import { numberToKM } from '@utils/helpers'
import { defaultRadius } from '@components/GoogleMaps/defaults'
import { Id, Zone } from './types'

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props {
  open: boolean
  disabled?: boolean
  closeModal: () => void
  onSubmit: (zoneIds: Id[]) => void
}

const DeliveryRadiusModal: React.FC<Props & PropsFromRedux> = ({
  open,
  disabled = false,
  closeModal,
  getZones,
  onSubmit,
}) => {
  const geolocation = useGeolocation()

  const { addDeliveryZones, confirmFlorist, noRecommendedZones, recommendedZones } =
    translations.form

  const [sliderRadius, setSliderRadius] = useState<number>()

  const [polygons, setPolygons] = useState<PolygonWithId[]>([])
  const [zonesList, setZonesList] = useState<Zone[]>([])
  const [zonesLoaded, setZonesLoaded] = useState(false)
  const [selectedZonesIds, setSelectedZonesIds] = useState<Id[]>([])

  const [preserve, setPreserve] = useState(false)

  const circleRef = useRef<Circle | null>(null)

  useEffect(() => {
    if (open) setPreserve(true)
  }, [open])

  useEffect(() => {
    if (!!selectedZonesIds && !!selectedZonesIds?.length) {
      setPolygons(
        zonesList
          .filter((z) => selectedZonesIds.includes(z.id))
          .map(({ data, id, name, description }) => ({
            name,
            description,
            data,
            id,
          }))
      )
    } else {
      setPolygons([])
    }
  }, [selectedZonesIds])

  const handleZonesChange = () => {
    onSubmit(selectedZonesIds)
    setPreserve(true)
    closeModal()
  }

  const SubmitZonesButton = () => (
    <Button
      disabled={disabled || !selectedZonesIds?.length}
      text={confirmFlorist}
      onClick={handleZonesChange}
    />
  )

  const onRadiusChange = () => {
    if (circleRef.current && circleRef.current.getRadius() !== sliderRadius)
      setSliderRadius(circleRef.current.getRadius())
  }

  const handleSliderChange = (value) => {
    if (circleRef.current) {
      circleRef.current.setRadius(value)
    }
  }
  const onSliderChange = (value) => {
    setSliderRadius(value)
  }

  const handleLoadZones = useCallback(
    (list: Zone[]) => {
      const lastZonesList = [...propertyMap(zonesList, 'id')]

      setZonesList(list)
      if (!zonesLoaded) setZonesLoaded(true)
      setSelectedZonesIds((lastSelectedZones) =>
        list && list?.length
          ? propertyMap(list, 'id').filter(
              (newId) => !lastZonesList.includes(newId) || lastSelectedZones.includes(newId)
            )
          : []
      )
    },
    [zonesList]
  )

  const toggleZoneSelected = (e) => {
    const { checked } = e.target
    const id = parseInt(e.target.name, 10)
    let currentZones = [...selectedZonesIds]

    if (checked && !currentZones.includes(id)) {
      currentZones = [...currentZones, id]
    }

    if (!checked && currentZones.includes(id)) {
      currentZones = currentZones.filter((val) => val !== id)
    }

    setSelectedZonesIds(currentZones)
  }

  const handleClose = () => {
    setPreserve(false)
    closeModal()
  }

  const initialCenter =
    geolocation && geolocation.latitude && geolocation.longitude
      ? {
          center: { lat: geolocation.latitude, lng: geolocation.longitude },
          radius: defaultRadius / 2,
        }
      : { useDefault: true }

  return (
    <Modal
      title={addDeliveryZones}
      open={open}
      footerButtons={SubmitZonesButton}
      closeModal={handleClose}
      onDismiss={handleClose}
      size="xl"
      bodyClassName="flex-row"
    >
      <CRow className="w-100">
        <Col xl={8}>
          <Col className="mb-5">
            <Slider
              min={1000}
              max={300000}
              stepPercentage={0}
              value={sliderRadius}
              onChange={onSliderChange}
              onAfterChange={handleSliderChange}
              tipFormatter={numberToKM}
              markFormatter={numberToKM}
              markPercentage={20}
              marks={[
                20000,
                {
                  200000: <p className="text-warning font-weight-bold">{numberToKM(200000)}</p>,
                },
              ]}
            />
          </Col>
          {preserve && (
            <RadiusPolygonsMap
              drawable
              width="100%"
              onCircleChanged={(...values) => getZones(...values, handleLoadZones)}
              circleRef={circleRef}
              polygons={polygons}
              onRadiusChange={onRadiusChange}
              {...initialCenter}
            />
          )}
        </Col>

        {zonesLoaded && (
          <Col className="mt-3 px-0" xl={4}>
            <CRow className="w-100 justify-content-start">
              {zonesList && !!zonesList?.length ? (
                <Col xl={12} className="flex-column" style={{ maxHeight: '600px' }}>
                  <h6>{recommendedZones(selectedZonesIds.length, zonesList.length)}</h6>
                  <CRow
                    className="justify-content-between w-100"
                    style={{ maxHeight: '90%', overflowY: 'auto' }}
                  >
                    {zonesList.map(({ name, description, id }) => (
                      <Col xl={zonesList.length < 20 ? 12 : 6} key={id}>
                        <Checkbox
                          id={id.toString()}
                          className="mt-2 mr-2"
                          onChange={toggleZoneSelected}
                          name={id.toString()}
                          value={!!selectedZonesIds.includes(id)}
                          labelClassName="line-clamp"
                          label={`${name} ${description ? `· ${description}` : ''}`}
                        />
                      </Col>
                    ))}
                  </CRow>
                </Col>
              ) : (
                <Col>
                  <p className="my-0">{noRecommendedZones}</p>
                </Col>
              )}
            </CRow>
          </Col>
        )}
      </CRow>
    </Modal>
  )
}

const mapDispatchToProps = {
  getZones: getPolygonZonesAction,
}

const connector = connect(null, mapDispatchToProps)

export default compose<React.FC<Props>>(withTranslation(), connector)(DeliveryRadiusModal)
