import React from 'react'

import { compose } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { withTranslation } from 'react-i18next'

import IntermediateForm from '../intermediate'
import FloristForm from '../florist'

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props {
  onSubmit: (values: any) => void
  entityType: 'itm' | 'fl'
  values?: any
}

const EntityForm: React.FC<Props & PropsFromRedux> = ({
  entityType,
  authToken,
  currentIntermediate,
  ...props
}) => {
  const isIntermediate = entityType === 'itm'

  return (
    <>
      {isIntermediate ? (
        <IntermediateForm {...props} />
      ) : (
        <FloristForm currentIntermediate={currentIntermediate?.id} link={!authToken} {...props} />
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  authToken: state.auth.token,
  currentIntermediate: state.auth.currentIntermediate,
})

const connector = connect(mapStateToProps)

export default compose<React.FC<Props>>(withTranslation(), connector)(EntityForm)
