import React from 'react'
import ImagesFeedbackCarousel from '@components/ImagesFeedbackCarousel'
import { OrderModalContext } from './context'

const OrderImagesModal: React.FC = () => {
  const { useImagesFeedbackModal, uploadedImages } = OrderModalContext()
  const { modalOpen, closeModal } = useImagesFeedbackModal()

  return (
    <ImagesFeedbackCarousel
      open={modalOpen}
      closeModal={closeModal}
      successCallback={(_, __) => closeModal()}
      {...uploadedImages}
    />
  )
}

export default OrderImagesModal
