import React from 'react'

import RPhoneInput from 'react-phone-input-2'

import { CLabel, CInputGroup, CInputGroupPrepend, CInputGroupText } from '@coreui/react'
import { Icon } from '@components/common'

const PhoneInput = ({
  value,
  label = null,
  placeholder = null,
  name,
  error,
  className = '',
  icon = 'cil-phone',
  searchPlaceholder,
  inputStyle = {},
  ...props
}) => (
  <CInputGroup
    className={`mb-3 ${className} 
        ${error ? 'invalid' : 'valid'}`}
  >
    {!!label && <CLabel htmlFor={name}>{label}</CLabel>}

    {!!icon && (
      <CInputGroupPrepend>
        <CInputGroupText>
          <Icon name={icon} />
        </CInputGroupText>
      </CInputGroupPrepend>
    )}

    <RPhoneInput
      id={name}
      country="ar"
      preferredCountries={['ar', 'mx', 'us']}
      value={value}
      searchPlaceholder={searchPlaceholder}
      disableSearchIcon
      enableSearch
      countryCodeEditable={false}
      placeholder={placeholder ?? name}
      isValid={!error}
      style={inputStyle}
      {...props}
    />

    {!!error && <div className="invalid-feedback d-inline">{error}</div>}
  </CInputGroup>
)

export default PhoneInput
