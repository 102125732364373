export const typeEnum = {
  OWNER: 'OWNER',
  INTERMEDIATE: 'INTERMEDIATE',
  FLORIST: 'FLORIST',
}

export const typeIdEnum = {
  1: typeEnum.OWNER,
  2: typeEnum.INTERMEDIATE,
  3: typeEnum.FLORIST,
  4: typeEnum.OWNER,
}
