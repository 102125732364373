import React, { useEffect } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'

import { compose } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { successToast } from '@utils/toast'

import translations from '@translations'

import {
  getIngredientsAction,
  createIngredientAction,
  updateIngredientAction,
} from '@actions/ingredient'

import NewIngredientForm from '@forms/ingredient'
import EditIngredientForm from '@forms/ingredient/EditForm'

import Col from '@utils/components/ResponsiveColumn'
import { Card } from '@components/common'

import { withRouteParams } from '@utils/helpers'
import { routes } from '@/routes'

interface MatchParams {
  id?: string
}

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = PropsFromRedux & RouteComponentProps<MatchParams>

const IngredientForm: React.FC<Props> = ({
  match,
  createIngredient,
  updateIngredient,
  getDetails,
  createSuccess,
  updateSuccess,
}) => {
  const history = useHistory()

  const id = match.params?.id
  const isCreating = id == null

  const { createIngredientSuccess, updateIngredientSuccess } = translations.toasts

  useEffect(() => {
    if (isCreating) {
      if (createSuccess) {
        successToast(createIngredientSuccess, {
          toastId: 'CREATE_INGREDIENT',
        })
        history.push(routes.Ingredients)
      }
    } else if (updateSuccess) {
      successToast(updateIngredientSuccess, {
        toastId: 'UPDATE_INGREDIENT',
      })
      history.push(withRouteParams(routes.IngredientDetails, { id }))
    }
  }, [createSuccess, updateSuccess])

  useEffect(() => {
    getDetails(id)
  }, [])

  return (
    <Col xl={[6, 8]}>
      <Card className="my-3">
        {isCreating ? (
          <NewIngredientForm onSubmit={createIngredient} />
        ) : (
          <EditIngredientForm onSubmit={(form) => updateIngredient(id, form)} />
        )}
      </Card>
    </Col>
  )
}

const mapStateToProps = (state) => ({
  createSuccess: state.ingredient.createIngredientSuccess,
  updateSuccess: state.ingredient.updateIngredientSuccess,
})

const mapDispatchToProps = {
  createIngredient: createIngredientAction,
  updateIngredient: updateIngredientAction,
  getDetails: getIngredientsAction,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default compose(withTranslation(), connector)(IngredientForm)
