import React, { useEffect, useState } from 'react'

import { isPlainObject, partition } from 'lodash'

import { compose } from 'redux'
import { connect, ConnectedProps } from 'react-redux'

import { useHistory, RouteComponentProps } from 'react-router-dom'
import { withTranslation, WithTranslation } from 'react-i18next'

import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'

import {
  getOrderAction,
  assignFloristAction,
  getFlNearOrderAction,
  createOrderFloristPriceAction,
  getOrderFloristsPricesAction,
} from '@actions/order'

import translations from '@translations'

import { CRow } from '@coreui/react'
import { Button, Card, /* NewTextInput, */ DataTable } from '@components/common'

import { successToast } from '@utils/toast'
import { withRouteParams, parseQuery } from '@utils/helpers'

import StripedTable from '@components/common/Tables/StripedTable'
import Col from '@utils/components/ResponsiveColumn'
import RenderPhoneNumber from '@components/RenderPhoneNumber'
import OrderProductsList from '../ABM/components/OrderProductsList'
import OrderAddressMap, { AddressProps } from '../ABM/components/OrderAddressMap'

import {
  getOrderChildActions,
  getOrderAddressData,
  getOrderStatusChip,
  ButtonType,
  ActionButtons,
} from '../helpers'

import { routes } from '@/routes'

import { OrderModalProvider } from '../ABM/components/context'
import OrderModals from './Modals'
import OrderActions from './Actions'

type PropsFromRedux = ConnectedProps<typeof connector>

interface MatchParams {
  id: string
}

type Props = WithTranslation & PropsFromRedux & RouteComponentProps<MatchParams>

const OrderDetail: React.FC<Props> = ({
  match,
  details,
  getOrder,
  userInfo,
  setFloristSuccess,
  location,
  // createOrderFloristPrice,
  successFloristPrice,
  getOrderFloristsPrices,
  prices,
  pricesLoading,
}) => {
  const history = useHistory()
  dayjs.extend(LocalizedFormat)

  const { costHistory, /* saveCost, */ order, floristDetailsTitle } = translations.form
  const { productsCountTitle, detailsTitle, recipientDetailsTitle } = translations.generic

  const { createOrderFloristPriceSuccess, assignOrderSuccess } = translations.toasts

  const imOwner = (userInfo?.typeId === 4 || userInfo?.typeId === 1) ?? false

  const [addressData, setAddressData] = useState<AddressProps | null>(null)

  const [orderActions, setOrderActions] = useState<ButtonType[]>([])
  const [buttons, setButtons] = useState<ActionButtons>({})

  const [fields, setFields] = useState<string[]>([])
  const [recipientFields, setRecipientFields] = useState({})

  const orderId = match.params.id
  const floristId = parseQuery(location.search)

  const products = details?.items ? details.items.filter((item) => !!item?.product) : []

  /*   const SendCostField = () => {
    const params = { price: null, id: orderId, floristId }

    const handleChange = (e) => {
      params.price = e.target.value
    }
    return (
      <td className="py-2">
        <NewTextInput
          name="cost"
          placeholder={`${'cost'}...`}
          type="number"
          onChange={(e) => {
            handleChange(e)
          }}
        />
        <Button
          text={saveCost}
          onClick={() => {
            createOrderFloristPrice(params)
          }}
        />
      </td>
    )
  } */

  const initialRequest = () => {
    getOrder(orderId)
  }

  useEffect(() => {
    if (successFloristPrice) {
      successToast(createOrderFloristPriceSuccess, { toastId: 'setOrderFloristSuccess' })
    }
  }, [successFloristPrice])

  useEffect(() => {
    initialRequest()
    if (!floristId) getOrderFloristsPrices(orderId)
  }, [])

  useEffect(() => {
    if (details) {
      const keys = Object.keys(details).filter(
        (key) =>
          !key.toLowerCase().startsWith('id') &&
          !key.toLowerCase().endsWith('id') &&
          !!details[key] &&
          details[key] !== 'undefined' &&
          !isPlainObject(details[key])
      )
      const [recipientKeys, orderKeys] = partition(keys, (k) =>
        k.toLowerCase().includes('destinatary')
      )

      setOrderActions(getOrderChildActions(details, buttons, userInfo))
      getOrderAddressData(details).then((data) => setAddressData(data))

      setFields(orderKeys)
      setRecipientFields(recipientKeys)
    }
  }, [details, buttons])

  useEffect(() => {
    if (setFloristSuccess) {
      successToast(assignOrderSuccess, { toastId: 'ASSIGN_ORDER' })
    }
  }, [setFloristSuccess])

  const ProductsHeader = () => (
    <CRow className="justify-content-between align-items-center mx-0">
      <strong> {productsCountTitle(products.length)} </strong>
    </CRow>
  )

  const goToFlorist = (id) => {
    if (id) history.push(withRouteParams(routes.Details, { type: 'fl', id }))
  }

  return (
    <OrderModalProvider>
      <OrderModals setButtons={setButtons} />
      <OrderActions actions={orderActions} />

      {details?.idInvoice && (
        <CRow className="d-flex justify-content-between align-items-center">
          <Col xl={6}>
            <h4>
              {order} #{details.idInvoice}
            </h4>
          </Col>
          <Col xl={4}>{getOrderStatusChip(details, translations.form)}</Col>
        </CRow>
      )}

      <CRow>
        <Col xl={6}>
          <Card title={detailsTitle}>
            <StripedTable
              fields={{ pool: fields, exclude: ['lat', 'lng'] }}
              data={details}
              render={{ deliveryDate: (_, item) => dayjs(item.deliveryDate).format('ll [·] LT') }}
            />
            {!!addressData && (
              <Card>
                <OrderAddressMap showMap {...addressData} />
              </Card>
            )}
            <Card collapsible title={recipientDetailsTitle}>
              <StripedTable
                fields={recipientFields}
                data={details}
                transformKey={(key) => key.replace('destinatary', '')}
              />
            </Card>
          </Card>
        </Col>

        {/* {floristId && <SendCostField />} */}

        {imOwner && (
          <Col xl={6}>
            {!details.florist ? (
              <Card title={costHistory}>
                <DataTable
                  scrollable={false}
                  verticalScroll
                  loading={pricesLoading}
                  data={prices}
                  fields={['florist', 'price']}
                  render={{
                    florist: {
                      render: (_, { florist }) => (
                        <Button
                          onClick={() => goToFlorist(florist.id)}
                          link
                          text={florist?.username}
                        />
                      ),
                    },
                  }}
                />
              </Card>
            ) : (
              <Card title={floristDetailsTitle}>
                <StripedTable
                  fields={{ exclude: ['lat', 'lng', 'active'] }}
                  data={details?.florist}
                  render={{
                    phone: (_, item) => <RenderPhoneNumber florist={item} />,
                    username: (_, { username, id }) => (
                      <Button onClick={() => goToFlorist(id)} link text={username} />
                    ),
                  }}
                />
              </Card>
            )}
          </Col>
        )}
      </CRow>

      {!!products.length && (
        <CRow>
          <Col md={9} xl={12} className="my-2">
            <Card header={ProductsHeader}>
              <OrderProductsList products={products} hidePrice />
            </Card>
          </Col>
        </CRow>
      )}
    </OrderModalProvider>
  )
}

const mapStateToProps = (state) => ({
  details: state.order.detail,
  userInfo: state.auth.user,
  florists: state.order.florists,
  setFloristSuccess: state.order.setOrderFloristSuccess,
  successFloristPrice: state.order.setOrderFloristPriceSuccess,
  prices: state.order.prices,
  pricesLoading: state.order.getOrderFloristsPricesRequesting,
  floristRequesting: state.order.getFlNearOrderRequesting,
  driverRequesting: state.driver.getDriversRequesting,
})

const mapDispatchToProps = {
  getOrder: getOrderAction,
  assign: assignFloristAction,
  getFlorists: getFlNearOrderAction,
  createOrderFloristPrice: createOrderFloristPriceAction,
  getOrderFloristsPrices: getOrderFloristsPricesAction,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default compose(withTranslation(), connector)(OrderDetail)
