import React, { useRef, useCallback } from 'react'
import { withTranslation } from 'react-i18next'
import { isString } from 'lodash'

import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api'
import mapStyles from '@styles/mapStyles'

import { CRow } from '@coreui/react'

import { MapStyles, MapContainerSize, Bounds, Map as MapType } from '@utils/interfaces/maps'
import { defaultZoom } from './defaults'

interface ParsedCenter {
  lat: number | string
  lng: number | string
}

interface Props extends MapContainerSize {
  center: ParsedCenter
  bounds: Bounds
}

const MiniMap: React.FC<Props> = ({
  width = '290px',
  height = '290px',
  center,
  bounds,
  ...containerStyle
}) => {
  const options = {
    styles: mapStyles as MapStyles,
    disableDefaultUI: true,
    clickableIcons: false,
    keyboardShortcuts: false,
    zoomControl: true,
  }

  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_KEY ?? ''
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey,
  })

  const parsedCenter =
    center.lat && center.lng
      ? {
          lat: isString(center.lat) ? parseFloat(center.lat) : center.lat,
          lng: isString(center.lng) ? parseFloat(center.lng) : center.lng,
        }
      : null

  const mapRef = useRef<MapType | null>(null)

  const onMapLoad = useCallback((map) => {
    mapRef.current = map
    if (bounds && !!mapRef?.current) {
      const LatLongBounds = new window.google.maps.LatLngBounds(bounds.southwest, bounds.northeast)
      mapRef.current.fitBounds(LatLongBounds)
    }
  }, [])

  if (loadError || !parsedCenter) return <p>Error</p>
  if (!isLoaded) return <p>Loading</p>

  return (
    <CRow
      className="my-2 mx-0 align-items-center position-relative"
      style={{ width, height, ...containerStyle }}
    >
      <GoogleMap
        onLoad={onMapLoad}
        options={options}
        mapContainerStyle={{ width, height, ...containerStyle }}
        center={parsedCenter}
        zoom={defaultZoom}
      >
        <Marker position={parsedCenter} />
      </GoogleMap>
    </CRow>
  )
}

export default withTranslation()(MiniMap)
