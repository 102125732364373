import React, { useEffect, useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { isArray } from 'lodash'

import translations from '@translations'

import { getEntityListAction } from '@actions/entity'
import { getRuleAction, updateRuleAction } from '@actions/rule'

import { Modal, Button, Select, Card } from '@components/common/index'
import Col from '@utils/components/ResponsiveColumn'

import { CRow } from '@coreui/react'

const RuleDetail = ({
  match,
  details,
  intermediates,
  getIntermediates,
  getRule,
  updateRuleSuccess,
  update,
}) => {
  const { setIntermediate, intermediate, country, zone, site, lines } = translations.form

  const { detailsTitle } = translations.generic

  const [ruleDetails, setRuleDetails] = useState({})
  const [modalOpen, setModalOpen] = useState(false)
  const [intermediateList, setIntermediateList] = useState([])
  const [idIntermediate, setIntermediateId] = useState()

  const { id: ruleId } = match.params

  const initialRequest = () => {
    getRule(ruleId)
  }

  useEffect(() => {
    initialRequest()
  }, [])

  useEffect(() => {
    if (details) {
      setRuleDetails(
        Object.keys(details)
          .filter(
            (key) =>
              ['id', 'description', 'order', 'country', 'intermediate'].includes(key) &&
              !!details[key] &&
              !isArray(details[key])
          )
          .reduce((o, key) => {
            // eslint-disable-next-line no-param-reassign
            o[key] = details[key]
            return o
          }, {})
      )
    }
  }, [details])

  useEffect(() => {
    if (updateRuleSuccess) {
      if (modalOpen) setModalOpen(false)
    }
  }, [updateRuleSuccess])

  const getIntermediatesNextPage = async (page, callback) => {
    await getIntermediates('itm', { page: page + 1, setList: callback })
  }

  const openIntermediateModal = () => {
    setModalOpen(true)
    getIntermediates('itm', { page: 1, setList: setIntermediateList })
  }

  const assignIntermediate = () => {
    if (idIntermediate) {
      update({ id: ruleId, idIntermediate })
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    }
  }

  const AssignIntermediateButton = () => (
    <Button disabled={!idIntermediate} onClick={assignIntermediate} text={setIntermediate} />
  )

  const ZonesHeader = () => (
    <CRow className="justify-content-between align-items-center mx-0">
      {`${country}: ${details?.country}`}
      <strong> {`${details?.zones?.length} ${zone}`} </strong>
    </CRow>
  )

  const SitesHeader = () => (
    <CRow className="justify-content-between align-items-center mx-0">
      <strong> {`${details?.sites?.length} ${site}`} </strong>
    </CRow>
  )

  const LinesHeader = () => (
    <CRow className="justify-content-between align-items-center mx-0">
      <strong> {`${details?.productsLines?.length} ${lines}`} </strong>
    </CRow>
  )

  return (
    <div>
      <Col>
        <Modal
          className="w-100 "
          title={setIntermediate}
          open={modalOpen}
          closeModal={() => setModalOpen(false)}
          onDismiss={() => setModalOpen(false)}
          footerButtons={AssignIntermediateButton}
        >
          <Col xl="8">
            <Select
              data={intermediateList}
              placeholder={intermediate}
              labelKey="username"
              onChange={(value) => setIntermediateId(value)}
              pagination={{
                page: 1,
                getData: getIntermediatesNextPage,
                next: intermediates.next,
              }}
            />
          </Col>
        </Modal>
      </Col>
      <CRow>
        {!details?.intermediate && (
          <Col col="2" className="d-flex align-items-center">
            <Button
              onClick={openIntermediateModal}
              icon="cil-user-follow"
              text={setIntermediate}
              className="ml-2"
              color="link"
            />
          </Col>
        )}
      </CRow>

      <CRow>
        <Col col="9">
          <Card title={detailsTitle}>
            <table className="table table-striped table-hover">
              <tbody>
                {Object.entries(ruleDetails).map(([key, value], index) => (
                  <tr key={index.toString()}>
                    <td>
                      <strong>{key}</strong>
                    </td>
                    <td>{value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
        </Col>
      </CRow>
      <CRow>
        <Col xl={4} className="my-2">
          {!!details?.zones?.length && (
            <Card collapsible header={(props) => <ZonesHeader {...props} />}>
              <table className="table table-striped table-hover">
                <tbody>
                  {details?.zones.map(({ id, name }) => (
                    <tr key={id}>
                      <td>
                        <strong>{id}</strong>
                      </td>
                      <td>{name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Card>
          )}
        </Col>

        <Col xl={4} className="my-2">
          {!!details?.sites?.length && (
            <Card collapsible header={(props) => <SitesHeader {...props} />}>
              <table className="table table-striped table-hover">
                <tbody>
                  {details?.sites.map(({ id, url }) => (
                    <tr key={id}>
                      <td>
                        <strong>{id}</strong>
                      </td>
                      <td>{url}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Card>
          )}
        </Col>

        <Col col="9" xl={4} className="my-2">
          {!!details?.productsLines?.length && (
            <Card collapsible header={(props) => <LinesHeader {...props} />}>
              <table className="table table-striped table-hover">
                <tbody>
                  {details?.productsLines.map(({ id, name }) => (
                    <tr key={id}>
                      <td>
                        <strong>{id}</strong>
                      </td>
                      <td>{name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Card>
          )}
        </Col>
      </CRow>
    </div>
  )
}

const mapStateToProps = (state) => ({
  details: state.rule.detail,
  updateRuleSuccess: state.entity.updateRuleSuccess,
  userInfo: state.auth.user,
  intermediates: state.entity.list,
})

const mapDispatchToProps = {
  getIntermediates: getEntityListAction,
  getRule: getRuleAction,
  update: updateRuleAction,
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(RuleDetail)
