import React from 'react'

import { TimePicker } from 'antd'

const { RangePicker } = TimePicker

const TimeRange = ({
  value,
  name,
  placeholder,
  onChange,
  onSelect,
  disabled,
  defaultVal,
  allowClear,
  ...props
}) => (
  <RangePicker
    format="HH:mm"
    minuteStep={15}
    bordered={false}
    size="large"
    onChange={onChange}
    value={value}
    name={name}
    placeholder={placeholder}
    onSelect={onSelect}
    disabled={disabled}
    defaultValue={defaultVal}
    allowClear={allowClear}
    {...props}
  />
)

TimeRange.defaultProps = {
  label: '',
  allowClear: false,
}

export default TimeRange
