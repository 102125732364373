import { camelCase } from 'lodash'
import { Object as EmptyObject } from '@utils/interfaces/react'

const REQUEST = 'REQUEST'
const SUCCESS = 'SUCCESS'
const ERROR = 'ERROR'
const RESET = 'RESET'

const Requesting = { base: 'Requesting', value: false }
const ErrorMessage = { base: 'ErrorMessage', value: '' }
const Success = { base: 'Success', value: false }

export const constantsSuffixes = { REQUEST, SUCCESS, ERROR, RESET }

interface Constant {
  [REQUEST]: string
  [SUCCESS]: string
  [ERROR]: string
  [RESET]: string
  base: string
}

export const createConstants = (base: string): Constant | EmptyObject =>
  [REQUEST, SUCCESS, ERROR, RESET, 'base'].reduce((o, type) => {
    o[type] = type === 'base' ? base : `${base}_${type}`
    return o
  }, {})

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const initConstant = (constant: Constant, name?: string) =>
  [Requesting, Success, ErrorMessage].reduce((o, type) => {
    o[`${camelCase(name ?? constant.base)}${type.base}`] = type.value
    return o
  }, {})

export default createConstants
