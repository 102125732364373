import React, { useEffect, useState } from 'react'

import { compose } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { Formik } from 'formik'

import translations from '@translations'

import { ProductIngredientsSchema } from '@utils/schemas/productIngredientsSchema'

// eslint-disable-next-line import/named
import { Button, Select, Card } from '@components/common'
import DataTable from '@components/common/Tables/PaginatedTable'

import Col from '@utils/components/ResponsiveColumn'
import { CRow } from '@coreui/react'

import {
  getIngredientChildrenAction,
  getIngredientsParentsAction,
  getProductIngredientsAction,
  getProductByIdAction,
} from '@actions/data'

import {
  addIngredientsToProductAction,
  removeIngredientsFromProductAction,
} from '@actions/ingredient'

// eslint-disable-next-line import/named
import { TableButtons } from '@utils/components/tableActions'
import { ObjectWithoutKey as Data } from '@utils/interfaces/react'
import { RouteComponentProps } from 'react-router-dom'
import { initialValues, formKeys } from './formKeys'

type PropsFromRedux = ConnectedProps<typeof connector>
interface MatchParams {
  id: string
}
interface Props {
  onSubmit: (values) => void
}

export interface ProductData {
  id: number
  name: string
  description: string
  image: string
}

const NewIngredientForm: React.FC<Props & PropsFromRedux & RouteComponentProps<MatchParams>> = ({
  match,
  onSubmit,
  ingredientsParent,
  ingredientsChildren,
  getIngredients,
  getProductById,
  getProductIngredients,
  getIngredientsChildren,
  addIngredientsToProduct,
  removeIngredientsFromProduct,
  requesting,
}) => {
  const productId = match.params.id

  const { add } = translations.generic
  const { assignIngredientsToProducts, relatedIngredient, ingredientName } = translations.form

  const [ingredientId, setIngredientId] = useState<number>(0)
  const [productData, setProductData] = useState<ProductData>()
  const [ingredientList, setIngredientList] = useState<Data[]>([])

  const onIngredientDelete = (id) => {
    removeIngredientsFromProduct({ productId, id }, () => {
      const currentList = [...ingredientList]
      const index = currentList.findIndex((ing) => ing.id === id)

      currentList.splice(index, 1)
      setIngredientList(currentList)
    })
  }

  const onIngredientAdd = () => {
    addIngredientsToProduct({ productId, id: ingredientId }, (data) => {
      setIngredientList([...ingredientList, data])
    })
  }

  const IngredientActions = ({ item }: { item: any }) => (
    <TableButtons
      buttons={[
        {
          onClick: () => onIngredientDelete(item.id),
          icon: 'cil-x',
          color: 'danger',
          text: 'Delete',
        },
      ]}
    />
  )

  const customFields = [
    {
      title: '',
      width: '10%',
      key: 'actions',
      dataIndex: 'actions',
      fixed: true,
      render: (_, item) => <IngredientActions item={item} />,
      sorter: false,
    },
  ]

  useEffect(() => {
    getIngredients(setIngredientList)
    if (productId) {
      getProductById(productId, setProductData)
      getProductIngredients(productId)
    }
  }, [productId])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ProductIngredientsSchema}
      onSubmit={onSubmit}
    >
      {({ handleBlur, setFieldValue, dirty, errors }) => {
        const onIngredientParentChange = (val: number) => {
          getIngredientsChildren(val)
          setIngredientId(val)
          setFieldValue(formKeys.ingredientId, val)
        }

        const onIngredientChildrenChange = (val: number) => {
          if (val) {
            setIngredientId(val)
            setFieldValue(formKeys.ingredientId, val)
          }
        }

        const disabled = !dirty || !!Object.keys(errors).length

        return (
          <Col xl={[12, 12]}>
            <CRow className="d-flex">
              <Col xl={8}>
                <h5>{assignIngredientsToProducts}</h5>
              </Col>
            </CRow>

            <Card className="my-3">
              <CRow>
                <Col xl={6}>
                  {productData && (
                    <>
                      <CRow>
                        <Col xl={8}>
                          <h5>{productData.name}</h5>
                          <p>{productData.description}</p>
                        </Col>
                      </CRow>

                      {!!productData.image && (
                        <CRow>
                          <Col xl={8}>
                            <img
                              src={productData.image}
                              alt={productData.name}
                              className="img-fluid rounded"
                              style={{ maxHeight: '200px' }}
                            />
                          </Col>
                        </CRow>
                      )}
                    </>
                  )}

                  <CRow className="mt-3">
                    <Col xl={6}>
                      <Select
                        data={ingredientsParent}
                        placeholder={ingredientName}
                        onChange={onIngredientParentChange}
                        onBlur={handleBlur}
                      />
                    </Col>
                    <Col xl={6}>
                      <Select
                        data={ingredientsChildren}
                        placeholder={relatedIngredient}
                        onChange={onIngredientChildrenChange}
                        onBlur={handleBlur}
                        disabled={!ingredientsChildren?.length}
                      />
                    </Col>
                  </CRow>

                  <CRow className="mt-3 justify-content-end w-100 mx-0">
                    <Button disabled={disabled} onClick={onIngredientAdd} text={add} />
                  </CRow>
                </Col>

                <Col xl={6}>
                  <DataTable
                    loading={requesting}
                    data={ingredientList || []}
                    customFields={customFields}
                    fields={{ exclude: ['parent'] }}
                    fieldsOrder={{ actions: 0 }}
                  />
                </Col>
              </CRow>
            </Card>
          </Col>
        )
      }}
    </Formik>
  )
}

const mapStateToProps = (state) => ({
  ingredientsParent: state.data.ingredientsParents,
  ingredientsChildren: state.data.ingredientsChildren,
  requesting: state.data.getProductIngredientsRequesting,
  deleteSuccess: state.ingredient.removeIngredientFromProductSuccess,
  addSuccess: state.ingredient.addIngredientToProductSuccess,
})

const mapDispatchToProps = {
  getIngredients: getIngredientsParentsAction,
  getIngredientsChildren: getIngredientChildrenAction,
  getProductIngredients: getProductIngredientsAction,
  addIngredientsToProduct: addIngredientsToProductAction,
  removeIngredientsFromProduct: removeIngredientsFromProductAction,
  getProductById: getProductByIdAction,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default compose<React.FC<Props>>(withTranslation(), connector)(NewIngredientForm)
