import React from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import { withTranslation, WithTranslation } from 'react-i18next'

import translations from '@translations'
import { setCurrentFloristAction } from '@actions/auth'

import { CDropdownMenu, CDropdownToggle, CDropdown, CDropdownItem } from '@coreui/react'

type PropsFromRedux = ConnectedProps<typeof connector>
interface Props extends WithTranslation, PropsFromRedux {}

const FloristSwitcher: React.FC<Props> = ({ florists, currentFlorist, setFlorist }) => {
  const { operateAs } = translations.generic

  const switchFlorist = (id) => {
    setFlorist(florists.find((f) => f.id === id))
  }

  if (!!florists && !!florists.length && florists.length > 1) {
    return (
      <CDropdown className="mr-2">
        <CDropdownToggle color="secondary" size="sm">
          {currentFlorist ? currentFlorist.username : operateAs}
        </CDropdownToggle>

        <CDropdownMenu>
          {florists.map(({ id, username }) => (
            <CDropdownItem
              key={id}
              onClick={() => switchFlorist(id)}
              color={currentFlorist?.id === id ? 'light' : undefined}
            >
              <div className="mx-1 d-inline-flex align-items-center">{username}</div>
            </CDropdownItem>
          ))}
        </CDropdownMenu>
      </CDropdown>
    )
  }

  return <></>
}

const mapStateToProps = (state) => ({
  florists: state.auth.florists,
  currentFlorist: state.auth.currentFlorist,
})

const mapDispatchToProps = {
  setFlorist: setCurrentFloristAction,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default compose(withTranslation(), connector)(FloristSwitcher)
