import React from 'react'

import { compose } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { logoutAction } from '@actions/auth'
import { getNameInitials } from '@utils/helpers'

import {
  CDropdown,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react'

import { Icon } from '@components/common/index'
import translations from '@translations'
import { routes } from '@/routes'

type PropsFromRedux = ConnectedProps<typeof connector>
interface Props extends WithTranslation, PropsFromRedux {}

const TheHeaderDropdown: React.FC<Props> = ({ logout, userInfo }) => {
  const history = useHistory()

  const { profile, logout: logoutTranslation, home } = translations.generic

  const goToHome = () => {
    history.push(routes.Home)
  }
  const goToProfile = () => {
    history.push(routes.Profile)
  }

  const fullName = `${userInfo.firstName} ${userInfo.lastName}`

  return (
    <CDropdown inNav className="c-header-nav-items mx-2">
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <div
          className="c-avatar border-color-black"
          style={{
            borderColor: 'black',
            borderRadius: '50%',
            border: '1px solid rgba(0, 0, 21, 0.5)',
          }}
        >
          {getNameInitials(fullName)}
        </div>
      </CDropdownToggle>

      <CDropdownMenu id="dropdown-menu-nav-profile" className="pt-0 mr-5" placement="bottom-end">
        <CDropdownItem header tag="div" color="light" className="text-center">
          <strong className="line-clamp">{fullName}</strong>
          <p className="mb-0 line-clamp">{userInfo.email}</p>
        </CDropdownItem>

        <CDropdownItem onClick={goToHome}>
          <Icon name="cil-home" className="mfe-2" />
          {home}
        </CDropdownItem>

        <CDropdownItem onClick={goToProfile}>
          <Icon name="cil-smile" className="mfe-2" />
          {profile}
        </CDropdownItem>

        <CDropdownItem divider />
        <CDropdownItem onClick={logout}>
          <Icon name="cil-lock-locked" className="mfe-2" />
          {logoutTranslation}
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.auth.user,
})

const mapDispatchToProps = {
  logout: logoutAction,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default compose(withTranslation(), connector)(TheHeaderDropdown)
