import { baseService } from '@utils/authRequest'

export const getPolygons = async (params) => {
  try {
    return await baseService.get('polygons', { params })
  } catch (e) {
    return e.response?.data.error
  }
}

export const getPolygonDetail = async (id) => {
  try {
    return await baseService.get(`polygons/${id}`)
  } catch (e) {
    return e.response?.data.error
  }
}

export const createPolygon = async (form) => {
  try {
    return await baseService.post('polygons', form)
  } catch (e) {
    return e.response?.data.error
  }
}

export const updatePolygon = async (id, form) => {
  try {
    return await baseService.put('polygons', { id, ...form })
  } catch (e) {
    return e.response?.data.error
  }
}

export const deletePolygon = async (id) => {
  try {
    return await baseService.delete(`polygons/${id}`)
  } catch (e) {
    return e.response?.data.error
  }
}

export const getPolygonZones = async (params) => {
  try {
    return await baseService.get('polygons/nearby', { params })
  } catch (e) {
    return e.response?.data.error
  }
}
