import React, { useEffect, useState } from 'react'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import translations from '@translations'

import { createDriverAction } from '@actions/driver'

import { successToast, errorToast } from '@utils/toast'

import { CCol, CLink, CRow } from '@coreui/react'

import { Card } from '@components/common'
import DriverForm from '@forms/driver'

const NewDriverForm = ({ match, create, success, error }) => {
  const [wspLink, setWspLink] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')

  const entityId = match.params.id

  const { newDriver } = translations.form

  const { driverWhatsapp, whatsappButtonDriver } = translations.generic

  const onFormSubmit = (currentForm) => {
    const form = { ...currentForm, floristId: entityId }
    if (form?.phoneNumber) setPhoneNumber(form.phoneNumber)
    create(form)
  }

  useEffect(() => {
    if (success) {
      successToast('createDriverSuccess', { toastId: 'CREATE_DRIVER' })
      setWspLink(`https://api.whatsapp.com/send?phone=+${phoneNumber}&text=${driverWhatsapp}`)
    }
  }, [success])

  useEffect(() => {
    if (error) {
      errorToast('createUserError', { toastId: 'CREATE_DRIVER' })
    }
  }, [error])

  const FormHeader = () => (
    <CRow className="justify-content-between align-items-center mx-0">
      {newDriver}
      {!!phoneNumber && !!wspLink && (
        <CLink href={wspLink} target="_blank">
          <strong>{whatsappButtonDriver}</strong>
        </CLink>
      )}
    </CRow>
  )

  return (
    <CCol xl={12}>
      <Card className="my-3" header={(props) => <FormHeader {...props} />}>
        <DriverForm onSubmit={onFormSubmit} />
      </Card>
    </CCol>
  )
}

const mapStateToProps = (state) => ({
  success: state.driver.createDriverSuccess,
  error: state.driver.createDriverError,
})

const mapDispatchToProps = {
  create: createDriverAction,
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(NewDriverForm)
