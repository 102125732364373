import { isArray } from 'lodash'
import { unsetAuthorizationHeader } from '@utils/authRequest'

import { login, forgotPassword, changePassword, validateToken } from '../services/auth'

import {
  LOGIN,
  FORGOT,
  CHANGE,
  SET_USER,
  SET_REDIRECT,
  UNSET_REDIRECT,
  LOGOUT,
  VALIDATE_TOKEN,
  SET_FLORISTS,
  SET_CURRENT_FLORIST,
  SET_INTERMEDIATES,
  SET_CURRENT_INTERMEDIATE,
} from '../constants/auth'

const loginAction =
  (formData, { redirectUrl }) =>
  async (dispatch) => {
    try {
      dispatch({ type: LOGIN.REQUEST })
      const { content, success } = await login(formData)

      if (success) {
        if (content?.token) {
          if (redirectUrl) {
            dispatch({ type: SET_REDIRECT, payload: redirectUrl })
          }

          if (content?.user) {
            const userInfo = content.user
            dispatch({ type: SET_USER, payload: userInfo })

            // imFlorist
            if (userInfo.type?.id === 3) {
              const userFlorists =
                !!userInfo.florists && isArray(userInfo.florists) ? userInfo.florists : []
              const currentFlorist = userFlorists.length ? userFlorists[0] : undefined
              // const floristIds = userFlorists.map(f => f.id)

              dispatch({ type: SET_FLORISTS, payload: userFlorists })
              dispatch({ type: SET_CURRENT_FLORIST, payload: currentFlorist })
            } else {
              const userIntermediates =
                !!userInfo.intermediates && isArray(userInfo.intermediates)
                  ? userInfo.intermediates
                  : []
              const currentIntermediate = userIntermediates.length
                ? userIntermediates[0]
                : undefined
              // const intermediateIds = userIntermediates.map(i => i.id)

              dispatch({ type: SET_INTERMEDIATES, payload: userIntermediates })
              dispatch({ type: SET_CURRENT_INTERMEDIATE, payload: currentIntermediate })
            }
          }
          dispatch({ type: LOGIN.SUCCESS, payload: content?.token })
        }
      }
    } catch (e) {
      dispatch({
        type: LOGIN.ERROR,
        payload: e.message,
      })
    } finally {
      dispatch({ type: LOGIN.RESET })
    }
  }

const forgotPasswordAction =
  ({ email }) =>
  async (dispatch) => {
    try {
      dispatch({ type: FORGOT.REQUEST })
      await forgotPassword(email)
      dispatch({ type: FORGOT.SUCCESS })
    } catch (e) {
      dispatch({
        type: FORGOT.ERROR,
        payload: e.message,
      })
    } finally {
      dispatch({ type: FORGOT.RESET })
    }
  }

const changePasswordAction = (password) => async (dispatch) => {
  try {
    dispatch({ type: CHANGE.REQUEST })
    const result = await changePassword(password)
    if (result.success) {
      dispatch({ type: CHANGE.SUCCESS })
    }
  } catch (e) {
    dispatch({
      type: CHANGE.ERROR,
      payload: e.error,
    })
  } finally {
    dispatch({ type: CHANGE.RESET })
  }
}

const validateTokenAction = (token) => async (dispatch) => {
  try {
    dispatch({ type: VALIDATE_TOKEN.REQUEST })
    const { success } = await validateToken(token)
    if (success) {
      dispatch({ type: VALIDATE_TOKEN.SUCCESS, payload: token })
    }
  } catch (e) {
    dispatch({
      type: VALIDATE_TOKEN.ERROR,
      payload: e.message,
    })
  } finally {
    dispatch({ type: VALIDATE_TOKEN.RESET })
  }
}

const logoutAction = () => async (dispatch) => {
  unsetAuthorizationHeader()
  dispatch({ type: LOGOUT })
}

const unsetRedirectAction = () => async (dispatch) => {
  dispatch({ type: UNSET_REDIRECT })
}

const setCurrentFloristAction = (florist) => async (dispatch) => {
  dispatch({ type: SET_CURRENT_FLORIST, payload: florist })
}

export {
  loginAction,
  forgotPasswordAction,
  changePasswordAction,
  validateTokenAction,
  unsetRedirectAction,
  logoutAction,
  setCurrentFloristAction,
}
