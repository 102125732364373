import { createConstants, initConstant } from './constantsCreator'

const ADD_USER_INTERMEDIATE = createConstants('ADD_USER_INTERMEDIATE')
const ADD_USER_FLORIST = createConstants('ADD_USER_FLORIST')

const DELETE_USER_INTERMEDIATE = createConstants('DELETE_USER_INTERMEDIATE')
const DELETE_USER_FLORIST = createConstants('DELETE_USER_FLORIST')

const GET_USER_PERMISSIONS = createConstants('GET_USER_PERMISSIONS')
const UPDATE_USER_PERMISSIONS = createConstants('UPDATE_USER_PERMISSIONS')

const UPDATE_USER = createConstants('UPDATE_USER')

const GET_USER = createConstants('GET_USER')

const entityConstants = {
  addUser: {
    intermediates: ADD_USER_INTERMEDIATE,
    florists: ADD_USER_FLORIST,
  },
  deleteUser: {
    intermediates: DELETE_USER_INTERMEDIATE,
    florists: DELETE_USER_FLORIST,
  },
}

const USERS_INIT = {
  ...initConstant(ADD_USER_INTERMEDIATE),
  ...initConstant(ADD_USER_FLORIST),
  ...initConstant(DELETE_USER_INTERMEDIATE),
  ...initConstant(DELETE_USER_FLORIST),
  ...initConstant(GET_USER_PERMISSIONS),
  ...initConstant(UPDATE_USER_PERMISSIONS),
  ...initConstant(UPDATE_USER),
  ...initConstant(GET_USER),
  userDetail: {},
  created: {},
  userPermissions: [],
}

export {
  ADD_USER_INTERMEDIATE,
  ADD_USER_FLORIST,
  DELETE_USER_INTERMEDIATE,
  DELETE_USER_FLORIST,
  GET_USER_PERMISSIONS,
  UPDATE_USER_PERMISSIONS,
  UPDATE_USER,
  GET_USER,
  USERS_INIT,
  entityConstants,
}
