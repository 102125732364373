import { createConstants, initConstant } from './constantsCreator'

const CREATE_INTERMEDIATE = createConstants('CREATE_INTERMEDIATE')
const CREATE_FLORIST = createConstants('CREATE_FLORIST')

const GET_INTERMEDIATES = createConstants('GET_INTERMEDIATES')
const GET_FLORISTS = createConstants('GET_FLORISTS')

const UPDATE_INTERMEDIATE = createConstants('UPDATE_INTERMEDIATE')
const UPDATE_FLORIST = createConstants('UPDATE_FLORIST')

const GET_INTERMEDIATE_DETAIL = createConstants('GET_INTERMEDIATE_DETAIL')
const GET_FLORIST_DETAIL = createConstants('GET_FLORIST_DETAIL')

const GET_USERS_INTERMEDIATE = createConstants('GET_USERS_INTERMEDIATE')
const GET_USERS_FLORIST = createConstants('GET_USERS_FLORIST')

const GET_PERMISSIONS_INTERMEDIATES = createConstants('GET_PERMISSIONS_INTERMEDIATES')
const GET_PERMISSIONS_FLORISTS = createConstants('GET_PERMISSIONS_FLORISTS')

const GET_FLORISTS_BY_INTERMEDIATE = createConstants('GET_FLORISTS_BY_INTERMEDIATE')

const GET_ZONE = createConstants('GET_ZONE')

const propertiesEnum = {
  GET_ZONE: 'main-zone',
}

const entityEnum = {
  fl: 'florists',
  itm: 'intermediates',
}

const entityIdEnum = {
  fl: 'floristId',
  itm: 'intermediateId',
}

const typeEnum = {
  3: 'florists',
  2: 'intermediates',
  1: 'intermediates',
}

const shortTypeEnum = {
  3: 'fl',
  2: 'itm',
  1: 'itm',
}

const entityConstants = {
  get: {
    intermediates: GET_INTERMEDIATES,
    florists: GET_FLORISTS,
  },
  update: {
    intermediates: UPDATE_INTERMEDIATE,
    florists: UPDATE_FLORIST,
  },
  getDetail: {
    intermediates: GET_INTERMEDIATE_DETAIL,
    florists: GET_FLORIST_DETAIL,
  },
  getUsers: {
    intermediates: GET_USERS_INTERMEDIATE,
    florists: GET_USERS_FLORIST,
  },
  create: {
    intermediates: CREATE_INTERMEDIATE,
    florists: CREATE_FLORIST,
  },
  getPermissions: {
    intermediates: GET_PERMISSIONS_INTERMEDIATES,
    florists: GET_PERMISSIONS_FLORISTS,
  },
}

const ENTITY_INIT = {
  ...initConstant(CREATE_INTERMEDIATE),
  ...initConstant(CREATE_FLORIST),
  ...initConstant(GET_INTERMEDIATES),
  ...initConstant(GET_FLORISTS),
  ...initConstant(GET_INTERMEDIATE_DETAIL),
  ...initConstant(GET_FLORIST_DETAIL),
  ...initConstant(UPDATE_INTERMEDIATE),
  ...initConstant(UPDATE_FLORIST),
  ...initConstant(GET_USERS_INTERMEDIATE),
  ...initConstant(GET_USERS_FLORIST),
  ...initConstant(GET_PERMISSIONS_INTERMEDIATES),
  ...initConstant(GET_PERMISSIONS_FLORISTS),
  ...initConstant(GET_FLORISTS_BY_INTERMEDIATE),
  ...initConstant(GET_ZONE, 'getZones'),
  // mainZone: null,
  list: {},
  florists: {},
  users: {},
  detail: {},
  permissions: {},
  created: {},
}

export {
  CREATE_INTERMEDIATE,
  CREATE_FLORIST,
  GET_INTERMEDIATES,
  GET_FLORISTS,
  UPDATE_INTERMEDIATE,
  UPDATE_FLORIST,
  GET_INTERMEDIATE_DETAIL,
  GET_FLORIST_DETAIL,
  GET_USERS_INTERMEDIATE,
  GET_PERMISSIONS_INTERMEDIATES,
  GET_PERMISSIONS_FLORISTS,
  GET_USERS_FLORIST,
  GET_FLORISTS_BY_INTERMEDIATE,
  GET_ZONE,
  ENTITY_INIT,
  entityEnum,
  entityIdEnum,
  typeEnum,
  shortTypeEnum,
  entityConstants,
  propertiesEnum,
}
