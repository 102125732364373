import { approvalStatusDataEnum } from './genericEnums'

export const badgeStatusEnum = {
  PENDING: 'PENDING',
  AGREED: 'AGREED',
  NO_STOCK: 'NO_STOCK',
  START_OFFER: 'START_OFFER',
}

export const badgeStatusDataEnum = {
  [badgeStatusEnum.PENDING]: {
    color: 'warning',
  },
  [badgeStatusEnum.AGREED]: {
    color: 'success',
    text: 'agreedCost',
  },
  [badgeStatusEnum.NO_STOCK]: {
    color: 'error',
    text: 'noStock',
  },
  [badgeStatusEnum.START_OFFER]: {
    text: 'startOffering',
  },
}

export const actionsEnum = {
  CONFIRM: 'CONFIRM',
  NO_STOCK: 'NO_STOCK',
  COUNTEROFFER: 'COUNTEROFFER',
}

export const actionsDataEnum = {
  [actionsEnum.CONFIRM]: {
    ...approvalStatusDataEnum.ACCEPT,
    name: 'confirm',
    param: 'accepted',
    confirm: 'confirmAccepted',
  },
  [actionsEnum.COUNTEROFFER]: {
    ...approvalStatusDataEnum.REJECT,
    color: 'warning',
    name: 'counteroffer',
  },
  [actionsEnum.NO_STOCK]: {
    name: 'noStock',
    param: 'noStock',
    confirm: 'confirmNoStock',
  },
}
