import { createGenericContext } from '@utils/createGenericContext'
import React, { useState } from 'react'

import { OrderImage } from '@utils/interfaces/order'

export interface OrderModal {
  useRatingModal: () => Modal
  useFloristModal: () => Modal
  useImagesFeedbackModal: () => Modal
  useDriverModal: () => Modal
  useImageModal: () => Modal
  selectedOrderId: Order['id']
  setSelectedOrderId: (id: Order['id']) => void
  selectedFloristName: Florist['username']
  setSelectedFloristName: (username: Florist['username']) => void
  setUploadedImages: (data: UploadedImages) => void
  uploadedImages: UploadedImages
}

type UploadedImages = ImagesData | null

interface ImagesData {
  images: OrderImage[]
  florist: Florist['username']
  id: string
}

interface Modal {
  modalOpen: boolean
  openModal: () => void
  closeModal: () => void
}

interface Order {
  id: number
}

interface Florist {
  username: string
}

export const [OrderModalContext, Provider] = createGenericContext<OrderModal>()

export const OrderModalProvider: React.FC = (props) => {
  const [selectedOrderId, setSelectedOrderId] = useState(-1)
  const [selectedFloristName, setSelectedFloristName] = useState('')
  const [uploadedImages, setUploadedImages] = useState<UploadedImages>(null)

  const [imagesFeedbackModalOpen, setImagesFeedbackModalOpen] = useState<boolean>(false)
  const [ratingModalOpen, setRatingModalOpen] = useState<boolean>(false)
  const [floristModalOpen, setFloristModalOpen] = useState<boolean>(false)
  const [driverModalOpen, setDriverModalOpen] = useState<boolean>(false)
  const [imageModalOpen, setImageModalOpen] = useState<boolean>(false)

  const useRatingModal = (): Modal => {
    const openModal = () => setRatingModalOpen(true)
    const closeModal = () => setRatingModalOpen(false)

    return {
      modalOpen: ratingModalOpen,
      openModal,
      closeModal,
    }
  }

  const useFloristModal = (): Modal => {
    const openModal = () => setFloristModalOpen(true)
    const closeModal = () => setFloristModalOpen(false)

    return {
      modalOpen: floristModalOpen,
      openModal,
      closeModal,
    }
  }

  const useImagesFeedbackModal = (): Modal => {
    const openModal = () => setImagesFeedbackModalOpen(true)
    const closeModal = () => setImagesFeedbackModalOpen(false)

    return {
      modalOpen: imagesFeedbackModalOpen,
      openModal,
      closeModal,
    }
  }

  const useDriverModal = (): Modal => {
    const openModal = () => setDriverModalOpen(true)
    const closeModal = () => setDriverModalOpen(false)

    return {
      modalOpen: driverModalOpen,
      openModal,
      closeModal,
    }
  }

  const useImageModal = (): Modal => {
    const openModal = () => setImageModalOpen(true)
    const closeModal = () => setImageModalOpen(false)

    return {
      modalOpen: imageModalOpen,
      openModal,
      closeModal,
    }
  }

  return (
    <Provider
      value={{
        useRatingModal,
        useFloristModal,
        useDriverModal,
        useImageModal,
        useImagesFeedbackModal,
        selectedOrderId,
        setSelectedOrderId,
        selectedFloristName,
        setSelectedFloristName,
        uploadedImages,
        setUploadedImages,
      }}
      {...props}
    />
  )
}
