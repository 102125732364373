import { createConstants, initConstant } from './constantsCreator'

const CREATE_DRIVER = createConstants('CREATE_DRIVER')
const GET_DRIVERS = createConstants('GET_DRIVERS')
const GET_DRIVER = createConstants('GET_DRIVER')
const DELETE_DRIVER = createConstants('DELETE_DRIVER')
const UPDATE_DRIVER = createConstants('UPDATE_DRIVER')

const GET_DRIVERS_INIT = {
  ...initConstant(GET_DRIVERS),
  list: [],
}

const GET_DRIVER_INIT = {
  ...initConstant(GET_DRIVER, 'getDriverDetail'),
  detail: {},
}

const DRIVER_INIT = {
  ...GET_DRIVERS_INIT,
  ...GET_DRIVER_INIT,
  ...initConstant(CREATE_DRIVER),
  ...initConstant(DELETE_DRIVER),
  ...initConstant(UPDATE_DRIVER),
}

export { CREATE_DRIVER, GET_DRIVERS, GET_DRIVER, DELETE_DRIVER, UPDATE_DRIVER, DRIVER_INIT }
