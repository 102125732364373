/* eslint-disable react/no-array-index-key */
import React, { Suspense } from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'

import RRLoadingBar from 'react-redux-loading-bar'

import ProtectedRoute from '@utils/components/ProtectedRoute'
import LazyLoader from '@utils/components/LazyLoader'
import { Spin } from 'antd'
import { CSpinner } from '@coreui/react'
import ToastContainers from './utils/components/toastContainers'

import TheLayout from './containers/TheLayout'

import { publicRoutes } from './routes'

import 'antd/dist/antd.css'
import './scss/style.scss'

import '@reach/combobox/styles.css'
import 'react-phone-input-2/lib/bootstrap.css'

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'

const LoadingBar = () => (
  <RRLoadingBar showFastActions style={{ backgroundColor: '#321fdb', height: '3px' }} />
)

Spin.setDefaultIndicator(<CSpinner color="primary" grow />)

const App: React.FC = () => (
  <BrowserRouter>
    <Suspense fallback={<LazyLoader delay={300} active />}>
      <LoadingBar />
      <ToastContainers />

      <Switch>
        {publicRoutes.map(
          (route, idx) =>
            !!route.component && (
              <ProtectedRoute
                key={idx}
                path={route.path}
                exact={route.exact}
                render={(props) => <route.component {...props} />}
                reversed
              />
            )
        )}
        <ProtectedRoute path="/" render={(props) => <TheLayout {...props} />} />
      </Switch>
    </Suspense>
  </BrowserRouter>
)

// export default withTranslation()(App);
export default App
