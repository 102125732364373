import React, { useEffect, useState } from 'react'
import LoadingOverlay, { Props as LoadingProps } from './LoadingOverlay'

export interface Props extends LoadingProps {
  delay?: number
}

const LazyLoader: React.FC<Props> = ({ delay = 250, ...props }) => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true)
    }, delay)
    return () => {
      clearTimeout(timeout)
    }
  }, [delay])

  return show ? <LoadingOverlay {...props} /> : null
}

export default LazyLoader
