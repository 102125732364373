import React, { useState, useMemo, useEffect, useRef, useCallback } from 'react'
import { debounce, last } from 'lodash'

import { withTranslation } from 'react-i18next'

import {
  GoogleMap,
  useLoadScript,
  DrawingManager,
  Circle as EditableCircle,
  Data,
  Polygon as ReadOnlyPolygon,
  InfoWindow,
} from '@react-google-maps/api'

import {
  Circle,
  Libraries,
  MapContainerSize,
  LatLng,
  LatLngLit,
  Map,
  PolygonWithId,
  PolygonOptions,
  PolygonPath,
} from '@utils/interfaces/maps'

import { Ref } from '@utils/interfaces/react'

import { generateColor, getPolygonPath, fitCircleToBounds } from '@utils/helpers'

import styles from '@styles/modules/mapSearch.module.scss'
import { mapOptions, defaultZoom, defaultCenter, defaultRadius } from './defaults'

import Search from './PlacesSearch'

const libraries: Libraries = ['places', 'geometry', 'drawing']

interface CircleData {
  radius?: number
  center?: LatLngLit
}

interface PolygonInfo extends Pick<PolygonWithId, 'name' | 'description'> {
  id: number
  polygon: PolygonPath
  options: PolygonOptions
}

interface Props extends MapContainerSize, CircleData {
  polygons?: PolygonWithId[]
  editable?: boolean
  drawable?: boolean
  draggable?: boolean
  useDefault?: boolean
  showTooltips?: boolean
  circleRef: Ref<Circle | null>
  onCircleChanged?: (center: LatLngLit, radius: number) => void
  circleUnder?: boolean
  onRadiusChange?: () => void
}

const RadiusPolygonsMap: React.FC<Props> = ({
  width = '600px',
  maxWidth = '100%',
  height = '600px',
  editable = true,
  drawable = false,
  draggable = true,
  useDefault = false,
  showTooltips = true,
  circleRef,
  center: initialCenter,
  radius: initialRadius,
  onCircleChanged,
  polygons,
  circleUnder = false,
  onRadiusChange,
  ...containerStyle
}) => {
  // https://codesandbox.io/s/react-google-maps-api-editing-a-polygon-forked-tzhk9?file=/src/index.js
  const [latestDrawing, setLatestDrawing] = useState<Circle>()

  const [paths, setPaths] = useState<PolygonInfo[]>([])
  const [infoIdOpen, setInfoIdOpen] = useState<number | null>(null)
  const [infoPosition, setInfoPosition] = useState<LatLng>()

  const [mapCenter, _setMapCenter] = useState<LatLng>(initialCenter ?? defaultCenter)
  const [center, setCenter] = useState<LatLng | undefined>(
    useDefault ? defaultCenter : initialCenter
  )
  const [radius, setRadius] = useState<number | undefined>(
    useDefault ? defaultRadius : initialRadius
  )

  const mapRef = useRef<Map | null>(null)

  const mapStyles = { width, height, maxWidth, ...containerStyle }

  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_KEY ?? ''
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey,
    libraries,
  })

  const onMapLoad = useCallback((map: Map) => {
    mapRef.current = map
  }, [])

  useEffect(() => {
    if (polygons) {
      const polyPaths = polygons.map(({ data, name, description, ...polygon }, index) => {
        const id = polygon?.id ?? index
        const color = generateColor(id)
        return {
          id,
          name,
          description,
          polygon: getPolygonPath(data),
          options: { zIndex: 1000 + index, fillColor: color, strokeColor: color, strokeWeight: 5 },
        }
      })

      setPaths(polyPaths)
    }
  }, [polygons])

  const panTo = useCallback((position, bounds) => {
    if (mapRef.current) {
      mapRef.current.panTo(position)
      mapRef.current.fitBounds(bounds)
      if (circleRef.current) fitCircleToBounds(circleRef.current, position, bounds)
    }
  }, [])

  const debouncedCircleChanged = () => {
    if (circleRef.current) {
      const currentRadius = circleRef.current.getRadius()

      if (onCircleChanged && !!circleRef.current.getCenter()) {
        onCircleChanged(
          { lat: circleRef.current.getCenter().lat(), lng: circleRef.current.getCenter().lng() },
          currentRadius
        )
      }
    }
  }

  const handleCircleChanged = useMemo(
    () => debounce(debouncedCircleChanged, 500),
    [onCircleChanged, circleRef, radius, center]
  )

  const handleInfoShow = (e, id: number) => {
    if (mapRef.current && showTooltips) {
      setInfoIdOpen(id)
      setInfoPosition(e.latLng)
    }
  }

  const handleInfoClose = () => setInfoIdOpen(null)

  const onEdit = () => {
    if (latestDrawing) latestDrawing.setMap(null)
    if (onRadiusChange) onRadiusChange()
    handleCircleChanged()
  }

  const onCircleLoad = useCallback(
    (circle: Circle) => {
      // eslint-disable-next-line no-param-reassign
      circleRef.current = circle
    },
    [onEdit]
  )

  const onCircleComplete = (circle: Circle) => {
    if (drawable && circle) {
      if (latestDrawing) latestDrawing.setMap(null)

      setCenter(circle.getCenter())
      setRadius(circle.getRadius())

      setLatestDrawing(circle)
    }
  }

  const onCircleUnmount = useCallback(() => {
    // eslint-disable-next-line no-param-reassign
    circleRef.current = null
  }, [])

  const getCircleZIndex = (list: PolygonInfo[]) =>
    circleUnder ? (list[0]?.options?.zIndex ?? 1000) - 1 : (last(list)?.options?.zIndex ?? 1000) + 1

  if (loadError || !isLoaded) return <p>Error</p>

  return (
    <div style={{ ...mapStyles, position: 'relative' }}>
      <Search panTo={panTo} styles={styles.searchContainer} />

      <GoogleMap
        onLoad={onMapLoad}
        options={mapOptions}
        mapContainerStyle={mapStyles}
        zoom={defaultZoom}
        center={mapCenter}
      >
        {radius && center && (
          <EditableCircle
            editable={editable}
            draggable={editable && draggable}
            onRadiusChanged={onEdit}
            onCenterChanged={onEdit}
            onLoad={onCircleLoad}
            onUnmount={onCircleUnmount}
            center={center}
            radius={radius}
            options={{
              zIndex: !!paths && paths?.length ? getCircleZIndex(paths) : 1001,
            }}
          />
        )}
        {!!paths &&
          !!paths?.length &&
          paths.map(({ polygon, id, name, options }) =>
            polygon ? (
              <>
                <ReadOnlyPolygon
                  key={id}
                  editable={false}
                  draggable={false}
                  path={polygon}
                  options={options}
                  onMouseOver={(e) => handleInfoShow(e, id)}
                  onMouseOut={handleInfoClose}
                />
                {!!infoPosition && infoIdOpen === id && (
                  <InfoWindow position={infoPosition} onCloseClick={handleInfoClose}>
                    <h6 className="mb-0" style={{ color: options.fillColor }}>
                      {name}
                    </h6>
                  </InfoWindow>
                )}
              </>
            ) : null
          )}
        {drawable && (
          <DrawingManager
            onCircleComplete={onCircleComplete}
            options={{
              drawingControlOptions: {
                drawingModes: [window.google.maps.drawing.OverlayType.CIRCLE],
                position: google.maps.ControlPosition.RIGHT_TOP,
              },
            }}
          />
        )}
        <Data />
      </GoogleMap>
    </div>
  )
}

export default withTranslation()(RadiusPolygonsMap)
