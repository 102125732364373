export const priceFormKeys = {
  variantId: 'id',
  price: 'price',
  initialPrice: 'initialPrice',
}

export const firstPriceFormKeys = {
  variantId: 'variantId',
  price: 'price',
  floristId: 'floristId',
  zoneId: 'zoneId',
}

export const priceInitialValues = {
  price: null,
  // MEXICO
  zoneId: 1,
}

export const formKeys = {
  id: 'variantId',
  florist: 'floristId',
  status: 'status',
  initialPrice: 'initialPrice',
  newPrice: 'newPrice',
}

export const initialValues = {
  [formKeys.status]: null,
  [formKeys.newPrice]: null,
}
