import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'

import translations from '@translations'

import { CDropdownMenu, CDropdownToggle, CDropdown, CDropdownItem } from '@coreui/react'
import { filterDataEnum, filterEnum } from '../enums/orderEnums'

const getTranslation = (text) => translations.generic[text] ?? translations.form[text] ?? text

export const OrderFilterDropdown = withTranslation()(({ onChange, placeholder, isFlorist }) => {
  const [selected, setSelected] = useState({
    key: filterEnum.ALL,
    ...filterDataEnum[filterEnum.ALL],
  })

  const handleSelect = (key, data) => {
    setSelected({ key, ...data })
    onChange(key, data.param)
  }

  return (
    <CDropdown>
      <CDropdownToggle
        className="d-flex justify-content-between align-items-center"
        style={{ minWidth: '100px' }}
        color={selected?.color ?? 'secondary'}
      >
        {getTranslation(selected?.name) ?? placeholder}
      </CDropdownToggle>

      <CDropdownMenu>
        {Object.entries(filterDataEnum)
          .filter(([key, _]) => !isFlorist || key !== 'PENDING_INTERMEDIATE')
          .map(([key, data]) => (
            <CDropdownItem
              key={key}
              className={`text-${data?.color}`}
              onClick={() => handleSelect(key, data)}
              disabled={selected && key === selected.key}
            >
              {getTranslation(data?.name)}
            </CDropdownItem>
          ))}
      </CDropdownMenu>
    </CDropdown>
  )
})
