import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'

import moment from 'moment'

import translations from '@translations'
import { weekArray as week } from '@utils/enums/weekdaysEnums'

import { Checkbox, TimeRange, Card } from '@components/common/index'
import Col from '@utils/components/ResponsiveColumn'
import { CRow } from '@coreui/react'

const OpenHoursSelector = ({ onChange, values = {} }) => {
  const [openHours, setOpenHours] = useState(null)
  const { generic } = translations

  useEffect(() => {
    if (!openHours) setOpenHours(values)
  }, [values])

  const handleChange = async (val) => {
    await setOpenHours(val)
    onChange(val)
  }

  const onCheckChange = (val, weekday) => {
    let newAvailability = {}
    if (val) {
      if (!Object.keys(openHours).includes(weekday.day)) {
        newAvailability = {
          ...openHours,
          [weekday.day]: {
            startHour: '09:00',
            endHour: '17:00',
          },
        }
      }
    } else if (Object.keys(openHours).includes(weekday.day)) {
      newAvailability = Object.keys(openHours)
        .filter((key) => key !== weekday.day)
        .reduce((o, key) => {
          o[key] = openHours[key]
          return o
        }, {})
    }

    handleChange(newAvailability)
  }

  const onChangeHour = (startTime, endTime, weekday) => {
    const newAvailability = {
      ...openHours,
      [weekday.day]: {
        startHour: startTime,
        endHour: endTime,
      },
    }
    handleChange(newAvailability)
  }

  return (
    <CRow className="my-2">
      {week.map((weekday) => {
        const { day, defaultStartTime, defaultEndTime, translationKey } = weekday
        const disabled = !openHours || !Object.keys(openHours).includes(day)

        const timeRange = [
          moment(
            (!disabled && openHours[day]?.startHour?.slice(0, 5)) || defaultStartTime,
            'HH:mm'
          ),
          moment((!disabled && openHours[day]?.endHour?.slice(0, 5)) || defaultEndTime, 'HH:mm'),
        ]

        return (
          <Col key={weekday.weekday} xxl={[4, 6]} xl={[6, 12]} md={4} sm={12}>
            <Card
              key={weekday.weekday}
              className="mx-2"
              style={{ height: 'fit-content' }}
              collapsible
              showCollapseArrow
              open={!disabled}
              header={(props) => (
                <Checkbox
                  onChange={(e) => {
                    onCheckChange(e.target.checked, weekday)
                  }}
                  name={weekday.day}
                  id={weekday.weekday}
                  label={generic[translationKey]}
                  value={!disabled}
                  {...props}
                />
              )}
            >
              <TimeRange
                disabled={disabled}
                onChange={(_, [startTime, endTime]) => onChangeHour(startTime, endTime, weekday)}
                defaultVal={timeRange}
              />
            </Card>
          </Col>
        )
      })}
    </CRow>
  )
}

export default withTranslation()(OpenHoursSelector)
