import React, { useEffect, useState } from 'react'
import { capitalize, isFunction, isString, get } from 'lodash'

import EmptyRecord from '@utils/components/EmptyTableRender'

import { FieldsConfig, ObjectWithValue, ObjectWithoutKey as Data } from '@utils/interfaces/react'
import translations from '@translations'
import { Render, EmptyRenderType } from './interfaces'
import { getKeys, renderRow } from './helpers'

type Key = React.Key
type RowRender = string | Render<Data>

interface Props {
  data: Data
  fields?: Key[] | FieldsConfig
  emptyRecordRender?: EmptyRenderType
  render?: ObjectWithValue<RowRender>
  transformKey?: (key: string) => Key
  renderKey?: ObjectWithValue<React.ReactElement>
}

const StripedTable: React.FC<Props> = ({
  data: initialData,
  fields = null,
  emptyRecordRender = (_) => <EmptyRecord />,
  render = {},
  renderKey = {},
  transformKey = (key) => translations.table[key] || capitalize(key),
}) => {
  const [keys, setKeys] = useState<Key[]>([])
  const [data, setData] = useState<Data>({})

  useEffect(() => {
    if (data && !keys.length) {
      setKeys(getKeys(fields, data))
    }
  }, [data])

  useEffect(() => {
    if (initialData && (!data || !Object.keys(data).length)) {
      setData(initialData)
    }
  }, [initialData])

  const renderValue = (key: Key) => {
    const keyRender = render[key]
    if (!!keyRender && isFunction(keyRender)) return keyRender(data[key], data)

    return renderRow(emptyRecordRender, key)(
      get(data, !!keyRender && isString(keyRender) ? `${key}.${keyRender}` : key),
      data
    )
  }

  const defaultKeyRender = (key: Key) => <strong>{isString(key) ? transformKey(key) : key}</strong>

  return (
    <table className="table table-striped table-hover">
      <tbody>
        {keys.map((key, index) => (
          <tr key={index.toString()}>
            <td>{renderKey[key] || defaultKeyRender(key)}</td>
            <td>{renderValue(key)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default StripedTable
