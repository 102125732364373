export const statusEnum = {
  ALL: 'ALL',
  NO_COST_SET: 'NO_COST_SET',
  AGREED: 'AGREED',
  PENDING: 'PENDING',
  NO_STOCK: 'NO_STOCK',
}

export const statusDataEnum = {
  [statusEnum.ALL]: {
    name: 'allProducts',
  },
  [statusEnum.NO_COST_SET]: {
    param: 'noCostSet',
    name: 'noCostFilter',
    color: 'primary',
  },
  [statusEnum.AGREED]: {
    param: 'showEnded',
    name: 'agreedFilter',
    color: 'success',
  },
  [statusEnum.PENDING]: {
    param: 'showPending',
    name: 'pendingFilter',
    color: 'warning',
  },
  [statusEnum.NO_STOCK]: {
    param: 'noStock',
    name: 'noStockFilter',
    color: 'danger',
  },
}
