import { baseService } from '@utils/authRequest'

export const getIngredients = async (params) => {
  try {
    return await baseService.get('ingredients', { params })
  } catch (e) {
    return e.response?.data.error
  }
}

export const getIngredientDetail = async (id) => {
  try {
    return await baseService.get(`ingredients/${id}`)
  } catch (e) {
    return e.response?.data.error
  }
}

export const createIngredient = async (form) => {
  try {
    return await baseService.post('ingredients', form)
  } catch (e) {
    return e.response?.data.error
  }
}

export const updateIngredient = async (id, form) => {
  try {
    return await baseService.put('ingredients', { id, ...form })
  } catch (e) {
    return e.response?.data.error
  }
}

export const deleteIngredient = async (id) => {
  try {
    return await baseService.delete(`ingredients/${id}`)
  } catch (e) {
    return e.response?.data.error
  }
}

export const addIngredientsToProduct = async (form) => {
  try {
    return await baseService.post('ingredients/product/add', form)
  } catch (e) {
    return e.response?.data.error
  }
}

export const removeIngredientsFromProduct = async (form) => {
  try {
    return await baseService.post('ingredients/product/remove', form)
  } catch (e) {
    return e.response?.data.error
  }
}
