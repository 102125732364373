import React, { useState } from 'react'
import moment, { Moment } from 'moment'

import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import { withTranslation, WithTranslation } from 'react-i18next'

import translations from '@translations'

import { DateRangePicker, FocusedInputShape } from 'react-dates'

type PropsFromRedux = ConnectedProps<typeof connector>

type MomentOrNull = Moment | null

interface MomentRange {
  startDate: MomentOrNull
  endDate: MomentOrNull
}

type FocusedInput = FocusedInputShape | null

interface Props extends DateRangePicker, WithTranslation, PropsFromRedux {
  onChange: (value: MomentRange) => void
  startDateId?: string
  endDateId?: string
  maxDate?: Moment
  value: MomentRange
  startPlaceholder?: string
  endPlaceholder?: string
  readonly?: boolean
}

const DateRange: React.FC<Props> = ({
  onChange,
  value,
  startDateId = 'startDateId',
  endDateId = 'endDateId',
  startPlaceholder,
  endPlaceholder,
  readonly = false,
  currentLanguage,
  maxDate,
}) => {
  if (currentLanguage) moment.locale(currentLanguage.deepLocale)
  const { searchFrom, searchTo } = translations.generic
  const today = moment()

  const [focusedInput, setFocusedInput] = useState<FocusedInput>(null)
  const isOutsideRange = (date) => date > today

  return (
    <DateRangePicker
      startDate={value?.startDate || null}
      startDateId={startDateId}
      endDate={value?.endDate || null}
      endDateId={endDateId}
      onDatesChange={onChange}
      onFocusChange={(focused) => setFocusedInput(focused)}
      focusedInput={focusedInput}
      required={false}
      isOutsideRange={isOutsideRange}
      startDatePlaceholderText={startPlaceholder ?? searchFrom}
      endDatePlaceholderText={endPlaceholder ?? searchTo}
      readOnly={readonly}
      hideKeyboardShortcutsPanel
      maxDate={maxDate}
    />
  )
}

const mapStateToProps = (state) => ({
  currentLanguage: state.generic.currentLanguage,
})

const connector = connect(mapStateToProps)

export default compose(withTranslation(), connector)(DateRange)
