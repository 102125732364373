import { baseService } from '@utils/authRequest'

export const createRule = async (form) => {
  try {
    return await baseService.post('rules', form)
  } catch (e) {
    return e.response?.data.error
  }
}

export const updateRule = async (form) => {
  try {
    return await baseService.post('rules/add-intermediate', form)
  } catch (e) {
    return e.response?.data.error
  }
}

export const updateRules = async (form) => {
  try {
    return await baseService.post('rules/reorder', form)
  } catch (e) {
    return e.response?.data.error
  }
}

export const getRules = async () => {
  try {
    return await baseService.get(`rules`)
  } catch (e) {
    return e.response?.data.error
  }
}

export const deleteRule = async (id) => {
  try {
    return await baseService.delete(`rules/${id}`)
  } catch (e) {
    return e.response?.data.error
  }
}

export const getRule = async (id) => {
  try {
    return await baseService.get(`rules/${id}`)
  } catch (e) {
    return e.response?.data.error
  }
}
