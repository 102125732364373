import { createConstants, initConstant } from './constantsCreator'

const GET_POLYGONS = createConstants('GET_POLYGONS')
const GET_ZONES = createConstants('GET_ZONES')

const CREATE_POLYGON = createConstants('CREATE_POLYGON')
const UPDATE_POLYGON = createConstants('UPDATE_POLYGON')
const GET_POLYGON = createConstants('GET_POLYGON')
const DELETE_POLYGON = createConstants('DELETE_POLYGON')

const GET_POLYGONS_INIT = {
  ...initConstant(GET_POLYGONS),
  list: {},
}

const GET_POLYGON_INIT = {
  ...initConstant(GET_POLYGON, 'getPolygonDetail'),
  detail: {},
}

const GET_ZONES_INIT = {
  ...initConstant(GET_ZONES),
  zones: [],
}

const POLYGON_INIT = {
  ...GET_POLYGONS_INIT,
  ...GET_ZONES_INIT,
  ...GET_POLYGON_INIT,
  ...initConstant(CREATE_POLYGON),
  ...initConstant(DELETE_POLYGON),
  ...initConstant(UPDATE_POLYGON),
}

export {
  GET_POLYGONS,
  GET_ZONES,
  CREATE_POLYGON,
  UPDATE_POLYGON,
  GET_POLYGON,
  DELETE_POLYGON,
  POLYGON_INIT,
}
