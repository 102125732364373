import { baseService } from '@utils/authRequest'

export const getOrders = async (endpoint, params) => {
  try {
    return await baseService.get(`${endpoint}/orders`, { params })
  } catch (e) {
    return e.response?.data.error
  }
}

export const getFlNearOrder = async (id) => {
  try {
    return await baseService.get(`orders/${id}/find-florists`)
  } catch (e) {
    return e.response?.data.error
  }
}

export const setOrderFlorist = async (id, floristId) => {
  try {
    return await baseService.get(`orders/${id}/florist/${floristId}`)
  } catch (e) {
    return e.response?.data.error
  }
}

export const getOrderDetail = async (id) => {
  try {
    return await baseService.get(`orders/${id}`)
  } catch (e) {
    return e.response?.data.error
  }
}

export const setOrderStatus = async (id, status) => {
  try {
    return await baseService.put(`orders/${id}/status`, { status })
  } catch (e) {
    return e.response?.data.error
  }
}

export const setOrderDriver = async (orderId, driverId) => {
  try {
    return await baseService.get(`orders/${orderId}/driver/${driverId}`)
  } catch (e) {
    return e.response?.data.error
  }
}

export const setOrderImagesFeedback = async (id, accepted) => {
  try {
    return await baseService.post(`orders/${id}/images/feedback`, { accepted })
  } catch (e) {
    return e.response?.data.error
  }
}

export const setOrderImages = async (id, ids) => {
  try {
    return await baseService.post(`orders/${id}/images`, { ids })
  } catch (e) {
    return e.response?.data.error
  }
}

export const uploadImage = async (id, image) => {
  try {
    return await baseService.post(`orders/${id}/images/upload`, { image })
  } catch (e) {
    return e.response?.data.error
  }
}

export const createOrderFloristPrice = async ({ id, price, floristId }) => {
  try {
    return await baseService.post(`orders/add-price`, { id, price, floristId })
  } catch (e) {
    return e.response?.data.error
  }
}

export const sendMailToFlorists = async ({ id, floristId }) => {
  try {
    return await baseService.post(`orders/florist/send-mail`, { id, floristId })
  } catch (e) {
    return e.response?.data.error
  }
}

export const getOrderFloristsPrices = async (id) => {
  try {
    return await baseService.get(`orders/${id}/florists-prices`)
  } catch (e) {
    return e.response?.data.error
  }
}
