import React from 'react'
import { useSelector } from 'react-redux'

import { RootState } from '@store/index'

import { ColumnProps } from '@utils/interfaces/react'
import { isArray } from 'lodash'

import { CCol } from '@coreui/react'

type ColumnPropsArray = [ColumnProps, ColumnProps] | [ColumnProps] | ColumnProps
type CColWithoutSizes = Omit<CCol, 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'>

interface Props extends CColWithoutSizes {
  xs?: ColumnPropsArray
  sm?: ColumnPropsArray
  md?: ColumnPropsArray
  lg?: ColumnPropsArray
  xl?: ColumnPropsArray
  xxl?: ColumnPropsArray
}

const ResponsiveColumn: React.FC<Props> = ({ children, ...props }) => {
  const sb = useSelector((state: RootState) => state.generic.sidebarShow)
  const getWidth = (size) => {
    if (size === 12) return 12

    const column = props[size]
    if (isArray(column) && column.length > 1) {
      return [true, 'responsive'].includes(sb) ? column[1] : column[0]
    }

    return isArray(column) ? column[0] : column
  }

  return (
    <CCol
      {...props}
      xs={getWidth('xs')}
      sm={getWidth('sm')}
      md={getWidth('md')}
      lg={getWidth('lg')}
      xl={getWidth('xl')}
      xxl={getWidth('xxl')}
    >
      {children}
    </CCol>
  )
}

export default ResponsiveColumn
