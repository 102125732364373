import React, { Children } from 'react'

import { CBadge } from '@coreui/react'
import { Icon } from '@components/common/index'

interface Props extends CBadge {
  icon?: string
  className?: string
  onClick?: () => void
}

const ChipBadge: React.FC<Props> = ({ children, icon = '', onClick, className = '', ...props }) => (
  <CBadge
    className={`d-flex align-items-center justify-content-between ${className}`}
    onClick={onClick}
    color="primary"
    {...props}
  >
    {!!children && !!Children.toArray(children).length && (
      <span className={`${icon ? 'pr-2' : ''}`}>{children ?? ''}</span>
    )}
    {icon && <Icon name={icon} className="ml-2" />}
  </CBadge>
)

export default ChipBadge
