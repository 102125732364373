import { isArray, isFunction } from 'lodash'

import { Dispatch } from 'redux'
import {
  getCountries,
  getZones,
  getSites,
  getLines,
  getCategories,
  getIngredientsParents,
  getIngredientsChildren,
  getProductIngredients,
  getProducts,
  getProduct,
} from '../services/data'

import {
  GET_ZONES,
  GET_COUNTRIES,
  GET_SITES,
  GET_LINES,
  GET_CATEGORIES,
  GET_INGREDIENTS_PARENTS,
  GET_INGREDIENTS_CHILDREN,
  GET_PRODUCTS,
  GET_PRODUCT,
  GET_PRODUCT_INGREDIENTS,
} from '../constants/data'

type SetListType<T = any> = ((results: T[]) => void) | undefined

const getZonesByCountryAction =
  ({ countryId, page, setList }: { countryId: string; page?: number; setList: SetListType }) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch({ type: GET_ZONES.REQUEST })
      const { content, success } = await getZones(countryId, { page })

      if (success) {
        dispatch({ type: GET_ZONES.SUCCESS, payload: content })
        if (isFunction(setList)) setList(content.results)
      }
    } catch (e: any) {
      dispatch({
        type: GET_ZONES.ERROR,
        payload: e.message,
      })
    } finally {
      dispatch({ type: GET_ZONES.RESET })
    }
  }

const getCountriesAction =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch({ type: GET_COUNTRIES.REQUEST })
      const { content, success } = await getCountries()

      if (success) dispatch({ type: GET_COUNTRIES.SUCCESS, payload: content })
    } catch (e: any) {
      dispatch({
        type: GET_COUNTRIES.ERROR,
        payload: e.message,
      })
    } finally {
      dispatch({ type: GET_COUNTRIES.RESET })
    }
  }

const getSitesAction =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch({ type: GET_SITES.REQUEST })
      const { content, success } = await getSites()

      if (success) dispatch({ type: GET_SITES.SUCCESS, payload: content })
    } catch (e: any) {
      dispatch({
        type: GET_SITES.ERROR,
        payload: e.message,
      })
    } finally {
      dispatch({ type: GET_SITES.RESET })
    }
  }

const getProductLinesAction =
  (setList?: SetListType) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch({ type: GET_LINES.REQUEST })
      const { content, success } = await getLines()

      if (success) {
        dispatch({ type: GET_LINES.SUCCESS, payload: content })
        if (isFunction(setList)) setList(content)
      }
    } catch (e: any) {
      dispatch({
        type: GET_LINES.ERROR,
        payload: e.message,
      })
    } finally {
      dispatch({ type: GET_LINES.RESET })
    }
  }

const getCategoriesAction =
  (setList?: SetListType, params?: { productLineId: string }) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch({ type: GET_CATEGORIES.REQUEST })
      const { content, success } = await getCategories(params)

      if (success) {
        if (isFunction(setList)) {
          setList(content)
        }
        dispatch({ type: GET_CATEGORIES.SUCCESS, payload: content })
      }
    } catch (e: any) {
      dispatch({
        type: GET_CATEGORIES.ERROR,
        payload: e.message,
      })
    } finally {
      dispatch({ type: GET_CATEGORIES.RESET })
    }
  }

const getIngredientsParentsAction =
  (setList?: SetListType) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch({ type: GET_INGREDIENTS_PARENTS.REQUEST })
      const { content, success } = await getIngredientsParents()

      if (success) {
        if (isFunction(setList)) setList(content)
        dispatch({ type: GET_INGREDIENTS_PARENTS.SUCCESS, payload: content })
      }
    } catch (e: any) {
      dispatch({
        type: GET_INGREDIENTS_PARENTS.ERROR,
        payload: e.message,
      })
    } finally {
      dispatch({ type: GET_INGREDIENTS_PARENTS.RESET })
    }
  }

const getIngredientChildrenAction =
  (id: string | number, setList?: SetListType) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch({ type: GET_INGREDIENTS_CHILDREN.REQUEST })
      const { content, success } = await getIngredientsChildren(id)

      if (success) {
        dispatch({ type: GET_INGREDIENTS_CHILDREN.SUCCESS, payload: content })
        if (isFunction(setList)) setList(content)
      }
    } catch (e: any) {
      dispatch({
        type: GET_INGREDIENTS_CHILDREN.ERROR,
        payload: e.message,
      })
    } finally {
      dispatch({ type: GET_INGREDIENTS_CHILDREN.RESET })
    }
  }

const getProductsAction =
  ({
    setList,
    page,
    ...params
  }: {
    page?: number
    setList?: SetListType
    [key: string]: any | any[]
  }) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch({ type: GET_PRODUCTS.REQUEST })

      const filters = Object.keys(params).reduce((o, key) => {
        const value = params[key]
        if (value) o[key] = isArray(value) ? value.join() : value
        return o
      }, {})

      const { success, content } = await getProducts({ page, ...filters })

      if (success) {
        dispatch({ type: GET_PRODUCTS.SUCCESS, payload: content })
        if (isFunction(setList)) setList(content.results)
      }
    } catch (e: any) {
      dispatch({
        type: GET_PRODUCTS.ERROR,
        payload: e.message,
      })
    } finally {
      dispatch({ type: GET_PRODUCTS.RESET })
    }
  }

const getProductByIdAction =
  (id: string, setData?: (data: any) => void) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch({ type: GET_PRODUCT.REQUEST })
      const { success, content } = await getProduct(id)

      if (success) {
        dispatch({ type: GET_PRODUCT.SUCCESS, payload: content })
        if (isFunction(setData)) setData(content)
      }
    } catch (e: any) {
      dispatch({
        type: GET_PRODUCT.ERROR,
        payload: e.message,
      })
    } finally {
      dispatch({ type: GET_PRODUCT.RESET })
    }
  }

const getProductIngredientsAction =
  (id: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch({ type: GET_PRODUCT_INGREDIENTS.REQUEST })
      const { success, content } = await getProductIngredients(id)

      if (success) {
        dispatch({ type: GET_PRODUCT_INGREDIENTS.SUCCESS, payload: content })
      }
    } catch (e: any) {
      dispatch({
        type: GET_PRODUCT_INGREDIENTS.ERROR,
        payload: e.message,
      })
    } finally {
      dispatch({ type: GET_PRODUCT_INGREDIENTS.RESET })
    }
  }

export {
  getZonesByCountryAction,
  getCountriesAction,
  getSitesAction,
  getProductLinesAction,
  getCategoriesAction,
  getIngredientChildrenAction,
  getIngredientsParentsAction,
  getProductsAction,
  getProductIngredientsAction,
  getProductByIdAction,
}
