export const formKeys = {
  username: 'username',
  openHours: 'openHours',
  setIntermediate: 'setIntermediate',
  intermediateId: 'intermediateId',
  phoneNumber: 'phoneNumber',
  setOperator: 'setOperator',
  zones: 'zones',
}

const { openHours, username, intermediateId, setIntermediate, setOperator, phoneNumber, zones } =
  formKeys

export const initialValues = {
  [username]: '',
  [openHours]: [],
  [intermediateId]: -1,
  [setIntermediate]: false,
  [setOperator]: false,
  [phoneNumber]: null,
  [zones]: [],
}
