/* eslint-disable react/prop-types */
import { Button, Icon } from '@components/common'
import MiniMap from '@components/GoogleMaps/MiniMap'
import EmptyRecord from '@utils/components/EmptyTableRender'
import { Popover } from 'antd'

import Col from '@utils/components/ResponsiveColumn'

import translations from '@translations'
import { AddressConfig } from '@utils/reverseGeocode'

export interface StringCenter {
  lat: string
  lng: string
}

export interface AddressProps {
  showMap?: boolean
  id: string
  lat: number | string
  lng: number | string
  addresses: {
    [id: string]: AddressConfig
  }
}

const OrderAddressMap: React.FC<AddressProps> = ({ showMap = false, addresses, id, lat, lng }) => {
  const { addressNotAvailable, coordinates } = translations.generic

  const trueAddress = addresses[id]?.address
  const text = trueAddress || (lat && lng && coordinates)

  const icon = 'cil-location-pin'
  const Map: React.ComponentType<{ width?: string | number }> = (props) => (
    // eslint-disable-next-line react/destructuring-assignment
    <MiniMap center={{ lat, lng }} bounds={addresses[id]?.bounds} width={props?.width} />
  )

  if (text) {
    return showMap ? (
      <Col>
        <p>
          {!!trueAddress && <Icon name={icon} />} {text}
        </p>
        <Map width="100%" />
      </Col>
    ) : (
      <Popover content={Map} trigger="hover">
        <Button link icon={!!trueAddress && icon} text={text} style={{ textAlign: 'start' }} />
      </Popover>
    )
  }
  return <EmptyRecord text={addressNotAvailable} />
}

export default OrderAddressMap
