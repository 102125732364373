import React from 'react'
import classNames from 'classnames'

import { actionsEnum } from '@utils/enums/offerEnums'
import { Tooltip } from '@components/common'
import { CRow } from '@coreui/react'

const { NO_STOCK, COUNTEROFFER } = actionsEnum

const PriceWithStatus = ({ price, newPrice, status, translations }) => {
  const { noStockInfo, noStock } = translations
  const hasPrice = !!price || !!newPrice

  const isNoStock = status === NO_STOCK
  const strikeThroughStatus = isNoStock || status === COUNTEROFFER

  const classes = classNames('mr-2 mb-0', {
    'text-secondary text-decoration-line-through': strikeThroughStatus,
  })

  return (
    <CRow className="d-flex align-items-center justify-content-between w-100">
      <Tooltip show={isNoStock} tooltip={noStockInfo}>
        <h5 className={classes}>{price}</h5>
      </Tooltip>
      {(!isNoStock || hasPrice) && (
        <h5 className={classNames('mb-0', { small: isNoStock })}>
          {isNoStock ? noStock : newPrice}
        </h5>
      )}
    </CRow>
  )
}

export default PriceWithStatus
