import React from 'react'
import { CCol, CContainer, CRow } from '@coreui/react'

const Page403 = () => (
  <div className="c-app c-default-layout flex-row align-items-center">
    <CContainer>
      <CRow className="justify-content-center">
        <CCol md="8">
          <div className="clearfix">
            <h1 className="float-left display-5 mr-4">403</h1>
            <h3 className="pt-3">Oops! This is forbidden.</h3>
            <p className="text-muted float-left">
              You are not allowed into this page or your token has expired.
            </p>
          </div>
        </CCol>
      </CRow>
    </CContainer>
  </div>
)

export default Page403
