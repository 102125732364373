import React from 'react'
import { withTranslation } from 'react-i18next'

interface Props {
  text?: string
  center?: boolean
}

const EmptyTableRecord: React.FC<Props> = ({ text, center = false }) => (
  <p className={`text-secondary ${center ? 'text-center' : ''}`}> {text ?? '-'} </p>
)

export default withTranslation()(EmptyTableRecord)
