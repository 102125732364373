export const formKeys = {
  username: 'username',
  zones: 'zones',
  sites: 'sites',
  setOperator: 'setOperator',
}

const { username, zones, sites, setOperator } = formKeys

export const initialValues = {
  [username]: '',
  [zones]: null,
  [sites]: '',
  [setOperator]: false,
}
