import {
  GET_ORDER,
  GET_ORDERS,
  SET_ORDER_FLORIST,
  ORDER_INIT,
  GET_FL_NEAR_ORDER,
  GET_ORDER_FLORISTS_PRICES,
  SET_ORDER_STATUS,
  SET_ORDER_DRIVER,
  SET_IMAGES_FEEDBACK,
  SET_IMAGES,
  SET_ORDER_FLORIST_PRICE,
  SET_MAIL_TO_FLORISTS,
} from '../constants/order'

const ruleReducer = (state = ORDER_INIT, action) => {
  switch (action.type) {
    // GET_ORDER
    case GET_ORDER.REQUEST:
      return {
        ...state,
        getOrderRequesting: true,
      }

    case GET_ORDER.SUCCESS:
      return {
        ...state,
        getOrderSuccess: true,
        getOrderRequesting: false,
        detail: action.payload,
      }

    case GET_ORDER.ERROR:
      return {
        ...state,
        getOrderRequesting: false,
        getOrderErrorMessage: action.payload,
      }

    case GET_ORDER.RESET:
      return {
        ...state,
        getOrderRequesting: false,
        getOrderSuccess: false,
        getOrderErrorMessage: '',
      }

    // GET_ORDERS
    case GET_ORDERS.REQUEST:
      return {
        ...state,
        getOrdersRequesting: true,
      }

    case GET_ORDERS.SUCCESS:
      return {
        ...state,
        getOrdersSuccess: true,
        getOrdersRequesting: false,
        list: action.payload,
      }

    case GET_ORDERS.ERROR:
      return {
        ...state,
        getOrdersRequesting: false,
        getOrdersErrorMessage: action.payload,
      }

    case GET_ORDERS.RESET:
      return {
        ...state,
        getOrdersRequesting: false,
        getOrdersSuccess: false,
        getOrdersErrorMessage: '',
      }

    // GET_FL_NEAR_ORDER
    case GET_FL_NEAR_ORDER.REQUEST:
      return {
        ...state,
        getFlNearOrderRequesting: true,
      }

    case GET_FL_NEAR_ORDER.SUCCESS:
      return {
        ...state,
        getFlNearOrderSuccess: true,
        getFlNearOrderRequesting: false,
        florists: action.payload,
      }

    case GET_FL_NEAR_ORDER.ERROR:
      return {
        ...state,
        getFlNearOrderRequesting: false,
        getFlNearOrderErrorMessage: action.payload,
      }

    case GET_FL_NEAR_ORDER.RESET:
      return {
        ...state,
        getFlNearOrderRequesting: false,
        getFlNearOrderSuccess: false,
        getFlNearOrderErrorMessage: '',
      }

    // GET_ORDER_FLORISTS_PRICES
    case GET_ORDER_FLORISTS_PRICES.REQUEST:
      return {
        ...state,
        getOrderFloristsPricesRequesting: true,
      }

    case GET_ORDER_FLORISTS_PRICES.SUCCESS:
      return {
        ...state,
        getOrderFloristsPricesSuccess: true,
        getOrderFloristsPricesRequesting: false,
        prices: action.payload,
      }

    case GET_ORDER_FLORISTS_PRICES.ERROR:
      return {
        ...state,
        getOrderFloristsPricesRequesting: false,
        getOrderFloristsPricesErrorMessage: action.payload,
      }

    case GET_ORDER_FLORISTS_PRICES.RESET:
      return {
        ...state,
        getOrderFloristsPricesRequesting: false,
        getOrderFloristsPricesSuccess: false,
        getOrderFloristsPricesErrorMessage: '',
      }

    // SET_ORDER_FLORIST
    case SET_ORDER_FLORIST.REQUEST:
      return {
        ...state,
        setOrderFloristRequesting: true,
      }

    case SET_ORDER_FLORIST.SUCCESS:
      return {
        ...state,
        setOrderFloristSuccess: true,
        setOrderFloristRequesting: false,
        count: action.payload.count,
      }

    case SET_ORDER_FLORIST.ERROR:
      return {
        ...state,
        setOrderFloristRequesting: false,
        setOrderFloristErrorMessage: action.payload,
      }

    case SET_ORDER_FLORIST.RESET:
      return {
        ...state,
        setOrderFloristRequesting: false,
        setOrderFloristSuccess: false,
        setOrderFloristErrorMessage: '',
      }

    // SET_ORDER_STATUS
    case SET_ORDER_STATUS.REQUEST:
      return {
        ...state,
        setOrderStatusRequesting: true,
      }

    case SET_ORDER_STATUS.SUCCESS:
      return {
        ...state,
        setOrderStatusSuccess: true,
        setOrderStatusRequesting: false,
      }

    case SET_ORDER_STATUS.ERROR:
      return {
        ...state,
        setOrderStatusRequesting: false,
        setOrderStatusErrorMessage: action.payload,
      }

    case SET_ORDER_STATUS.RESET:
      return {
        ...state,
        setOrderStatusRequesting: false,
        setOrderStatusSuccess: false,
        setOrderStatusErrorMessage: '',
      }

    // SET_ORDER_DRIVER
    case SET_ORDER_DRIVER.REQUEST:
      return {
        ...state,
        setOrderDriverRequesting: true,
      }

    case SET_ORDER_DRIVER.SUCCESS:
      return {
        ...state,
        setOrderDriverSuccess: true,
        setOrderDriverRequesting: false,
      }

    case SET_ORDER_DRIVER.ERROR:
      return {
        ...state,
        setOrderDriverRequesting: false,
        setOrderDriverErrorMessage: action.payload,
      }

    case SET_ORDER_DRIVER.RESET:
      return {
        ...state,
        setOrderDriverRequesting: false,
        setOrderDriverSuccess: false,
        setOrderDriverErrorMessage: '',
      }

    // SET_IMAGES_FEEDBACK
    case SET_IMAGES_FEEDBACK.REQUEST:
      return {
        ...state,
        setImagesFeedbackRequesting: true,
      }

    case SET_IMAGES_FEEDBACK.SUCCESS:
      return {
        ...state,
        setImagesFeedbackSuccess: true,
        setImagesFeedbackRequesting: false,
      }

    case SET_IMAGES_FEEDBACK.ERROR:
      return {
        ...state,
        setImagesFeedbackRequesting: false,
        setImagesFeedbackErrorMessage: action.payload,
      }

    case SET_IMAGES_FEEDBACK.RESET:
      return {
        ...state,
        setImagesFeedbackRequesting: false,
        setImagesFeedbackSuccess: false,
        setImagesFeedbackErrorMessage: '',
      }

    // SET_IMAGES
    case SET_IMAGES.REQUEST:
      return {
        ...state,
        setImagesRequesting: true,
      }

    case SET_IMAGES.SUCCESS:
      return {
        ...state,
        setImagesSuccess: true,
        setImagesRequesting: false,
      }

    case SET_IMAGES.ERROR:
      return {
        ...state,
        setImagesRequesting: false,
        setImagesErrorMessage: action.payload,
      }

    case SET_IMAGES.RESET:
      return {
        ...state,
        setImagesRequesting: false,
        setImagesSuccess: false,
        setImagesErrorMessage: '',
      }

    // SET_ORDER_FLORIST_PRICE
    case SET_ORDER_FLORIST_PRICE.REQUEST:
      return {
        ...state,
        setOrderFloristPriceRequesting: true,
      }

    case SET_ORDER_FLORIST_PRICE.SUCCESS:
      return {
        ...state,
        setOrderFloristPriceSuccess: true,
        setOrderFloristPriceRequesting: false,
      }

    case SET_ORDER_FLORIST_PRICE.ERROR:
      return {
        ...state,
        setOrderFloristPriceRequesting: false,
        setOrderFloristPriceErrorMessage: action.payload,
      }

    case SET_ORDER_FLORIST_PRICE.RESET:
      return {
        ...state,
        setOrderFloristPriceRequesting: false,
        setOrderFloristPriceSuccess: false,
        setOrderFloristPriceErrorMessage: '',
      }
    case SET_MAIL_TO_FLORISTS.REQUEST:
      return {
        ...state,
        setMailToFloristsRequesting: true,
      }
    case SET_MAIL_TO_FLORISTS.SUCCESS:
      return {
        ...state,
        setMailToFloristsSuccess: true,
        setMailToFloristsRequesting: false,
      }
    case SET_MAIL_TO_FLORISTS.ERROR:
      return {
        ...state,
        setMailToFloristsRequesting: false,
        setMailToFloristsErrorMessage: action.payload,
      }
    case SET_MAIL_TO_FLORISTS.RESET:
      return {
        ...state,
        setMailToFloristsRequesting: false,
        setMailToFloristsSuccess: false,
        setMailToFloristsErrorMessage: '',
      }
    default:
      return state
  }
}

export default ruleReducer
