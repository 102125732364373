import React, { useState, useEffect, useRef, useCallback } from 'react'

import { withTranslation } from 'react-i18next'

import {
  getPathArray,
  getPolygonBounds,
  getCenterFromPoint,
  getPolygonPath,
  getCenterFromBounds,
} from '@utils/helpers'

import {
  GoogleMap,
  useLoadScript,
  DrawingManager,
  Polygon as EditablePolygon,
  Data,
} from '@react-google-maps/api'

import {
  Polygon,
  Libraries,
  MapContainerSize,
  PolygonPaths,
  MapsEventListener,
  PolygonOptions,
  Map,
  PointCoords,
  LatLngLit,
} from '@utils/interfaces/maps'

import { Ref } from '@utils/interfaces/react'

import styles from '@styles/modules/mapSearch.module.scss'
import { defaultCenter, defaultZoom, mapOptions } from './defaults'

import Search from './PlacesSearch'

const libraries: Libraries = ['places', 'geometry', 'drawing']

interface Props extends MapContainerSize {
  initialPath?: PolygonPaths
  editable?: boolean
  drawable?: boolean
  draggable?: boolean
  data?: any
  initialCenter?: PointCoords
  polygonRef: Ref<google.maps.Polygon | null>
  onPolygonChanged?: () => void
  onCenterChanged?: (center: LatLngLit) => void
}

const PolygonMap: React.FC<Props> = ({
  width = '600px',
  maxWidth = '100%',
  height = '600px',
  data,
  initialPath,
  initialCenter,
  editable = true,
  drawable = false,
  draggable = true,
  polygonRef,
  onPolygonChanged,
  onCenterChanged,
  ...containerStyle
}) => {
  // https://codesandbox.io/s/react-google-maps-api-editing-a-polygon-forked-tzhk9?file=/src/index.js
  const [path, setPath] = useState<PolygonPaths>(initialPath ?? [])
  const [latestDrawing, setLatestDrawing] = useState<Polygon>()

  const [center, setCenter] = useState<LatLngLit>()
  const [polygonOptions, setPolygonOptions] = useState<PolygonOptions>({})

  const hasData = data && data?.coordinates && !!data.coordinates?.length

  const mapRef = useRef<Map | null>(null)
  const listenersRef = useRef<MapsEventListener>([])

  useEffect(() => {
    if (hasData) {
      setPath(getPolygonPath(data))
      if (!editable && !drawable) {
        setPolygonOptions({ fillColor: '#2A1AB9', strokeColor: '#2A1AB9', strokeWeight: 5 })
      }
    }
  }, [data])

  useEffect(() => {
    if (initialCenter && initialCenter?.coordinates) {
      setCenter(getCenterFromPoint(initialCenter))
    }
  }, [path, initialCenter])

  useEffect(() => {
    if (hasData && !!polygonRef.current && !!mapRef.current) {
      const bounds = getPolygonBounds(polygonRef.current)
      if (bounds) {
        mapRef.current.fitBounds(bounds)
        if (!initialCenter) {
          const newCenter = getCenterFromBounds(bounds)
          setCenter(newCenter)
          if (onCenterChanged) onCenterChanged(newCenter)
        }
      } else if (!center) setCenter(defaultCenter)
    } else if (!center) setCenter(defaultCenter)
  }, [path, polygonRef.current, mapRef.current])

  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_KEY ?? ''
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey,
    libraries,
  })

  const panTo = useCallback((position, bounds) => {
    if (mapRef.current) {
      mapRef.current.panTo(position)
      mapRef.current.fitBounds(bounds)
    }
  }, [])

  const getPaths = (polygon) =>
    getPathArray(polygon).map((latLng) => ({ lat: latLng.lat(), lng: latLng.lng() }))

  const onMapLoad = useCallback((map: Map) => {
    mapRef.current = map
  }, [])

  const onEdit = useCallback(() => {
    if (latestDrawing) latestDrawing.setMap(null)

    const currentPolygon = polygonRef.current
    if (currentPolygon) {
      setPath(getPaths(currentPolygon))
      if (onPolygonChanged) onPolygonChanged()
    }
  }, [latestDrawing, setPath])

  const onPolygonLoad = useCallback(
    (polygon: Polygon) => {
      // eslint-disable-next-line no-param-reassign
      polygonRef.current = polygon

      const polyPath = polygon.getPath()
      listenersRef.current.push(
        polyPath.addListener('set_at', onEdit),
        polyPath.addListener('insert_at', onEdit),
        polyPath.addListener('remove_at', onEdit)
      )
    },
    [onEdit]
  )

  const onPolygonComplete = (polygon: Polygon) => {
    if (drawable && polygon) {
      if (latestDrawing) {
        latestDrawing.setMap(null)
      }

      setPath(getPaths(polygon))
      setLatestDrawing(polygon)
      if (onPolygonChanged) onPolygonChanged()
    }
  }

  const onPolygonUnmount = useCallback(() => {
    listenersRef.current.forEach((listener) => listener.remove())
    // eslint-disable-next-line no-param-reassign
    polygonRef.current = null
  }, [])

  if (loadError || !isLoaded) return <p>Error</p>

  return (
    <>
      <div style={{ position: 'relative' }}>
        {!!editable && <Search panTo={panTo} styles={styles.searchContainer} />}

        <GoogleMap
          onLoad={onMapLoad}
          options={mapOptions}
          mapContainerStyle={{ width, height, maxWidth, ...containerStyle }}
          zoom={defaultZoom}
          center={center}
        >
          <EditablePolygon
            editable={editable}
            draggable={editable && draggable}
            path={path}
            onMouseUp={onEdit}
            onDragEnd={onEdit}
            onLoad={onPolygonLoad}
            onUnmount={onPolygonUnmount}
            options={polygonOptions}
          />
          {drawable && (
            <DrawingManager
              onPolygonComplete={onPolygonComplete}
              options={{
                // drawingMode: window.google.maps.drawing.OverlayType.POLYGON,
                drawingControlOptions: {
                  drawingModes: [window.google.maps.drawing.OverlayType.POLYGON],
                  position: google.maps.ControlPosition.RIGHT_TOP,
                },
              }}
            />
          )}
          <Data />
        </GoogleMap>
      </div>
    </>
  )
}

export default withTranslation()(PolygonMap)
