type Day = 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY'

interface Time {
  weekday: number
  startHour: Date
  endHour: Date
}

export type OpenHours = {
  [day in Day as string]: {
    startHour: Date
    endHour: Date
  }
}

export const daysEnum = {
  MONDAY: {
    day: 'MONDAY',
    weekday: 1,
    translationKey: 'monday',
    defaultStartTime: '09:00',
    defaultEndTime: '17:00',
  },
  TUESDAY: {
    day: 'TUESDAY',
    weekday: 2,
    translationKey: 'tuesday',
    defaultStartTime: '09:00',
    defaultEndTime: '17:00',
  },
  WEDNESDAY: {
    day: 'WEDNESDAY',
    weekday: 3,
    translationKey: 'wednesday',
    defaultStartTime: '09:00',
    defaultEndTime: '17:00',
  },
  THURSDAY: {
    day: 'THURSDAY',
    weekday: 4,
    translationKey: 'thursday',
    defaultStartTime: '09:00',
    defaultEndTime: '17:00',
  },
  FRIDAY: {
    day: 'FRIDAY',
    weekday: 5,
    translationKey: 'friday',
    defaultStartTime: '09:00',
    defaultEndTime: '17:00',
  },
  SATURDAY: {
    day: 'SATURDAY',
    weekday: 6,
    translationKey: 'saturday',
    defaultStartTime: '10:00',
    defaultEndTime: '16:00',
  },
  SUNDAY: {
    day: 'SUNDAY',
    weekday: 7,
    translationKey: 'sunday',
    defaultStartTime: '10:00',
    defaultEndTime: '14:00',
  },
  HOLIDAYS: {
    day: 'HOLIDAYS',
    weekday: 8,
    translationKey: 'holidays',
    defaultStartTime: '10:00',
    defaultEndTime: '14:00',
  },
}

export const weekArray = [
  daysEnum.MONDAY,
  daysEnum.TUESDAY,
  daysEnum.WEDNESDAY,
  daysEnum.THURSDAY,
  daysEnum.FRIDAY,
  daysEnum.SATURDAY,
  daysEnum.SUNDAY,
  daysEnum.HOLIDAYS,
]

const findByWeekday = (weekday) =>
  Object.keys(daysEnum).find((key) => daysEnum[key].weekday === weekday)

export const weekdayTranslation = (weekday: number): string => {
  const dayKey = findByWeekday(weekday)
  return dayKey ? daysEnum[dayKey].translationKey ?? '' : ''
}

export const getOpenHoursFromTimes = (times: Time[]): OpenHours =>
  times.reduce((o, dayHours) => {
    const { weekday, startHour, endHour } = dayHours

    const key = findByWeekday(weekday)
    if (key) o[key] = { startHour, endHour }
    return o
  }, {})
