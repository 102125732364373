import { createConstants, initConstant } from './constantsCreator'

const GET_ZONES = createConstants('GET_ZONES')
const GET_COUNTRIES = createConstants('GET_COUNTRIES')
const GET_SITES = createConstants('GET_SITES')
const GET_LINES = createConstants('GET_LINES')
const GET_CATEGORIES = createConstants('GET_CATEGORIES')
const GET_INGREDIENTS_PARENTS = createConstants('GET_INGREDIENTS_PARENTS')
const GET_INGREDIENTS_CHILDREN = createConstants('GET_INGREDIENTS_CHILDREN')
const GET_PRODUCTS = createConstants('GET_PRODUCTS')
const GET_PRODUCT_INGREDIENTS = createConstants('GET_PRODUCT_INGREDIENTS')
const GET_PRODUCT = createConstants('GET_PRODUCT')

const GET_PRODUCT_INGREDIENTS_INIT = {
  ...initConstant(GET_PRODUCT_INGREDIENTS),
  productIngredients: [],
}

const GET_PRODUCTS_INIT = {
  ...initConstant(GET_PRODUCTS),
  products: [],
}

const GET_PRODUCT_INIT = {
  ...initConstant(GET_PRODUCT),
  product: {},
}

const GET_ZONES_INIT = {
  ...initConstant(GET_ZONES),
  zones: [],
}

const GET_COUNTRIES_INIT = {
  ...initConstant(GET_COUNTRIES),
  countries: [],
}

const GET_SITES_INIT = {
  ...initConstant(GET_SITES),
  sites: [],
}

const GET_LINES_INIT = {
  ...initConstant(GET_LINES),
  lines: [],
}

const GET_CATEGORIES_INIT = {
  ...initConstant(GET_CATEGORIES),
  categories: [],
}

const GET_INGREDIENTS_PARENTS_INIT = {
  ...initConstant(GET_INGREDIENTS_PARENTS),
  ingredientsParents: [],
}

const GET_INGREDIENTS_CHILDREN_INIT = {
  ...initConstant(GET_INGREDIENTS_CHILDREN),
  ingredientsChildren: [],
}

const DATA_INIT = {
  ...GET_ZONES_INIT,
  ...GET_COUNTRIES_INIT,
  ...GET_SITES_INIT,
  ...GET_LINES_INIT,
  ...GET_CATEGORIES_INIT,
  ...GET_INGREDIENTS_PARENTS_INIT,
  ...GET_INGREDIENTS_CHILDREN_INIT,
  ...GET_PRODUCTS_INIT,
  ...GET_PRODUCT_INIT,
  ...GET_PRODUCT_INGREDIENTS_INIT,
}

export {
  GET_PRODUCTS,
  GET_PRODUCT,
  GET_ZONES,
  GET_COUNTRIES,
  GET_SITES,
  GET_LINES,
  GET_CATEGORIES,
  GET_INGREDIENTS_PARENTS,
  GET_INGREDIENTS_CHILDREN,
  GET_PRODUCT_INGREDIENTS,
  DATA_INIT,
}
