import { baseService } from '@utils/authRequest'

export const createEntity = async (endpoint, form) => {
  try {
    return await baseService.post(`${endpoint}`, form)
  } catch (e) {
    return e.response?.data.error
  }
}

export const updateEntity = async (endpoint, form) => {
  try {
    return await baseService.put(`${endpoint}`, form)
  } catch (e) {
    return e.response?.data.error
  }
}

export const getEntity = async (endpoint, params) => {
  try {
    return await baseService.get(`${endpoint}`, { params })
  } catch (e) {
    return e.response?.data.error
  }
}

export const getEntityDetail = async (endpoint, id, property = '') => {
  try {
    return await baseService.get(`${endpoint}/${id}/${property}`)
  } catch (e) {
    return e.response?.data.error
  }
}

export const getUsers = async (endpoint, id, params) => {
  try {
    return await baseService.get(`${endpoint}/${id}/users`, { params })
  } catch (e) {
    return e.response?.data.error
  }
}

export const getFloristsByIntermediate = async (id, params) => {
  try {
    return await baseService.get(`intermediates/${id}/florists`, { params })
  } catch (e) {
    return e.response?.data.error
  }
}

export const getEntityPermissions = async (id) => {
  try {
    return await baseService.get(`/permissions/type/${id}`)
  } catch (e) {
    return e.response?.data.error
  }
}
