/* eslint-disable import/no-cycle */
import { groupEnum, permissionEnum } from '@utils/enums/permissionEnums'
import { typeEnum } from '@utils/enums/entityEnums'

import { CContainer, CFooter } from '@coreui/react'
import LanguageSwitcher from '@components/header/LanguageSwitcher'
import Page403 from './pages/page403/Page403'
import Page404 from './pages/page404/Page404'
import Page500 from './pages/page500/Page500'

import Dashboard from './views/dashboard'
import PolygonsABM from './views/polygons/ABM'
import IngredientsABM from './views/ingredients/ABM'
import EntityABM from './views/entities/ABM'
import RuleABM from './views/rules/ABM'
import OrderABM from './views/orders/ABM'
import DriverABM from './views/drivers/ABM'
import UserABM from './views/users/ABM'
import NewEntity from './views/entities/EntityForm/NewEntityForm'
import EditEntity from './views/entities/EntityForm/EditEntityForm'
import EditDriver from './views/drivers/DriverForm/EditDriverForm'
import RuleForm from './views/rules/RuleForm'
import PolygonForm from './views/polygons/PolygonForm'
import PolygonDetail from './views/polygons/PolygonDetail'
import NewUserForm from './views/users/UserForm'
import NewDriverForm from './views/drivers/DriverForm/NewDriverForm'
import EntityDetail from './views/entities/EntityDetails'
import UserDetail from './views/users/UserDetails'
import RuleDetail from './views/rules/RuleDetails'
import OrderDetail from './views/orders/OrderDetails'
import UserPermissions from './views/dashboard/permissions'
import ProductsDashboard from './views/dashboard/products'

import Home from './pages/home'
import Profile from './pages/profile'
import EditProfile from './pages/profile/editProfile'
import ChangePassword from './pages/changePassword'

import Login from './pages/login'
import ForgotPassword from './pages/forgotPassword'
import ResetPassword from './pages/resetPassword'
import IngredientsForm from './views/ingredients/IngredientForm'
import IngredientDetail from './views/ingredients/IngredientDetail'
import AssignIngredientsToProducts from './views/ingredients/AssignIngredientsToProducts'
import ProductsList from './views/ingredients/ProductsList'

const { VIEW, CREATE } = groupEnum

const {
  [VIEW]: { VIEW_OPERATORS, VIEW_DRIVERS, VIEW_PRODUCTS },
  [CREATE]: { CREATE_OPERATOR, CREATE_DRIVER },
} = permissionEnum

const { OWNER, INTERMEDIATE, FLORIST } = typeEnum

function TheContentWrapper(WrappedComponent) {
  return function Wrapper(props: JSX.IntrinsicAttributes) {
    return (
      <div className="c-app c-default-layout">
        <div className="c-wrapper">
          <CFooter fixed={false} style={{ paddingRight: '30px', justifyContent: 'flex-end' }}>
            <LanguageSwitcher />
          </CFooter>
          <div className="c-body">
            <main className="c-main">
              <CContainer fluid>
                <WrappedComponent {...props} />
              </CContainer>
            </main>
          </div>
        </div>
      </div>
    )
  }
}

export const protectedRoutes = [
  { path: '/', exact: true, name: 'Home', component: Home, icon: 'cil-home' },
  { path: '/dashboard', exact: true, name: 'Dashboard', component: Dashboard, hidden: true },
  {
    path: '/dashboard/users',
    exact: true,
    prettyName: 'myOperators',
    name: 'Users',
    icon: 'cil-people',
    component: UserABM,
    types: [OWNER, INTERMEDIATE, FLORIST],
    permissions: [VIEW_OPERATORS],
  },
  {
    path: '/dashboard/itm',
    exact: true,
    name: 'Intermediates',
    icon: 'cil-star',
    component: EntityABM,
    types: [OWNER],
  },
  {
    path: '/dashboard/fl',
    exact: true,
    name: 'Florists',
    icon: 'cil-star',
    component: EntityABM,
    types: [OWNER, INTERMEDIATE],
  },
  {
    path: '/dashboard/orders',
    exact: true,
    name: 'Orders',
    icon: 'cil-bell-exclamation',
    component: OrderABM,
    types: [OWNER, INTERMEDIATE, FLORIST],
  },
  {
    path: '/dashboard/products',
    exact: true,
    name: 'Products',
    component: ProductsDashboard,
    types: [OWNER, FLORIST],
    permissions: [VIEW_PRODUCTS],
  },
  {
    path: '/dashboard/rules',
    exact: true,
    name: 'Rules',
    component: RuleABM,
    types: [OWNER],
    icon: 'cil-lightbulb',
  },
  {
    path: '/dashboard/ingredients',
    exact: true,
    name: 'Ingredients',
    prettyName: 'ingredients',
    icon: 'cil-leaf',
    component: IngredientsABM,
    types: [OWNER],
  },
  {
    path: '/dashboard/ingredients/new',
    exact: true,
    name: 'NewIngredient',
    prettyName: 'new',
    component: IngredientsForm,
    hidden: true,
    types: [OWNER],
  },
  {
    path: '/dashboard/ingredients/products/:id(\\d+)',
    exact: true,
    name: 'AssignIngredientsToProducts',
    prettyName: 'assignToProduct',
    component: AssignIngredientsToProducts,
    hidden: true,
    types: [OWNER],
  },
  /*   {
    path: '/dashboard/products',
    exact: true,
    name: 'Products',
    prettyName: 'products',
    component: ProductsList,
    types: [OWNER],
  }, */
  {
    path: '/dashboard/ingredients/:id(\\d+)',
    name: 'IngredientDetails',
    exact: true,
    prettyName: 'details',
    component: IngredientDetail,
    hidden: true,
    types: [OWNER],
  },
  {
    path: '/dashboard/ingredients/:id(\\d+)/edit',
    name: 'EditIngredient',
    prettyName: 'edit',
    exact: true,
    component: IngredientsForm,
    hidden: true,
    types: [OWNER],
  },
  {
    path: '/dashboard/polygons',
    exact: true,
    name: 'Polygons',
    icon: 'cil-location-pin',
    prettyName: 'polygons',
    component: PolygonsABM,
    types: [OWNER],
  },
  {
    path: '/dashboard/polygons/new',
    name: 'New Polygon',
    prettyName: 'new',
    component: PolygonForm,
    hidden: true,
    types: [OWNER],
  },
  {
    path: '/dashboard/polygons/:id(\\d+)/edit',
    name: 'Edit Polygon',
    prettyName: 'edit',
    component: PolygonForm,
    hidden: true,
    types: [OWNER],
  },
  {
    path: '/dashboard/polygons/:id(\\d+)',
    name: 'Polygon Details',
    prettyName: 'details',
    component: PolygonDetail,
    hidden: true,
    types: [OWNER],
  },
  { path: '/dashboard/:type(itm|fl)/new', name: 'New', component: NewEntity, hidden: true },
  {
    path: '/dashboard/:type(fl)/:id(\\d+)/edit',
    name: 'Edit Entity',
    component: EditEntity,
    prettyName: 'edit',
    hidden: true,
  },
  {
    path: '/dashboard/:type(fl)/:idFlorist(\\d+)/drivers/:id(\\d+)/edit',
    name: 'EditDriver',
    prettyName: 'edit',
    component: EditDriver,
    hidden: true,
  },
  {
    path: '/dashboard/rules/new',
    name: 'New Rule',
    prettyName: 'new',
    component: RuleForm,
    hidden: true,
  },
  {
    path: '/dashboard/fl/new',
    name: 'NewFlorist',
    prettyName: 'new',
    component: NewUserForm,
    hidden: true,
  },
  {
    path: '/dashboard/:type(itm|fl)/:id(\\d+)',
    exact: true,
    name: 'Details',
    component: EntityDetail,
    hidden: true,
  },
  {
    path: '/dashboard/:type(fl)/:id(\\d+)/:tab(drivers)?',
    exact: true,
    name: 'Details',
    component: EntityDetail,
    hidden: true,
  },
  {
    path: '/dashboard/:type(itm)/:id(\\d+)/:tab(florists)?',
    exact: true,
    name: 'Details',
    component: EntityDetail,
    hidden: true,
  },
  {
    path: '/dashboard/rules/:id(\\d+)',
    exact: true,
    prettyName: 'details',
    name: 'Rule Details',
    component: RuleDetail,
    hidden: true,
  },
  {
    path: '/dashboard/orders/:id(\\d+)',
    exact: true,
    prettyName: 'details',
    name: 'Order Details',
    component: OrderDetail,
    hidden: true,
  },
  {
    path: '/dashboard/:type(itm|fl)/:id(\\d+)/new-user',
    name: 'New User',
    prettyName: 'newUser',
    component: NewUserForm,
    hidden: true,
    exact: true,
    types: [OWNER, INTERMEDIATE, FLORIST],
    permissions: [CREATE_OPERATOR],
  },
  {
    path: '/dashboard/:type(fl)/:id(\\d+)/new-driver',
    name: 'New Driver',
    prettyName: 'newDriver',
    component: NewDriverForm,
    hidden: true,
    exact: true,
    types: [OWNER, FLORIST],
    permissions: [CREATE_DRIVER],
  },
  {
    path: '/dashboard/users/new',
    exact: true,
    prettyName: 'new',
    name: 'Add User',
    component: NewUserForm,
    hidden: true,
    types: [OWNER, INTERMEDIATE, FLORIST],
    permissions: [CREATE_OPERATOR],
  },
  {
    path: '/dashboard/drivers',
    exact: true,
    prettyName: 'myDrivers',
    name: 'Drivers',
    icon: 'cil-motorbike',
    component: DriverABM,
    types: [FLORIST],
    permissions: [VIEW_DRIVERS],
  },
  {
    path: '/dashboard/users/:id(\\d+)',
    exact: true,
    prettyName: 'user',
    name: 'User Details',
    component: UserDetail,
    hidden: true,
  },
  {
    path: '/dashboard/:type(fl|itm)/:entityId(\\d+)/users/:id(\\d+)',
    exact: true,
    prettyName: 'user',
    name: 'Entity User Details',
    component: UserDetail,
    hidden: true,
  },
  {
    path: '/dashboard/users/:id(\\d+)/permissions',
    exact: true,
    prettyName: 'permissions',
    name: 'User Permissions',
    component: UserPermissions,
    hidden: true,
  },
  {
    path: '/dashboard/:type(fl|itm)/:entityId(\\d+)/users/:id(\\d+)/permissions',
    prettyName: 'permissions',
    name: 'Entity User Permissions',
    component: UserPermissions,
    hidden: true,
    exact: true,
  },
  {
    path: '/dashboard/:type(fl)/:id(\\d+)/products',
    exact: true,
    hidden: true,
    prettyName: 'productNegotiation',
    name: 'FloristProducts',
    component: ProductsDashboard,
    types: [OWNER],
    permissions: [VIEW_PRODUCTS],
  },
  {
    path: '/profile',
    exact: true,
    name: 'Profile',
    component: Profile,
    hidden: true,
    icon: 'cil-smile',
  },
  { path: '/profile/edit-profile', name: 'Edit Profile', component: EditProfile, hidden: true },
  {
    path: '/profile/change-password',
    name: 'Change Password',
    component: ChangePassword,
    hidden: true,
  },
]

export const publicRoutes = [
  { path: '/404', exact: true, name: 'Page 404', component: Page404 },
  { path: '/403', exact: true, name: 'Page 403', component: Page403 },
  { path: '/500', exact: true, name: 'Page 500', component: Page500 },
  { path: '/login', exact: true, name: 'Login', component: Login },
  { path: '/forgot-password', exact: true, name: 'Forgot Password', component: ForgotPassword },
  {
    path: '/reset-password/:token',
    exact: true,
    name: 'Reset Password',
    component: ResetPassword,
    hidden: true,
  },
  {
    path: '/:type(fl)/new',
    exact: true,
    name: 'Newflorist',
    prettyName: 'newFlorist',
    component: TheContentWrapper(NewEntity),
  },
]

type Route = {
  name: string
  path: string
}

type Routes = {
  [key: string]: string
}

const renderRoutes = (list: Route[]) => {
  const obj: Routes = {}
  list.forEach((route: Route) => {
    const key = route.name.replace(/\s+/g, '')
    const value = route.path.replace(/\([^()]*\)/g, '')
    obj[key] = value
  })
  return obj
}

export const routes = renderRoutes([...protectedRoutes, ...publicRoutes])
export const allRoutes = [...protectedRoutes]
