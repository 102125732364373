import * as Yup from 'yup'
import { EmailTypes } from '../formTypes'

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .matches(EmailTypes?.regex, 'Email is invalid')
    .max(EmailTypes?.maxLength, 'emailMaxLength')
    .required('Email is required'),
})

export default ForgotPasswordSchema
