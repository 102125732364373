import axios from 'axios'
import { store } from '../store'

export const AUTHORIZATION_HEADER = 'Authorization'

export const baseURL = `${process.env.REACT_APP_BACKEND_PROTOCOL}://${process.env.REACT_APP_BACKEND_HOST}:${process.env.REACT_APP_BACKEND_PORT}/`

const apiConnection = {
  baseURL,
  timeout: process.env.TIMEOUT || 10000,
  headers: {
    'content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
}

export const baseService = axios.create(apiConnection)

baseService.interceptors.request.use(
  (config) => {
    const { token } = store.getState().auth
    return {
      ...config,
      headers: {
        ...config.headers,
        ...(token ? { [AUTHORIZATION_HEADER]: token } : {}),
      },
    }
  },
  (err) => Promise.reject(err)
)

baseService.interceptors.response.use(
  (res) => res.data,
  (err) =>
    // Do something with response error
    Promise.reject(err)
)

export const unsetAuthorizationHeader = () => {
  baseService.defaults.headers.common[AUTHORIZATION_HEADER] = null
  delete baseService.defaults.headers.common[AUTHORIZATION_HEADER]
}
