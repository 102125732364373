import { isFunction } from 'lodash'

import {
  createDriver,
  deleteDriver,
  getDrivers,
  getDriverDetail,
  updateDriver,
} from '../services/driver'

import {
  CREATE_DRIVER,
  GET_DRIVERS,
  GET_DRIVER,
  DELETE_DRIVER,
  UPDATE_DRIVER,
} from '../constants/driver'

const createDriverAction = (form) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_DRIVER.REQUEST })
    const { content, success } = await createDriver(form)

    if (success) dispatch({ type: CREATE_DRIVER.SUCCESS, payload: content })
  } catch (e) {
    dispatch({
      type: CREATE_DRIVER.ERROR,
      payload: e.message,
    })
  } finally {
    dispatch({ type: CREATE_DRIVER.RESET })
  }
}

const deleteDriverAction = (driverId, successCallback) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_DRIVER.REQUEST })
    const { content, success } = await deleteDriver(driverId)

    if (success) {
      dispatch({ type: DELETE_DRIVER.SUCCESS, payload: content })
      if (successCallback) successCallback()
    }
  } catch (e) {
    dispatch({
      type: DELETE_DRIVER.ERROR,
      payload: e.message,
    })
  } finally {
    dispatch({ type: DELETE_DRIVER.RESET })
  }
}

const getDriversAction =
  (floristId, setList, { ...params }) =>
  async (dispatch) => {
    try {
      dispatch({ type: GET_DRIVERS.REQUEST })
      const { content, success } = await getDrivers(floristId, params)

      if (success) {
        dispatch({ type: GET_DRIVERS.SUCCESS, payload: content })
        if (isFunction(setList)) setList(content.results)
      }
    } catch (e) {
      dispatch({
        type: GET_DRIVERS.ERROR,
        payload: e.message,
      })
    } finally {
      dispatch({ type: GET_DRIVERS.RESET })
    }
  }

const getDriverAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_DRIVER.REQUEST })
    const { content, success } = await getDriverDetail(id)

    if (success) dispatch({ type: GET_DRIVER.SUCCESS, payload: content })
  } catch (e) {
    dispatch({
      type: GET_DRIVER.ERROR,
      payload: e.message,
    })
  } finally {
    dispatch({ type: GET_DRIVER.RESET })
  }
}

const updateDriverAction = (form) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_DRIVER.REQUEST })
    const { content, success } = await updateDriver(form)

    if (success) dispatch({ type: UPDATE_DRIVER.SUCCESS, payload: content })
  } catch (e) {
    dispatch({
      type: UPDATE_DRIVER.ERROR,
      payload: e.message,
    })
  } finally {
    dispatch({ type: UPDATE_DRIVER.RESET })
  }
}

export {
  createDriverAction,
  getDriversAction,
  getDriverAction,
  deleteDriverAction,
  updateDriverAction,
}
