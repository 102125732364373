import React, { useState, useEffect } from 'react'
import useStateRef from 'react-usestateref'

import { successToast } from '@utils/toast'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import { uploadOrderImage, setOrderImagesAction } from '@actions/order'

import { Modal, Button } from '@components/common'
import Uploader from '@components/common/Uploader'

import translations from '@translations'
import { CCol } from '@coreui/react'
import { OrderModalContext } from './context'

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = WithTranslation & PropsFromRedux

interface UrlData {
  id: string
  url: string
  uid: string
}

const OrderImageModal: React.FC<Props> = ({ setOrderImages, loading, success }) => {
  const { useImageModal, selectedOrderId: orderId } = OrderModalContext()
  const { modalOpen, closeModal } = useImageModal()

  const [clear, setClear] = useState(false)
  const [urls, setUrls, urlsRef] = useStateRef<UrlData[]>([])

  const { uploadImages } = translations.form
  const { uploadOrderImagesSuccess } = translations.toasts

  const handleClose = () => {
    setClear(true)
    closeModal()
  }

  useEffect(() => {
    if (success) {
      handleClose()
      successToast(uploadOrderImagesSuccess, { toastId: 'uploadedOrderImages' })
    }
  }, [success])

  const handleImageUpload = async ({ uid }: { uid: string }, base64: string) => {
    if (base64) {
      const url = await uploadOrderImage(orderId, base64)
      if (url) {
        setUrls([...urlsRef.current, { ...url, uid }])
        return url
      }
    }
    return ''
  }

  const handleRemove = (file: { uid: string }) => {
    const currentList = [...urlsRef.current]
    const index = currentList.findIndex((u) => u.uid === file.uid)

    currentList.splice(index, 1)
    setUrls(currentList)
  }

  const handleSubmit = () => {
    setOrderImages(
      orderId,
      urls.map((u) => u.id)
    )
  }

  return (
    <Modal
      size="lg"
      title={uploadImages}
      open={modalOpen}
      closeModal={handleClose}
      onDismiss={handleClose}
      footerButtons={() => (
        <Button text={uploadImages} onClick={handleSubmit} disabled={loading || !urls?.length} />
      )}
    >
      <CCol xxl={12}>
        <Uploader
          clear={clear}
          setClear={setClear}
          name={`upload-${orderId}`}
          onRemove={handleRemove}
          onUpload={handleImageUpload}
        />
      </CCol>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  success: state.order.setImagesSuccess,
  loading: state.order.setImagesRequesting,
})

const mapDispatchToProps = {
  setOrderImages: setOrderImagesAction,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default compose<React.FC>(withTranslation(), connector)(OrderImageModal)
