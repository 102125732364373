export const statusEnum = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  PENDING: 'PENDING',
  BLOCKED: 'BLOCKED',
}

export const getStatus = (statusId) => {
  switch (statusId) {
    case 0:
      return statusEnum.PENDING
    case !!statusId:
      return statusEnum.ACTIVE
    default:
      return statusEnum.PENDING
  }
}

export const getStatusColor = (status) => {
  switch (status) {
    case statusEnum.ACTIVE:
      return 'success'
    case statusEnum.INACTIVE:
      return 'secondary'
    case statusEnum.PENDING:
      return 'warning'
    case statusEnum.BLOCKED:
      return 'danger'
    default:
      return 'primary'
  }
}
