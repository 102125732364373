import React, { useEffect } from 'react'
import { Formik } from 'formik'
import { withTranslation } from 'react-i18next'

import DriverSchema from '@utils/schemas/driverSchema'

import { NewTextInput, PhoneInput, Button } from '@components/common/index'

import { CRow } from '@coreui/react'
import translations from '@translations'
import { initialValues, formKeys } from './formKeys'

const DriverForm = ({ onSubmit, onFormChange, prevValues }) => {
  const { name, lastName, identification, numberSearch } = translations.form
  const { update, create } = translations.generic

  if (prevValues) {
    initialValues.firstName = prevValues.name
    initialValues.lastName = prevValues.lastname
    initialValues.phoneNumber = prevValues.phone
    initialValues.identification = prevValues.identification
  } else {
    initialValues.firstName = ''
    initialValues.lastName = ''
    initialValues.phoneNumber = ''
    initialValues.identification = ''
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={DriverSchema}
      onSubmit={(values) => {
        onSubmit(values)
      }}
    >
      {({
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        values,
        errors,
        touched,
        dirty,
      }) => {
        useEffect(() => {
          if (!!onFormChange && dirty) {
            onFormChange(values)
          }
        }, [values])

        return (
          <>
            <NewTextInput
              icon="cil-user"
              value={values[formKeys.firstName]}
              placeholder={name}
              name={formKeys.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched[formKeys.firstName] &&
                errors[formKeys.firstName] &&
                errors[formKeys.firstName]
              }
            />

            <NewTextInput
              icon="cil-user"
              value={values[formKeys.lastName]}
              placeholder={lastName}
              name={formKeys.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched[formKeys.lastName] && errors[formKeys.lastName] && errors[formKeys.lastName]
              }
            />

            <PhoneInput
              name={formKeys.phoneNumber}
              value={values.phoneNumber}
              onChange={(val) => setFieldValue(formKeys.phoneNumber, val)}
              onBlur={handleBlur}
              searchPlaceholder={numberSearch}
              error={!!errors.phoneNumber && touched.phoneNumber && errors.phoneNumber}
            />

            <NewTextInput
              icon="cil-user"
              value={values[formKeys.identification]}
              placeholder={identification}
              name={formKeys.identification}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched[formKeys.identification] &&
                errors[formKeys.identification] &&
                errors[formKeys.identification]
              }
            />

            {onSubmit && (
              <CRow className="justify-content-end">
                <Button
                  color="primary"
                  className="px-4"
                  disabled={!dirty || !!Object.keys(errors).length}
                  onClick={handleSubmit}
                  text={prevValues ? update : create}
                  type="submit"
                />
              </CRow>
            )}
          </>
        )
      }}
    </Formik>
  )
}

DriverForm.defaultProps = {
  onFormChange: null,
}

export default withTranslation()(DriverForm)
