import React, { useEffect } from 'react'
import { Redirect, useHistory } from 'react-router-dom'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { successToast } from '@utils/toast'

import translations from '@translations'

import { validateTokenAction, changePasswordAction } from '@actions/auth'
import PasswordForm from '@forms/passwordForm'

import { CCard, CCardBody, CCardGroup, CCol, CContainer, CRow } from '@coreui/react'
import { routes } from '@/routes'

const ResetPassword = ({ match, validateError, validateToken, changePassword, changeSuccess }) => {
  const { token } = match.params

  const { setPassword } = translations.form

  const { changePasswordTitle } = translations.generic

  const history = useHistory()

  useEffect(() => {
    validateToken(token)
  }, [])

  useEffect(() => {
    if (changeSuccess) {
      successToast('changePasswordSuccess', { toastId: 'PasswordChanged' })
      history.push(routes.Login)
    }
  }, [changeSuccess])

  const onPasswordChange = (values) => {
    changePassword({
      newPassword: values.password,
      passwordToken: token,
    })
  }

  return (
    <>
      {validateError ? (
        <Redirect to={routes.Page403} />
      ) : (
        <div className="c-app c-default-layout flex-row align-items-center">
          <CContainer>
            <CRow className="justify-content-center">
              <CCol md="8">
                <CCardGroup>
                  <CCard className="p-4">
                    <CCardBody>
                      <h1>{setPassword}</h1>
                      <p className="text-muted">{changePasswordTitle}</p>

                      <PasswordForm onSubmit={onPasswordChange} />
                    </CCardBody>
                  </CCard>
                </CCardGroup>
              </CCol>
            </CRow>
          </CContainer>
        </div>
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  requesting: state.auth.validateTokenRequesting,
  validateError: state.auth.validateTokenErrorMessage,
  changeSuccess: state.auth.changeSuccess,
})

const mapDispatchToProps = {
  validateToken: validateTokenAction,
  changePassword: changePasswordAction,
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(ResetPassword)
