export const EmailTypes = {
  regex: /^[~!$%^&*_=+'?\-.\w]+@[^@\sÀ-ÿ]+\.[a-zA-Z]+(\.[a-zA-Z]+)*$/,
  autoCapitalize: 'none',
  keyboardType: 'email-address',
  maxLength: 320,
}

export const PasswordTypes = {
  regex: /^(?=.*[a-z])[\w]{1,}$/,
  minLength: 1,
  maxLength: 40,
  autoCapitalize: 'none',
  secureTextEntry: true,
}
/// ^(?=.*\d)(?=.*[a-zA-Z])[\w~#:@$!*?&\-.+]{8,}$/

/* export const NameTypes = {
    regex: /^( )*[a-zÀ-ÿ,.'´-]+( [a-zÀ-ÿ,.'´-]+)*( )*$/i,
    maxLength: 24,
    minLength: 2,
    autoCapitalize: 'words',
}; */

export const PhoneTypes = {
  regex:
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
}
