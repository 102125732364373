import React from 'react'
import { CCol } from '@coreui/react'

import Offers from './offers'
import OrderImages from './orderImages'

const Dashboard = () => (
  <CCol xl={12}>
    <Offers />
    <OrderImages />
  </CCol>
)

export default Dashboard
