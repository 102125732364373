export const approvalStatusEnum = {
  ACCEPT: 'ACCEPT',
  REJECT: 'REJECT',
}

export const approvalStatusDataEnum = {
  [approvalStatusEnum.ACCEPT]: {
    name: 'accept',
    color: 'success',
    icon: 'cil-thumb-up',
  },
  [approvalStatusEnum.REJECT]: {
    name: 'reject',
    color: 'danger',
    icon: 'cil-thumb-down',
  },
}
