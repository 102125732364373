import { createConstants, initConstant } from './constantsCreator'

const START_OFFER = createConstants('START_OFFER')
const SEND_OFFER = createConstants('SEND_OFFER')
const GET_PRODUCTS = createConstants('GET_PRODUCTS')
const GET_DASH_OFFERS = createConstants('GET_DASH_OFFERS')

const GET_PRODUCTS_INIT = {
  ...initConstant(GET_PRODUCTS),
  products: [],
}

const GET_DASH_OFFERS_INIT = {
  ...initConstant(GET_DASH_OFFERS),
  dashboard: [],
}

const OFFER_INIT = {
  ...GET_PRODUCTS_INIT,
  ...GET_DASH_OFFERS_INIT,
  ...initConstant(START_OFFER),
  ...initConstant(SEND_OFFER),
}

export { START_OFFER, SEND_OFFER, GET_PRODUCTS, GET_DASH_OFFERS, OFFER_INIT }
