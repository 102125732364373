/* eslint-disable no-debugger */
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { compose } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'

import translations from '@translations'
import { withRouteParams } from '@utils/helpers'

import { getRuleListAction, updateRulesOrderAction, deleteRuleAction } from '@actions/rule'

import { TableButtons } from '@utils/components/tableActions'
import { Button, Card } from '@components/common/index'
import DraggableSorterTable from '@components/common/Tables/PaginatedTable'

import { FixedType, ObjectWithoutKey as Data } from '@utils/interfaces/react'

import { CRow, CCol } from '@coreui/react'
import { isArray } from 'lodash'
import { routes } from '@/routes'

interface Rule {
  id: string
}

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = WithTranslation & PropsFromRedux

const RuleABM: React.FC<Props> = ({ getRules, rules, loading, updateOrder, deleteRule }) => {
  const history = useHistory()

  const { newRule } = translations.form

  const { detailsTitle, deleteTitle } = translations.generic

  const [ruleList, setRuleList] = useState<Rule[]>([])

  const initialRequest = () => {
    getRules()
  }

  const goToDetails = (id) => {
    history.push(withRouteParams(routes.RuleDetails, { id }))
  }

  const onRuleDelete = (id) => {
    deleteRule(id, () => {
      const currentList = [...ruleList]
      const index = currentList.findIndex((rule) => rule.id === id)

      currentList.splice(index, 1)
      setRuleList(currentList)
    })
  }

  const RuleActions = ({ item }: { item: any }) => (
    <TableButtons
      buttons={[
        {
          onClick: () => goToDetails(item.id),
          icon: 'cil-description',
          text: detailsTitle,
        },
        {
          onClick: () => onRuleDelete(item.id),
          icon: 'cil-x',
          color: 'danger',
          text: deleteTitle,
        },
      ]}
    />
  )

  useEffect(() => {
    initialRequest()
  }, [])

  useEffect(() => {
    if (rules && isArray(rules)) setRuleList(rules as Rule[])
  }, [rules])

  const goToNewRule = () => {
    history.push(routes.NewRule)
  }

  return (
    <CCol xl={12}>
      <CRow className="d-flex justify-content-end">
        <Button onClick={goToNewRule} color="primary" icon="cil-plus-circle" text={newRule} />
      </CRow>

      <CRow>
        <Card className="my-3">
          <DraggableSorterTable
            data={ruleList as any as Data[]}
            loading={loading}
            customFields={[
              {
                title: '',
                width: '10%',
                key: 'actions',
                dataIndex: 'actions',
                fixed: 'left' as FixedType,
                sorter: false,
                render: (_, item) => <RuleActions item={item} />,
              },
            ]}
            pagination={false}
            fieldsOrder={{ actions: 0 }}
            draggable={{ onOrderChange: updateOrder, indexKey: 'order' }}
          />
        </Card>
      </CRow>
    </CCol>
  )
}

const mapStateToProps = (state) => ({
  rules: state.rule.list,
  loading: state.rule.getRulesRequesting,
})

const mapDispatchToProps = {
  getRules: getRuleListAction,
  updateOrder: updateRulesOrderAction,
  deleteRule: deleteRuleAction,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default compose(withTranslation(), connector)(RuleABM)
