import { combineReducers } from 'redux'
import { loadingBarReducer } from 'react-redux-loading-bar'

import auth from './auth'
import data from './data'
import generic from './generic'
import user from './user'
import entity from './entity'
import driver from './driver'
import rule from './rule'
import offer from './offer'
import order from './order'
import polygon from './polygon'
import ingredient from './ingredient'

export const rootObject = {
  loadingBar: loadingBarReducer,
  auth,
  generic,
  user,
  driver,
  data,
  entity,
  rule,
  offer,
  order,
  polygon,
  ingredient,
}

const rootReducer = combineReducers(rootObject)

export default rootReducer
