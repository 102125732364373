import { isFunction } from 'lodash'

import {
  createEntity,
  getEntity,
  getEntityDetail,
  updateEntity,
  getEntityPermissions,
  getUsers,
  getFloristsByIntermediate,
} from '../services/entity'

import {
  entityEnum,
  propertiesEnum,
  typeEnum,
  entityConstants,
  GET_FLORISTS_BY_INTERMEDIATE,
  GET_ZONE,
} from '../constants/entity'

const createEntityAction = (type, form) => async (dispatch) => {
  const endpoint = entityEnum[type]
  try {
    dispatch({ type: entityConstants.create[endpoint].REQUEST })
    const { content, success } = await createEntity(endpoint, form)

    if (success && content?.id) {
      dispatch({
        type: entityConstants.create[endpoint].SUCCESS,
        payload: content,
      })
    }
  } catch (e) {
    dispatch({
      type: entityConstants.create[endpoint].ERROR,
      payload: e.message,
    })
  } finally {
    dispatch({ type: entityConstants.create[endpoint].RESET })
  }
}

const getEntityListAction =
  (type, { setList, ...params }) =>
  async (dispatch) => {
    const endpoint = entityEnum[type]
    try {
      dispatch({ type: entityConstants.get[endpoint].REQUEST })
      const { content, success } = await getEntity(endpoint, params)

      if (success) {
        dispatch({
          type: entityConstants.get[endpoint].SUCCESS,
          payload: content,
        })

        if (isFunction(setList)) setList(content.results, content.next)
      }
    } catch (e) {
      dispatch({
        type: entityConstants.get[endpoint].ERROR,
        payload: e.message,
      })
    } finally {
      dispatch({ type: entityConstants.get[endpoint].RESET })
    }
  }

const getEntityAction = (type, id) => async (dispatch) => {
  const endpoint = entityEnum[type]
  try {
    dispatch({ type: entityConstants.getDetail[endpoint].REQUEST })
    const { content, success } = await getEntityDetail(endpoint, id)

    if (success)
      dispatch({
        type: entityConstants.getDetail[endpoint].SUCCESS,
        payload: content,
      })
  } catch (e) {
    dispatch({
      type: entityConstants.getDetail[endpoint].ERROR,
      payload: e.message,
    })
  } finally {
    dispatch({ type: entityConstants.getDetail[endpoint].RESET })
  }
}

const getFloristMainZoneAction = (id) => async (dispatch) => {
  const endpoint = entityEnum.fl
  const property = propertiesEnum.GET_ZONE

  try {
    dispatch({ type: GET_ZONE.REQUEST })
    const { content, success } = await getEntityDetail(endpoint, id, property)

    if (success)
      dispatch({
        type: GET_ZONE.SUCCESS,
        payload: content,
      })
  } catch (e) {
    dispatch({
      type: GET_ZONE.ERROR,
      payload: e.message,
    })
  } finally {
    dispatch({ type: GET_ZONE.RESET })
  }
}

const clearFloristMainZoneAction = () => ({
  type: GET_ZONE.SUCCESS,
  payload: null,
})

const updateEntityAction = (type, form) => async (dispatch) => {
  const endpoint = entityEnum[type]
  try {
    dispatch({ type: entityConstants.update[endpoint].REQUEST })
    const { content, success } = await updateEntity(endpoint, form)

    if (success)
      dispatch({
        type: entityConstants.update[endpoint].SUCCESS,
        payload: content,
      })
  } catch (e) {
    dispatch({
      type: entityConstants.update[endpoint].ERROR,
      payload: e.message,
    })
  } finally {
    dispatch({ type: entityConstants.update[endpoint].RESET })
  }
}

const getEntityUsersAction =
  (type, { id, setList, ...params }) =>
  async (dispatch) => {
    const endpoint = entityEnum[type]
    try {
      dispatch({ type: entityConstants.getUsers[endpoint].REQUEST })
      const { content, success } = await getUsers(endpoint, id, params)

      if (success)
        dispatch({
          type: entityConstants.getUsers[endpoint].SUCCESS,
          payload: content,
        })
      if (isFunction(setList)) setList(content.results)
    } catch (e) {
      dispatch({
        type: entityConstants.getUsers[endpoint].ERROR,
        payload: e.message,
      })
    } finally {
      dispatch({ type: entityConstants.getUsers[endpoint].RESET })
    }
  }

const getPermissionsByTypeAction = (typeId) => async (dispatch) => {
  const endpoint = typeEnum[typeId]
  try {
    dispatch({ type: entityConstants.getPermissions[endpoint].REQUEST })
    const { content, success } = await getEntityPermissions(typeId)

    if (success)
      dispatch({
        type: entityConstants.getPermissions[endpoint].SUCCESS,
        payload: content,
      })
  } catch (e) {
    dispatch({
      type: entityConstants.getPermissions[endpoint].ERROR,
      payload: e.message,
    })
  } finally {
    dispatch({ type: entityConstants.getPermissions[endpoint].RESET })
  }
}

const getFloristsByIntermediateAction =
  ({ id, setList, ...params }) =>
  async (dispatch) => {
    try {
      dispatch({ type: GET_FLORISTS_BY_INTERMEDIATE.REQUEST })
      const { content, success } = await getFloristsByIntermediate(id, params)

      if (success) {
        dispatch({
          type: GET_FLORISTS_BY_INTERMEDIATE.SUCCESS,
          payload: content,
        })
        if (isFunction(setList)) setList(content.results)
      }
    } catch (e) {
      dispatch({
        type: GET_FLORISTS_BY_INTERMEDIATE.ERROR,
        payload: e.message,
      })
    } finally {
      dispatch({ type: GET_FLORISTS_BY_INTERMEDIATE.RESET })
    }
  }

export {
  createEntityAction,
  getEntityListAction,
  getEntityUsersAction,
  getEntityAction,
  updateEntityAction,
  getPermissionsByTypeAction,
  getFloristsByIntermediateAction,
  getFloristMainZoneAction,
  clearFloristMainZoneAction,
}
