import React, { useEffect, useState } from 'react'
import { useHistory, RouteComponentProps } from 'react-router-dom'

import { compose } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { withTranslation, WithTranslation } from 'react-i18next'

import { getEntityListAction, getFloristsByIntermediateAction } from '@actions/entity'

import { Button, Card } from '@components/common/index'
import RenderPhoneNumber from '@components/RenderPhoneNumber'
import { TableBadge, TableButtons } from '@utils/components/tableActions'
import PaginatedTable from '@components/common/Tables/PaginatedTable'

import { withRouteParams } from '@utils/helpers'
import translations from '@translations'

import { CCol, CRow } from '@coreui/react'

import { routes } from '@/routes'

type PropsFromRedux = ConnectedProps<typeof connector>

type Props = WithTranslation & PropsFromRedux & RouteComponentProps

const EntityABM: React.FC<Props> = ({
  match,
  location,
  getList,
  getFlorists,
  entities,
  getIntermediatesRequesting,
  getFloristsRequesting,
  userInfo,
  currentIntermediate,
}) => {
  const history = useHistory()

  const { newIntermediate, newFlorist, newUser } = translations.form

  const { detailsTitle } = translations.generic

  const [entityList, setEntityList] = useState([])

  const entityType = match.url.split('/').pop() || ''
  const isIntermediate = entityType === 'itm'

  const intermediateId = userInfo?.typeId === 2 ? currentIntermediate?.id : -1

  const queryPage = location.search.match(/page=([0-9]+)/)
  const currentPage = Number((queryPage && queryPage[1]) ?? 1)

  const requesting = getIntermediatesRequesting || getFloristsRequesting

  const initialRequest = (overrides = {}) => {
    const requestProps = {
      page: currentPage,
      setList: setEntityList,
      ...overrides,
    }

    if (intermediateId !== -1) {
      getFlorists({ id: intermediateId, ...requestProps })
    } else getList(entityType, requestProps)
  }

  useEffect(() => {
    initialRequest()
  }, [currentPage])

  const onPageChange = (newPage) => {
    if (currentPage !== newPage) history.push(`/dashboard/${entityType}?page=${newPage}`)
  }

  const onSearchChange = (queries, isClear) => {
    if (isClear) initialRequest()
    else initialRequest(queries)
  }

  const goToNewUser = (id) => {
    history.push(withRouteParams(routes.NewUser, { type: entityType, id }))
  }

  const goToEditEntity = (id) => {
    history.push(withRouteParams(routes.EditEntity, { type: entityType, id }))
  }

  const goToNewEntity = () => {
    history.push(withRouteParams(routes.New, { type: entityType }))
  }

  const goToDetails = (id) => {
    history.push(withRouteParams(routes.Details, { type: entityType, id }))
  }

  const customFields = [
    {
      dataIndex: 'entity_actions',
      key: 'entity_actions',
      title: '',
      width: '10%',
      fixed: true,
      sorter: false,
      render: (_, item) => (
        <TableButtons
          buttons={[
            {
              onClick: () => goToDetails(item.id),
              icon: 'cil-description',
              text: detailsTitle,
            },
            {
              onClick: () => goToNewUser(item.id),
              icon: 'cil-user-follow',
              color: 'success',
              text: newUser,
            },
            ...(!isIntermediate
              ? [
                  {
                    onClick: () => goToEditEntity(item.id),
                    icon: 'cil-pencil',
                    text: 'Edit',
                  },
                ]
              : []),
          ]}
        />
      ),
    },
  ]

  return (
    <CCol xl={12}>
      <CRow className="d-flex justify-content-end">
        <Button
          onClick={goToNewEntity}
          color="primary"
          icon="cil-plus-circle"
          text={isIntermediate ? newIntermediate : newFlorist}
        />
      </CRow>

      <CRow>
        <Card className="my-3">
          <PaginatedTable
            onSearchChange={onSearchChange}
            queryFields={entities?.queryFields}
            loading={requesting}
            data={entityList || []}
            customFields={customFields}
            pagination={{ current: currentPage, total: entities?.count }}
            onPageChange={onPageChange}
            fixed={['username']}
            fieldsOrder={{ entity_actions: 0 }}
            render={{
              active: {
                render: (_, item) => <TableBadge statusId={item.active} />,
              },
              ...(!isIntermediate && {
                phone: {
                  render: (_, item) => <RenderPhoneNumber florist={item} />,
                },
              }),
            }}
          />
        </Card>
      </CRow>
    </CCol>
  )
}

const mapStateToProps = (state) => ({
  entities: state.entity.list,
  getIntermediatesRequesting: state.entity.getIntermediatesRequesting,
  getFloristsRequesting: state.entity.getFloristsRequesting,
  userInfo: state.auth.user,
  currentIntermediate: state.auth.currentIntermediate,
})

const mapDispatchToProps = {
  getList: getEntityListAction,
  getFlorists: getFloristsByIntermediateAction,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default compose(withTranslation(), connector)(EntityABM)
