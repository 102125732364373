import {
  USERS_INIT,
  ADD_USER_INTERMEDIATE,
  ADD_USER_FLORIST,
  DELETE_USER_INTERMEDIATE,
  DELETE_USER_FLORIST,
  GET_USER_PERMISSIONS,
  UPDATE_USER_PERMISSIONS,
  UPDATE_USER,
  GET_USER,
} from '../constants/user'

const userReducer = (state = USERS_INIT, action) => {
  switch (action.type) {
    // ADD_USER_INTERMEDIATE
    case ADD_USER_INTERMEDIATE.REQUEST:
      return {
        ...state,
        addUserIntermediateRequesting: true,
      }

    case ADD_USER_INTERMEDIATE.SUCCESS:
      return {
        ...state,
        addUserIntermediateSuccess: true,
        addUserIntermediateRequesting: false,
        created: action.payload,
      }

    case ADD_USER_INTERMEDIATE.ERROR:
      return {
        ...state,
        addUserIntermediateRequesting: false,
        addUserIntermediateErrorMessage: action.payload,
      }

    case ADD_USER_INTERMEDIATE.RESET:
      return {
        ...state,
        addUserIntermediateRequesting: false,
        addUserIntermediateSuccess: false,
        addUserIntermediateErrorMessage: '',
      }

    // ADD_USER_FLORIST
    case ADD_USER_FLORIST.REQUEST:
      return {
        ...state,
        addUserFloristRequesting: true,
      }

    case ADD_USER_FLORIST.SUCCESS:
      return {
        ...state,
        addUserFloristSuccess: true,
        addUserFloristRequesting: false,
        created: action.payload,
      }

    case ADD_USER_FLORIST.ERROR:
      return {
        ...state,
        addUserFloristRequesting: false,
        addUserFloristErrorMessage: action.payload,
      }

    case ADD_USER_FLORIST.RESET:
      return {
        ...state,
        addUserFloristRequesting: false,
        addUserFloristSuccess: false,
        addUserFloristErrorMessage: '',
      }
    // DELETE_USER_INTERMEDIATE
    case DELETE_USER_INTERMEDIATE.REQUEST:
      return {
        ...state,
        deleteUserIntermediateRequesting: true,
      }

    case DELETE_USER_INTERMEDIATE.SUCCESS:
      return {
        ...state,
        deleteUserIntermediateSuccess: true, // same
        deleteUserIntermediateRequesting: false,
      }

    case DELETE_USER_INTERMEDIATE.ERROR:
      return {
        ...state,
        deleteUserIntermediateRequesting: false,
        deleteUserIntermediateErrorMessage: action.payload,
      }

    case DELETE_USER_INTERMEDIATE.RESET:
      return {
        ...state,
        deleteUserIntermediateRequesting: false,
        deleteUserIntermediateSuccess: false,
        deleteUserIntermediateErrorMessage: '',
      }

    // DELETE_USER_FLORIST
    case DELETE_USER_FLORIST.REQUEST:
      return {
        ...state,
        deleteUserFloristRequesting: true,
      }

    case DELETE_USER_FLORIST.SUCCESS:
      return {
        ...state,
        deleteUserFloristSuccess: true, // aca acrualizo la lista
        deleteUserFloristRequesting: false,
      }

    case DELETE_USER_FLORIST.ERROR:
      return {
        ...state,
        deleteUserFloristRequesting: false,
        deleteUserFloristErrorMessage: action.payload,
      }

    case DELETE_USER_FLORIST.RESET:
      return {
        ...state,
        deleteUserFloristRequesting: false,
        deleteUserFloristSuccess: false,
        deleteUserFloristErrorMessage: '',
      }

    // GET_USER_PERMISSIONS
    case GET_USER_PERMISSIONS.REQUEST:
      return {
        ...state,
        getUserPermissionsRequesting: true,
      }

    case GET_USER_PERMISSIONS.SUCCESS:
      return {
        ...state,
        getUserPermissionsSuccess: true,
        getUserPermissionsRequesting: false,
        userPermissions: action.payload,
      }

    case GET_USER_PERMISSIONS.ERROR:
      return {
        ...state,
        getUserPermissionsRequesting: false,
        getUserPermissionsErrorMessage: action.payload,
      }

    case GET_USER_PERMISSIONS.RESET:
      return {
        ...state,
        getUserPermissionsRequesting: false,
        getUserPermissionsSuccess: false,
        getUserPermissionsErrorMessage: '',
      }

    // UPDATE_USER_PERMISSIONS
    case UPDATE_USER_PERMISSIONS.REQUEST:
      return {
        ...state,
        updateUserPermissionsRequesting: true,
      }

    case UPDATE_USER_PERMISSIONS.SUCCESS:
      return {
        ...state,
        updateUserPermissionsSuccess: true,
        updateUserPermissionsRequesting: false,
      }

    case UPDATE_USER_PERMISSIONS.ERROR:
      return {
        ...state,
        updateUserPermissionsRequesting: false,
        updateUserPermissionsErrorMessage: action.payload,
      }

    case UPDATE_USER_PERMISSIONS.RESET:
      return {
        ...state,
        updateUserPermissionsRequesting: false,
        updateUserPermissionsSuccess: false,
        updateUserPermissionsErrorMessage: '',
      }

    // UPDATE_USER
    case UPDATE_USER.REQUEST:
      return {
        ...state,
        updateUserRequesting: true,
      }

    case UPDATE_USER.SUCCESS:
      return {
        ...state,
        updateUserSuccess: true,
        updateUserRequesting: false,
      }

    case UPDATE_USER.ERROR:
      return {
        ...state,
        updateUserRequesting: false,
        updateUserErrorMessage: action.payload,
      }

    case UPDATE_USER.RESET:
      return {
        ...state,
        updateUserRequesting: false,
        updateUserSuccess: false,
        updateUserErrorMessage: '',
      }

    // GET_USER
    case GET_USER.REQUEST:
      return {
        ...state,
        getUserRequesting: true,
      }

    case GET_USER.SUCCESS:
      return {
        ...state,
        getUserSuccess: true,
        getUserRequesting: false,
        userDetail: action.payload,
      }

    case GET_USER.ERROR:
      return {
        ...state,
        getUserRequesting: false,
        getUserErrorMessage: action.payload,
      }

    case GET_USER.RESET:
      return {
        ...state,
        getUserRequesting: false,
        getUserSuccess: false,
        getUserErrorMessage: '',
      }

    default:
      return state
  }
}

export default userReducer
