/* eslint-disable react/button-has-type */
import React from 'react'

interface Props {
  expanded: boolean
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}
const ExpandIcon: React.FC<Props> = ({ expanded, onClick }) => (
  <button
    aria-label={`${expanded ? 'Collapse' : 'Expand'} row`}
    onClick={onClick}
    style={{ fontSize: '16px' }}
    className={`ant-table-row-expand-icon ant-table-row-expand-icon-${
      expanded ? 'expanded' : 'collapsed'
    }`}
  />
)

export default ExpandIcon
