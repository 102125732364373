import React, { useEffect } from 'react'
import useStateRef from 'react-usestateref'

import { useHistory } from 'react-router-dom'

import { compose } from 'redux'
import { connect } from 'react-redux'

import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'

import { withTranslation } from 'react-i18next'
import translations from '@translations'

import { isArray } from 'lodash'

import { withRouteParams } from '@utils/helpers'

import { getFlNearOrderAction, setStatusOrderAction } from '@actions/order'

import PaginatedTable from '@components/common/Tables/PaginatedTable'
import { TableButtons } from '@utils/components/tableActions'
import EmptyRecord from '@utils/components/EmptyTableRender'
import ExpandIcon from '@utils/components/ExpandIcon'

import { Card, Badge, Button } from '@components/common'

import { filterEnum, filterDataEnum } from '@utils/enums/orderEnums'

import { getDriversAction } from '@actions/driver'

import { getAddressFromLatLng } from '@utils/reverseGeocode'
import {
  getCurrentStatus,
  getOrderChildActions,
  getOrderStatusChip,
  useStatusButtons,
} from '../helpers'
import { routes } from '@/routes'

import { OrderModalContext } from './components/context'
import OrderProductsList from './components/OrderProductsList'
import OrderAddressMap from './components/OrderAddressMap'

const OrderList = ({
  data,
  currentPage,
  userInfo,
  loading,
  orders,
  onPageChange,
  onSearchChange,
  setDriverList,
}) => {
  const history = useHistory()
  dayjs.extend(LocalizedFormat)

  const imFlorist = userInfo?.type?.id === 3

  const [addressList, setAddressList, addressListRef] = useStateRef({})

  useEffect(() => {
    if (!!data && isArray(data) && data.length) {
      data.forEach(async ({ id, lat, lng }) => {
        const addressData = await getAddressFromLatLng({ lat, lng })

        if (addressData) {
          const { address, bounds } = addressData
          setAddressList({ ...addressListRef.current, [id]: { address, bounds } })
        }
      })
    }
  }, [data])

  const {
    setUploadedImages,
    useImagesFeedbackModal,
    useDriverModal,
    setSelectedOrderId,
    useImageModal,
    useFloristModal,
  } = OrderModalContext()

  const { openModal: openImagesFeedbackModal } = useImagesFeedbackModal()
  const { openModal: openDriverModal } = useDriverModal()
  const { openModal: openImageModal } = useImageModal()
  const { openModal: openFloristModal } = useFloristModal()

  const { viewImages, noImages } = translations.form

  const { orderActions, noMessage, wait, noAvailableActions, detailsTitle, addressDisabled } =
    translations.generic

  const { address: addressTranslation, itemCount } = translations.table

  const { count: total, queryFields } = orders ?? { count: 1, queryFields: [] }

  const viewUploadedImages = ({ images, florist: { username }, id }) => {
    setUploadedImages({ images, username, id })
    openImagesFeedbackModal()
  }

  const goToFlorist = (id) => {
    if (id) history.push(withRouteParams(routes.Details, { type: 'fl', id }))
  }

  const goToDetails = (id) => {
    if (id) history.push(withRouteParams(routes.OrderDetails, { id }))
  }

  const currentStatusRender = {
    ...(imFlorist && {
      [filterEnum.IMAGE_UPLOADED]: ({ images, florist, id }) => {
        if (!images.length) return <EmptyRecord text={noImages} />

        return (
          <Button
            icon="cil-eye"
            small
            text={images.length}
            color="secondary"
            tooltip={viewImages}
            onClick={() => viewUploadedImages({ images, florist, id })}
            className="mb-0 h5"
          />
        )
      },
    }),
  }

  const OrderActions = ({ item }) => {
    const currentStatus = getCurrentStatus(item)
    const StatusRender = currentStatusRender[currentStatus]
    const statusData = filterDataEnum[currentStatus]

    const actions = getOrderChildActions(
      item,
      useStatusButtons({
        setDriverList,
        translations: translations.form,
        setSelectedOrderId,
        openDriverModal,
        openImageModal,
        openFloristModal,
      }),
      userInfo
    )

    if (!actions?.length && !StatusRender) {
      if (!statusData) return null
      return (
        <EmptyRecord
          text={noAvailableActions({
            status: translations.form[statusData.name] || wait.toLocaleLowerCase(),
          })}
        />
      )
    }

    return (
      <>
        {StatusRender && <StatusRender {...item} />}
        {!!actions && !!actions.length && (
          <TableButtons
            align="flex-start"
            buttons={actions.map((action) => ({
              ...action,
              tooltip: translations.form[action.name],
              color: action?.color ?? 'secondary',
            }))}
          />
        )}
      </>
    )
  }

  const customFields = [
    {
      key: 'order_actions',
      title: orderActions,
      width: '10%',
      fixed: true,
      sorter: false,
      render: (_, item) => <OrderActions item={item} />,
    },
    {
      key: 'address',
      title: addressTranslation,
      width: '15%',
      render: (_, item) => {
        if (item.status_id < 7) {
          return <EmptyRecord text={addressDisabled} />
        }
        return <OrderAddressMap addresses={addressList} {...item} />
      },
    },
    {
      key: 'florist',
      width: '10%',
      fixed: 'right',
      render: (_, item) =>
        item.florist && (
          <Button
            onClick={() => goToFlorist(item.florist?.id)}
            text={item.florist?.username}
            link
          />
        ),
    },
    {
      key: 'order_status',
      title: '',
      width: '15%',
      fixed: 'right',
      render: (_, item) => getOrderStatusChip(item, translations.form),
    },
    {
      key: 'order_details',
      title: '',
      width: '5%',
      fixed: 'right',
      sorter: false,
      render: (_, item) => (
        <TableButtons
          buttons={[
            {
              small: true,
              onClick: () => goToDetails(item.id),
              icon: 'cil-description',
              text: detailsTitle,
            },
          ]}
        />
      ),
    },
  ]

  const emptyRender = {
    message: (_) => <EmptyRecord text={noMessage} />,
  }

  const ProductCountExpandIcon = ({ expanded, onClick, record }) => (
    <>
      <Badge value={record?.itemCount ?? 0} title={itemCount} small>
        <ExpandIcon expanded={expanded} onClick={onClick} />
      </Badge>
    </>
  )

  return (
    <Card className="my-3">
      <PaginatedTable
        loading={loading}
        data={data || []}
        onSearchChange={onSearchChange}
        queryFields={queryFields}
        customFields={customFields}
        pagination={{ current: currentPage, total }}
        onPageChange={onPageChange}
        emptyRecordRender={(key, _) => (emptyRender[key] ? emptyRender[key](_) : null)}
        render={{
          deliveryDate: {
            fixed: true,
            render: (_, item) => dayjs(item.deliveryDate).format('ll [·] LT'),
          },
          itemCount: {
            width: '10%',
          },
        }}
        fieldsOrder={{ order_actions: 1, deliveryDate: 0 }}
        fields={['message', 'deliveryDate', 'internalId', 'idInvoice']}
        expandable={{
          expandedRowRender: ({ items }) => (
            <OrderProductsList products={items.filter((item) => !!item?.product)} />
          ),
          rowExpandable: ({ items }) =>
            !!items?.length && !!items?.filter((item) => !!item?.product).length,
        }}
        expandIcons={[ProductCountExpandIcon]}
        expandMultiple
      />
    </Card>
  )
}

const mapStateToProps = (state) => ({
  orders: state.order.orders,
  getOrdersRequesting: state.order.getOrdersRequesting,
  getOrdersByFlRequesting: state.order.getOrdersByFlRequesting,
  userInfo: state.auth.user,
  floristRequesting: state.order.getFlNearOrderRequesting,
  driverRequesting: state.driver.getDriversRequesting,
  florists: state.order.florists,
  setOrderFloristSuccess: state.order.setOrderFloristSuccess,
  assignDriverSuccess: state.order.setOrderDriverSuccess,
})

const mapDispatchToProps = {
  getFlorists: getFlNearOrderAction,
  getDrivers: getDriversAction,
  setStatus: setStatusOrderAction,
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(OrderList)
