import { baseService } from '@utils/authRequest'

export const getProducts = async (params) => {
  try {
    return await baseService.get('/products', { params })
  } catch (e) {
    return e.response?.data.error
  }
}

export const getOffers = async (params) => {
  try {
    return await baseService.get('/users/offers-count', { params })
  } catch (e) {
    return e.response?.data.error
  }
}

export const sendVariantOffer = async (form) => {
  try {
    return await baseService.post('/negotiations/negotiate', form)
  } catch (e) {
    return e.response?.data.error
  }
}

export const startVariantOffer = async (form) => {
  try {
    return await baseService.post('/negotiations/price', form)
  } catch (e) {
    return e.response?.data.error
  }
}
