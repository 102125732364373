import { isFunction } from 'lodash'

import {
  getIngredientDetail,
  getIngredients,
  createIngredient,
  updateIngredient,
  deleteIngredient,
  addIngredientsToProduct,
  removeIngredientsFromProduct,
} from '../services/ingredient'

import {
  GET_INGREDIENTS,
  GET_INGREDIENT_DETAIL,
  CREATE_INGREDIENT,
  UPDATE_INGREDIENT,
  DELETE_INGREDIENT,
  ADD_INGREDIENT_TO_PRODUCT,
  REMOVE_INGREDIENT_FROM_PRODUCT,
} from '../constants/ingredient'

const getIngredientsAction = (params, setList) => async (dispatch) => {
  try {
    dispatch({ type: GET_INGREDIENTS.REQUEST })
    const { content, success } = await getIngredients(params)

    if (success) {
      if (isFunction(setList)) {
        setList(content?.results)
      }
      dispatch({ type: GET_INGREDIENTS.SUCCESS, payload: content })
    }
  } catch (e) {
    dispatch({
      type: GET_INGREDIENTS.ERROR,
      payload: e.message,
    })
  } finally {
    dispatch({ type: GET_INGREDIENTS.RESET })
  }
}

const getIngredientAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_INGREDIENT_DETAIL.REQUEST })
    const { content, success } = await getIngredientDetail(id)

    if (success) {
      dispatch({ type: GET_INGREDIENT_DETAIL.SUCCESS, payload: content })
    }
  } catch (e) {
    dispatch({
      type: GET_INGREDIENT_DETAIL.ERROR,
      payload: e.message,
    })
  } finally {
    dispatch({ type: GET_INGREDIENT_DETAIL.RESET })
  }
}

const createIngredientAction = (form) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_INGREDIENT.REQUEST })
    const { content, success } = await createIngredient(form)

    if (success) {
      dispatch({ type: CREATE_INGREDIENT.SUCCESS, payload: content })
    }
  } catch (e) {
    dispatch({
      type: CREATE_INGREDIENT.ERROR,
      payload: e.message,
    })
  } finally {
    dispatch({ type: CREATE_INGREDIENT.RESET })
  }
}

const updateIngredientAction = (id, form) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_INGREDIENT.REQUEST })
    const { content, success } = await updateIngredient(id, form)

    if (success) {
      dispatch({ type: UPDATE_INGREDIENT.SUCCESS, payload: content })
    }
  } catch (e) {
    dispatch({
      type: UPDATE_INGREDIENT.ERROR,
      payload: e.message,
    })
  } finally {
    dispatch({ type: UPDATE_INGREDIENT.RESET })
  }
}

const deleteIngredientAction = (id, successCallback) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_INGREDIENT.REQUEST })
    const { success } = await deleteIngredient(id)

    if (success) {
      dispatch({ type: DELETE_INGREDIENT.SUCCESS })
      if (successCallback) successCallback()
    }
  } catch (e) {
    dispatch({
      type: DELETE_INGREDIENT.ERROR,
      payload: e.message,
    })
  } finally {
    dispatch({ type: DELETE_INGREDIENT.RESET })
  }
}

const addIngredientsToProductAction = (params, successCallback) => async (dispatch) => {
  try {
    dispatch({ type: ADD_INGREDIENT_TO_PRODUCT.REQUEST })
    const { success } = await addIngredientsToProduct(params)

    if (success) {
      dispatch({ type: ADD_INGREDIENT_TO_PRODUCT.SUCCESS })
      if (successCallback) successCallback()
    }
  } catch (e) {
    dispatch({
      type: ADD_INGREDIENT_TO_PRODUCT.ERROR,
      payload: e.message,
    })
  } finally {
    dispatch({ type: ADD_INGREDIENT_TO_PRODUCT.RESET })
  }
}

const removeIngredientsFromProductAction = (params, successCallback) => async (dispatch) => {
  try {
    dispatch({ type: REMOVE_INGREDIENT_FROM_PRODUCT.REQUEST })
    const { success } = await removeIngredientsFromProduct(params)

    if (success) {
      dispatch({ type: REMOVE_INGREDIENT_FROM_PRODUCT.SUCCESS })
      if (successCallback) successCallback()
    }
  } catch (e) {
    dispatch({
      type: REMOVE_INGREDIENT_FROM_PRODUCT.ERROR,
      payload: e.message,
    })
  } finally {
    dispatch({ type: REMOVE_INGREDIENT_FROM_PRODUCT.RESET })
  }
}

export {
  getIngredientsAction,
  createIngredientAction,
  updateIngredientAction,
  deleteIngredientAction,
  getIngredientAction,
  addIngredientsToProductAction,
  removeIngredientsFromProductAction,
}
