import { SET_SIDEBAR, SET_CURRENT_LANGUAGE } from '../constants/generic'

const setSidebar = (value) => ({
  type: SET_SIDEBAR,
  payload: value,
})

const setCurrentLanguage = (lang) => ({
  type: SET_CURRENT_LANGUAGE,
  payload: lang,
})

export { setSidebar, setCurrentLanguage }
