import { React, useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'

import translations from '@translations'

import { successToast } from '@utils/toast'

import { changePasswordAction } from '@actions/auth'
import PasswordForm from '@forms/passwordForm'

import { CCard, CCardBody, CCardGroup, CCol, CContainer, CRow } from '@coreui/react'

const ChangePassword = ({ changePassword, success }) => {
  const onPasswordChange = (values) => {
    changePassword({ newPassword: values.password, currentPassword: values.currentPassword })
  }

  const { setPassword } = translations.form

  const { changePasswordTitle } = translations.generic

  useEffect(() => {
    if (success) {
      successToast('changePasswordSuccess', { toastId: 'PasswordChanged' })
    }
  }, [success])

  return (
    <CContainer>
      <CRow className="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard className="p-4">
              <CCardBody>
                <h1>{setPassword}</h1>
                <p className="text-muted">{changePasswordTitle}</p>

                <PasswordForm onSubmit={onPasswordChange} askPrevPass />
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  )
}

const mapStateToProps = (state) => ({
  success: state.auth.changeSuccess,
})

const mapDispatchToProps = {
  changePassword: changePasswordAction,
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(ChangePassword)
