import {
  INGREDIENT_INIT,
  GET_INGREDIENTS,
  CREATE_INGREDIENT,
  UPDATE_INGREDIENT,
  DELETE_INGREDIENT,
  GET_INGREDIENT_DETAIL,
  ADD_INGREDIENT_TO_PRODUCT,
  REMOVE_INGREDIENT_FROM_PRODUCT,
} from '../constants/ingredient'

const dataReducer = (state = INGREDIENT_INIT, action) => {
  switch (action.type) {
    // GET_INGREDIENTS
    case GET_INGREDIENTS.REQUEST:
      return {
        ...state,
        getIngredientsRequesting: true,
      }

    case GET_INGREDIENTS.SUCCESS:
      return {
        ...state,
        getIngredientsSuccess: true,
        getIngredientsRequesting: false,
        list: action.payload,
      }

    case GET_INGREDIENTS.ERROR:
      return {
        ...state,
        getIngredientsRequesting: false,
        getIngredientsErrorMessage: action.payload,
      }

    case GET_INGREDIENTS.RESET:
      return {
        ...state,
        getIngredientsRequesting: false,
        getIngredientsSuccess: false,
        getIngredientsErrorMessage: '',
      }

    // CREATE_INGREDIENT
    case CREATE_INGREDIENT.REQUEST:
      return {
        ...state,
        createIngredientRequesting: true,
      }

    case CREATE_INGREDIENT.SUCCESS:
      return {
        ...state,
        createIngredientSuccess: true,
        createIngredientRequesting: false,
      }

    case CREATE_INGREDIENT.ERROR:
      return {
        ...state,
        createIngredientRequesting: false,
        createIngredientErrorMessage: action.payload,
      }

    case CREATE_INGREDIENT.RESET:
      return {
        ...state,
        createIngredientRequesting: false,
        createIngredientSuccess: false,
        createIngredientErrorMessage: '',
      }

    // UPDATE_INGREDIENT
    case UPDATE_INGREDIENT.REQUEST:
      return {
        ...state,
        updateIngredientRequesting: true,
      }

    case UPDATE_INGREDIENT.SUCCESS:
      return {
        ...state,
        updateIngredientSuccess: true,
        updateIngredientRequesting: false,
      }

    case UPDATE_INGREDIENT.ERROR:
      return {
        ...state,
        updateIngredientRequesting: false,
        updateIngredientErrorMessage: action.payload,
      }

    case UPDATE_INGREDIENT.RESET:
      return {
        ...state,
        updateIngredientRequesting: false,
        updateIngredientSuccess: false,
        updateIngredientErrorMessage: '',
      }

    // DELETE_INGREDIENT
    case DELETE_INGREDIENT.REQUEST:
      return {
        ...state,
        deleteIngredientRequesting: true,
      }

    case DELETE_INGREDIENT.SUCCESS:
      return {
        ...state,
        deleteIngredientSuccess: true,
        deleteIngredientRequesting: false,
      }

    case DELETE_INGREDIENT.ERROR:
      return {
        ...state,
        deleteIngredientRequesting: false,
        deleteIngredientErrorMessage: action.payload,
      }

    case DELETE_INGREDIENT.RESET:
      return {
        ...state,
        deleteIngredientRequesting: false,
        deleteIngredientSuccess: false,
        deleteIngredientErrorMessage: '',
      }

    // GET INGREDIENT_DETAIL
    case GET_INGREDIENT_DETAIL.REQUEST:
      return {
        ...state,
        getIngredientDetailRequesting: true,
      }

    case GET_INGREDIENT_DETAIL.SUCCESS:
      return {
        ...state,
        getIngredientDetailSuccess: true,
        getIngredientDetailRequesting: false,
        detail: action.payload,
      }

    case GET_INGREDIENT_DETAIL.ERROR:
      return {
        ...state,
        getIngredientDetailRequesting: false,
        getIngredientDetailErrorMessage: action.payload,
      }

    case GET_INGREDIENT_DETAIL.RESET:
      return {
        ...state,
        getIngredientDetailRequesting: false,
        getIngredientDetailSuccess: false,
        getIngredientDetailErrorMessage: '',
      }

    // ADD_INGREDIENT_TO_PRODUCT
    case ADD_INGREDIENT_TO_PRODUCT.REQUEST:
      return {
        ...state,
        removeIngredientFromProductRequesting: true,
      }

    case ADD_INGREDIENT_TO_PRODUCT.SUCCESS:
      return {
        ...state,
        removeIngredientFromProductSuccess: true,
        removeIngredientFromProductRequesting: false,
        detail: action.payload,
      }

    case ADD_INGREDIENT_TO_PRODUCT.ERROR:
      return {
        ...state,
        removeIngredientFromProductRequesting: false,
        removeIngredientFromProductErrorMessage: action.payload,
      }

    case ADD_INGREDIENT_TO_PRODUCT.RESET:
      return {
        ...state,
        removeIngredientFromProductRequesting: false,
        removeIngredientFromProductSuccess: false,
        removeIngredientFromProductErrorMessage: '',
      }

    // ADD_INGREDIENT_TO_PRODUCT
    case REMOVE_INGREDIENT_FROM_PRODUCT.REQUEST:
      return {
        ...state,
        addIngredientToProductRequesting: true,
      }

    case REMOVE_INGREDIENT_FROM_PRODUCT.SUCCESS:
      return {
        ...state,
        addIngredientToProductSuccess: true,
        addIngredientToProductRequesting: false,
        detail: action.payload,
      }

    case REMOVE_INGREDIENT_FROM_PRODUCT.ERROR:
      return {
        ...state,
        addIngredientToProductRequesting: false,
        addIngredientToProductErrorMessage: action.payload,
      }

    case REMOVE_INGREDIENT_FROM_PRODUCT.RESET:
      return {
        ...state,
        addIngredientToProductRequesting: false,
        addIngredientToProductSuccess: false,
        addIngredientToProductErrorMessage: '',
      }

    default:
      return state
  }
}

export default dataReducer
