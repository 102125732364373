import React from 'react'
import { ChipBadge, Tooltip } from '@components/common'

interface Props {
  id: string
  text?: string
  className?: string
  disabled?: boolean
  onClose?: (id: string) => void
}

const Chip: React.FC<Props> = ({ onClose, text, id, disabled = false }) => {
  const disabledProps = !disabled && {
    icon: 'cil-x',
    onClick: () => !!onClose && onClose(id),
    style: { cursor: 'pointer' },
  }

  return (
    <Tooltip tooltip={text}>
      <h3 className="mb-0">
        <ChipBadge color="secondary" {...disabledProps}>
          {text}
        </ChipBadge>
      </h3>
    </Tooltip>
  )
}

export default Chip
