import React from 'react'

import { connect, ConnectedProps } from 'react-redux'

import { setImagesFeedbackAction } from '@actions/order'

import { approvalStatusDataEnum, approvalStatusEnum } from '@utils/enums/genericEnums'

import { CButtonGroup, CRow } from '@coreui/react'
import { Image, Modal, Button } from '@components/common/index'
import Col from '@utils/components/ResponsiveColumn'

import AwesomeSlider from 'react-awesome-slider'
import AwesomeSliderStyles from 'react-awesome-slider/src/styles'
import styles from '@styles/modules/carousel.module.scss'

import { OrderImage } from '@utils/interfaces/order'

import translations from '@translations'

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  id?: string
  images?: OrderImage[]
  open: boolean
  closeModal: () => void
  florist?: string
  successCallback: (id: string, approved: boolean) => void
}

const ImagesFeedbackCarousel: React.FC<Props> = ({
  id,
  florist,
  images,
  open,
  closeModal,
  setFeedback,
  successCallback,
}) => {
  const { orderImages, imagesFeedbackAlert } = translations.generic

  const handleStatusChange = (key) => {
    if (!id) return

    const approved = key === approvalStatusEnum.ACCEPT
    setFeedback(id, approved, () => successCallback(id, approved))
  }

  const ImageActionButtons = () => (
    <CRow className="justify-content-center">
      <CButtonGroup>
        {Object.entries(approvalStatusDataEnum).map(([key, { color, name, icon }]) => (
          <Button
            onClick={() => handleStatusChange(key)}
            color={color}
            icon={icon}
            key={key}
            text={translations.generic[name]}
            size="lg"
          />
        ))}
      </CButtonGroup>
      <small className="mt-3 text-start w-100"> {imagesFeedbackAlert} </small>
    </CRow>
  )

  if (!id || !images || !images.filter.length) return null

  return (
    <Modal
      title={florist ? orderImages({ florist }) : undefined}
      size="lg"
      open={open}
      closeModal={closeModal}
      onDismiss={closeModal}
      footerButtons={ImageActionButtons}
      hideCancel
      footerClassName="justify-content-center"
    >
      <AwesomeSlider
        cssModule={[AwesomeSliderStyles, styles.awesomeSlider]}
        style={{ height: '100%' }}
        bullets={false}
      >
        {images.map(({ id: imageId, url }) => (
          <Col xl={6} key={imageId} className="pl-0 pr-1 cs-mw-80">
            <Image
              src={url}
              style={{
                width: '100%',
                objectFit: 'cover',
              }}
            />
          </Col>
        ))}
      </AwesomeSlider>
    </Modal>
  )
}

const mapDispatchToProps = {
  setFeedback: setImagesFeedbackAction,
}

const connector = connect(null, mapDispatchToProps)

export default connector(ImagesFeedbackCarousel)
