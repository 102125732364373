import * as Yup from 'yup'

import { actionsEnum } from '@utils/enums/offerEnums'

const OfferSchema = Yup.object().shape({
  floristId: Yup.number().required('Florist id is required'),
  variantId: Yup.string().required('Variant id is required'),
  status: Yup.string().oneOf(Object.keys(actionsEnum)),
  initialPrice: Yup.number().nullable().defined('Price is required'),
  newPrice: Yup.number()
    .nullable()
    .when('status', {
      is: actionsEnum.COUNTEROFFER,
      then: Yup.number()
        .nullable()
        .default(undefined)
        .moreThan(0, 'Price must be greater than 0')
        .required('Price is required'),
    }),
})

export default OfferSchema
