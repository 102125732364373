import React, { useEffect } from 'react'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { Formik } from 'formik'
import translations from '@translations'

import IntermediateDataSchema from '@utils/schemas/intermediateDataSchema'

import { getSitesAction } from '@actions/data'

import { Button, Select, Checkbox, NewTextInput } from '@components/common/index'
import ZonesSelector from '@components/ZonesSelector'
import Col from '@utils/components/ResponsiveColumn'

import { CForm, CRow } from '@coreui/react'
import { initialValues, formKeys } from './formKeys'

import UserForm from '../userForm'

const IntermediateForm = ({ onSubmit, getSites, sites }) => {
  const { site, zone, setOperator, name } = translations.form
  const { create } = translations.generic

  useEffect(() => {
    getSites()
  }, [])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={IntermediateDataSchema}
      onSubmit={onSubmit}
    >
      {({
        setFieldValue,
        setFieldTouched,
        handleBlur,
        handleChange,
        handleSubmit,
        setValues,
        values,
        errors,
        touched,
        dirty,
      }) => {
        const onUserFormChange = (userValues) => {
          setValues({
            ...values,
            user: userValues,
          })
        }

        const changeZones = async (val) => {
          await setFieldValue(formKeys.zones, val)
          setFieldTouched(formKeys.zones, true)
        }

        return (
          <CForm>
            <CRow className="my-2 align-items-center">
              <Col>
                <CRow>
                  <Col xl={12}>
                    <NewTextInput
                      icon="cil-at"
                      value={values[formKeys.username]}
                      placeholder={name}
                      name={formKeys.username}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched[formKeys.username] &&
                        errors[formKeys.username] &&
                        errors[formKeys.username]
                      }
                    />
                  </Col>
                </CRow>

                <CRow className="mt-3">
                  {/*  <Col xl={6}>
                    <ZonesSelector
                      name={formKeys.zones}
                      onChange={changeZones}
                      placeholder={zone}
                      onBlur={handleBlur}
                      error={!!errors.zones && touched.zones && errors.zones}
                      multiple
                      responsiveTags
                    />
                  </Col> */}

                  <Col xl={6}>
                    <Select
                      multiple
                      data={sites}
                      placeholder={site}
                      labelKey="url"
                      onChange={(value) => setFieldValue(formKeys.sites, value)}
                      onBlur={handleBlur}
                      error={
                        touched[formKeys.sites] && !!errors[formKeys.sites]
                          ? errors[formKeys.sites]
                          : undefined
                      }
                      name={formKeys.sites}
                    />
                  </Col>
                </CRow>

                <CRow className="mt-3">
                  <Col>
                    <Checkbox
                      id={formKeys.setOperator}
                      onChange={handleChange}
                      name={formKeys.setOperator}
                      value={values[formKeys.setOperator]}
                      label={setOperator}
                    />

                    {!!values.setOperator && (
                      <UserForm className="mt-3" onFormChange={onUserFormChange} />
                    )}
                  </Col>
                </CRow>
              </Col>
            </CRow>

            <CRow className="justify-content-end">
              <Button
                color="primary"
                className="px-4"
                disabled={!dirty || !!Object.keys(errors).length}
                onClick={handleSubmit}
                text={create}
              />
            </CRow>
          </CForm>
        )
      }}
    </Formik>
  )
}

IntermediateForm.defaultProps = {
  onSubmit: null,
  onFormChange: null,
}

const mapStateToProps = (state) => ({
  sites: state.data.sites,
})

const mapDispatchToProps = {
  getSites: getSitesAction,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default compose(withTranslation(), connector)(IntermediateForm)
