import { useDispatch, useSelector } from 'react-redux'

import { isArray, compact } from 'lodash'

import { ChipBadge } from '@components/common'

import { filterDataEnum, filterEnum } from '@utils/enums/orderEnums'
import { EntityWithId } from '@utils/interfaces/react'

import { getFlNearOrderAction, setStatusOrderAction } from '@actions/order'
import { getDriversAction } from '@actions/driver'

import { FormTranslations } from '@translations'
import { Type, Status } from '@utils/interfaces/order'
import { getAddressFromLatLng } from '@utils/reverseGeocode'
import { AddressProps, StringCenter } from './ABM/components/OrderAddressMap'

export interface ButtonType {
  onClick: () => void
  text: string
  icon: string
}

type DetailsWithAddress = EntityWithId & StringCenter

export interface ActionButtons {
  [status: string]: (order: EntityWithId) => ButtonType[]
}

export const getOrderAddressData = async (
  details: DetailsWithAddress
): Promise<AddressProps | null> => {
  if (details && details.lat && details.lng) {
    const { lat, lng, id } = details
    const data = await getAddressFromLatLng({ lat, lng })

    if (data) {
      const { address, bounds } = data
      return { id, lat, lng, addresses: { [id]: { address, bounds } } }
    }
  }
  return null
}

export const useStatusButtons = ({
  setDriverList,
  setSelectedOrderId,
  openDriverModal,
  openImageModal,
  openFloristModal,
  translations,
}: {
  setDriverList?: (list) => void
  setSelectedOrderId?: (id) => void
  openDriverModal?: () => void
  openImageModal?: () => void
  openFloristModal?: () => void
  translations: FormTranslations
}): ActionButtons => {
  const dispatch = useDispatch()
  const currentFlorist = useSelector((state: any) => state.auth.currentFlorist)
  const currentFloristId = currentFlorist?.id

  const setStatus = (...params) => dispatch(setStatusOrderAction(...params))
  const getFlorists = (...params) => dispatch(getFlNearOrderAction(...params))
  const getDrivers = (...params) => dispatch(getDriversAction(...params))

  const selectFlorists = (id) => {
    getFlorists(id)
    if (setSelectedOrderId) setSelectedOrderId(id)
    if (openFloristModal) openFloristModal()
  }

  const selectDriver = (id) => {
    getDrivers(currentFloristId, setDriverList, { page: 1 })
    if (setSelectedOrderId) setSelectedOrderId(id)
    if (openDriverModal) openDriverModal()
  }

  const uploadImage = (id) => {
    if (setSelectedOrderId) setSelectedOrderId(id)
    if (openImageModal) openImageModal()
  }

  const {
    assignFlorist,
    assignIntermediate,
    startMaking,
    uploadImages: uploadImagesTranslation,
    setDriver,
    setDelivering,
    setDelivered,
    rate,
  } = translations

  return {
    [filterEnum.ASSIGNED_TO_INTERMEDIATE]: ({ id }) => [
      {
        onClick: () => setStatus(id, 2),
        icon: 'cil-flower',
        text: assignIntermediate,
      },
    ],
    [filterEnum.ASSIGNED_TO_FLORIST]: ({ id }) => [
      {
        onClick: () => selectFlorists(id),
        icon: 'cil-flower',
        text: assignFlorist,
      },
    ],
    [filterEnum.MAKING]: ({ id }) => [
      {
        onClick: () => {
          setStatus(id, 4)
        },
        icon: 'cil-sign-language',
        text: startMaking,
      },
    ],
    [filterEnum.IMAGE_UPLOADED]: ({ id }) => [
      {
        onClick: () => uploadImage(id),
        icon: 'cil-image',
        text: uploadImagesTranslation,
      },
    ],
    [filterEnum.SELECT_DRIVER]: (item) => [
      {
        onClick: () => selectDriver(item),
        icon: 'cil-truck',
        text: setDriver,
      },
    ],
    [filterEnum.DELIVERING]: ({ id }) => [
      {
        onClick: () => setStatus(id, 10),
        icon: 'cil-truck',
        text: setDelivering,
      },
    ],
    [filterEnum.DELIVERED]: ({ id }) => [
      {
        onClick: () => setStatus(id, 11),
        icon: 'cil-truck',
        text: setDelivered,
      },
    ],
    [filterEnum.QUALIFIED]: ({ id }) => [
      {
        onClick: () => setStatus(id, 12),
        icon: 'cil-check',
        text: rate,
      },
    ],
  }
}

interface ChildStatus {
  status: Status
}
interface OrderWorkflow {
  childStatus?: ChildStatus[]
  status: Status
}
interface Order extends EntityWithId {
  workflow: OrderWorkflow
}

export const getOrderChildStatus = (workflow: OrderWorkflow, userType: Type): ChildStatus[] =>
  isArray(workflow?.childStatus)
    ? workflow?.childStatus.filter(
        (item) => !!item && filterDataEnum[item.status]?.userTypeAction === userType
      )
    : []

export const getOrderChildActions = (
  order: Order,
  buttons: ActionButtons,
  user: { typeId: number }
): ButtonType[] => {
  if (!order || !order.workflow || !order?.workflow?.childStatus) return []

  const userType = user?.typeId === 3 ? 'fl' : 'itm'
  const { workflow } = order

  return compact(
    getOrderChildStatus(workflow, userType).map(({ status }) =>
      status && !!buttons[status] ? buttons[status](order) : null
    )
  ).flat()
}

export const getCurrentStatus = (order: Order): OrderWorkflow['status'] => order?.workflow?.status

export const getOrderStatusChip = (
  order: Order,
  translations: { [key: string]: any }
): React.ReactNode => {
  const status = getCurrentStatus(order)
  const statusData = filterDataEnum[status]
  return statusData ? (
    <ChipBadge
      className="mr-1 height-100-px"
      shape="pill"
      style={{ height: 'auto' }}
      color={statusData.color}
    >
      {translations[statusData.name]}
    </ChipBadge>
  ) : null
}
