import * as Yup from 'yup'

const PriceSchema = Yup.object().shape({
  variantId: Yup.string().required('Variant id is required'),
  floristId: Yup.number().nullable(),
  price: Yup.number()
    .nullable()
    .default(undefined)
    .moreThan(0, 'Price must be greater than 0')
    .required('Price is required'),
  zoneId: Yup.number().required('Zone id is required'),
})

export default PriceSchema
