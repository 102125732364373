import React, { useEffect } from 'react'
import { Formik } from 'formik'
import { merge } from 'lodash'

import translations from '@translations'
import { withTranslation } from 'react-i18next'

import PasswordSchema from '@utils/schemas/passwordSchema'

import NewTextInput from '@components/common/NewTextInput'
import TheRow from '@containers/TheRow'

import { CButton, CCol, CForm, CRow } from '@coreui/react'
import { initialValues, formKeys } from './formKeys'

const PasswordForm = ({ onSubmit, onFormChange, column, askPrevPass }) => {
  const { newPassword, confirmPassword, changePassword, currentPassword } = translations.form

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={PasswordSchema}
      onSubmit={(v) => {
        const values = { ...v }
        values.confirmPassword = undefined
        onSubmit(values)
      }}
    >
      {({ handleBlur, handleChange, handleSubmit, values, errors, dirty, touched }) => {
        const formProps = {
          onChange: handleChange,
          onBlur: handleBlur,
          icon: 'cil-asterisk',
          password: true,
        }

        const getPropsByKey = (key) => ({
          value: values[key],
          name: formKeys[key],
          error: !!errors[key] && touched[key] && errors[key],
        })

        useEffect(() => {
          if (!!onFormChange && dirty) {
            onFormChange(values)
          }
        }, [values])

        return (
          <CForm>
            {column ? (
              <TheRow>
                <>
                  {askPrevPass && (
                    <NewTextInput
                      placeholder={currentPassword}
                      {...merge(formProps, getPropsByKey('currentPassword'))}
                    />
                  )}
                  <NewTextInput
                    placeholder={newPassword}
                    {...merge(formProps, getPropsByKey('password'))}
                  />
                  <NewTextInput
                    placeholder={confirmPassword}
                    {...merge(formProps, getPropsByKey('confirmPassword'))}
                  />
                </>
              </TheRow>
            ) : (
              <>
                {askPrevPass && (
                  <NewTextInput
                    placeholder={currentPassword}
                    {...merge(formProps, getPropsByKey('currentPassword'))}
                  />
                )}
                <NewTextInput
                  placeholder={newPassword}
                  {...merge(formProps, getPropsByKey('password'))}
                />
                <NewTextInput
                  placeholder={confirmPassword}
                  {...merge(formProps, getPropsByKey('confirmPassword'))}
                />
              </>
            )}

            {onSubmit && (
              <CRow className="justify-content-end">
                <CButton
                  color="primary"
                  className="px-4"
                  disabled={!dirty || !!Object.keys(errors).length}
                  onClick={handleSubmit}
                  type="submit"
                >
                  {changePassword}
                </CButton>
              </CRow>
            )}
          </CForm>
        )
      }}
    </Formik>
  )
}

PasswordForm.defaultProps = {
  column: false,
  onFormChange: null,
  onSubmit: null,
}

export default withTranslation()(PasswordForm)
