import React, { useRef } from 'react'

import { ConnectedProps } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { Formik } from 'formik'

import translations from '@translations'

import { getGeoJSON } from '@utils/helpers'
import { Polygon as PolygonType } from '@utils/interfaces/maps'

import { CreatePolygonSchema } from '@utils/schemas/polygonSchema'

// eslint-disable-next-line import/named
import { Button } from '@components/common'
import Col from '@utils/components/ResponsiveColumn'

import PolygonMap from '@components/GoogleMaps/EditablePolygonMap'

import { CForm, CRow } from '@coreui/react'
import NewTextInput from '@components/common/NewTextInput'
import { initialValues, formKeys } from './formKeys'

interface Props {
  onSubmit: (values) => void
}

const NewPolygonForm: React.FC<Props> = ({ onSubmit }) => {
  const { create } = translations.generic
  const { polygonName, polygonDescription, drawMap, drawMapHint } = translations.form

  const polygonRef = useRef<PolygonType | null>(null)

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={CreatePolygonSchema}
      onSubmit={onSubmit}
    >
      {({
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
        values,
        errors,
        touched,
        dirty,
      }) => {
        const disabled = !dirty || !!Object.keys(errors).length

        const handlePolygonChange = () => {
          getGeoJSON(polygonRef.current, (geojson, center) => {
            setFieldValue(formKeys.geojson, geojson)
            setFieldValue(formKeys.center, center)
          })
        }

        return (
          <CForm>
            <CRow className="d-flex">
              <Col xl={8}>
                <h5>{drawMap}</h5>
                <p>{drawMapHint}</p>
              </Col>
            </CRow>

            <CRow>
              <Col xl={8} xxl={6} className="my-3">
                <PolygonMap
                  polygonRef={polygonRef}
                  onPolygonChanged={handlePolygonChange}
                  drawable
                  width="100%"
                />
              </Col>

              <Col xl={4} className="mt-3">
                <CRow>
                  <Col xl={12}>
                    <NewTextInput
                      value={values[formKeys.name] as string}
                      placeholder={polygonName}
                      name={formKeys.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched[formKeys.name] && !!errors[formKeys.name]
                          ? (errors[formKeys.name] as string)
                          : undefined
                      }
                      icon="cil-location-pin-plus"
                    />
                  </Col>
                </CRow>

                <CRow>
                  <Col xl={12}>
                    <NewTextInput
                      value={values[formKeys.description] as string}
                      placeholder={polygonDescription}
                      name={formKeys.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched[formKeys.description] && !!errors[formKeys.description]
                          ? (errors[formKeys.description] as string)
                          : undefined
                      }
                      icon="cil-description"
                    />
                  </Col>
                </CRow>
              </Col>
            </CRow>

            <CRow className="justify-content-end mt-3 mx-0">
              <Button color="primary" disabled={disabled} onClick={handleSubmit} text={create} />
            </CRow>
          </CForm>
        )
      }}
    </Formik>
  )
}

export default withTranslation()(NewPolygonForm)
