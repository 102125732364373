import {
  POLYGON_INIT,
  GET_POLYGONS,
  GET_ZONES,
  CREATE_POLYGON,
  UPDATE_POLYGON,
  DELETE_POLYGON,
  GET_POLYGON,
} from '../constants/polygon'

const dataReducer = (state = POLYGON_INIT, action) => {
  switch (action.type) {
    // GET_POLYGONS
    case GET_POLYGONS.REQUEST:
      return {
        ...state,
        getPolygonsRequesting: true,
      }

    case GET_POLYGONS.SUCCESS:
      return {
        ...state,
        getPolygonsSuccess: true,
        getPolygonsRequesting: false,
        list: action.payload,
      }

    case GET_POLYGONS.ERROR:
      return {
        ...state,
        getPolygonsRequesting: false,
        getPolygonsErrorMessage: action.payload,
      }

    case GET_POLYGONS.RESET:
      return {
        ...state,
        getPolygonsRequesting: false,
        getPolygonsSuccess: false,
        getPolygonsErrorMessage: '',
      }

    // GET_ZONES
    case GET_ZONES.REQUEST:
      return {
        ...state,
        getZonesRequesting: true,
      }

    case GET_ZONES.SUCCESS:
      return {
        ...state,
        getZonesSuccess: true,
        getZonesRequesting: false,
        zones: action.payload,
      }

    case GET_ZONES.ERROR:
      return {
        ...state,
        getZonesRequesting: false,
        getZonesErrorMessage: action.payload,
      }

    case GET_ZONES.RESET:
      return {
        ...state,
        getZonesRequesting: false,
        getZonesSuccess: false,
        getZonesErrorMessage: '',
      }

    // CREATE_POLYGON
    case CREATE_POLYGON.REQUEST:
      return {
        ...state,
        createPolygonRequesting: true,
      }

    case CREATE_POLYGON.SUCCESS:
      return {
        ...state,
        createPolygonSuccess: true,
        createPolygonRequesting: false,
      }

    case CREATE_POLYGON.ERROR:
      return {
        ...state,
        createPolygonRequesting: false,
        createPolygonErrorMessage: action.payload,
      }

    case CREATE_POLYGON.RESET:
      return {
        ...state,
        createPolygonRequesting: false,
        createPolygonSuccess: false,
        createPolygonErrorMessage: '',
      }

    // UPDATE_POLYGON
    case UPDATE_POLYGON.REQUEST:
      return {
        ...state,
        updatePolygonRequesting: true,
      }

    case UPDATE_POLYGON.SUCCESS:
      return {
        ...state,
        updatePolygonSuccess: true,
        updatePolygonRequesting: false,
      }

    case UPDATE_POLYGON.ERROR:
      return {
        ...state,
        updatePolygonRequesting: false,
        updatePolygonErrorMessage: action.payload,
      }

    case UPDATE_POLYGON.RESET:
      return {
        ...state,
        updatePolygonRequesting: false,
        updatePolygonSuccess: false,
        updatePolygonErrorMessage: '',
      }

    // DELETE_POLYGON
    case DELETE_POLYGON.REQUEST:
      return {
        ...state,
        deletePolygonRequesting: true,
      }

    case DELETE_POLYGON.SUCCESS:
      return {
        ...state,
        deletePolygonSuccess: true,
        deletePolygonRequesting: false,
      }

    case DELETE_POLYGON.ERROR:
      return {
        ...state,
        deletePolygonRequesting: false,
        deletePolygonErrorMessage: action.payload,
      }

    case DELETE_POLYGON.RESET:
      return {
        ...state,
        deletePolygonRequesting: false,
        deletePolygonSuccess: false,
        deletePolygonErrorMessage: '',
      }

    // GET_POLYGON
    case GET_POLYGON.REQUEST:
      return {
        ...state,
        getPolygonDetailRequesting: true,
      }

    case GET_POLYGON.SUCCESS:
      return {
        ...state,
        getPolygonDetailSuccess: true,
        getPolygonDetailRequesting: false,
        detail: action.payload,
      }

    case GET_POLYGON.ERROR:
      return {
        ...state,
        getPolygonDetailRequesting: false,
        getPolygonDetailErrorMessage: action.payload,
      }

    case GET_POLYGON.RESET:
      return {
        ...state,
        getPolygonDetailRequesting: false,
        getPolygonDetailSuccess: false,
        getPolygonDetailErrorMessage: '',
      }

    default:
      return state
  }
}

export default dataReducer
