import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { isPlainObject } from 'lodash'

import { withRouteParams } from '@utils/helpers'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { getEntityAction } from '@actions/entity'

import { CCol, CRow } from '@coreui/react'
import { routes } from '@/routes'

import FloristDetail from './FloristDetail'
import IntermediateDetail from './IntermediateDetail'

import { EntityProvider } from './components/context'

const EntityDetail = ({ match, location, details, getDetails }) => {
  const history = useHistory()

  const [entityKeys, setEntityKeys] = useState([])

  const { id, type: entityType, tab } = match.params

  const queryPage = location.search.match(/page=([0-9]+)/)
  const currentPage = Number((!!queryPage && queryPage[1]) || 1)

  const isFlorist = entityType === 'fl'

  const initialRequest = () => {
    getDetails(entityType, id)
  }

  useEffect(() => {
    initialRequest()
  }, [currentPage, isFlorist])

  useEffect(() => {
    if (details) {
      const currentKeys = Object.keys(details)
      let tableKeys = currentKeys.filter(
        (key) => !!details[key] && !isPlainObject(details[key]) && !key.toLowerCase().includes('id')
      )

      if (isFlorist) {
        tableKeys = tableKeys.filter((key) => !['lat', 'lng', 'active'].includes(key))
      }

      setEntityKeys(tableKeys)
    }
  }, [details])

  const onPageChange = (newPage) => {
    if (currentPage !== newPage) {
      const route = withRouteParams(routes.Details, { type: 'fl', id })
      history.push(`${route}?page=${newPage}`)
    }
  }

  return (
    <CCol>
      <CRow>
        <EntityProvider
          value={{
            id,
            keys: entityKeys,
            tab,
            currentPage,
            onPageChange,
            type: isFlorist ? 'fl' : 'itm',
          }}
        >
          {isFlorist ? (
            <FloristDetail details={details} viewLists />
          ) : (
            <IntermediateDetail details={details} />
          )}
        </EntityProvider>
      </CRow>
    </CCol>
  )
}

const mapStateToProps = (state) => ({
  details: state.entity.detail,
})

const mapDispatchToProps = {
  getDetails: getEntityAction,
}

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(EntityDetail)
