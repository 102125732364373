import React from 'react'
import { useHistory } from 'react-router-dom'

import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { Button, Card } from '@components/common/index'

import { withRouteParams } from '@utils/helpers'
import translations from '@translations'

import { CCol } from '@coreui/react'
import { routes } from '@/routes'
import DriversList from '../entities/EntityDetails/components/DriversList'

const DriverABM = ({ location, userInfo }) => {
  const history = useHistory()

  const { newDriver } = translations.form

  const queryPage = location.search.match(/page=([0-9]+)/)
  const currentPage = Number((queryPage && queryPage[1]) ?? 1)

  const floristId = userInfo?.florists[0]?.id

  const onPageChange = (newPage) => {
    if (currentPage !== newPage) {
      history.push(`${routes.Drivers}?page=${newPage}`)
    }
  }

  const goToNewDriver = () => {
    history.push(withRouteParams(routes.NewDriver, { type: 'fl', id: floristId }))
  }

  return (
    <CCol xl={12}>
      <CCol col="2" className="d-flex justify-content-end">
        <Button onClick={goToNewDriver} color="primary" icon="cil-user-follow" text={newDriver} />
      </CCol>

      <Card className="my-3">
        <DriversList
          fromDashboard
          entityId={floristId}
          currentPage={currentPage}
          onPageChange={onPageChange}
        />
      </Card>
    </CCol>
  )
}

const mapStateToProps = (state) => ({
  userInfo: state.auth.user,
})

export default compose(withTranslation(), connect(mapStateToProps))(DriverABM)
