import React from 'react'

import { Input } from 'antd'
import { SearchProps } from 'antd/lib/input'

const { Search } = Input

type Props = SearchProps

const SearchInput: React.FC<Props> = ({ onSearch, ...props }) => (
  <Search onSearch={onSearch} {...props} />
)

export default SearchInput
