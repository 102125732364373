export const containerIdEnum = {
  ERROR: 'ERROR',
  UNDO: 'UNDO',
  SUCCESS: 'SUCCESS',
  INFO: 'INFO',
}

export const messageEnums = {
  CREATE_FLORIST: 'CREATE_FLORIST',
  CREATE_INTERMEDIATE: 'CREATE_INTERMEDIATE',
  ADD_USER_FLORIST: 'ADD_USER_FLORIST',
  ADD_USER_INTERMEDIATE: 'ADD_USER_INTERMEDIATE',
  CREATE_DRIVER: 'CREATE_DRIVER',
}
