import React, { useEffect } from 'react'

import { compose } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { Formik } from 'formik'

import translations from '@translations'

import { IngredientDataSchema } from '@utils/schemas/ingredientSchema'

import { Button, Select } from '@components/common'
import Col from '@utils/components/ResponsiveColumn'

import { CForm, CRow } from '@coreui/react'
import { getIngredientsParentsAction } from '@actions/data'
import NewTextInput from '@components/common/NewTextInput'
import { initialValues, formKeys } from './formKeys'

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props {
  onSubmit: (values) => void
}

const NewIngredientForm: React.FC<Props & PropsFromRedux> = ({
  onSubmit,
  ingredients,
  getIngredients,
}) => {
  const { create } = translations.generic
  const { newIngredient, ingredientName, relatedIngredient } = translations.form

  useEffect(() => {
    getIngredients()
  }, [])

  const searchIngredients = (search: string, ingredientItem: any) =>
    ingredientItem.name.toLowerCase().includes(search.toLowerCase())

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={IngredientDataSchema}
      onSubmit={onSubmit}
    >
      {({
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
        values,
        errors,
        touched,
        dirty,
      }) => (
        <CForm>
          <CRow className="d-flex">
            <Col xl={8}>
              <h5>{newIngredient}</h5>
            </Col>
          </CRow>

          <CRow>
            <Col xl={8} className="mt-3">
              <NewTextInput
                value={values[formKeys.name] as string}
                placeholder={ingredientName}
                name={formKeys.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched[formKeys.name] && !!errors[formKeys.name]
                    ? (errors[formKeys.name] as string)
                    : undefined
                }
              />
            </Col>
            <Col xl={4} className="mt-3">
              <Select
                data={ingredients}
                placeholder={relatedIngredient}
                filterOption={searchIngredients}
                onChange={(value) => setFieldValue(formKeys.ingredientId, value)}
                onBlur={handleBlur}
                error={
                  touched[formKeys.ingredientId] && !!errors[formKeys.ingredientId]
                    ? (errors[formKeys.ingredientId] as string)
                    : undefined
                }
              />
            </Col>
          </CRow>

          <CRow className="justify-content-end mt-3 mx-0">
            <Button
              disabled={!dirty || !!Object.keys(errors).length}
              onClick={handleSubmit}
              text={create}
            />
          </CRow>
        </CForm>
      )}
    </Formik>
  )
}

const mapStateToProps = (state) => ({
  ingredients: state.data.ingredientsParents,
})

const mapDispatchToProps = {
  getIngredients: getIngredientsParentsAction,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default compose<React.FC<Props>>(withTranslation(), connector)(NewIngredientForm)
