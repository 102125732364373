export const formKeys = {
  productId: 'productId',
  ingredientId: 'ingredientId',
}

const { productId, ingredientId } = formKeys

export const initialValues = {
  [productId]: null,
  [ingredientId]: null,
}
