import { baseService, AUTHORIZATION_HEADER } from '@utils/authRequest'

export const login = async (form) => {
  try {
    return await baseService.post('login', form)
  } catch (e) {
    return e.response?.data.error
  }
}

export const forgotPassword = async (email) => {
  try {
    return await baseService.post('forgot-password', { email })
  } catch (e) {
    return e.response?.data.error
  }
}

export const changePassword = async (password) => {
  try {
    return await baseService.post('change-password', password)
  } catch (e) {
    return e.response?.data
  }
}

export const validateToken = async (token) => {
  try {
    return await baseService.get('validate-token', {
      headers: {
        [AUTHORIZATION_HEADER]: token,
      },
    })
  } catch (e) {
    return e.response?.data.error
  }
}
