import {
  RULE_INIT,
  CREATE_RULE,
  GET_RULES,
  UPDATE_RULE,
  GET_RULE,
  DELETE_RULE,
  UPDATE_RULES_ORDER,
} from '../constants/rule'

const ruleReducer = (state = RULE_INIT, action) => {
  switch (action.type) {
    // CREATE_RULE
    case CREATE_RULE.REQUEST:
      return {
        ...state,
        createRuleRequesting: true,
      }

    case CREATE_RULE.SUCCESS:
      return {
        ...state,
        createRuleSuccess: true,
        createRuleRequesting: false,
      }

    case CREATE_RULE.ERROR:
      return {
        ...state,
        createRuleRequesting: false,
        createRuleErrorMessage: action.payload,
      }

    case CREATE_RULE.RESET:
      return {
        ...state,
        createRuleRequesting: false,
        createRuleSuccess: false,
        createRuleErrorMessage: '',
      }

    // GET_RULES
    case GET_RULES.REQUEST:
      return {
        ...state,
        getRulesRequesting: true,
      }

    case GET_RULES.SUCCESS:
      return {
        ...state,
        getRulesSuccess: true,
        getRulesRequesting: false,
        list: action.payload,
      }

    case GET_RULES.ERROR:
      return {
        ...state,
        getRulesRequesting: false,
        getRulesErrorMessage: action.payload,
      }

    case GET_RULES.RESET:
      return {
        ...state,
        getRulesRequesting: false,
        getRulesSuccess: false,
        getRulesErrorMessage: '',
      }

    // GET_RULE
    case GET_RULE.REQUEST:
      return {
        ...state,
        getRuleDetailRequesting: true,
      }

    case GET_RULE.SUCCESS:
      return {
        ...state,
        getRuleDetailSuccess: true,
        getRuleDetailRequesting: false,
        detail: action.payload,
      }

    case GET_RULE.ERROR:
      return {
        ...state,
        getRuleDetailRequesting: false,
        getRuleDetailErrorMessage: action.payload,
      }

    case GET_RULE.RESET:
      return {
        ...state,
        getRuleDetailRequesting: false,
        getRuleDetailSuccess: false,
        getRuleDetailErrorMessage: '',
      }

    // UPDATE_RULE
    case UPDATE_RULE.REQUEST:
      return {
        ...state,
        updateRuleRequesting: true,
      }

    case UPDATE_RULE.SUCCESS:
      return {
        ...state,
        updateRuleSuccess: true,
        updateRuleRequesting: false,
        detail: action.payload,
      }

    case UPDATE_RULE.ERROR:
      return {
        ...state,
        updateRuleRequesting: false,
        updateRuleErrorMessage: action.payload,
      }

    case UPDATE_RULE.RESET:
      return {
        ...state,
        updateRuleRequesting: false,
        updateRuleSuccess: false,
        updateRuleErrorMessage: '',
      }

    // DELETE_RULE
    case DELETE_RULE.REQUEST:
      return {
        ...state,
        deleteRuleRequesting: true,
      }

    case DELETE_RULE.SUCCESS:
      return {
        ...state,
        deleteRuleSuccess: true,
        deleteRuleRequesting: false,
      }

    case DELETE_RULE.ERROR:
      return {
        ...state,
        deleteRuleRequesting: false,
        deleteRuleErrorMessage: action.payload,
      }

    case DELETE_RULE.RESET:
      return {
        ...state,
        deleteRuleRequesting: false,
        deleteRuleSuccess: false,
        deleteRuleErrorMessage: '',
      }

    // UPDATE_RULES_ORDER
    case UPDATE_RULES_ORDER.REQUEST:
      return {
        ...state,
        updateRulesRequesting: true,
      }

    case UPDATE_RULES_ORDER.SUCCESS:
      return {
        ...state,
        updateRulesSuccess: true,
        updateRulesRequesting: false,
        list: action.payload,
      }

    case UPDATE_RULES_ORDER.ERROR:
      return {
        ...state,
        updateRulesRequesting: false,
        updateRulesErrorMessage: action.payload,
      }

    case UPDATE_RULES_ORDER.RESET:
      return {
        ...state,
        updateRulesRequesting: false,
        updateRulesSuccess: false,
        updateRulesErrorMessage: '',
      }

    default:
      return state
  }
}

export default ruleReducer
